import {
  ACTIONS,
  ADMIN_ACTIONS,
  APP_ACTIONS,
  CDR_ACTIONS,
  LINK_ACTIONS, OPERATIONS,
  OPS_ACTIONS,
  PARAMETERS_ACTIONS,
  VERSIONS_ACTIONS,
} from "../actions/constants";
import {
  readInstanceIdFromStorage,
  readTokenFromStorage,
  readTokenSSEFromStorage,
  removeTokenFromStorage,
  removeTokenSSEFromStorage,
  writeInstanceIdToStorage,
  writeSSETokenToStorage,
  writeTokenToStorage,
} from "../modules/authentification";
import {log} from "../modules/logger";
import {changeInstanceId} from "../modules/realtime";

const moduleName = "red-app";

export const CALL_STATE = {
  IDLE: 0,
  IN_PROGRESS: 10,
  FAILED_AUTHORIZATION: 19,
  AUTHORIZED: 20,
  FAILED_CANDIDATE: 29,
  CANDIDATED: 30,
  FAILED_CONNECTED: 39,
  CONNECTED: 40,
  FAILED_CALLED: 49,
  CALLED: 50,
  ENDED: 60,
};

export const USER_STATE = {
  NOT_SIGNED: "NOT_SIGNED",
  SIGNIN_IN_PROGRESS: "SIGNIN_IN_PROGRESS",
  SIGNED: "SIGNED",
  NOT_SIGNED_ERROR: "NOT_SIGNED_ERROR",
};

const initialAppState = {
  coturnStatus: null,
  redisStatus: null,
  restStatus: null,
  mongoDBStatus: null,
  nodeStatus: null,
  statusInProgress: false,

  callInProgress: false,
  callState: CALL_STATE.IDLE,
  elapsedTime: null,
  address: null,

  statsRTT: [],
  statsRTTVideo: [],
  statsJitter: [],
  statsJitterVideo: [],
  statsMOSEModel: [],
  statsLostAudio: [],
  statsLostVideo: [],
  statsIncomingBwt: [],
  statsIncomingReceived: [],
  statsOutgoingBwt: [],
  statsOutgoingSent: [],
  finalRTT: {},
  finalRTTVideo: {},
  finalJitter: {},
  finalJitterVideo: {},
  finalMOSEModel: {},
  finalBitrateOut: {},
  finalBitrateIn: {},
  finalTrafficIn: {},
  finalTrafficOut: {},
  finalPacketsLostAudio: {},
  finalPacketsLostVideo: {},

  updateProfileInProgress: false,
  user: null,
  token: readTokenFromStorage(),
  sseToken: readTokenSSEFromStorage(),

  userState: readTokenFromStorage()
    ? USER_STATE.SIGNIN_IN_PROGRESS
    : USER_STATE.NOT_SIGNED,

  calls: [],
  listCallsInProgress: false,
  callsCount: 0,
  callsPage: 0,
  callsPerPage: 0,

  trafficCalls: [],
  listTrafficCallsInProgress: false,

  trafficCallsBytes: [],
  listTrafficCallsBytesInProgress: false,

  listSettingsInProgress: false,
  updateSettingsInProgress: false,
  generateKeysInProgress: false,
  deleteKeysInProgress: false,
  settings: [],
  listParametersInProgress: false,
  parameters: {},
  externalToken: null,

  listInstancesInProgress: false,
  addNewInstanceInProgress: false,
  removeInstanceInProgress: false,
  refreshInstanceInProgress: false,
  updateInstanceInProgress: false,
  updateServiceFrequencyInProgress: false,
  updateTurnInProgress: false,
  instances: [],
  currentInstance: null,

  listEventsInProgress: false,
  events: [],
  eventsCount: 0,
  eventsPage: 0,
  eventsPerPage: 0,

  listOutagesInProgress: false,
  outages: [],
  outagesCount: 0,
  outagesPage: 0,
  outagesPerPage: 0,
  getOutagesForHostInProgress: false,
  outagesForHost: [],
  degradationsForHost: [],
  healthChecksForHost: [],

  slaLastMonth: [],
  slaInProgress: false,

  listSeriesInProgress: false,
  series: [],
  listSeriesForAllInstancesProgress: false,
  seriesForAllInstances: [],
  timelineSeriesInProgress: false,
  timelineSeries: [],

  listSeriesTestingInProgress: false,
  testingSeries: [],
  pagedSeriesInProgress: false,
  pagedSeries: [],
  pagedSeriesPerPage: 25,
  pagedSeriesPage: 0,
  pagedSeriesCount: 0,

  summary: {},
  summaryHealth: {},
  summaryTests: {},
  summaryStatus: [],
  summaryInProgress: false,
  overallSummaryStatus: [],
  overallSummaryInProgress: false,
  lastUsageInProgress: false,
  lastUsage: {},
  todayInProgress: false,
  today: {},

  testSSHInProgress: false,
  testSSHResult: null,

  testHealthInProgress: false,
  testHealthResult: null,

  firstTimeUserLoaded: false,
  firstTimeUserAndInstanceLoaded: false,

  updateLinkInProgress: false,
  links: [],

  listCdrInProgress: false,
  cdr: [],

  versions: [],
  allVersions: [],
  getVersionInProgress: false,

  // admin
  metrics: null,
  getMetricsInProgress: false,
  listUsersInProgress: false,
  users: [],
  usersCount: 0,
  usersPage: 0,
  usersPerPage: 10,
  createUserInProgress: false,

  configurationInProgress: false,
  configuration: null,

  launchServiceInProgress: false,
  launchServiceName: null,
  launchServiceDetails: null,

  getJobsInProgress: false,
  jobs: null,

  sendCommandInProgress: false,
  sendCommandResult: 0,

  customizationInProgress: false,
  customization: null,

  ops: 0,
  opsInProgress: false,

  operations: [],
  pagedOperations: [],
  operationsCount: 0,
  operationsPage: 0,
  operationsPerPage: 20,
  currentOperation: null,
  operationsInProgress: false,
  operationsPagedInProgress: false,
};

const updateAnInstance = (instances, updatedInstance) => {
  const updatedInstances = instances.map((item) => {
    if (item._id === updatedInstance._id) {
      return {...updatedInstance};
    }
    return item;
  });

  return updatedInstances;
};

const removeAnInstance = (instances, instanceIdToRemove) => {
  return instances.filter((instance) => instance._id !== instanceIdToRemove);
};

const findAnInstanceIndex = (instances, instanceIdToFind) => {
  return instances.findIndex((instance) => instance._id === instanceIdToFind);
};

const appReducer = (state = initialAppState, action) => {
  log(moduleName, "reduce", {action: action.type, data: action.payload});

  switch (action.type) {
    case APP_ACTIONS.USER_DATA_LOADING_FAILED:
    case APP_ACTIONS.USER_DATA_LOADING_SUCCESS:
      return {...state, firstTimeUserLoaded: true};

    case APP_ACTIONS.USER_INSTANCE_DATA_LOADING_SUCCESS:
      return {...state, firstTimeUserAndInstanceLoaded: true};

    case APP_ACTIONS.GET_CUSTOMIZATION_IN_PROGRESS:
      return {
        ...state,
        customizationInProgress: true,
      };
    case APP_ACTIONS.GET_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        customizationInProgress: false,
        customization: action.payload.value,
      };
    case APP_ACTIONS.GET_CUSTOMIZATION_FAILED:
      return {
        ...state,
        customizationInProgress: false,
      };

    case ACTIONS.CALL_IN_PROGRESS:
      return {
        ...state,
        elapsedTime: null,
        address: null,
        callInProgress: true,
        callState: CALL_STATE.IN_PROGRESS,
      };
    case ACTIONS.CALL_AUTHORIZATION_FAILED:
      return {
        ...state,
        callInProgress: false,
        callState: CALL_STATE.FAILED_AUTHORIZATION,
      };
    case ACTIONS.CALL_AUTHORIZATION_SUCCESS:
      return {...state, callState: CALL_STATE.AUTHORIZED};
    case ACTIONS.CALL_CANDIDATE_FAILED:
      return {
        ...state,
        callInProgress: false,
        callState: CALL_STATE.FAILED_CANDIDATE,
      };
    case ACTIONS.CALL_CANDIDATE_SUCCESS:
      return {...state, callState: CALL_STATE.CANDIDATED};
    case ACTIONS.CALL_ICE_FAILED:
      return {
        ...state,
        callInProgress: false,
        callState: CALL_STATE.FAILED_CONNECTED,
      };
    case ACTIONS.CALL_ICE_SUCCESS:
      return {...state, callState: CALL_STATE.CONNECTED};
    case ACTIONS.VIDEO_FAILED:
      return {
        ...state,
        callInProgress: false,
        callState: CALL_STATE.FAILED_CALLED,
      };
    case ACTIONS.VIDEO_SUCCESS:
      return {
        ...state,
        callState: CALL_STATE.CALLED,
        elapsedTime: action.payload.value.elapsedTime,
      };
    case ACTIONS.VIDEO_ADDRESS:
      return {
        ...state,
        address: action.payload.value.address,
      };
    case ACTIONS.TERMINATE_CALL:
      if (action.payload.value.withError) {
        return {...state, callInProgress: false};
      }
      return {...state, callInProgress: false, callState: CALL_STATE.ENDED};
    case ACTIONS.RESET_CALL:
      return {
        ...state,
        callState: CALL_STATE.IDLE,
        statsRTT: [],
        statsRTTVideo: [],
        statsJitter: [],
        statsJitterVideo: [],
        statsMOSEModel: [],
        statsLostAudio: [],
        statsLostVideo: [],
        statsIncomingBwt: [],
        statsIncomingReceived: [],
        statsOutgoingBwt: [],
        statsOutgoingSent: [],
        finalRTT: {},
        finalRTTVideo: {},
        finalJitter: {},
        finalJitterVideo: {},
        finalMOSEModel: {},
        finalBitrateOut: {},
        finalBitrateIn: {},
        finalTrafficIn: {},
        finalTrafficOut: {},
        finalPacketsLostAudio: {},
        finalPacketsLostVideo: {},
      };

    case ACTIONS.GOT_STATS_SENDER:
      let nStatsRTT = state.statsRTT;
      if (action.payload.value.rtt) {
        nStatsRTT = [
          ...state.statsRTT,
          {x: action.payload.value.timestamp, y: action.payload.value.rtt},
        ];
      }

      let nStatsRTTVideo = state.statsRTTVideo;
      if (action.payload.value.rttVideo) {
        nStatsRTTVideo = [
          ...state.statsRTTVideo,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.rttVideo,
          },
        ];
      }

      return {
        ...state,

        // Graph RTT
        statsRTT: nStatsRTT,
        statsRTTVideo: nStatsRTTVideo,

        // Graph Bandwidth outgoing
        statsOutgoingBwt: [
          ...state.statsOutgoingBwt,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.outgoingBwt,
          },
        ],
        statsOutgoingSent: [
          ...state.statsOutgoingSent,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.outgoingSent,
          },
        ],
      };

    case ACTIONS.GOT_STATS_RECEIVER:
      return {
        ...state,

        //Graph Jitter
        statsJitter: [
          ...state.statsJitter,
          {x: action.payload.value.timestamp, y: action.payload.value.jitter},
        ],
        statsJitterVideo: [
          ...state.statsJitterVideo,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.jitterVideo,
          },
        ],

        //Graph MOS
        statsMOSEModel: [
          ...state.statsMOSEModel,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.mos_emodel,
          },
        ],

        // Graph packets Lost
        statsLostAudio: [
          ...state.statsLostAudio,
          {x: action.payload.value.timestamp, y: action.payload.value.lost},
        ],
        statsLostVideo: [
          ...state.statsLostVideo,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.lostVideo,
          },
        ],

        // Graph Bandwidth Incoming
        statsIncomingBwt: [
          ...state.statsIncomingBwt,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.incomingBwt,
          },
        ],
        statsIncomingReceived: [
          ...state.statsIncomingReceived,
          {
            x: action.payload.value.timestamp,
            y: action.payload.value.incomingReceived,
          },
        ],
      };

    case ACTIONS.GOT_STATS_FINAL_RECEIVER:
      return {
        ...state,
        finalJitter: action.payload.value.jitterAudio,
        finalJitterVideo: action.payload.value.jitterVideo,
        finalMOSEModel: action.payload.value.mos_emodel,
        finalBitrateIn: action.payload.value.bitrateIn,
        finalTrafficIn: action.payload.value.trafficIn,
        finalPacketsLostAudio: action.payload.value.packetsLostAudio,
        finalPacketsLostVideo: action.payload.value.packetsLostVideo,
      };
    case ACTIONS.GOT_STATS_FINAL_SENDER:
      return {
        ...state,
        finalRTT: action.payload.value.rttAudio,
        finalRTTVideo: action.payload.value.rttVideo,
        finalBitrateOut: action.payload.value.bitrateOut,
        finalTrafficOut: action.payload.value.trafficOut,
      };
    case ACTIONS.LOGIN_IN_PROGRESS:
    case ACTIONS.LOGIN_TOKEN_IN_PROGRESS:
      return {...state, userState: USER_STATE.SIGNIN_IN_PROGRESS};
    case ACTIONS.LOGIN_FAILED:
    case ACTIONS.LOGIN_TOKEN_FAILED:
      removeTokenFromStorage();
      removeTokenSSEFromStorage();
      return {
        ...state,
        userState: USER_STATE.NOT_SIGNED_ERROR,
        user: null,
        token: null,
        sseToken: null,
      };
    case ACTIONS.LOGIN_SUCCESS:
    case ACTIONS.LOGIN_TOKEN_SUCCESS:
      const token = action.payload.value.token;
      const sseToken = action.payload.value.sseToken;
      writeTokenToStorage(token);
      writeSSETokenToStorage(sseToken);
      return {
        ...state,
        userState: USER_STATE.SIGNED,
        user: action.payload.value.user,
        token,
        sseToken,
      };
    case ACTIONS.LOGOUT:
      removeTokenFromStorage();
      removeTokenSSEFromStorage();
      return {
        ...initialAppState,
        token: "",
        sseToken: "",
        userState: USER_STATE.NOT_SIGNED,
      };
    case ACTIONS.LOGIN_FAILED_RESET:
      return {...state, userState: USER_STATE.NOT_SIGNED};

    case ACTIONS.LIST_CALLS_IN_PROGRESS:
      return {...state, listCallsInProgress: true};
    case ACTIONS.LIST_CALLS_FAILED:
      return {
        ...state,
        listCallsInProgress: false,
        calls: [],
        callsPage: 0,
        callsTotal: 0,
      };
    case ACTIONS.LIST_CALLS_SUCCESS:
      return {
        ...state,
        listCallsInProgress: false,
        calls: action.payload.value.calls,
        callsPage: action.payload.value.page,
        callsCount: action.payload.value.count,
        callsPerPage: action.payload.value.perPage,
      };
    case ACTIONS.GET_TRAFFIC_CALLS_IN_PROGRESS:
      return {...state, listTrafficCallsInProgress: true};
    case ACTIONS.GET_TRAFFIC_CALLS_FAILED:
      return {...state, listTrafficCallsInProgress: false, trafficCalls: []};
    case ACTIONS.GET_TRAFFIC_CALLS_SUCCESS:
      return {
        ...state,
        listTrafficCallsInProgress: false,
        trafficCalls: action.payload.value.traffic,
      };
    case ACTIONS.GET_TRAFFIC_CALLS_BYTES_IN_PROGRESS:
      return {...state, listTrafficCallsBytesInProgress: true};
    case ACTIONS.GET_TRAFFIC_CALLS_BYTES_FAILED:
      return {
        ...state,
        listTrafficCallsBytesInProgress: false,
        trafficCallsBytes: [],
      };
    case ACTIONS.GET_TRAFFIC_CALLS_BYTES_SUCCESS:
      return {
        ...state,
        listTrafficCallsBytesInProgress: false,
        trafficCallsBytes: action.payload.value.traffic,
      };
    case ACTIONS.LIST_SETTINGS_IN_PROGRESS:
      return {...state, listSettingsInProgress: true};
    case ACTIONS.LIST_SETTINGS_FAILED:
      return {...state, listSettingsInProgress: false, settings: []};
    case ACTIONS.LIST_SETTINGS_SUCCESS:
      return {
        ...state,
        listSettingsInProgress: false,
        settings: action.payload.value.settings,
      };
    case ACTIONS.GENERATE_KEYS_IN_PROGRESS:
      return {...state, generateKeysInProgress: true};
    case ACTIONS.GENERATE_KEYS_FAILED:
      return {...state, generateKeysInProgress: false};
    case ACTIONS.GENERATE_KEYS_SUCCESS:
      return {
        ...state,
        generateKeysInProgress: false,
        settings: action.payload.value.settings,
      };
    case ACTIONS.DELETE_KEYS_IN_PROGRESS:
      return {...state, deleteKeysInProgress: true};
    case ACTIONS.DELETE_KEYS_FAILED:
      return {...state, deleteKeysInProgress: false};
    case ACTIONS.DELETE_KEYS_SUCCESS:
      return {
        ...state,
        deleteKeysInProgress: false,
        settings: action.payload.value.settings,
      };
    case ACTIONS.UPDATE_SETTINGS_FAILED:
      return {...state, updateSettingsInProgress: false};
    case ACTIONS.UPDATE_SETTINGS_IN_PROGRESS:
      return {...state, updateSettingsInProgress: true};
    case ACTIONS.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        updateSettingsInProgress: false,
        settings: action.payload.value.settings,
      };
    case ACTIONS.LIST_INSTANCES_IN_PROGRESS:
      return {...state, listInstancesInProgress: true};
    case ACTIONS.LIST_INSTANCES_FAILED:
      return {...state, listInstancesInProgress: false, instances: []};
    case ACTIONS.LIST_INSTANCES_SUCCESS:
      const instances = action.payload.value.instances;
      let currentInstance = state.currentInstance;
      if (!currentInstance) {
        const oldInstanceId = readInstanceIdFromStorage();
        if (oldInstanceId) {
          currentInstance = instances.find(
            (instance) => instance._id === oldInstanceId,
          );
        }
      }

      if (currentInstance) {
        changeInstanceId(currentInstance._id);
      } else {
      }

      return {
        ...state,
        listInstancesInProgress: false,
        firstTimeUserAndInstanceLoaded: !currentInstance
          ? true
          : state.firstTimeUserAndInstanceLoaded,
        instances,
        currentInstance,
      };
    case ACTIONS.ADD_INSTANCE_IN_PROGRESS:
      return {...state, addNewInstanceInProgress: true};
    case ACTIONS.ADD_INSTANCE_FAILED:
      return {...state, addNewInstanceInProgress: false};
    case ACTIONS.ADD_INSTANCE_SUCCESS:
      return {
        ...state,
        addNewInstanceInProgress: false,
        instances: action.payload.value.instances,
        currentInstance: action.payload.value.instance,
      };
    case ACTIONS.REMOVE_INSTANCE_IN_PROGRESS:
      return {...state, removeInstanceInProgress: true};
    case ACTIONS.REMOVE_INSTANCE_FAILED:
      return {...state, removeInstanceInProgress: false};
    case ACTIONS.REMOVE_INSTANCE_SUCCESS:
      const instanceId = action.payload.value.instanceId;
      const index = findAnInstanceIndex(state.instances, instanceId);
      const newInstances = removeAnInstance(state.instances, instanceId);
      return {
        ...state,
        removeInstanceInProgress: false,
        instances: newInstances,
        currentInstance: null,
      };
    case ACTIONS.SET_CURRENT_INSTANCE:
      writeInstanceIdToStorage(action.payload.value.instance?._id || null);
      const hasChanged =
        state.currentInstance?._id !== action.payload.value.instance?._id;
      if (hasChanged && action.payload.value.instance) {
        return {
          ...state,
          currentInstance: action.payload.value.instance,
          //firstTimeUserAndInstanceLoaded: false,
        };
      } else {
        return {
          ...state,
          currentInstance: action.payload.value.instance,
        };
      }
    case ACTIONS.GET_INSTANCE_IN_PROGRESS:
      return {...state, refreshInstanceInProgress: true};
    case ACTIONS.GET_INSTANCE_FAILED:
      return {...state, refreshInstanceInProgress: false};
    case ACTIONS.GET_INSTANCE_SUCCESS:
      const updatedGetInstance = action.payload.value.instance;
      return {
        ...state,
        refreshInstanceInProgress: false,
        currentInstance:
          !state.currentInstance ||
          updatedGetInstance._id === state.currentInstance._id
            ? updatedGetInstance
            : state.currentInstance,
        instances: updateAnInstance(state.instances, updatedGetInstance),
      };
    case ACTIONS.UPDATE_INSTANCE_IN_PROGRESS:
      return {...state, updateInstanceInProgress: true};
    case ACTIONS.UPDATE_INSTANCE_FAILED:
      return {...state, updateInstanceInProgress: false};
    case ACTIONS.UPDATE_INSTANCE_SUCCESS:
      const updatedInstance = action.payload.value.instance;
      return {
        ...state,
        updateInstanceInProgress: false,
        currentInstance:
          updatedInstance._id === state.currentInstance._id
            ? updatedInstance
            : state.currentInstance,
        instances: updateAnInstance(
          state.instances,
          action.payload.value.instance,
        ),
      };
    case ACTIONS.UPDATE_FREQUENCY_IN_PROGRESS:
      return {...state, updateServiceFrequencyInProgress: true};
    case ACTIONS.UPDATE_FREQUENCY_FAILED:
      return {...state, updateServiceFrequencyInProgress: false};
    case ACTIONS.UPDATE_FREQUENCY_SUCCESS: {
      const updatedInstance = action.payload.value.instance;
      return {
        ...state,
        updateServiceFrequencyInProgress: false,
        currentInstance:
          updatedInstance._id === state.currentInstance._id
            ? updatedInstance
            : state.currentInstance,
        instances: updateAnInstance(
          state.instances,
          action.payload.value.instance,
        ),
      };
    }
    case ACTIONS.MONITOR_INSTANCE_IN_PROGRESS:
    case ACTIONS.UNMONITOR_INSTANCE_IN_PROGRESS:
      return {...state, updateInstanceInProgress: true};
    case ACTIONS.UNMONITOR_INSTANCE_FAILED:
    case ACTIONS.MONITOR_INSTANCE_FAILED:
      return {...state, updateInstanceInProgress: false};
    case ACTIONS.MONITOR_INSTANCE_SUCCESS:
    case ACTIONS.UNMONITOR_INSTANCE_SUCCESS:
      return {
        ...state,
        updateInstanceInProgress: false,
        currentInstance: action.payload.value.instance,
        instances: updateAnInstance(
          state.instances,
          action.payload.value.instance,
        ),
      };
    case ACTIONS.START_COTURN_IN_PROGRESS:
    case ACTIONS.STOP_COTURN_IN_PROGRESS:
      return {...state, updateTurnInProgress: true};
    case ACTIONS.STOP_COTURN_FAILED:
    case ACTIONS.START_COTURN_FAILED:
      return {...state, updateTurnInProgress: false};
    case ACTIONS.STOP_COTURN_SUCCESS:
    case ACTIONS.START_COTURN_SUCCESS:
      return {
        ...state,
        updateTurnInProgress: false,
        currentInstance: action.payload.value.instance,
        instances: updateAnInstance(
          state.instances,
          action.payload.value.instance,
        ),
      };
    case ACTIONS.LIST_EVENTS_IN_PROGRESS:
      return {...state, listEventsInProgress: true};
    case ACTIONS.LIST_EVENTS_FAILED:
      return {...state, listEventsInProgress: false, events: []};
    case ACTIONS.LIST_EVENTS_SUCCESS:
      return {
        ...state,
        listEventsInProgress: false,
        events: action.payload.value.events,
        eventsPage: action.payload.value.page,
        eventsCount: action.payload.value.count,
        eventsPerPage: action.payload.value.perPage,
      };
    case ACTIONS.LIST_OUTAGES_IN_PROGRESS:
      return {...state, listOutagesInProgress: true};
    case ACTIONS.LIST_OUTAGES_FAILED:
      return {...state, listOutagesInProgress: false, outages: []};
    case ACTIONS.LIST_OUTAGES_SUCCESS:
      return {
        ...state,
        listOutagesInProgress: false,
        outages: action.payload.value.outages,
        outagesPage: action.payload.value.page,
        outagesCount: action.payload.value.count,
        outagesPerPage: action.payload.value.perPage,
      };
    case ACTIONS.GET_OUTAGES_FOR_INSTANCE_IN_PROGRESS:
      return {...state, getOutagesForHostInProgress: true};
    case ACTIONS.GET_OUTAGES_FOR_INSTANCE_FAILED:
      return {
        ...state,
        getOutagesForHostInProgress: false,
        outagesForHost: [],
        degradationsForHost: [],
        healthChecksForHost: [],
      };
    case ACTIONS.GET_OUTAGES_FOR_INSTANCE_SUCCESS:
      return {
        ...state,
        getOutagesForHostInProgress: false,
        outagesForHost: action.payload.value.outages,
        degradationsForHost: action.payload.value.degradations,
        healthChecksForHost: action.payload.value.healthChecks,
      };
    case ACTIONS.GET_SERIES_FOR_INSTANCE_IN_PROGRESS:
      return {...state, listSeriesInProgress: true};
    case ACTIONS.GET_SERIES_FOR_INSTANCE_SUCCESS:
      return {
        ...state,
        listSeriesInProgress: false,
        series: action.payload.value.series,
      };
    case ACTIONS.GET_SERIES_FOR_INSTANCE_FAILED:
      return {...state, listSeriesInProgress: false, series: []};

    case ACTIONS.GET_TIMELINE_FOR_INSTANCE_IN_PROGRESS:
      return {...state, timelineSeriesInProgress: true};
    case ACTIONS.GET_TIMELINE_FOR_INSTANCE_SUCCESS:
      return {
        ...state,
        timelineSeriesInProgress: false,
        timelineSeries: action.payload.value.timeline,
      };
    case ACTIONS.GET_TIMELINE_FOR_INSTANCE_FAILED:
      return {...state, timelineSeriesInProgress: false, timelineSeries: []};

    case ACTIONS.GET_SERIES_FOR_USER_IN_PROGRESS:
      return {
        ...state,
        listSeriesForAllInstancesProgress: true,
      };
    case ACTIONS.GET_SERIES_FOR_USER_SUCCESS:
      return {
        ...state,
        listSeriesForAllInstancesProgress: false,
        seriesForAllInstances: action.payload.value.series.data,
      };
    case ACTIONS.GET_SERIES_FOR_USER_FAILED:
      return {
        ...state,
        listSeriesForAllInstancesProgress: false,
        seriesForAllInstances: [],
      };

    case ACTIONS.GET_PAGED_SERIES_FOR_INSTANCE_IN_PROGRESS:
      return {...state, pagedSeriesInProgress: true};
    case ACTIONS.GET_PAGED_SERIES_FOR_INSTANCE_SUCCESS:
      return {
        ...state,
        pagedSeriesInProgress: false,
        pagedSeries: action.payload.value.series,
        pagedSeriesCount: action.payload.value.series.count,
        pagedSeriesPerPage: action.payload.value.series.perPage,
        pagedSeriesPage: action.payload.value.series.page,
      };
    case ACTIONS.GET_PAGED_SERIES_FOR_INSTANCE_FAILED:
      return {...state, pagedSeriesInProgress: false, pagedSeries: []};

    case ACTIONS.GET_SERIES_TESTING_FOR_INSTANCE_IN_PROGRESS:
      return {...state, listSeriesTestingInProgress: true};
    case ACTIONS.GET_SERIES_TESTING_FOR_INSTANCE_SUCCESS:
      return {
        ...state,
        listSeriesTestingInProgress: false,
        testingSeries: action.payload.value.series.data,
      };
    case ACTIONS.GET_SERIES_TESTING_FOR_INSTANCE_FAILED:
      return {
        ...state,
        listSeriesTestingInProgress: false,
        testingSeries: [],
      };
    case ACTIONS.GET_SLA_SUCCESS:
      return {
        ...state,
        slaInProgress: false,
        slaLastMonth: action.payload.value.sla,
      };
    case ACTIONS.UPDATE_PROFILE_IN_PROGRESS:
      return {...state, updateProfileInProgress: true};
    case ACTIONS.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload.value.user,
        updateProfileInProgress: false,
      };
    case ACTIONS.UPDATE_PROFILE_FAILED:
      return {...state, updateProfileInProgress: false};

    case ACTIONS.GET_SUMMARY_FOR_INSTANCE_IN_PROGRESS:
      return {...state, summaryInProgress: true};
    case ACTIONS.GET_SUMMARY_FOR_INSTANCE_SUCCESS:
      return {
        ...state,
        summary: action.payload.value.summary,
        summaryHealth: action.payload.value.summaryHealth,
        summaryTests: action.payload.value.summaryTests,
        summaryStatus: action.payload.value.summaryStatus,
        summaryInProgress: false,
      };
    case ACTIONS.GET_SUMMARY_FOR_INSTANCE_FAILED:
      return {...state, summaryInProgress: false};

    case ACTIONS.GET_SUMMARY_OVERALL_IN_PROGRESS:
      return {
        ...state,
        overallSummaryInProgress: true,
      };
    case ACTIONS.GET_SUMMARY_OVERALL_SUCCESS:
      return {
        ...state,
        overallSummaryInProgress: false,
        overallSummaryStatus: action.payload.value.summaryStatus,
      };
    case ACTIONS.GET_SUMMARY_OVERALL_FAILED:
      return {
        ...state,
        overallSummaryInProgress: false,
        overallSummaryStatus: [],
      };

    case ACTIONS.GET_LAST_USAGE_HOSTS_IN_PROGRESS:
      return {
        ...state,
        lastUsageInProgress: true,
      };
    case ACTIONS.GET_LAST_USAGE_HOSTS_SUCCESS:
      return {
        ...state,
        lastUsageInProgress: false,
        lastUsage: action.payload.value.lastUsage,
      };
    case ACTIONS.GET_LAST_USAGE_HOSTS_FAILED:
      return {
        ...state,
        lastUsageInProgress: false,
      };

    case ACTIONS.GET_TODAY_STATUS_HOSTS_IN_PROGRESS:
      return {
        ...state,
        todayInProgress: true,
      };
    case ACTIONS.GET_TODAY_STATUS_HOSTS_SUCCESS:
      return {
        ...state,
        todayInProgress: false,
        today: action.payload.value.today,
      };
    case ACTIONS.GET_TODAY_STATUS_HOSTS_FAILED:
      return {
        ...state,
        todayInProgress: false,
      };

    case ACTIONS.TEST_SSH_IN_PROGRESS:
      return {...state, testSSHInProgress: true, testSSHResult: null};
    case ACTIONS.TEST_SSH_SUCCESS:
      return {...state, testSSHInProgress: false, testSSHResult: "ok"};
    case ACTIONS.TEST_SSH_FAILED:
      return {
        ...state,
        testSSHInProgress: false,
        testSSHResult: action.payload.value,
      };
    case ACTIONS.TEST_SSH_RESET:
      return {...state, testSSHResult: null};

    case ACTIONS.TEST_HEALTH_IN_PROGRESS:
      return {...state, testHealthInProgress: true, testHealthResult: null};
    case ACTIONS.TEST_HEALTH_SUCCESS:
      return {...state, testHealthInProgress: false, testHealthResult: "ok"};
    case ACTIONS.TEST_HEALTH_FAILED:
      return {
        ...state,
        testHealthInProgress: false,
        testHealthResult: action.payload.value,
      };
    case ACTIONS.TEST_HEALTH_RESET:
      return {...state, testHealthResult: null};

    case ACTIONS.TURN_ACTION_IN_PROGRESS:
      return {...state, configurationInProgress: true};
    case ACTIONS.TURN_ACTION_SUCCESS:
      return {
        ...state,
        configurationInProgress: false,
        configuration: action.payload.value.configuration,
      };
    case ACTIONS.TURN_ACTION_FAILED: {
      return {...state, configurationInProgress: false, configuration: null};
    }

    case ACTIONS.LAUNCH_SERVICE_IN_PROGRESS:
      return {
        ...state,
        launchServiceInProgress: true,
        launchServiceName: action.payload.value,
      };
    case ACTIONS.LAUNCH_SERVICE_FAILED:
      return {
        ...state,
        launchServiceInProgress: false,
        launchServiceName: action.payload.value.service,
        launchServiceDetails: action.payload.value.details,
      };
    case ACTIONS.LAUNCH_SERVICE_SUCCESS:
      return {
        ...state,
        launchServiceInProgress: false,
        launchServiceName: null,
        launchServiceDetails: null,
      };
    case ACTIONS.LAUNCH_SERVICE_RESET:
      return {
        ...state,
        launchServiceInProgress: false,
        launchServiceName: null,
        launchServiceDetails: null,
      };

    case LINK_ACTIONS.GET_LINKS_SUCCESS:
      return {...state, links: action.payload.value};

    case LINK_ACTIONS.UPDATE_LINK_IN_PROGRESS:
      return {...state, updateLinkInProgress: true};
    case LINK_ACTIONS.UPDATE_LINK_TEST_IN_FAILED:
      return {...state, updateLinkInProgress: false};
    case LINK_ACTIONS.UPDATE_LINK_TEST_IN_SUCCESS:
      return {
        ...state,
        updateLinkInProgress: false,
        link: action.payload.value,
      };

    case PARAMETERS_ACTIONS.UPDATE_PARAMETERS_IN_PROGRESS:
      return {
        ...state,
        listParametersInProgress: true,
      };
    case PARAMETERS_ACTIONS.UPDATE_PARAMETER_SUCCESS:
      return {
        ...state,
        parameters: action.payload.value,
        listParametersInProgress: false,
      };
    case PARAMETERS_ACTIONS.UPDATE_PARAMETERS_FAILED:
      return {
        ...state,
        listParametersInProgress: false,
        parameters: {},
      };
    case PARAMETERS_ACTIONS.GET_PARAMETERS_IN_PROGRESS:
      return {
        ...state,
        listParametersInProgress: true,
      };
    case PARAMETERS_ACTIONS.GET_PARAMETERS_SUCCESS:
      return {
        ...state,
        listParametersInProgress: false,
        parameters: action.payload.value,
      };
    case PARAMETERS_ACTIONS.GET_PARAMETERS_FAILED:
      return {
        ...state,
        listSeriesTestingInProgress: false,
        parameters: {},
      };
    case PARAMETERS_ACTIONS.UPDATE_TOKEN_IN_PROGRESS:
      return {
        ...state,
        listParametersInProgress: true,
      };
    case PARAMETERS_ACTIONS.UPDATE_TOKEN_SUCCESS:
      return {
        ...state,
        listParametersInProgress: false,
        externalToken: action.payload.value.externalToken,
      };

    case CDR_ACTIONS.GET_CDR_IN_PROGRESS:
      return {
        ...state,
        listCdrInProgress: true,
      };
    case CDR_ACTIONS.GET_CDR_SUCCESS:
      return {
        ...state,
        listCdrInProgress: false,
        cdr: action.payload.value,
      };
    case CDR_ACTIONS.GET_CDR_FAILED:
      return {
        ...state,
        listCdrInProgress: false,
        cdr: 0,
      };

    case OPS_ACTIONS.GET_OPS_IN_PROGRESS:
      return {
        ...state,
        opsInProgress: true,
      };
    case OPS_ACTIONS.GET_OPS_SUCCESS:
      return {
        ...state,
        opsInProgress: false,
        ops: action.payload.value,
      };
    case OPS_ACTIONS.GET_OPS_FAILED:
      return {
        ...state,
        opsInProgress: false,
        ops: [],
      };

    case OPERATIONS.UPDATE_OPERATIONS_IN_PROGRESS:
    case OPERATIONS.CREATE_OPERATIONS_IN_PROGRESS:
    case OPERATIONS.GET_OPERATIONS_IN_PROGRESS:
      return {
        ...state,
        operationsInProgress: true,
      }

    case OPERATIONS.GET_OPERATIONS_PAGED_IN_PROGRESS:
      return {
        ...state,
        operationsPagedInProgress: true,
      }

    case OPERATIONS.GET_OPERATIONS_PAGED_SUCCESS:
      return {
        ...state,
        operationsPagedInProgress: false,
        pagedOperations: action.payload.value.operations,
        operationsCount: action.payload.value.count,
        operationsPage: action.payload.value.page,
        operationsPerPage: action.payload.value.perPage,
      }

    case OPERATIONS.GET_OPERATIONS_PAGED_FAILED:
      return {
        ...state,
        operationsPagedInProgress: false,
      }

    case OPERATIONS.GET_OPERATIONS_SUCCESS:
      return {
        ...state,
        operationsInProgress: false,
        operations: action.payload.value.operations,
      }

    case OPERATIONS.GET_OPERATIONS_FAILED:
      return {
        ...state,
        operationsInProgress: false,
      }

    case OPERATIONS.CREATE_OPERATIONS_SUCCESS:
      return {
        ...state,
        operationsInProgress: false,
        currentOperation: action.payload.value.currentOperation,
        pagedOperations: action.payload.value.operations,
        operationsCount: action.payload.value.count,
        operationsPage: action.payload.value.page,
        operationsPerPage: action.payload.value.perPage,
      }

    case OPERATIONS.CREATE_OPERATIONS_FAILED:
      return {
        ...state,
        operationsInProgress: false
      }

    case OPERATIONS.UPDATE_OPERATIONS_SUCCESS:
      return {
        ...state,
        operationsInProgress: false,
        currentOperation: action.payload.value.currentOperation,
        pagedOperations: action.payload.value.operations,
        operationsCount: action.payload.value.count,
        operationsPage: action.payload.value.page,
        operationsPerPage: action.payload.value.perPage,
      }

    case OPERATIONS.UPDATE_OPERATIONS_FAILED:
      return {
        ...state,
        operationsInProgress: false
      }

    case OPERATIONS.DELETE_OPERATIONS_IN_PROGRESS:
      return {
        ...state,
        operationsInProgress: false
      }
    case OPERATIONS.DELETE_OPERATIONS_SUCCESS:
      return {
        ...state,
        operationsInProgress: false,
        currentOperation: action.payload.value.currentOperation,
        pagedOperations: action.payload.value.operations,
        operationsCount: action.payload.value.count,
        operationsPage: action.payload.value.page,
        operationsPerPage: action.payload.value.perPage,
      }
    case OPERATIONS.DELETE_OPERATIONS_FAILED:
      return {
        ...state,
        operationsInProgress: false
      }

    case OPERATIONS.SET_CURRENT_OPERATION_SUCCESS:
      return {
        ...state,
        currentOperation: action.payload.value
      }

    case VERSIONS_ACTIONS.GET_LAST_VERSION_FOR_ALL_HOSTS_IN_PROGRESS:
      return {
        ...state,
        getVersionInProgress: true,
      };
    case VERSIONS_ACTIONS.GET_LAST_VERSION_FOR_ALL_HOSTS_SUCCESS:
      return {
        ...state,
        getVersionInProgress: false,
        versions: action.payload.value,
      };
    case VERSIONS_ACTIONS.GET_LAST_VERSION_FOR_ALL_HOSTS_FAILED:
      return {
        ...state,
        getVersionInProgress: false,
      };

    case VERSIONS_ACTIONS.GET_ALL_VERSIONS_FOR_HOST_IN_PROGRESS:
      return {
        ...state,
        getVersionInProgress: true,
      };
    case VERSIONS_ACTIONS.GET_ALL_VERSIONS_FOR_HOST_SUCCESS:
      return {
        ...state,
        getVersionInProgress: false,
        allVersions: action.payload.value,
      };
    case VERSIONS_ACTIONS.GET_ALL_VERSIONS_FOR_HOST_FAILED:
      return {
        ...state,
        getVersionInProgress: false,
      };

    case ADMIN_ACTIONS.GET_GLOBAL_METRICS_IN_PROGRESS:
      return {...state, getMetricsInProgress: true};
    case ADMIN_ACTIONS.GET_GLOBAL_METRICS_SUCCESS:
      return {
        ...state,
        getMetricsInProgress: false,
        metrics: action.payload.value,
      };
    case ADMIN_ACTIONS.GET_GLOBAL_METRICS_FAILED:
      return {...state, getMetricsInProgress: false, metrics: null};
    case ADMIN_ACTIONS.GET_USERS_IN_PROGRESS:
      return {...state, listUsersInProgress: true};
    case ADMIN_ACTIONS.GET_USERS_SUCCESS:
      return {
        ...state,
        listUsersInProgress: false,
        users: action.payload.value.accounts,
        eventsPage: action.payload.value.page,
        eventsCount: action.payload.value.count,
        usersPerPage: action.payload.value.perPage,
      };
    case ADMIN_ACTIONS.GET_USERS_FAILED:
      return {...state, listUsersInProgress: false, users: []};
    case ADMIN_ACTIONS.CREATE_USER_IN_PROGRESS:
      return {...state, createUserInProgress: true};
    case ADMIN_ACTIONS.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserInProgress: false,
        users: action.payload.value.accounts,
        eventsPage: action.payload.value.page,
        eventsCount: action.payload.value.count,
        usersPerPage: action.payload.value.perPage,
      };
    case ADMIN_ACTIONS.CREATE_USER_FAILED:
      return {...state, createUserInProgress: false};
    case ADMIN_ACTIONS.GET_JOBS_IN_PROGRESS:
      return {...state, getJobsInProgress: true};
    case ADMIN_ACTIONS.GET_JOBS_SUCCESS:
      return {...state, getJobsInProgress: false, jobs: action.payload.value};
    case ADMIN_ACTIONS.GET_JOBS_FAILED:
      return {...state, getJobsInProgress: false, jobs: null};
    case ADMIN_ACTIONS.SEND_COMMAND_IN_PROGRESS:
      return {...state, sendCommandInProgress: true, sendCommandResult: 0};
    case ADMIN_ACTIONS.SEND_COMMAND_SUCCESS:
      return {
        ...state,
        sendCommandInProgress: false,
        sendCommandResult: action.payload.value,
      };
    case ADMIN_ACTIONS.SEND_COMMAND_FAILED:
      return {
        ...state,
        sendCommandInProgress: false,
        sendCommandResult: action.payload.value,
      };

    default:
      return state;
  }
};

export {appReducer, initialAppState};
