const monitoringRoutes = [
  {
    path: "/host/:hostId/board",
    name: "Host",
    icon: "icon cafe-computer",
  },
  {
    path: "/host/:hostId/monitor",
    name: "Monitoring",
    icon: "icon cafe-configuration-2",
  },
  {
    path: "/host/:hostId/settings",
    name: "Settings",
    icon: "icon cafe-wrench",
  },
];

export default monitoringRoutes;
