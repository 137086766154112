import React, {useContext, useEffect, useState} from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Label,
  Form,
  FormGroup,
  Input,
  Spinner,
  Button,
} from "reactstrap";

import AppContext from "../contexts/appContext";
import ProfilePlaceHolder from "./placeholder/ProfilePlaceHolder";
import "./Parameters.css";
import {
  regenerateToken,
  updateParametersForUser,
} from "../actions/parametersActions";
import {generateUniqueID} from "../modules/helper";
import {getTokenExpirationDate} from "../modules/authentification";
import {DateTime} from "luxon";
import {copyToClipboard} from "../modules/clipboard";
import BreadCrumbCusto from "./BreadCrumb";

function Parameters({dispatch}) {
  const appState = useContext(AppContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [recipients, setRecipients] = useState(
    appState.parameters?.recipients || "",
  );
  const [hasNotificationsForReport, setHasNotificationsForReport] = useState(
    appState.parameters?.hasNotificationsForReport || false,
  );
  const [hasNotificationsForCDR, setHasNotificationsForCDR] = useState(
    appState.parameters?.hasNotificationsForCDR || false,
  );
  const [hasNotificationsForDetailedCDR, setHasNotificationsForDetailedCDR] =
    useState(appState.parameters?.hasNotificationsForDetailedCDR || false);
  const [hasNotificationsForAlerts, setHasNotificationsForAlerts] = useState(
    appState.parameters?.hasNotificationsForAlerts || false,
  );
  const [hasNotificationsForDegraded, setHasNotificationsForDegraded] = useState(
    appState.parameters?.hasNotificationsForDegraded || false,
  );
  const [hasExternalAccess, setHasExternalAccess] = useState(
    appState.parameters?.hasExternalAccess || false,
  );
  const [externalHost, setExternalHost] = useState(
    appState.parameters?.externalHost || "",
  );
  const [externalToken, setExternalToken] = useState(
    appState.parameters?.externalToken || "",
  );
  const [observationRetries, setObservationRetries] = useState(
    appState.parameters?.observationRetries || 3,
  );
  const [hasNotificationsForObservation, setHasNotificationsForObservation] = useState(
    appState.parameters?.hasNotificationsForObservation || false,
  );

  useEffect(() => {
    if (appState.parameters) {
      setHasExternalAccess(appState.parameters.hasExternalAccess || false);
      setExternalToken(appState.parameters.externalToken);
      setExternalHost(appState.parameters.externalHost);
      setHasNotificationsForReport(
        appState.parameters.hasNotificationsForReport || false,
      );
      setHasNotificationsForCDR(
        appState.parameters.hasNotificationsForCDR || false,
      );
      setHasNotificationsForDetailedCDR(
        appState.parameters.hasNotificationsForDetailedCDR || false,
      );
      setHasNotificationsForAlerts(
        appState.parameters.hasNotificationsForAlerts || false,
      );
      setHasNotificationsForDegraded(
        appState.parameters.hasNotificationsForDegraded || false,
      );
      setRecipients(appState.parameters.recipients);
      setObservationRetries(appState.parameters.observationRetries || 3);
      setHasNotificationsForObservation(appState.parameters.hasNotificationsForObservation || false);
      setHasChanges(false);
    }
  }, [appState.parameters]);

  useEffect(() => {
    if (appState.externalToken) {
      setExternalToken(appState.externalToken);
    }
  }, [appState.externalToken]);

  const saveChanges = async (e) => {
    e.preventDefault();
    await updateParametersForUser(
      appState.user._id,
      {
        hasExternalAccess,
        externalToken,
        externalHost,
        hasNotificationsForReport,
        hasNotificationsForCDR,
        hasNotificationsForDetailedCDR,
        hasNotificationsForAlerts,
        hasNotificationsForDegraded,
        recipients,
        observationRetries,
        hasNotificationsForObservation
      },
      appState.token,
      dispatch,
    );
  };

  const displayTokenExpirationDate = (token) => {
    if (!token) {
      return "";
    }
    const expirationDate = getTokenExpirationDate(token);
    if (!expirationDate) {
      return "";
    }

    return DateTime.fromMillis(expirationDate).toLocaleString(
      DateTime.DATE_FULL,
    );
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <ProfilePlaceHolder/>}
      {appState.firstTimeUserAndInstanceLoaded && (
        <>
          <BreadCrumbCusto
            dispatch={dispatch}
            instance={null}
            env={null}
            name={"parameters"}
          />

          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card>
                <CardHeader>
                  <h5 className="card-category">Recipients parameters</h5>
                  <CardTitle tag="h3">Emails</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#">
                    <Row>
                      <Col className="col-xxl-6 col-xl-6 col-lg-8 col-md-12 col-sm-12">
                        <Label>Recipients</Label>
                        <FormGroup>
                          <Input
                            type="text"
                            value={recipients}
                            onChange={(e) => {
                              setHasChanges(true);
                              setRecipients(e.currentTarget.value);
                            }}
                          />
                          <span className="form-description">
                            Addresses used for receiving emails
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-category">Reports parameters</h5>
                  <CardTitle tag="h3">Notifications</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#">
                    <Row>
                      <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <Label>Email Weekly Report</Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={hasNotificationsForReport}
                            onChange={(e) => {
                              setHasChanges(true);
                              setHasNotificationsForReport(
                                !hasNotificationsForReport,
                              );
                            }}
                          />
                          <Label check>Send Report</Label>
                          <br/>
                          <span className="form-description">
                            Email a weekly report summarizing the usage, tests,
                            and health check for all your hosts.
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <Label>Email Monthly Usage Report</Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={hasNotificationsForCDR}
                            onChange={(e) => {
                              setHasChanges(true);
                              setHasNotificationsForCDR(
                                !hasNotificationsForCDR,
                              );
                            }}
                          />
                          <Label check>Send Report</Label>
                          <br/>
                          <span className="form-description">
                            Email a monthly CSV report summarizing all usage
                            done per day for all your hosts
                          </span>
                        </FormGroup>
                      </Col>
                      <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <Label>Email Monthly Detailed MUR</Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={hasNotificationsForDetailedCDR}
                            onChange={(e) => {
                              setHasChanges(true);
                              setHasNotificationsForDetailedCDR(
                                !hasNotificationsForDetailedCDR,
                              );
                            }}
                          />
                          <Label check>Send Report</Label>
                          <br/>
                          <span className="form-description">
                            Email a monthly CSV report summarizing all services
                            done for all your hosts
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <br/>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-category">Alerts parameters</h5>
                  <CardTitle tag="h3">Alerts</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#">
                    <Row>
                      <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <Label>Email Alerts</Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={hasNotificationsForAlerts}
                            onChange={(e) => {
                              setHasChanges(true);
                              setHasNotificationsForAlerts(
                                !hasNotificationsForAlerts,
                              );
                            }}
                          />
                          <Label check>Send Alerts on host failure</Label>
                          <br/>
                          <span className="form-description">
                            Email an alert each time the status of a host
                            changes to 'In failure'.
                          </span>
                        </FormGroup>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            checked={hasNotificationsForDegraded}
                            onChange={(e) => {
                              setHasChanges(true);
                              setHasNotificationsForDegraded(
                                !hasNotificationsForDegraded,
                              );
                            }}
                          />
                          <Label check>Send Alerts on host degradation</Label>
                          <br/>
                          <span className="form-description">
                            Email an alert each time the status of a host
                            changes to 'degraded'.
                          </span>
                        </FormGroup>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            checked={hasNotificationsForObservation}
                            onChange={(e) => {
                              setHasChanges(true);
                              setHasNotificationsForObservation(
                                !hasNotificationsForObservation,
                              );
                            }}
                          />
                          <Label check>Send Alerts on host observation</Label>
                          <br/>
                          <span className="form-description">
                            Send alerts when the status of a host
                            moves to Observation.
                          </span>
                        </FormGroup>
                      </Col>
                      <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <Label>Observation Period</Label>
                        <FormGroup>
                          <Input
                            type="select"
                            style={{fontSize: "12px", maxWidth: "350px"}}
                            value={observationRetries}
                            onChange={(e) => {
                              setHasChanges(true);
                              setObservationRetries(Number(e.currentTarget.value));
                            }}
                          >
                            <option value={0}>No Observation period</option>
                            <option value={3}>Observe during 3 attempts - (at least 10s)</option>
                            <option value={5}>Observe during 5 attempts - (at least 30s)</option>
                            <option value={10}>Observe during 10 attempts - (at least 5 minutes)</option>
                          </Input>

                          <span className="form-description">
                            Number of service attempts for the observation period
                          </span>
                        </FormGroup>
                      </Col>

                    </Row>
                  </Form>
                </CardBody>
                <CardFooter/>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-category">Authorization parameters</h5>
                  <CardTitle tag="h3">External Access</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#">
                    <Row>
                      <Col className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4">
                        <Label>Activation</Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={hasExternalAccess}
                            onChange={(e) => {
                              setHasChanges(true);
                              setHasExternalAccess(!hasExternalAccess);
                              if (!hasExternalAccess && !externalToken) {
                                setExternalToken(generateUniqueID());
                              }
                            }}
                          />
                          <Label check>Authorize external access</Label>
                          <br/>
                          <span className="form-description">
                            Allow or not to access your data
                          </span>
                        </FormGroup>
                      </Col>

                      <Col className="col-xxl-4 col-xl-4 col-lg-3 col-md-4 col-sm-4">
                        <Label>Host</Label>
                        <FormGroup>
                          <Input
                            type="text"
                            value={externalHost}
                            onChange={(e) => {
                              setHasChanges(true);
                              setExternalHost(e.currentTarget.value);
                            }}
                          />
                          <span className="form-description">
                            IP address authorized (For S2S access)
                          </span>
                        </FormGroup>
                      </Col>
                      <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-4">
                        <Label>Token</Label>
                        <FormGroup>
                          <Input
                            type="text"
                            disabled={true}
                            value={hasExternalAccess ? externalToken : ""}
                            onChange={(e) => {
                              setHasChanges(true);
                              setExternalToken(e.currentTarget.value);
                            }}
                          />
                          <span className="form-description">
                            Token authorized (For C2S access - alternative to IP
                            address)
                          </span>
                        </FormGroup>
                        {hasExternalAccess && (
                          <div style={{display: "flex"}}>
                            <Button
                              className="mt-1"
                              size="sm"
                              color="warning"
                              onClick={async () => {
                                await regenerateToken(
                                  appState.user._id,
                                  appState.token,
                                  dispatch,
                                );
                              }}
                            >
                              Regenerate
                            </Button>
                            <Button
                              className="mt-1"
                              size="sm"
                              onClick={() => {
                                copyToClipboard(externalToken);
                              }}
                            >
                              Copy
                            </Button>
                            <span
                              className="form-description ml-2"
                              style={{lineHeight: "36px"}}
                            >
                              Expires:{" "}
                              {displayTokenExpirationDate(externalToken)}
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter/>
              </Card>

              <div className="my-5">
                <div style={{display: "flex"}}>
                  {!hasChanges && (
                    <p className="text-info ml-1" style={{flex: 1}}>
                      <i className="icon  cafe-bullet icon-save"/>
                      No pending changes
                    </p>
                  )}
                  {hasChanges && (
                    <p className="text-warning ml-1" style={{flex: 1}}>
                      <i className="icon cafe-change icon-save"/>
                      Some changes have been done
                    </p>
                  )}
                </div>
                <Button
                  color={hasChanges ? "primary" : "secondary"}
                  disabled={appState.listParametersInProgress}
                  onClick={(e) => saveChanges(e)}
                >
                  Save
                </Button>
                {appState.listParametersInProgress && (
                  <Spinner
                    children={null}
                    color="primary"
                    size="sm"
                    className="ml-2"
                  />
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Parameters;
