import {useState, useEffect, useRef} from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import {createChart, updateChart} from "../../modules/callCharts";
import "../testing/media.css";

function GlobalStatusChart({status, title, property}) {
  const chartStatusHealth = useRef();
  const [series, setSeries] = useState(
    property in status ? status[property] : [],
  );
  const [successSeries, setSuccessSeries] = useState([]);
  const [warningSeries, setWarningSeries] = useState([]);
  const [errorSeries, setErrorSeries] = useState([]);

  const changeSeries = (series) => {
    if (series.length > 0) {
      const successSeries = series.map((elt) => ({
        x: elt.created,
        y: elt.success,
      }));
      const warningSeries = series.map((elt) => ({
        x: elt.created,
        y: elt.warning,
      }));
      const errorSeries = series.map((elt) => ({
        x: elt.created,
        y: elt.error,
      }));

      setSuccessSeries(successSeries);
      setWarningSeries(warningSeries);
      setErrorSeries(errorSeries);
    }
  };

  useEffect(() => {
    if (successSeries.length > 0) {
      updateChart(
        successSeries,
        warningSeries,
        chartStatusHealth,
        2,
        60000,
        true,
        null,
        errorSeries,
      );
    }
  }, [successSeries, warningSeries, errorSeries]);

  useEffect(() => {
    changeSeries(series);
  }, [series]);

  useEffect(() => {
    if (status && property && property in status) {
      setSeries(status[property]);
      createChart(
        document.querySelector("#chartStatusHealth"),
        chartStatusHealth,
        "STATUS",
        {
          unit: "day",
        },
      );
    }
  }, [status, property]);

  return (
    <Card className="card">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="6">
            <h5 className="card-category">Last 100 days</h5>
            <CardTitle tag="h3">{title}</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <div className="chart-container-status">
              <canvas id="chartStatusHealth"></canvas>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default GlobalStatusChart;
