import React, { useRef, useState, useContext, useEffect } from "react";
import "chartjs-adapter-luxon";
import AppContext from "../../contexts/appContext";
import "./media.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
  Button,
  Table,
  Spinner,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { info } from "../../modules/logger";
import {
  launchCall,
  resetCall,
  terminateCall,
  failCall,
} from "../../actions/appActions";
import { CALL_STATE } from "../../reducers/appReducer";
import { getValueFromSetting } from "../../modules/helper";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  detectIceTypeFromUrl,
  detectTypeOfICETest,
  TEST_TYPE,
} from "../../modules/rtc";

const moduleName = "view:media";

let currentState = 0;
let toTest = "All transports";
let timeoutTest = true;

function Media({ dispatch, onCallReset, scoreFct }) {
  const appState = useContext(AppContext);
  const [testDone, setTestDone] = useState(false);
  const [testInProgress, setTestInProgress] = useStateWithCallbackLazy(false);
  const [instanceId, setInstanceId] = useState(
    appState.current && appState.currentInstance._id,
  );

  const localVideo = useRef();
  const remoteVideo = useRef();
  const intervalId = useRef();

  useEffect(() => {
    if (appState.callState === CALL_STATE.IDLE) {
      setTestDone(false);
    } else if (appState.callState % 2 === 1) {
      endCall(true);
    } else if (
      appState.callState === CALL_STATE.ENDED &&
      appState.finalMOSEModel?.avg
    ) {
      setTestDone(true);
    }
  }, [appState.callState, appState.finalMOSEModel]);

  useEffect(() => {
    currentState = appState.callState;
  }, [appState.callState]);

  useEffect(() => {
    if (
      appState.currentInstance &&
      appState.currentInstance._id !== instanceId
    ) {
      setInstanceId(appState.currentInstance._id);
      reset();
    }
  }, [appState.currentInstance]);

  const startCall = async () => {
    info(moduleName, "[click] call");
    if (testDone) {
      resetCall(dispatch);
      onCallReset();
      setTestDone(false);
    }
    intervalId.current = setTimeout(() => {
      // Automatically hangup the call after Xs
      info(
        moduleName,
        `[timer] auto-end current call in state ${appState.callState} and ${currentState}`,
      );
      if (currentState < 40) {
        failCall(dispatch);
      } else {
        terminateCall(false, dispatch);
      }
    }, 25000);

    const ICETransport = "all";
    const test_type = detectIceTypeFromUrl(toTest);

    launchCall(
      localVideo.current,
      remoteVideo.current,
      appState.settings,
      appState.currentInstance._id,
      test_type || TEST_TYPE.UNRESTRICTED,
      toTest,
      dispatch,
    );
  };

  const endCall = async (withError = false) => {
    info(
      moduleName,
      `[click] end call ${withError ? "with error" : "successfully"}`,
    );
    if (intervalId.current) {
      clearTimeout(intervalId.current);
    }
    terminateCall(withError, dispatch);
    setTestDone(true);
  };

  const getAuthorizationResult = (callState) => {
    if (callState >= CALL_STATE.AUTHORIZED) {
      return <span className="text-muted">PASSED</span>;
    } else {
      if (callState === CALL_STATE.FAILED_AUTHORIZATION) {
        return <span className="text-muted">FAILED</span>;
      }
    }
    return <span className="text-muted">N/A</span>;
  };

  const getRelayedCandidate = (callState) => {
    if (callState >= CALL_STATE.CANDIDATED) {
      return <span className="text-muted">PASSED</span>;
    } else {
      if (callState === CALL_STATE.FAILED_CANDIDATE) {
        return <span className="text-muted">FAILED</span>;
      }
    }
    return <span className="text-muted">N/A</span>;
  };

  const getIceState = (callState) => {
    if (callState >= CALL_STATE.CONNECTED) {
      return <span className="text-muted">PASSED</span>;
    } else {
      if (callState === CALL_STATE.FAILED_CONNECTED) {
        return <span className="text-muted">FAILED</span>;
      }
    }
    return <span className="text-muted">N/A</span>;
  };

  const getVideoState = (callState) => {
    if (callState >= CALL_STATE.CALLED) {
      return <span className="text-muted">{`PASSED`}</span>;
    } else {
      if (callState === CALL_STATE.FAILED_CONNECTED) {
        return <span className="text-muted">FAILED</span>;
      }
    }
    return <span className="text-muted">N/A</span>;
  };

  const getMetricsCollected = (callState) => {
    if (callState >= CALL_STATE.ENDED) {
      if (appState.finalMOSEModel?.avg) {
        return <span className="text-muted">{`PASSED`}</span>;
      } else {
        return <span className="text-muted">N/A</span>;
      }
    } else if (callState === CALL_STATE.FAILED_CONNECTED) {
      return <span className="text-muted">FAILED</span>;
    } else if (callState === CALL_STATE.CALLED) {
      return <span className="text-info">PENDING...</span>;
    }
    return <span className="text-muted">N/A</span>;
  };

  const reset = () => {
    currentState = 0;
    terminateCall(false, dispatch);
    setTestDone(false);
    resetCall(dispatch);
  };

  const getScoreIconColor = (score) => {
    if (!testDone) {
      return "";
    }
    if (score.includes("A")) {
      return "text-success";
    } else if (score.includes("B")) {
      return "text-warning";
    }
    return "text-danger";
  };

  const onTestsChange = (e) => {
    toTest = e.target.value;
    console.log(">>>TO Test", toTest);
  };

  const onTimeoutChange = (e) => {
    timeoutTest = e.target.checked;
  };

  const getOptionsForTest = () => {
    let list = ["All transports"];

    const STUNSettingURL = getValueFromSetting(
      "stunAddress",
      appState.settings,
      appState.currentInstance._id,
    );
    const TURNSettingURL = getValueFromSetting(
      "turnAddress",
      appState.settings,
      appState.currentInstance._id,
    );

    let stuns = [];
    let turns = [];
    if (STUNSettingURL) {
      stuns = STUNSettingURL.split(";");
    }
    if (TURNSettingURL) {
      turns = TURNSettingURL.split(";");
    }
    list = list.concat(stuns).concat(turns);

    return list.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };

  return (
    <Card className="card-stats">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="9">
            <h5 className="card-category">Testing</h5>
            <CardTitle tag="h3">Media</CardTitle>
          </Col>
          <Col className="text-right" sm="3">
            <h5 className="card-category">Score</h5>
            <CardTitle tag="h2" className={getScoreIconColor(scoreFct())}>
              {scoreFct()}
            </CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <div className="table-full-width">
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th className="text-left">Actions</th>
                    <th className="text-right">Result</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">
                      <span
                        className={
                          appState.callState === CALL_STATE.FAILED_AUTHORIZATION
                            ? "text-danger"
                            : appState.callState >= CALL_STATE.AUTHORIZED
                            ? "text-success"
                            : ""
                        }
                      >
                        Devices authorization
                      </span>
                    </td>
                    <td className="text-right">
                      {appState.callState >= CALL_STATE.AUTHORIZED && (
                        <i className="icon cafe-selected icon-selected text-success mr-2" />
                      )}
                      {getAuthorizationResult(appState.callState)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span
                        className={
                          appState.callState === CALL_STATE.FAILED_CANDIDATE
                            ? "text-danger"
                            : appState.callState >= CALL_STATE.CANDIDATED
                            ? "text-success"
                            : ""
                        }
                      >
                        ICE candidates
                      </span>
                    </td>
                    <td className="text-right">
                      {appState.callState >= CALL_STATE.CANDIDATED && (
                        <i className="icon cafe-selected icon-selected text-success mr-2" />
                      )}
                      {getRelayedCandidate(appState.callState)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span
                        className={
                          appState.callState === CALL_STATE.FAILED_CONNECTED
                            ? "text-danger"
                            : appState.callState >= CALL_STATE.CONNECTED
                            ? "text-success"
                            : ""
                        }
                      >
                        ICE State
                      </span>
                    </td>
                    <td className="text-right">
                      {appState.callState >= CALL_STATE.CONNECTED && (
                        <i className="icon cafe-selected icon-selected text-success mr-2" />
                      )}
                      {getIceState(appState.callState)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span
                        className={
                          appState.callState === CALL_STATE.FAILED_CALLED
                            ? "text-danger"
                            : appState.callState >= CALL_STATE.CALLED
                            ? "text-success"
                            : ""
                        }
                      >
                        Connected peers
                      </span>
                    </td>
                    <td className="text-right">
                      {appState.callState >= CALL_STATE.CALLED && (
                        <i className="icon cafe-selected icon-selected text-success mr-2" />
                      )}
                      {getVideoState(appState.callState)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span
                        className={
                          appState.callState === CALL_STATE.FAILED_CALLED
                            ? "text-danger"
                            : appState.callState >= CALL_STATE.ENDED
                            ? "text-success"
                            : ""
                        }
                      >
                        Metrics collected
                      </span>
                    </td>
                    <td className="text-right">
                      {appState.callState === CALL_STATE.ENDED && (
                        <i className="icon cafe-selected icon-selected text-success mr-2" />
                      )}
                      {getMetricsCollected(appState.callState)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label for="exampleSelect">Select transport to use</Label>
              <Input
                type="select"
                className="form-control"
                defaultValue="All transports"
                onChange={onTestsChange}
                disabled={testInProgress}
                style={{
                  padding: "0.3rem 2.25rem 0.3rem 0.75rem",
                  fontSize: "0.8rem",
                }}
              >
                {getOptionsForTest()}
              </Input>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <Label>Limit test duration</Label>
            <FormGroup className="ml-4">
              <Input
                defaultChecked={timeoutTest}
                disabled={testInProgress}
                type="checkbox"
                style={{
                  height: "18px",
                  width: "18px",
                }}
                value={timeoutTest}
                onChange={onTimeoutChange}
              />
              <Label className="ml-1 pt-1">
                Wait 5 seconds before timing-out
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {testDone && appState.callState !== CALL_STATE.ENDED && (
          <p className="text-danger">
            <i className="icon cafe-change" /> Check your Coturn configuration;
            the call as not been established!
          </p>
        )}
      </CardBody>
      <CardFooter>
        <hr />
        <div className="stats">
          <p className="text-muted">
            This test connects two peers through the host and measures the call
            quality during 25 seconds
          </p>
          <Button
            disabled={appState.callInProgress}
            size="sm"
            onClick={startCall}
          >
            Test
          </Button>
          {appState.callInProgress && (
            <Spinner children={null} className="ml-2" size="sm" />
          )}
        </div>
      </CardFooter>
      <div>
        <div className="videoArea">
          <video
            className="video"
            ref={localVideo}
            playsInline
            autoPlay
            muted
            className={appState.callInProgress ? "" : "hidden"}
          ></video>
        </div>
        <div className="videoArea">
          <video
            className="video"
            ref={remoteVideo}
            playsInline
            autoPlay
            muted
            className={appState.callInProgress ? "" : "hidden"}
          ></video>
        </div>
      </div>
    </Card>
  );
}

export default Media;
