import React, {forwardRef, useContext, useEffect, useState} from "react";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import {getLabelFromTypeList, getNameFromOperation, getTypeList} from "../../modules/helper";
import "./RunOperation.css";
import {OPERATIONS_COMPLEXITY, OPERATIONS_DURATION, OPERATIONS_IMPACT, OPERATIONS_STATE} from "../../modules/bm";
import {DateTime} from "luxon";
import DatePicker from "react-datepicker";
import {updateAnOperation} from "../../actions/operationActions";
import {useHistory} from "react-router-dom";

function RunOperation(props) {
  const appState = useContext(AppContext);
  const history = useHistory();
  const [operation, setOperation] = useState(appState.currentOperation);
  const [started, setStarted] = useState(DateTime.fromISO(appState.currentOperation?.started || appState.currentOperation?.planned || new Date().toJSON()).toJSDate());
  const [ended, setEnded] = useState(DateTime.fromISO(appState.currentOperation?.ended || appState.currentOperation?.planned || new Date().toJSON()).toJSDate());
  const [timeline, setTimeline] = useState("");
  const [impact, setImpact] = useState(appState.currentOperation?.impact || 1);
  const [impactDetails, setImpactDetails] = useState("");
  const [rca, setRCA] = useState("");
  const [state, setState] = useState(appState.currentOperation?.state || "planned");

  useEffect(() => {
    if (appState.currentOperation) {
      setOperation(appState.currentOperation);
      setStarted(DateTime.fromISO(appState.currentOperation.started || appState.currentOperation.planned || new Date().toJSON()).toJSDate());
      setEnded(DateTime.fromISO(appState.currentOperation.ended || appState.currentOperation.planned || new Date().toJSON()).toJSDate());
      setTimeline(appState.currentOperation.timeline);
      setImpact(appState.currentOperation.impact);
      setImpactDetails(appState.currentOperation.impactDetails);
      setRCA(appState.currentOperation.rca);
      setState(appState.currentOperation.state);
    }
  }, [appState.currentOperation]);

  const onSave = () => {
    const updatedOperation = {...operation};
    updatedOperation.started = started;
    updatedOperation.ended = ended;
    updatedOperation.timeline = timeline;
    updatedOperation.impact = impact;
    updatedOperation.impactDetails = impactDetails;
    updatedOperation.rca = rca;
    updatedOperation.state = state;
    updateAnOperation(appState.user._id, appState.currentInstance._id, updatedOperation._id, updatedOperation, appState.token, props.dispatch);
    history.push(`/host/${operation.instanceId}/operations/`);
  }

  const onBack = () => {
    history.push(`/host/${operation.instanceId}/operations`);
  }

  const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
    <Button size="sm" color="secondary" onClick={onClick}>
      {value}
    </Button>
  ));

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder/>}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch}/>
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && operation && (
          <>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="9">
                        <h5 className="card-category">
                          <Badge color="blue">{getNameFromOperation(operation.operation)}</Badge>
                        </h5>
                        <CardTitle tag="h3">{operation.title}</CardTitle>
                        <p className="fix-html" style={{fontSize: "12px"}}>{operation.instructions}</p>
                      </Col>
                      <Col className="text-right" sm="3">
                        <Button size="sm" color="primary" onClick={onBack}>
                          Back
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-left" sm="3">
                        <p className="text-muted"><b>Duration</b></p>
                        <p className="fix-html"
                           style={{fontSize: "12px"}}><Badge
                          color='green'>{getLabelFromTypeList(operation.estimatedDuration, OPERATIONS_DURATION)}</Badge>
                        </p>
                      </Col>
                      <Col className="text-center" sm="3">
                        <p className="text-muted"><b>Impact</b></p>
                        <p className="fix-html"
                           style={{fontSize: "12px"}}><Badge
                          color='green'>{getLabelFromTypeList(operation.estimatedImpact, OPERATIONS_IMPACT)}</Badge>
                        </p>
                      </Col>
                      <Col className="text-center" sm="3">
                        <p className="text-muted"><b>Complexity</b></p>
                        <p className="fix-html"
                           style={{fontSize: "12px"}}><Badge
                          color='green'>{getLabelFromTypeList(operation.estimatedComplexity, OPERATIONS_COMPLEXITY)}</Badge>
                        </p>
                      </Col>
                      <Col className="text-right" sm="3">
                        <p className="text-muted"><b>Planned</b></p>
                        <p className="fix-html"
                           style={{fontSize: "12px"}}><Badge
                          color='blue'>{DateTime.fromISO(operation.planned).toFormat("ff")}</Badge></p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Procedure</span>
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="9">
                        <h5 className="card-category">
                          Fill this procedure while doing the operation
                        </h5>
                        <CardTitle tag="h3">Operation</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <label className="mt-2">Started At</label>
                      <FormGroup>
                        <DatePicker
                          selected={started}
                          onChange={(date) => {
                            setStarted(date);
                          }}
                          showTimeInput
                          customInput={<ExampleCustomInput/>}
                        />
                        <span className="ml-2 text-active">
              At{" "}
                          {DateTime.fromJSDate(started).toLocaleString(
                            DateTime.TIME_SIMPLE,
                          )}
            </span>
                      </FormGroup>
                      <label className="mt-2">Timeline</label>
                      <FormGroup>
                        <Input
                          type="textarea"
                          rows={6}
                          style={{minHeight: "130px"}}
                          className="form-control p-2"
                          placeholder="Describe all the tasks done with the timestamp. Eg. 8:23 AM: Launch command 'apt-get update'"
                          value={timeline}
                          onChange={(e) => {
                            setTimeline(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <Col sm="4" className="mt-2">
                        <Label>Level of Impact perceived by end-users</Label>
                        <FormGroup>
                          <Input
                            name="select"
                            type="select"
                            className="form-control"
                            value={impact}
                            onChange={(e, b, c) => {
                              setImpact(e.target.value);
                            }}
                          >
                            {getTypeList(OPERATIONS_IMPACT)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="8" className="mt-2">
                        <label>Impact Details</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            rows={6}
                            style={{minHeight: "130px"}}
                            className="form-control p-2"
                            placeholder="Describe the impact perceived by end-users in details"
                            value={impactDetails}
                            onChange={(e) => {
                              setImpactDetails(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Label>Improvements</Label>
                      <FormGroup>
                        <Input
                          type="textarea"
                          rows={6}
                          style={{minHeight: "130px"}}
                          className="form-control p-2"
                          placeholder="Describe the improvements or RCA"
                          value={rca}
                          onChange={(e) => {
                            setRCA(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <Col sm="4">
                        <label className="mt-2">Ended At</label>
                        <FormGroup>
                          <DatePicker
                            selected={ended}
                            onChange={(date) => {
                              setEnded(date);
                            }}
                            showTimeInput
                            customInput={<ExampleCustomInput/>}
                          />
                          <span className="ml-2 text-active">
              At{" "}
                            {DateTime.fromJSDate(ended).toLocaleString(
                              DateTime.TIME_SIMPLE,
                            )}
            </span>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <Label>With Status</Label>
                        <FormGroup>
                          <Input
                            name="select"
                            type="select"
                            className="form-control"
                            value={state}
                            onChange={(e, b, c) => {
                              setState(e.target.value);
                            }}
                          >
                            {getTypeList(OPERATIONS_STATE)}
                          </Input>
                        </FormGroup>
                      </Col>

                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="text-right m-4">
                      <Button size="sm" color="primary" onClick={onSave} className="ml-4">
                        Save and return to operations
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </>
        )}
    </div>
  )
}

export default RunOperation;
