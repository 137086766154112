import { log } from "../modules/logger";
import { ACTIONS } from "./constants";
import {
  getLastUsageHosts,
  getSummaryForAPeriod,
  getSummaryForInstanceForAPeriod,
  getSummaryHealthForInstanceForAPeriod,
  getSummaryResultForAPeriod,
  getSummaryTestsForInstanceForAPeriod,
  getTodayStatusHosts,
} from "../modules/status";

const moduleName = "summaryActions";

export const getSummaryForInstance = async (
  userId,
  instanceId,
  token,
  dispatch,
) => {
  log(moduleName, "get summary for instance", { instanceId });
  dispatch({
    type: ACTIONS.GET_SUMMARY_FOR_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const summaryUsage = await getSummaryForInstanceForAPeriod(
      userId,
      instanceId,
      token,
    );

    const summaryHealth = await getSummaryHealthForInstanceForAPeriod(
      userId,
      instanceId,
      token,
    );

    const summaryTests = await getSummaryTestsForInstanceForAPeriod(
      userId,
      instanceId,
      token,
    );

    const summaryStatus = await getSummaryResultForAPeriod(
      userId,
      instanceId,
      token,
    );

    if (summaryUsage && summaryHealth) {
      dispatch({
        type: ACTIONS.GET_SUMMARY_FOR_INSTANCE_SUCCESS,
        payload: {
          value: {
            summary: summaryUsage,
            summaryHealth,
            summaryTests,
            summaryStatus,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_SUMMARY_FOR_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_SUMMARY_FOR_INSTANCE_FAILED,
      payload: { value: null },
    });
  }
};

export const getSummaryOverall = async (userId, token, dispatch) => {
  log(moduleName, "get summary overall for user", { userId });
  dispatch({
    type: ACTIONS.GET_SUMMARY_OVERALL_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const summaryStatus = await getSummaryForAPeriod(userId, token);

    if (summaryStatus) {
      dispatch({
        type: ACTIONS.GET_SUMMARY_OVERALL_SUCCESS,
        payload: {
          value: {
            summaryStatus,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_SUMMARY_OVERALL_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_SUMMARY_OVERALL_FAILED,
      payload: { value: null },
    });
  }
};

export const getLastUsageForAllHosts = async (userId, token, dispatch) => {
  log(moduleName, "get last usage for all hosts of user", { userId });
  dispatch({
    type: ACTIONS.GET_LAST_USAGE_HOSTS_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const lastUsage = (await getLastUsageHosts(userId, token)).lastUsage;

    if (lastUsage) {
      dispatch({
        type: ACTIONS.GET_LAST_USAGE_HOSTS_SUCCESS,
        payload: {
          value: {
            lastUsage,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_LAST_USAGE_HOSTS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_LAST_USAGE_HOSTS_FAILED,
      payload: { value: null },
    });
  }
};

export const getTodayStatusForAllHosts = async (userId, token, dispatch) => {
  log(moduleName, "get today status for all hosts of user", { userId });
  dispatch({
    type: ACTIONS.GET_TODAY_STATUS_HOSTS_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const today = (await getTodayStatusHosts(userId, token)).today;

    if (today) {
      dispatch({
        type: ACTIONS.GET_TODAY_STATUS_HOSTS_SUCCESS,
        payload: {
          value: {
            today,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_TODAY_STATUS_HOSTS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_TODAY_STATUS_HOSTS_FAILED,
      payload: { value: null },
    });
  }
};
