import {
  Card,
  CardBody,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getRoleColor, getRoleIcon } from "../modules/helper";
import { displayHostStatus, getHostStatusColor } from "../modules/bm";
import { setCurrentInstance } from "../actions/instanceActions";
import React from "react";

function CardSimple(props) {
  const instance = props.data.instance;
  const today = props.data.today;

  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col xs="7">
            <Link
              to={`/host/${instance._id}/board`}
              onClick={async () => {
                await setCurrentInstance(instance, props.dispatch);
              }}
            >
              <span style={{ display: "flex", fontSize: "14px" }}>
                <i
                  className={`icon cafe-${getRoleIcon(
                    instance,
                  )} text-${getRoleColor(instance.role)} icon-selected mr-1`}
                ></i>
                <span className={`text-${getRoleColor(instance.role)}`}>
                  {instance.name}
                </span>
              </span>
            </Link>
          </Col>
          <Col xs="5" className="text-right">
            <Badge
              pill
              color={getHostStatusColor(instance.status, instance.active)}
            >
              {displayHostStatus(instance.status, instance.active)}
            </Badge>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs="7">
            <div style={{ display: "flex" }} className="text-left">
              <span style={{ fontSize: "12px" }} className="mr-2">
                <i
                  className={`icon cafe-trend-up icon-selected text-white`}
                ></i>
              </span>
              <span
                id="successValue"
                style={{ fontSize: "12px", display: "flex" }}
                className={`${
                  today && today.success ? "text-success" : "text-muted"
                } mr-1`}
              >
                {today ? today.success : 0}
                <i
                  className={`icon cafe-selected icon-selected text-${
                    today && today.success ? "success" : "muted"
                  } ml-1`}
                ></i>
              </span>
              <UncontrolledTooltip target="successValue">
                # of today's successful jobs
              </UncontrolledTooltip>
              <span style={{ fontSize: "12px" }} className="text-muted mr-1">
                |
              </span>
              <span
                id="warningValue"
                style={{ fontSize: "12px", display: "flex" }}
                className={`${
                  today && today.warning ? "text-warning" : "text-muted"
                } mr-1`}
              >
                {today ? today.warning : 0}
                <i
                  className={`icon cafe-warning-sign icon-selected text-${
                    today && today.warning ? "warning" : "muted"
                  } ml-1`}
                ></i>
              </span>
              <UncontrolledTooltip target="warningValue">
                # of today's warning jobs
              </UncontrolledTooltip>
              <span style={{ fontSize: "12px" }} className="text-muted mr-1">
                |
              </span>
              <span
                id="errorValue"
                style={{ fontSize: "12px", display: "flex" }}
                className={`${
                  today && today.error ? "text-danger" : "text-muted"
                } mr-1`}
              >
                {today ? today.error : 0}
                <i
                  className={`icon cafe-error icon-selected text-${
                    today && today.error ? "error" : "muted"
                  } ml-1`}
                ></i>
              </span>
              <UncontrolledTooltip target="errorValue">
                # of today's failed jobs
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col xs="5" className="text-right">
            {!instance.active && (
              <span style={{ fontSize: "12px" }} className="mr-1 text-muted">
                &#9888;&nbsp;Manual
              </span>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default CardSimple;
