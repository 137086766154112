import WebRTCMetrics from "webrtcmetrics";
import { info, log } from "../modules/logger";
import { ACTIONS } from "../actions/constants";

let analyzers = [];

const moduleName = "mod:metrics";

export const startAnalyze = (
  pc1,
  pname1,
  cid1,
  uid1,
  pc2,
  pname2,
  cid2,
  uid2,
  dispatch
) => {
  info(moduleName, "register metrics");

  const analyzer = new WebRTCMetrics({
    refreshEvery: 1000,
    startAfter: 1000,
    verbose: false,
  });

  const probe1 = analyzer.createProbe(pc1, {
    pname1,
    cid1,
    uid1,
    record: false,
  });

  const probe2 = analyzer.createProbe(pc2, {
    pname2,
    cid2,
    uid2,
    record: false,
  });

  probe1.onreport = (report) => {
    info(moduleName, `reported metrics for ${probe1.pname}`, { report });

    const streamAudio = report.audio[Object.keys(report.audio)[0]];
    const streamVideo = report.video[Object.keys(report.video)[0]];

    dispatch({
      type: ACTIONS.GOT_STATS_SENDER,
      payload: {
        value: {
          timestamp: report.timestamp,
          rtt: streamAudio.delta_rtt_ms_out || null,
          rttVideo: streamVideo.delta_rtt_ms_out || null,
          outgoingBwt: report.data.delta_kbs_out,
          outgoingSent: report.data.delta_KBytes_out,
          res: streamVideo.size_out.width || 0,
        },
      },
    });
  };

  probe2.onreport = (report) => {
    info(moduleName, `reported metrics for ${probe2.pname}`, { report });

    const streamAudio = report.audio[Object.keys(report.audio)[0]];
    const streamVideo = report.video[Object.keys(report.video)[0]];

    dispatch({
      type: ACTIONS.GOT_STATS_RECEIVER,
      payload: {
        value: {
          timestamp: report.timestamp,
          mos_emodel: streamAudio.mos_emodel_in,
          jitter: streamAudio.delta_jitter_ms_in,
          jitterVideo: streamVideo.delta_jitter_ms_in,
          lost: streamAudio.delta_packets_lost_in,
          lostVideo: streamVideo.delta_packets_lost_in,
          incomingBwt: report.data.delta_kbs_in,
          incomingReceived: report.data.delta_KBytes_in,
        },
      },
    });
  };

  probe1.onticket = (ticket) => {
    log(moduleName, `reported ticket for ${probe1.pname}`, { ticket });

    const ssrcKeys = Object.keys(ticket.ssrc);
    const audioKey = ssrcKeys.find((key) => ticket.ssrc[key].type === "audio");
    const videoKey = ssrcKeys.find((key) => ticket.ssrc[key].type === "video");
    const audioStream = ticket.ssrc[audioKey];
    const videoStream = ticket.ssrc[videoKey];

    dispatch({
      type: ACTIONS.GOT_STATS_FINAL_SENDER,
      payload: {
        value: {
          rttAudio: audioStream.rtt,
          rttVideo: videoStream.rtt,
          bitrateOut: ticket.data.bitrate.out,
          trafficOut: ticket.data.traffic.out,
        },
      },
    });
  };

  probe2.onticket = (ticket) => {
    log(moduleName, `reported ticket for ${probe2.pname}`, { ticket });

    const ssrcKeys = Object.keys(ticket.ssrc);
    const audioKey = ssrcKeys.find((key) => ticket.ssrc[key].type === "audio");
    const videoKey = ssrcKeys.find((key) => ticket.ssrc[key].type === "video");
    const audioStream = ticket.ssrc[audioKey];
    const videoStream = ticket.ssrc[videoKey];

    dispatch({
      type: ACTIONS.GOT_STATS_FINAL_RECEIVER,
      payload: {
        value: {
          jitterAudio: audioStream.jitter,
          jitterVideo: videoStream.jitter,
          mos_emodel: audioStream.mos.emodel,
          packetsLostAudio: audioStream.loss,
          packetsLostVideo: videoStream.loss,
          bitrateIn: ticket.data.bitrate.in,
          trafficIn: ticket.data.traffic.in,
        },
      },
    });
  };

  analyzer.startAllProbes();
  analyzers.push(analyzer);
};

export const stopAnalyze = () => {
  if (analyzers) {
    while (!!analyzers.length) {
      const analyzer = analyzers.pop();
      log(moduleName, "unregister metrics", {
        pname: analyzer.pname,
        cid: analyzer.cid,
        uid: analyzer.uid,
      });
      analyzer.stopAllProbes();
    }
  }
};
