import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Table,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { manualLaunchService } from "../../actions/instanceActions";
import AppContext from "../../contexts/appContext";
import { createUser, sendCommand } from "../../actions/adminActions";
import { HOST_SERVICE } from "../../modules/bm";

function AdminCommand({ dispatch }) {
  const appState = useContext(AppContext);
  const [command, setCommand] = useState("");

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Quick launch a command manually</h5>
        <CardTitle tag="h3">Command</CardTitle>
      </CardHeader>
      <CardBody>
        <Form className="mt-0">
          <Label>Command</Label>
          <FormGroup>
            <Input
              autoComplete="new-password"
              type="text"
              id="command"
              value={command}
              onChange={(e) => {
                setCommand(e.target.value);
              }}
            />
            <span
              className="form-description"
              style={{ display: "block", marginBottom: "4px" }}
            >
              Execute admin command
            </span>
          </FormGroup>
          <Button
            type="button"
            color="primary"
            size="sm"
            disabled={!command || command.length === 0}
            onClick={() => {
              sendCommand(command, appState.token, dispatch);
            }}
          >
            Execute
          </Button>
          {appState.sendCommandInProgress && (
            <Spinner className="mt-1" size="sm" children={null} />
          )}
          {appState.sendCommandResult !== 0 && (
            <Label
              className={
                appState.sendCommandResult === 1
                  ? "text-success ml-2"
                  : "text-danger ml-2"
              }
            >
              {appState.sendCommandResult === 1
                ? "Executed Successfully"
                : "Failed"}
            </Label>
          )}
        </Form>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default AdminCommand;
