import Chart from "chart.js/auto";
import {DateTime} from "luxon";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  createRTTGraph,
  createBandwidthGraph,
  createBytesGraph,
  createJitterGraph,
  getMaxYValueOfSerie,
  createMOSGraph,
  createResolutionGraph,
  createLostGraph,
  createOutagesGraph,
  createHealthCheckGraph,
  createServiceGraph,
  createIceGraph,
  createMonthlyGraph,
  createStatusGraph,
  createStatusPieGraph, getMostRecentDateFromSeries, getMostOldDateFromSeries,
} from "./helper";

const CHART_TYPE = {
  RTT: createRTTGraph,
  JITTER: createJitterGraph,
  LOST: createLostGraph,
  BANDWIDTH: createBandwidthGraph,
  BYTES: createBytesGraph,
  MOS: createMOSGraph,
  RES: createResolutionGraph,
  OUTAGES: createOutagesGraph,
  HEALTHCHECK: createHealthCheckGraph,
  SERVICE: createServiceGraph,
  ICE: createIceGraph,
  MONTHLY: createMonthlyGraph,
  STATUS: createStatusGraph,
  STATUSPIE: createStatusPieGraph,
};

const getFirstDateItem = (list) => {
  if (list.length === 0) {
    return null;
  }
  const first = list[0];
  return DateTime.fromISO(first.x).toJSDate();
};

export const createChart = (chartDomRef, chart, type, options) => {
  const graph = CHART_TYPE[type](options);

  if (chart.current) {
    chart.current.destroy();
  }
  Chart.register(zoomPlugin);
  chart.current = new Chart(chartDomRef, graph);
};

export const updateChart = (
  stats,
  stats2 = null,
  chart,
  threshold,
  max = 100000000,
  reduceDate = false,
  fromDate = null,
  stats3 = null,
  toDate = null
) => {
  const globalStats = stats.concat(stats2 || []).concat(stats3 || []);

  if (globalStats.length > 0) {
    if (chart.current.config.options.scales.y) {
      chart.current.config.options.scales.y.max = Math.min(
        getMaxYValueOfSerie(globalStats) + threshold,
        max
      );
    }
  }

  if (globalStats.length > 0) {
    const endDate = getMostRecentDateFromSeries(globalStats);
    const startDate = getMostOldDateFromSeries(globalStats);

    if (endDate && startDate) {
      const lastDay = DateTime.fromISO(startDate.x).minus({days: 1});
      const endDay = DateTime.fromISO(endDate.x).plus({days: 1});

      if (lastDay && chart.current.config.options.scales.x) {
        chart.current.config.options.scales.x.min = lastDay.toJSON();
      }

      if (endDay && chart.current.config.options.scales.x) {
        chart.current.config.options.scales.x.max = endDay.toJSON();
      }
    }
  }

  chart.current.data.datasets[0].data = stats || [];
  if (stats2) {
    chart.current.data.datasets[1].data = stats2 || [];
  }

  if (stats3) {
    chart.current.data.datasets[2].data = stats3 || [];
  }

  chart.current.update();
};
