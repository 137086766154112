import jwt_decode from "jwt-decode";
import axios from "axios";

import { error, log } from "../modules/logger";
import CONFIG from "./environment";
import { createHeader } from "./helper";

const TOKEN_KEY = "dashboard_token";
const TOKEN_SSE_KEY = "dashboard_token_sse";
const CURRENT_INSTANCE_KEY = "dashboard_instanceId";
const moduleName = "mod:authent";

export const readTokenFromStorage = () => {
  const token = window.localStorage
    ? window.localStorage.getItem(TOKEN_KEY)
    : "";
  log(moduleName, "read token", { token });
  return token;
};

export const readTokenSSEFromStorage = () => {
  const token = window.localStorage
    ? window.localStorage.getItem(TOKEN_SSE_KEY)
    : "";
  log(moduleName, "read sse token", { token });
  return token;
};

export const readInstanceIdFromStorage = () => {
  const instanceId = window.localStorage
    ? window.localStorage.getItem(CURRENT_INSTANCE_KEY)
    : "";
  log(moduleName, "read instance ID", { instanceId });
  return instanceId;
};

export const writeTokenToStorage = (newValue) => {
  log(moduleName, "write token", { newValue });
  window.localStorage.setItem(TOKEN_KEY, newValue);
};

export const writeSSETokenToStorage = (newValue) => {
  log(moduleName, "write sse token", { newValue });
  window.localStorage.setItem(TOKEN_SSE_KEY, newValue);
};

export const writeInstanceIdToStorage = (newValue) => {
  log(moduleName, "write instance ID", { newValue });
  window.localStorage.setItem(CURRENT_INSTANCE_KEY, newValue);
};

export const removeTokenFromStorage = () => {
  log(moduleName, "remove token");
  window.localStorage.removeItem(TOKEN_KEY);
};

export const removeTokenSSEFromStorage = () => {
  log(moduleName, "remove sse token");
  window.localStorage.removeItem(TOKEN_SSE_KEY);
};

export const getPropertyFromStorage = (key) => {
  return window.localStorage ? window.localStorage.getItem(key) || "" : "";
};

export const checkTokenIsValid = (token) => {
  if (!token) {
    log(moduleName, `invalid token - not found`);
    return false;
  }

  try {
    const decoded = jwt_decode(token);
    const exp = decoded ? decoded.exp : null;

    if (!exp) {
      log(moduleName, `invalid token -  no 'exp'`, { decoded });
      return false;
    }

    const hasExpired = Date.now() >= exp * 1000;
    if (hasExpired) {
      log(moduleName, "invalid token - expired", { decoded });
    }

    return !hasExpired;
  } catch (err) {
    log(moduleName, `invalid token - can't read`, { err });
    return false;
  }
};

export const checkUserIsAdmin = (user) => {
  if (!user) {
    return false;
  }
  return user.role === "admin";
};

export const loginWithUsername = async (username, password) => {
  try {
    const response = await axios.post(`${CONFIG.restUrl}/signin`, {
      username,
      password,
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "login received", { received: response.data });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't POST /api/signin", { error: err });
    return null;
  }
};

export const loginWithTokenUserToken = async (token) => {
  try {
    const response = await axios.get(`${CONFIG.restUrl}/check`, {
      headers: createHeader(token),
    });

    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "login with token received", { received: response.data });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't GET /api/check", { error: err });
    return null;
  }
};

export const updateProfile = async (userId, profile, token) => {
  try {
    const response = await axios.put(
      `${CONFIG.restUrl}/users/${userId}/profile`,
      profile,
      { headers: createHeader(token) }
    );

    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "profile updated", { received: response.data });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't PUT /api/user", { error: err });
    return null;
  }
};

export const getTokenExpirationDate = (token) => {
  if (!token) {
    log(moduleName, `invalid token - not found`);
    return false;
  }

  try {
    const decoded = jwt_decode(token);
    return decoded ? decoded.exp * 1000 : null;
  } catch (err) {
    log(moduleName, `invalid token - can't read`, { err });
    return null;
  }
};
