import React, { useContext, useEffect, useState } from "react";
import AppContext from "../contexts/appContext";
import HostPlaceHolder from "./placeholder/HostPlaceHolder";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Spinner,
  Table,
  Row,
  Badge,
} from "reactstrap";
import PublicNavBar from "./PublicNavBar";
import CONFIG from "../modules/environment";
import "./Public.css";

function Public(props) {
  const appState = useContext(AppContext);
  const [host, setHost] = useState(appState.currentInstance);
  const [link, setLink] = useState(appState.link);

  useEffect(() => {
    if (appState.currentInstance) {
      setHost(appState.currentInstance);
    }
  }, [appState.currentInstance]);

  useEffect(() => {
    setLink(appState.link);
  }, [appState.link]);

  return (
    <div className="content-top">
      <div className="wrapper">
        <div className="main-panel">
          <PublicNavBar dispatch={props.dispatch} brandText={CONFIG.appName} />
          <div className="public-content">
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5 className="card-category">Welcome to the public link</h5>
                    <CardTitle tag="h3">Public Link</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p className="mb-4 text-danger">Hello public link</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Public;
