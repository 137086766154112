import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Table,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { manualLaunchService } from "../../actions/instanceActions";
import AppContext from "../../contexts/appContext";
import { HOST_SERVICE } from "../../modules/bm";
import { getAdminMetrics, getJobs, getUsers } from "../../actions/adminActions";

function AdminJobs({ dispatch }) {
  const appState = useContext(AppContext);
  const [jobs, setJobs] = useState(null);

  const getInProgress = (jobs) => {
    if (!jobs) {
      return 0;
    }
    return jobs.active + jobs.delayed + jobs.paused + jobs.waiting;
  };

  const getOutProgress = (jobs) => {
    if (!jobs) {
      return 0;
    }
    return jobs.failed + jobs.completed;
  };

  const getTotal = (jobs) => {
    if (!jobs) {
      return 0;
    }
    return (
      jobs.active +
      jobs.delayed +
      jobs.paused +
      jobs.waiting +
      jobs.failed +
      jobs.completed
    );
  };

  useEffect(() => {
    getJobs(appState.token, dispatch);
  }, []);

  useEffect(() => {
    setJobs(appState.jobs);
  }, [appState.jobs]);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Jobs usage</h5>
        <CardTitle tag="h3">JOBS</CardTitle>
      </CardHeader>
      <CardBody>
        <Table>
          <tbody>
            <tr>
              <td className="text-left">Count</td>
              <td className="text-right">{getTotal(jobs)}</td>
            </tr>
          </tbody>
        </Table>
        <Table>
          <thead className="text-primary">
            <tr>
              <th className="text-left" colSpan="2">
                In Progress ({getInProgress(jobs)})
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">Active</td>
              <td className="text-right">{jobs ? jobs.active : 0}</td>
            </tr>
            <tr>
              <td className="text-left">Delayed</td>
              <td className="text-right">{jobs ? jobs.delayed : 0}</td>
            </tr>
            <tr>
              <td className="text-left">Paused</td>
              <td className="text-right">{jobs ? jobs.paused : 0}</td>
            </tr>
            <tr>
              <td className="text-left">Waiting</td>
              <td className="text-right">{jobs ? jobs.waiting : 0}</td>
            </tr>
          </tbody>
        </Table>
        <Table>
          <thead className="text-primary">
            <tr>
              <th className="text-left" colSpan="2">
                Finished ({getOutProgress(jobs)})
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">Completed</td>
              <td className="text-right">{jobs ? jobs.completed : 0}</td>
            </tr>
            <tr>
              <td className="text-left">Failed</td>
              <td className="text-right">{jobs ? jobs.failed : 0}</td>
            </tr>
          </tbody>
        </Table>
        <Button
          disabled={appState.getJobsInProgress}
          className="tableButtons"
          color="primary"
          size="sm"
          onClick={() => {
            getJobs(appState.token, dispatch);
          }}
        >
          {" "}
          <i className="icon cafe-refresh" />
        </Button>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default AdminJobs;
