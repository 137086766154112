import React, { useContext, useState } from "react";
import AppContext from "../contexts/appContext";

import "./Login.css";

import {
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Label,
  Spinner,
} from "reactstrap";

import CONFIG from "../modules/environment";
import { getPropertyFromStorage } from "../modules/authentification";
import { login, resetSignedError } from "../actions/userActions";
import { USER_STATE } from "../reducers/appReducer";

const Login = ({ dispatch, brandText }) => {
  const [state, setState] = useState({});
  const [email, setEmail] = useState(getPropertyFromStorage("APP_EMAIL"));
  const [password, setPassword] = useState(
    getPropertyFromStorage("APP_PASSWORD")
  );

  const appState = useContext(AppContext);

  const loginToDashboard = () => {
    login(email, password, dispatch);
  };

  return (
    <div className="full-page login-page">
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="6" md="8" sm="8">
            <Form className="form" autoComplete="new-password">
              <Card className="card-login card-white">
                <CardHeader style={{ backgroundColor: "#ba54f5" }}>
                  <CardTitle className="pt-5">
                    <i className="icon cafe-cafe mr-1" /> {brandText}
                  </CardTitle>
                  <CardSubtitle>Take care of your infrastructure!</CardSubtitle>
                </CardHeader>
                <CardBody className="mt-3">
                  {appState.userState === USER_STATE.NOT_SIGNED_ERROR && (
                    <p style={{ color: "#f5365c", fontSize: "0.75rem" }}>
                      Your login or password is not correct!
                    </p>
                  )}
                  {appState.userState !== USER_STATE.NOT_SIGNED_ERROR && (
                    <p style={{ color: "#344675", fontSize: "0.75rem" }}>
                      Please enter your information
                    </p>
                  )}
                  <InputGroup>
                    <InputGroupText>
                      <i className="icon cafe-login login-button" />
                    </InputGroupText>
                    <Input
                      placeholder="Email"
                      type="text"
                      autoComplete="new-password"
                      value={email}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          loginToDashboard();
                        }
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (
                          appState.userState === USER_STATE.NOT_SIGNED_ERROR
                        ) {
                          resetSignedError(dispatch);
                        }
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupText>
                      <i className="icon cafe-password login-button" />
                    </InputGroupText>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      password={password}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          loginToDashboard();
                        }
                      }}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (
                          appState.userState === USER_STATE.NOT_SIGNED_ERROR
                        ) {
                          resetSignedError(dispatch);
                        }
                      }}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="primary"
                    onClick={(e) => loginToDashboard()}
                    size="lg"
                    disabled={
                      appState.userState === USER_STATE.SIGNIN_IN_PROGRESS
                    }
                  >
                    Log In{" "}
                    {appState.userState === USER_STATE.SIGNIN_IN_PROGRESS && (
                      <Spinner children={null} color="light" size="sm" />
                    )}
                  </Button>
                  <div className="pull-left">
                    <Label color="primary">v{CONFIG.appVersion}</Label>
                  </div>
                  <div className="pull-right">
                    <Label color="primary">©{CONFIG.appCopyright}</Label>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </div>
  );
};

Login.propTypes = {};

export default Login;
