import {log} from "../modules/logger";
import {ACTIONS} from "./constants";
import {
  createSeriesForInstanceForAPeriod,
  getSeriesTrendsForInstance,
  getSeriesTestingForInstanceForAllPeriod,
  getPagedSeriesForAnInstance,
  getSeriesForInstances, getSeriesTimelineForInstance,
} from "../modules/status";
import {
  getDistanceFromLatLonInKm,
  getGeolocalizationFromIP,
} from "../modules/geo";

const moduleName = "seriesActions";

export const getSeriesForInstance = async (
  userId,
  instanceId,
  token,
  dispatch
) => {
  log(moduleName, "get series trends for instance", {instanceId});
  dispatch({
    type: ACTIONS.GET_SERIES_FOR_INSTANCE_IN_PROGRESS,
    payload: {value: null},
  });

  try {
    const series = await getSeriesTrendsForInstance(userId, instanceId, token);

    if (series) {
      dispatch({
        type: ACTIONS.GET_SERIES_FOR_INSTANCE_SUCCESS,
        payload: {
          value: {
            series,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_SERIES_FOR_INSTANCE_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_SERIES_FOR_INSTANCE_FAILED,
      payload: {value: null},
    });
  }
};

export const getTimelineForInstance = async (
  userId,
  instanceId,
  token,
  dispatch
) => {
  log(moduleName, "get timeline for instance", {instanceId});
  dispatch({
    type: ACTIONS.GET_TIMELINE_FOR_INSTANCE_IN_PROGRESS,
    payload: {value: null},
  });

  try {
    const timeline = await getSeriesTimelineForInstance(userId, instanceId, token);

    if (timeline) {
      dispatch({
        type: ACTIONS.GET_TIMELINE_FOR_INSTANCE_SUCCESS,
        payload: {
          value: {
            timeline,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_TIMELINE_FOR_INSTANCE_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_TIMELINE_FOR_INSTANCE_FAILED,
      payload: {value: null},
    });
  }
};

export const getPagedSeriesForInstance = async (
  userId,
  instanceId,
  page,
  perPage,
  service,
  result,
  upTo,
  token,
  dispatch
) => {
  log(moduleName, "get paged series for instance", {instanceId});
  dispatch({
    type: ACTIONS.GET_PAGED_SERIES_FOR_INSTANCE_IN_PROGRESS,
    payload: {value: null},
  });

  try {
    const series = await getPagedSeriesForAnInstance(
      userId,
      instanceId,
      page,
      perPage,
      service,
      result,
      upTo,
      token
    );

    if (series) {
      dispatch({
        type: ACTIONS.GET_PAGED_SERIES_FOR_INSTANCE_SUCCESS,
        payload: {
          value: {
            series,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_PAGED_SERIES_FOR_INSTANCE_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_OUTAGES_FOR_INSTANCE_FAILED,
      payload: {value: null},
    });
  }
};

export const getSeriesTestingForInstance = async (
  userId,
  instanceId,
  token,
  dispatch
) => {
  log(moduleName, "get series testing for instance", {instanceId});
  dispatch({
    type: ACTIONS.GET_SERIES_TESTING_FOR_INSTANCE_IN_PROGRESS,
    payload: {value: null},
  });

  try {
    const series = await getSeriesTestingForInstanceForAllPeriod(
      userId,
      instanceId,
      token
    );

    if (series) {
      dispatch({
        type: ACTIONS.GET_SERIES_TESTING_FOR_INSTANCE_SUCCESS,
        payload: {
          value: {
            series,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_SERIES_TESTING_FOR_INSTANCE_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_SERIES_TESTING_FOR_INSTANCE_FAILED,
      payload: {value: null},
    });
  }
};

export const getSeriesForAllInstances = async (userId, token, dispatch) => {
  log(moduleName, "get series for all instances of user", {userId});
  dispatch({
    type: ACTIONS.GET_SERIES_FOR_USER_IN_PROGRESS,
    payload: {value: null},
  });

  try {
    const series = await getSeriesForInstances(userId, 10, token);

    if (series) {
      dispatch({
        type: ACTIONS.GET_SERIES_FOR_USER_SUCCESS,
        payload: {
          value: {
            series,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_SERIES_FOR_USER_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_SERIES_FOR_USER_FAILED,
      payload: {value: null},
    });
  }
};

export const createNewSeries = async (
  userId,
  instanceId,
  category,
  created,
  status,
  data,
  token,
  dispatch
) => {
  log(moduleName, "create series for instance", {instanceId});
  dispatch({
    type: ACTIONS.CREATE_SERIES_FOR_INSTANCE_IN_PROGRESS,
    payload: {value: null},
  });

  try {
    if (data.address && data.fromLongitude && data.fromLatitude) {
      const loc = await getGeolocalizationFromIP(data.address);
      if (loc) {
        const distance = getDistanceFromLatLonInKm(
          data.fromLatitude,
          data.fromLongitude,
          loc.latitude,
          loc.longitude
        );
        data.distance = distance;
        data.longitude = loc.longitude;
        data.latitude = loc.latitude;
        delete data.fromLongitude;
        delete data.fromLatitude;
      }
      delete data.address;
    } else {
      data.address = "";
      data.longitude = "";
      data.latitude = "";
      data.distance = 0;
    }

    const series = await createSeriesForInstanceForAPeriod(
      userId,
      instanceId,
      created,
      category,
      status,
      data,
      token
    );

    if (series) {
      dispatch({
        type: ACTIONS.CREATE_SERIES_FOR_INSTANCE_SUCCESS,
        payload: {
          value: {
            series,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.CREATE_SERIES_FOR_INSTANCE_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.CREATE_SERIES_FOR_INSTANCE_FAILED,
      payload: {value: null},
    });
  }
};
