import { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { createChart, updateChart } from "../../modules/callCharts";
import "../testing/media.css";

function GlobalStatusChart({ status, title, property }) {
  const chartStatusPie = useRef();
  const [series, setSeries] = useState(
    property in status ? status[property] : [],
  );
  const [pie, setPie] = useState([]);

  const changeSeries = (series) => {
    if (series.length > 0) {
      const successTotal = series.reduce((p, c) => p + c.success * c.count, 0);
      const warningTotal = series.reduce((p, c) => p + c.warning * c.count, 0);
      const errorTotal = series.reduce((p, c) => p + c.error * c.count, 0);
      setPie([successTotal, warningTotal, errorTotal]);
    }
  };

  useEffect(() => {
    if (pie.length > 0) {
      updateChart(pie, null, chartStatusPie, 2, 60000, true, null, null);
    }
  }, [pie]);

  useEffect(() => {
    changeSeries(series);
  }, [series]);

  useEffect(() => {
    if (status && property in status) {
      setSeries(status[property]);
      createChart(
        document.querySelector("#chartStatusPie"),
        chartStatusPie,
        "STATUSPIE",
        {},
      );
    }
  }, [status, property]);

  return (
    <Card className="card">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="10">
            <h5 className="card-category">Overall</h5>
            <CardTitle tag="h3">{title}</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <div className="chart-container-status">
              <canvas id="chartStatusPie"></canvas>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default GlobalStatusChart;
