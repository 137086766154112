import React from 'react';
import ReactDOM from 'react-dom';
import adapter from 'webrtc-adapter';
import CONFIG from "./modules/environment";
import { Debugout } from "debugout.js";
import { log, setReporter } from "./modules/logger";

import './index.css';
import './css/bootstrap.min.css';
import './css/black-dashboard-pro-react.css';
import './css/nucleo-icons.css';
import App from './components/App';

import reportWebVitals from './reportWebVitals';

log("index", "application started");

log("index", "environment", { mode: CONFIG.mode });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const bugout = new Debugout({
  realTimeLoggingOn: false,
  useTimestamps: true,
  useLocalStorage: true,
  includeSessionMetadata: true,
  logFilename: `coturnDashboard-${Date.now()}.log`,
  quoteStrings: true,
});

setReporter(bugout);

log("index", "webrtc", { browser: adapter.browserDetails.browser, version: adapter.browserDetails.version });
