import { useState, useContext, useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Table,
  Button,
  Spinner,
} from "reactstrap";
import { DateTime } from "luxon";
import { info, log } from "../modules/logger";
import { listCalls } from "../actions/appActions";
import CONFIG from "../modules/environment";
import AppContext from "../contexts/appContext";
import { convert } from "../modules/helper";

const moduleName = "view:calls";

function CallsTable({ dispatch }) {
  const appState = useContext(AppContext);
  const [calls, setCalls] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setCalls(appState.calls);
  }, [appState.calls]);

  useEffect(() => {
    getCalls();
  }, [page]);

  const reset = () => {
    setCalls([]);
  };

  const detectNoNextPage = () => {
    return (page + 1) * appState.callsPerPage >= appState.callsCount;
  };

  const detectNoPreviousPage = () => {
    return page === 0;
  };

  const goToFirstPage = () => {
    setPage(0);
  };

  const goToLastPage = () => {
    setPage(Math.floor(appState.callsCount / appState.callsPerPage));
  };

  const goToNextPage = async () => {
    setPage(page + 1);
  };

  const goToPreviousPage = async () => {
    setPage(page - 1);
  };

  const getCalls = async (auto) => {
    info(moduleName, `${auto ? "get calls" : "[click] get calls"}`);
    reset();

    try {
      await listCalls(page, appState.token, dispatch);
    } catch (err) {}
  };

  const shortness = (value) => {
    if (value.length > 20) {
      return `${value.substr(0, 9)}...${value.substr(value.length - 8)}`;
    }
    return value;
  };

  const isRealCall = (sent, received) => {
    if (sent > 1000 && received > 1000) {
      return "tim-icons icon-shape-star text-success";
    }
    return "";
  };

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">
          Last relayed calls or Turn binding requests
        </h5>
        <CardTitle tag="h3">HISTORY</CardTitle>
      </CardHeader>
      <CardBody>
        <Table>
          <thead className="text-primary">
            <tr>
              <th className="text-center">#</th>
              <th className="text-left">Date</th>
              <th className="text-left">CallId</th>
              <th className="text-left">UserId</th>
              <th className="text-right">Sent</th>
              <th className="text-right">Received</th>
            </tr>
          </thead>
          <tbody>
            {calls.map((call, key) => (
              <tr key={key}>
                <td className="text-center">
                  <i
                    className={isRealCall(call.total_sent, call.total_received)}
                  />
                </td>
                <td className="text-left">
                  {DateTime.fromISO(call.ended).toLocaleString(
                    DateTime.DATETIME_MED
                  )}
                </td>
                <td className="text-left">{shortness(call.callId)}</td>
                <td className="text-left">{shortness(call.userId)}</td>
                <td className="text-right">{convert(call.total_sent)}</td>
                <td className="text-right">{convert(call.total_received)}</td>
              </tr>
            ))}
            {calls.length === 0 && !appState.listCallsInProgress && (
              <tr>
                <td colSpan="5">
                  <br />
                  No calls found. <br />
                  <br />
                  Please relay a call to generate the report.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <p className="text-success">
          <i className="tim-icons icon-bullet-list-67" /> Showing{" "}
          <b>{appState.callsPage * appState.callsPerPage + 1}</b> to{" "}
          <b>
            {Math.min(
              (appState.callsPage + 1) * appState.callsPerPage,
              appState.callsCount
            )}
          </b>{" "}
          of <b>{appState.callsCount}</b> entries
        </p>
      </CardBody>
      <CardFooter>
        <hr />
        <div className="stats">
          <Button
            disabled={appState.listCallsInProgress}
            color="primary"
            size="sm"
            onClick={getCalls}
          >
            {" "}
            <i className="tim-icons icon-refresh-01" />{" "}
          </Button>
          <Button
            disabled={appState.listCallsInProgress || detectNoPreviousPage()}
            color="info"
            size="sm"
            onClick={goToFirstPage}
          >
            <i className="tim-icons icon-double-left" />
          </Button>
          <Button
            disabled={appState.listCallsInProgress || detectNoPreviousPage()}
            color="info"
            size="sm"
            onClick={goToPreviousPage}
          >
            {" "}
            <i className="tim-icons icon-minimal-left" />{" "}
          </Button>
          <Button
            disabled={appState.listCallsInProgress || detectNoNextPage()}
            color="info"
            size="sm"
            onClick={goToNextPage}
          >
            {" "}
            <i className="tim-icons icon-minimal-right" />{" "}
          </Button>
          <Button
            disabled={appState.listCallsInProgress || detectNoNextPage()}
            color="info"
            size="sm"
            onClick={goToLastPage}
          >
            <i className="tim-icons icon-double-right" />
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}

export default CallsTable;
