import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import React, { useContext } from "react";
import { setCurrentInstance } from "../actions/instanceActions";
import AppContext from "../contexts/appContext";
import { capitalizeFirstLetter } from "../modules/helper";

function BreadCrumbCusto(props) {
  const appState = useContext(AppContext);

  const removeInstance = async () => {
    await setCurrentInstance(null, props.dispatch);
  };

  const getEnvLink = (env) => {
    if (env === "staging" || env === "production") {
      return env;
    }
    return "others";
  };

  return (
    <Breadcrumb className="text-muted">
      &gt;
      <BreadcrumbItem className="ml-1 text-muted">
        {!props.env &&
          !props.instance &&
          !props.name &&
          (`${appState.user.company} (Home)` || "Dashboard (Home)")}
        {(props.env || props.instance || props.name) && (
          <a href="/#/" onClick={() => removeInstance(null)}>
            {appState.user.company || "Dashboard"}
          </a>
        )}
      </BreadcrumbItem>
      {props.env && (
        <BreadcrumbItem active={!!props.instance} className="text-muted">
          {props.instance && (
            <a
              href={`/#/${getEnvLink(props.env)}`}
              onClick={() => removeInstance(null)}
            >
              {capitalizeFirstLetter(getEnvLink(props.env))}
            </a>
          )}
          {!props.instance && capitalizeFirstLetter(props.env)}
        </BreadcrumbItem>
      )}
      {props.instance && (
        <BreadcrumbItem active={true} className="text-muted">
          {props.instance.name}
        </BreadcrumbItem>
      )}
      {props.name && (
        <BreadcrumbItem active={true} className="text-muted">
          {props.name}
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default BreadCrumbCusto;
