import { log } from "../modules/logger";
import { QUICK_ACTIONS } from "./constants";
import { launchQuickTest } from "../modules/status";

const moduleName = "quickActions";

export const initiateQuick = async (userId, instanceId, token, limitedUrl) => {
  log(moduleName, "initiate quick test", { userId });

  let data = null;

  try {
    data = await launchQuickTest(userId, instanceId, token, limitedUrl);
    return data;
  } catch (err) {
    return null;
  }
};
