/*eslint-disable*/
import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
} from "reactstrap";
import { Link, useLocation, useHistory } from "react-router-dom";

import AppContext from "../contexts/appContext";
import monitoringRoutes from "../routes/monitoringRoutes";

import "./Sidebar.css";
import CONFIG from "../modules/environment";
import statsRoutes from "../routes/statsRoutes";
import { createInstance, setCurrentInstance } from "../actions/instanceActions";
import { HOST_ROLE, ROLE } from "../modules/bm";
import { animals, colors, uniqueNamesGenerator } from "unique-names-generator";
import configRoutes from "../routes/configRoutes";
import archiveRoutes from "../routes/archiveRoutes";
import commonRoutes from "../routes/commonRoutes";
import allRoutes from "../routes/allRoutes";
import testingRoutes from "../routes/testingRoutes";
import operationRoutes from "../routes/operationRoutes";

var ps;

const Sidebar = (props) => {
  const history = useHistory();
  const appState = useContext(AppContext);
  const sidebarRef = useRef(null);
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState(
    uniqueNamesGenerator({
      dictionaries: [colors, animals],
    }),
  );
  const [role, setRole] = useState(HOST_ROLE.COTURN);

  useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  useEffect(() => {}, [location]);

  useEffect(() => {}, [history]);

  const activeRoute = (routeName) => {
    let currentRoute = routeName;
    if (appState.currentInstance) {
      currentRoute = routeName.replace(":hostId", appState.currentInstance._id);
    }
    return location.pathname === currentRoute
      ? "active menu-entry"
      : "menu-entry";
  };

  const { activeColor, logo, instances } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <Link
          to={logo.outterLink}
          className="simple-text logo-mini"
          onClick={props.closeSidebar}
        >
          <span
            style={{
              fontSize: "16px",
              backgroundColor: "#1A65FB",
              color: "#fff",
              padding: "3px 8px 6px",
              borderRadius: "50%",
            }}
          >
            <i className="tim-icons icon-molecule-40" />
          </span>
        </Link>
      );
      logoText = (
        <Link
          to={logo.outterLink}
          className="simple-text logo-normal"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }

  const onHostChange = async (instance) => {
    await setCurrentInstance(instance, props.dispatch);
    if (!instance) {
      history.push("/dashboard");
    } else {
      // Get the location
      const pathName = location.pathname;
      const parts = pathName.split("/");
      if (parts.length > 3) {
        const screen = parts[parts.length - 1];
        const section = parts[parts.length - 3];
        history.push(`/${section}/${instance._id}/${screen}`);
      } else {
        history.push(`/host/${instance._id}/board`);
      }
    }
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleModal = () => setModal(!modal);

  const addNewHost = () => {
    setName(
      uniqueNamesGenerator({
        dictionaries: [colors, animals],
      }),
    );
    setRole(HOST_ROLE.COTURN);
    setModal(true);
  };

  const onCreateNewHost = async () => {
    setModal(false);
    const newHost = await createInstance(
      appState.user._id,
      appState.token,
      props.dispatch,
      name,
      role,
    );
    //history.push(`/settings`);
  };

  const buildPathWithHostId = (path) => {
    if (!appState.currentInstance) {
      return path;
    }

    return path.replace(":hostId", appState.currentInstance._id);
  };

  return (
    <div className="sidebar" data={activeColor}>
      <Modal isOpen={modal} toggle={toggleModal} className="sidebar-modal">
        <ModalHeader>Create a new host</ModalHeader>
        <ModalBody>
          <p className="sidebar-modal-text">
            Enter the information and click on 'Create' to have your new host
            ready!
          </p>
          <br />
          <Form>
            <FormGroup>
              <Label for="hostName">Name</Label>
              <Input
                name="hostName"
                placeholder="Name of the host"
                value={name}
                className="sidebar-modal-input"
                onChange={(e) => setName(e.currentTarget.value)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Label for="hostCategory">Category</Label>
              <Input
                name="hostCategory"
                type="select"
                value={role}
                className="sidebar-modal-select"
                onChange={(e) => setRole(e.currentTarget.value)}
              >
                <option>{HOST_ROLE.COTURN}</option>
                <option>{HOST_ROLE.ETURNAL}</option>
                <option>{HOST_ROLE.JITSI}</option>
                <option>{HOST_ROLE.SIG}</option>
                <option>{HOST_ROLE.JSONGLE}</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="" onClick={() => onCreateNewHost()}>
            Create
          </Button>
        </ModalFooter>
      </Modal>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        {logoImg !== null || logoText !== null ? (
          <>
            <div className="sidebar-host">
              <Dropdown
                isOpen={dropdownOpen && appState.instances?.length > 0}
                toggle={toggle}
              >
                <DropdownToggle
                  color="dark"
                  className="sidebar-picker"
                  data-toggle="dropdown"
                >
                  <i className="icon cafe-host icon-small" />
                  <span className="sidebar-picker-name">
                    {appState.currentInstance
                      ? appState.currentInstance.name
                      : appState.user.company || "ALL HOSTS"}
                  </span>
                </DropdownToggle>
                {appState.instances?.length > 0 && (
                  <DropdownMenu dark className="sidebar-picker">
                    <DropdownItem
                      active={!appState.currentInstance}
                      key="9999"
                      onClick={() => onHostChange(null)}
                    >
                      {appState.user.company || "ALL HOSTS"}
                    </DropdownItem>
                    <DropdownItem divider />
                    {appState.instances?.map((instance, key) => (
                      <DropdownItem
                        active={instance._id === appState.currentInstance?._id}
                        key={key}
                        onClick={() => onHostChange(instance)}
                      >
                        {instance.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                )}
              </Dropdown>
              <div className="sidebar-new-host">
                <Button
                  color="link"
                  size="sm"
                  className="sidebar-add-button"
                  onClick={() => addNewHost()}
                >
                  + add new host
                </Button>
              </div>
            </div>
          </>
        ) : null}
        {appState.currentInstance && (
          <Nav>
            <li className="menu-separator mt-3">PREPARE</li>
            {monitoringRoutes.map((prop, key) => {
              return (
                <li
                  className={activeRoute(prop.path)}
                  key={key}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to={buildPathWithHostId(prop.path)}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </Link>
                </li>
              );
            })}

            <li className="menu-separator mt-3">ANALYZE</li>
            {statsRoutes.map((prop, key) => {
              return (
                <li
                  className={activeRoute(prop.path)}
                  key={key}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to={buildPathWithHostId(prop.path)}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </Link>
                </li>
              );
            })}

            <li className="menu-separator mt-3">OPERATE</li>
            {operationRoutes.map((prop, key) => {
              return (
                <li
                  className={activeRoute(prop.path)}
                  key={key}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to={buildPathWithHostId(prop.path)}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </Link>
                </li>
              );
            })}

            {appState.currentInstance?.role.startsWith(ROLE.RELAY) && (
              <>
                <li className="menu-separator mt-3">CONTROL</li>
                {testingRoutes.map((prop, key) => {
                  return (
                    <li
                      className={activeRoute(prop.path)}
                      key={key}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <Link to={buildPathWithHostId(prop.path)}>
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </Link>
                    </li>
                  );
                })}
              </>
            )}

            {/*<li className="menu-separator mt-3">CONFIGURE</li>*/}
            {/*{configRoutes.map((prop, key) => {*/}
            {/*  return (*/}
            {/*    <li*/}
            {/*      className={activeRoute(prop.path)}*/}
            {/*      key={key}*/}
            {/*      onClick={() => {*/}
            {/*        window.scrollTo({ top: 0, behavior: "smooth" });*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Link to={prop.path}>*/}
            {/*        <i className={prop.icon} />*/}
            {/*        <p>{prop.name}</p>*/}
            {/*      </Link>*/}
            {/*    </li>*/}
            {/*  );*/}
            {/*})}*/}
          </Nav>
        )}
        {!appState.currentInstance && (
          <Nav>
            <li className="menu-separator mt-3">VIEW</li>
            {allRoutes.map((prop, key) => {
              return (
                <li
                  className={activeRoute(prop.path)}
                  key={key}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to={prop.path}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </Link>
                </li>
              );
            })}

            <li className="menu-separator mt-3">DOWNLOAD</li>
            {archiveRoutes.map((prop, key) => {
              return (
                <li
                  className={activeRoute(prop.path)}
                  key={key}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to={prop.path}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </Link>
                </li>
              );
            })}

            <li className="menu-separator mt-3">SETUP</li>
            {commonRoutes.map((prop, key) => {
              return (
                <li
                  className={activeRoute(prop.path)}
                  key={key}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to={prop.path}>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </Link>
                </li>
              );
            })}
          </Nav>
        )}
      </div>
      <center>
        <small>{`${CONFIG.appCopyright} © ${new Date().getFullYear()} - v${
          CONFIG.appVersion
        }`}</small>
      </center>
    </div>
  );
};

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  rtlActive: PropTypes.bool,
  logo: PropTypes.shape({
    outterLink: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func,
};

export default Sidebar;
