import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

import AppContext from "../contexts/appContext";

import CardNumber from "./CardNumber";

import MapBoard from "./MapBoard";
import Outages from "./Outages";
import { getDuration } from "../modules/helper";
import DashboardPlaceHolder from "./placeholder/DashboardPlaceHolder";
import NoHost from "./NoHost";
import { ROLE } from "../modules/bm";
import GlobalStatusChart from "./charts/GlobalStatusChart";
import GlobalStatusPieChart from "./charts/GlobalStatusPieChart";
import Series from "./Series";
import { useHistory, useLocation } from "react-router-dom";
import { setCurrentInstance } from "../actions/instanceActions";
import BreadCrumbCusto from "./BreadCrumb";

function Main(props) {
  const location = useLocation();

  const appState = useContext(AppContext);
  const [status, setStatus] = useState({});

  useEffect(() => {
    setStatus(appState.overallSummaryStatus);
  }, [appState.overallSummaryStatus]);

  const countGlobalSLAForPeriod = (sla) => {
    if (!sla || sla.length === 0) {
      return "100 %";
    }
    return `${(sla.reduce((p, c) => p + c.sla, 0) / sla.length).toFixed(3)} %`;
  };

  const goTo = async (instance) => {
    if (!instance) {
      await setCurrentInstance(null, props.dispatch);
    }
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <DashboardPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={null}
              env={location.pathname.substring(1)}
              name={null}
            />
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <MapBoard
                  dispatch={props.dispatch}
                  env={location.pathname.substring(1)}
                />
              </Col>
              <Col xxl="10" xl="9" lg="9" md="9" sm="9" xs="9">
                <GlobalStatusChart
                  status={status ? status : {}}
                  property={location.pathname.substring(1)}
                  title="DAILY STATUS"
                />
              </Col>
              <Col xxl="2" xl="3" lg="3" md="3" sm="3" xs="3">
                <GlobalStatusPieChart
                  status={status ? status : {}}
                  property={location.pathname.substring(1)}
                  title="STATUS"
                />
              </Col>
              <Col xxl="6" xl="12" lg="12" md="12" sm="12" xs="12"></Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <Series dispatch={props.dispatch} />
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <Outages dispatch={props.dispatch} />
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default Main;
