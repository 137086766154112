import React, { useContext } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import {
  NavbarBrand,
  Navbar,
} from "reactstrap";

import AppContext from "../contexts/appContext";

import "./AdminNavbar.css";

const PublicNavBar = (props) => {
  const [color, setColor] = React.useState("navbar-transparent");
  const appState = useContext(AppContext);

  return (
    <>
      <Navbar
        style={{ position: "fixed" }}
        className={classNames("navbar-absolute", {
          [color]: false,
        })}
        expand="lg"
      >
        <div className="navbar-wrapper">
          <NavbarBrand className="brandIcon">
            {`${props.brandText}`}
            <i className="icon cafe-cafe pt-2 ml-1 mr-0" />

            <span className="ml-3">

            </span>
          </NavbarBrand>
        </div>
      </Navbar>
    </>
  );
};

export default PublicNavBar;
