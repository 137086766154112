import React, { useContext, useEffect, useRef, useState } from "react";
import NoHost from "../NoHost";
import { Col, Row } from "reactstrap";
import AppContext from "../../contexts/appContext";
import IceChart from "../charts/IceChart";
import MediaChart from "../charts/MediaChart";
import HostManualTests from "./HostManualTests";
import HostServicesPlaceHolder from "../placeholder/HostServicesPlaceHolder";

function HostServices(props) {
  const appState = useContext(AppContext);

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostServicesPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <HostManualTests />
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <IceChart />
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <MediaChart />
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default HostServices;
