const PROPERTIES = {
  "listening-port": {
    text: "TURN listener port for UDP and TCP (Default: 3478)",
  },
  "tls-listening-port": {
    text: "TURN listener port for TLS (Default: 5349)",
  },
  "alt-listening-port": {
    text: "Alternative listening port for UDP and TCP listeners; This is needed for RFC 5780 support",
  },
  "alt-tls-listening-port": {
    text: "Alternative listening port for TLS and DTLS protocols",
  },
  "tcp-proxy-port": {
    text: "TCP reverse proxy in front of the STUN server",
  },
  "listening-ip": {
    text: "Listener IP address of relay server. Multiple listeners can be specified",
  },
  "relay-ip": {
    text:
      "Relay address (the local IP address that will be used to relay the packets to the peer)." +
      " Multiple relay addresses may be used. The same IP(s) can be used as both listening IP(s) and relay IP(s)",
  },
  "external-ip": {
    text: "TURN Server public/private address mapping, if the server is behind NAT. Format could be public-ip/private-ip or just public-ip",
  },
  "min-port": {
    text: "Lower bounds of the UDP relay endpoints",
  },
  "max-port": {
    text: "Upper bounds of the UDP relay endpoints",
  },
  verbose: {
    text: "'normal' or 'moderate' verbose mode. By default the verbose mode is off",
  },
  Verbose: {
    text: "'extra' verbose mode",
  },
  fingerprint: {
    text: "Use fingerprints in the TURN messages. By default, the fingerprints are off",
  },
  "lt-cred-mech": {
    text: "long-term credential mechanism. By default no credentials mechanism is used (any user allowed)",
  },
  "no-auth": {
    text: "This option is the opposite of lt-cred-mech. TURN Server with no-auth option allows anonymous access.",
  },
  prometheus: {
    text: "Prometheus exporter. Expose the endpoint /metrics on port 9641",
  },
  "use-auth-secret": {
    text: "Time Limited Long Term Credential based upon authentication secret",
  },
  "static-auth-secret": {
    text: "'Static' authentication secret value",
  },
  oauth: {
    text: "Allow oAuth authentication",
  },
  "server-name": {
    text: "Server name used for the oAuth authentication purposes. The default value is the realm name",
  },
  user: {
    text: "'Static' user accounts for the long term credentials mechanism, only",
  },
  "redis-statsdb": {
    text: "Redis status and statistics database connection string, if used (default - empty, no Redis stats DB used)",
  },
  realm: {
    text: "The default realm to be used for the users",
  },
  "check-origin-consistency": {
    text: "This flag sets the origin consistency check. Across the session, all requests must have the same main ORIGIN attribute value",
  },
  "user-quota": {
    text: "Per-user allocation quota default value is 0 (no quota, unlimited number of sessions per user)",
  },
  "total-quota": {
    text: "Total allocation quota. default value is 0 (no quota)",
  },
  "max-bps": {
    text: "Max bytes-per-second bandwidth a TURN session is allowed to handle (input and output network streams are treated separately). Anything above that limit will be dropped or temporarily suppressed",
  },
  "bps-capacity": {
    text: "Maximum server capacity. Total bytes-per-second bandwidth the TURN server is allowed to allocate for the sessions, combined (input and output network streams are treated separately)",
  },
  "no-udp": {
    text: "Disable UDP client listener. Always started by default",
  },
  "no-tcp": {
    text: "Disable TCP client listener. Always started by default",
  },
  "no-tls": {
    text: "Disable TLS client listener. Always started by default",
  },
  "no-dtls": {
    text: "Disable TLS client listener. Always started by default",
  },
  "no-udp-relay": {
    text: "Disable UDP relay endpoints. By default UDP relay endpoints are enabled",
  },
  "no-tcp-relay": {
    text: "Disable TCP relay endpoints. By default TCP relay endpoints are enabled",
  },
  "stale-nonce": {
    text: " limit the nonce lifetime. 0 for unlimited lifetime. Default to 600 secs (10 min) if no value is provided. After that delay, the client will get 438 error and will have to re-authenticate itself",
  },
  "max-allocate-lifetime": {
    text: "Maximum allocation time. Default is 3600s",
  },
  "channel-lifetime": {
    text: "lifetime for the channel. Default value is 600 secs (10 minutes)",
  },
  "permission-lifetime": {
    text: "set the permission lifetime. Default to 300 secs (5 minutes)",
  },
  cert: {
    text: "Certificate file. PEM format",
  },
  pkey: {
    text: "Private key file. PEM format",
  },
  "pkey-pwd": {
    text: "Private key password",
  },
  "cipher-list": {
    text: "Allowed OpenSSL cipher list for TLS/DTLS connections. Default value is 'DEFAULT'",
  },
  "CA-file": {
    text: "CA file in OpenSSL format. Forces TURN server to verify the client SSL certificates. By default this is not set",
  },
  "ec-curve-name": {
    text: "Curve name for EC ciphers, if supported by OpenSSL library (TLS and DTLS). The default value is prime256v1",
  },
  "no-stdout": {
    text: "Flag to prevent stdout log messages. By default, all log messages go to both stdout and to the configured log file",
  },
  "log-file": {
    text: "log file name. By default, the turnserver tries to open a log file in /var/log, /var/tmp, /tmp and the current directory",
  },
  syslog: {
    text: "Redirect all log output into system log (syslog)",
  },
  "simple-log": {
    text: "Prevent log file to rollover. Log file name will be constructed as-is, without PID and date appendage",
  },
  "new-log-timestamp": {
    text: "Enable full ISO-8601 timestamp in all logs",
  },
  "new-log-timestamp-format": {
    text: "Set timestamp format (in strftime(1) format). Eg: '%FT%T%z'",
  },
  "alternate-server": {
    text: "set the 'redirection' mode",
  },
  "tls-alternate-server": {
    text: "set alternative server for TLS & DTLS services in form of <ip>:<port>",
  },
  "stun-only": {
    text: "suppress TURN functionality, only STUN requests will be processed. Run as STUN server only, all TURN requests will be ignored",
  },
  "no-software-attribute": {
    text: "Hide software version. Enhance security when used in production",
  },
  "no-stun": {
    text: "Suppress STUN functionality, only TURN requests will be processed. Run as TURN server only, all STUN requests will be ignored",
  },
  "allow-loopback-peers": {
    text: "Allow peers on the loopback addresses (127.x.x.x and ::1). This is an extra security measure",
  },
  "no-multicast-peers": {
    text: "Disallow peers on well-known broadcast addresses (224.0.0.0 and above, and FFXX:*). This is an extra security measure",
  },
  "max-allocate-timeout": {
    text: "set the max time, in seconds, allowed for full allocation establishment. Default is 60 seconds",
  },
  "denied-peer-ip": {
    text: "Ban specific ip addresses or ranges of ip addresses",
  },
  "allowed-peer-ip": {
    text: "Allow specific ip addresses or ranges of ip addresses",
  },
  "secure-stun": {
    text: "Require authentication of the STUN Binding request",
  },
  "no-cli": {
    text: "Turn OFF the CLI support. By default it is always ON.",
  },
  "cli-ip": {
    text: "Local system IP address to be used for CLI server endpoint. Default value is 127.0.0.1",
  },
  "cli-port": {
    text: "CLI server port. Default is 5766",
  },
  "cli-password": {
    text: "CLI access password. Default is empty (no password)",
  },
  "web-admin": {
    text: "Enable Web-admin support on https. By default it is Disabled",
  },
  "web-admin-ip": {
    text: "Local system IP address to be used for Web-admin server endpoint. Default value is 127.0.0.1",
  },
  "web-admin-port": {
    text: "Web-admin server port. Default is 8080",
  },
  "web-admin-listen-on-workers": {
    text: "Web-admin server listen on STUN/TURN worker threads By default it is disabled for security reasons",
  },
  "no-tlsv1": {
    text: "Do not allow an TLS/DTLS version of protocol",
  },
  "no-tlsv1_1": {
    text: "Do not allow an TLS/DTLS version of protocol",
  },
  "no-tlsv1_2": {
    text: "Do not allow an TLS/DTLS version of protocol",
  },
  "no-rfc5780": {
    text: "Disable RFC5780 (NAT behavior discovery)",
  },
  "no-stun-backward-compatibility": {
    text: "Disable handling old STUN Binding requests and disable MAPPED-ADDRESS attribute in binding response (use only the XOR-MAPPED-ADDRESS). Strongly encouraged to use this option to decrease gain factor in STUN binding responses",
  },
  "response-origin-only-with-rfc5780": {
    text: "Only send RESPONSE-ORIGIN attribute in binding response if RFC5780 is enabled. Strongly encouraged to use this option to decrease gain factor in STUN binding responses",
  },
};

export const getPropertyDetailsFromName = (name) => {
  if (!(name in PROPERTIES)) {
    return "";
  }
  return PROPERTIES[name].text;
};
