import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
} from "reactstrap";

import JitterStat from "../charts/JitterStats";
import RTTStats from "../charts/RTTStats";
import MOS from "../charts/MOSStats";
import PacketsLost from "../charts/PaquetsLostStats";
import Media from "./media";
import BytesStat from "../charts/BytesStats";
import BandwidthStat from "../charts/BandwidthStats";

import AppContext from "../../contexts/appContext";
import NoHost from "../NoHost";
import { CALL_STATE } from "../../reducers/appReducer";
import { createNewSeries } from "../../actions/seriesActions";
import MediaPlaceHolder from "../placeholder/MediaPlaceHolder";
import { getMediaErrorDetailsFromCode } from "../../modules/MediaErrors";
import { isStunConfigured, isTurnConfigured } from "../../modules/helper";
import NoSetup from "../NoSetup";
import BreadCrumbCusto from "../BreadCrumb";

let startTime = null;
let endTime = null;
const TIMEOUT = 5000;

function Call({ dispatch }) {
  const appState = useContext(AppContext);
  const [testDone, setTestDone] = useState(false);
  const [mediaError, setMediaError] = useState([]);
  const [host, setHost] = useState(appState.currentInstance);

  useEffect(() => {
    if (!host || host._id !== appState.currentInstance._id) {
      setHost(appState.currentInstance);
    }
  }, [appState.currentInstance]);

  const computeScore = () => {
    let score = "E";
    if (testDone) {
      if (appState.callState >= CALL_STATE.AUTHORIZED) {
        score = "D";
      }
      if (appState.callState >= CALL_STATE.CANDIDATED) {
        score = "C";
      }
      if (appState.callState >= CALL_STATE.CONNECTED) {
        score = "C+";
      }
      if (appState.callState >= CALL_STATE.CALLED) {
        if (appState.finalMOSEModel?.avg > 4) {
          if (
            appState.finalJitter?.avg < 20 &&
            appState.finalRTT?.avg < 120 &&
            appState.finalPacketsLostAudio?.avg === 0
          ) {
            score = "A++";
          } else if (
            appState.finalJitter?.avg < 20 ||
            appState.finalRTT?.avg < 120 ||
            appState.finalPacketsLostAudio?.avg === 0
          ) {
            score = "A+";
          } else {
            score = "A";
          }
        } else if (appState.finalMOSEModel?.avg > 3.6) {
          score = "B+";
        } else if (appState.finalMOSEModel?.avg > 3.2) {
          score = "B";
        } else {
          score = "B-";
        }
      }
      return score;
    }
    return "-";
  };

  useEffect(() => {
    if (appState.callState === CALL_STATE.IDLE) {
      startTime = null;
      endTime = null;
      setMediaError([]);
      setTestDone(false);
    } else if (appState.callState % 2 === 1) {
      mediaError.push(getMediaErrorDetailsFromCode(appState.callState));
      setTestDone(true);
    } else if (
      appState.callState === CALL_STATE.ENDED &&
      appState.finalMOSEModel?.avg
    ) {
      if (appState.finalMOSEModel?.avg < 3.6) {
        mediaError.push(getMediaErrorDetailsFromCode(59));
      }
      if (appState.finalPacketsLostAudio?.avg > 1) {
        mediaError.push(getMediaErrorDetailsFromCode(69));
      }
      setTestDone(true);
    }
  }, [appState.callState, appState.finalMOSEModel]);

  useEffect(() => {
    let connectionTime = appState.elapsedTime || TIMEOUT;
    const score = computeScore();
    const isSuccess = (score) => ["A", "A+", "A++", "B", "B+"].includes(score);
    const isWarning = (score) => ["C+", "B"].includes(score);

    if (testDone) {
      createNewSeries(
        appState.user._id,
        appState.currentInstance._id,
        "media",
        new Date().toJSON(),
        isSuccess(score) ? "success" : isWarning(score) ? "warning" : "error",
        {
          connectionTime,
          score,
          mos: appState.finalMOSEModel.avg,
          address: appState.address || null,
          fromLatitude: appState.currentInstance.latitude,
          fromLongitude: appState.currentInstance.longitude,
        },
        appState.token,
        dispatch,
      );
    }
  }, [testDone]);

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <MediaPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            {(!isStunConfigured(host, appState.settings) ||
              !isTurnConfigured(host, appState.settings)) && <NoSetup />}
            <BreadCrumbCusto
              dispatch={dispatch}
              instance={host}
              env={host?.env}
              name={null}
            />
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Media
                  dispatch={dispatch}
                  scoreFct={computeScore}
                  onCallReset={() => {
                    setTestDone(false);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <RTTStats />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <JitterStat />
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <MOS />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <PacketsLost />
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <BytesStat />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12">
                <BandwidthStat />
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5 className="card-category">Media</h5>
                    <CardTitle tag="h3">ERRORS</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-left">Type</th>
                          <th className="text-left">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mediaError.map((error, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              <span
                                className={
                                  error.level === "error"
                                    ? "text-danger"
                                    : "text-warning"
                                }
                              >
                                {error.code}
                              </span>
                            </td>
                            <td className="text-left">{error.text}</td>
                          </tr>
                        ))}
                        {mediaError.length === 0 && (
                          <tr>
                            <td colSpan="6">
                              <br />
                              {testDone ? "No error found" : "No result yet"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default Call;
