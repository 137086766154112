import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import {
  Button,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Card,
  CardBody,
  Table,
  ModalHeader,
  Modal,
  ModalBody,
  Label,
  FormGroup,
  Input,
  ModalFooter,
  Badge
} from "reactstrap";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import AppContext from "../../contexts/appContext";
import {
  OPERATIONS_COMPLEXITY,
  OPERATIONS_DURATION,
  OPERATIONS_IMPACT, OPERATIONS_STATE,
  OPERATIONS_TYPE,
} from "../../modules/bm";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";
import {
  createAnOperation,
  deleteAnOperation, getAllOperationsForAHost, getAllOperationsPagedForAHost,
  setCurrentOperation,
  updateAnOperation
} from "../../actions/operationActions";
import "./Operations.css";
import {useHistory} from "react-router-dom";
import {getNameFromOperation, getTypeList} from "../../modules/helper";

function Operations(props) {
  const appState = useContext(AppContext);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const [operations, setOperations] = useState([]);
  const [title, setTitle] = useState(`OPS-${Date.now()}`);
  const [operation, setOperation] = useState("upgrade");
  const [planned, setPlanned] = useState(
    DateTime.now().plus({hours: 1}).set({minutes: 0}).toJSDate(),
  );
  const [estimatedDuration, setEstimatedDuration] = useState(1);
  const [estimatedImpact, setEstimatedImpact] = useState(1);
  const [estimatedComplexity, setEstimatedComplexity] = useState(2);
  const [instructions, setInstructions] = useState("");
  const [owner, setOwner] = useState("");
  const [operationId, setOperationId] = useState(null);
  const [params, setParams] = useState({
    page: 0,
    perPage: 15,
  });

  useEffect(() => {
    setOperations(appState.pagedOperations);
  }, [appState.pagedOperations]);

  useEffect(() => {
    if (appState.currentInstance) {
      getAllOperationsPagedForAHost(appState.user._id, appState.currentInstance._id, params.page, params.perPage, appState.token, props.dispatch);
    }
  }, [params, appState.currentInstance]);

  const toggle = () => setModal(!modal);

  const reset = () => {
    setOperationId(null);
    setOwner("");
    setInstructions("");
    setEstimatedComplexity(2)
    setEstimatedImpact(1)
    setEstimatedDuration(1)
    setPlanned(DateTime.now().plus({hours: 1}).set({minutes: 0}).toJSDate());
    setTitle(`OPS-${Date.now()}`);
  }


  const save = () => {
    const operationsParams = {
      title,
      operation,
      planned: planned.toJSON(),
      estimatedDuration,
      estimatedImpact,
      estimatedComplexity,
      instructions,
      owner,
      page: params.page,
      perPage: params.perPage,
    };

    if (operationId) {
      updateAnOperation(appState.user._id, appState.currentInstance._id, operationId, operationsParams, appState.token, props.dispatch);
    } else {
      createAnOperation(
        appState.user._id,
        appState.currentInstance._id,
        operationsParams,
        appState.token,
        props.dispatch,
      );
    }

    toggle();
    reset();
  };

  const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
    <Button size="sm" color="secondary" onClick={onClick}>
      {value}
    </Button>
  ));

  const editOperation = (operation) => {
    setOwner(operation.owner);
    setOperation(operation.operation);
    setTitle(operation.title);
    setInstructions(operation.instructions);
    setEstimatedComplexity(operation.estimatedComplexity);
    setEstimatedImpact(operation.estimatedImpact);
    setEstimatedDuration(operation.estimatedDuration);
    setPlanned(new Date(operation.planned));
    setOperationId(operation._id);
    toggle();
  }

  const deleteOperation = (operation) => {
    deleteAnOperation(appState.user._id, appState.currentInstance._id, operation._id, params.perPage, appState.token, props.dispatch);
  }

  const startOperation = (operation) => {
    setCurrentOperation(operation, props.dispatch);
    history.push(`/host/${operation.instanceId}/operations/${operation._id}/operate`);
  }

  const getOperationStatusColor = (status) => {
    switch (status) {
      case "planned":
        return "blue";
      case "done":
        return "success";
      case "failed":
        return "danger";
      case "canceled":
        return "light";
      default:
        return "light";
    }
  }

  const getOperationDuration = (started, ended) => {
    const startedAt = DateTime.fromISO(started);
    const endedAt = DateTime.fromISO(ended);
    const diff = endedAt.diff(startedAt, "minutes").toObject();
    return +(diff.minutes.toFixed(0));
  }

  const detectNoNextPage = () => {
    return (
      (params.page + 1) * appState.operationsPerPage >=
      appState.operationsCount
    );
  };

  const detectNoPreviousPage = () => {
    return params.page === 0;
  };

  const goToFirstPage = () => {
    setParams({...params, page: 0});
  };

  const goToLastPage = () => {
    setParams({
      ...params,
      page: Math.floor(Math.max(appState.operationsCount - 1, 0) / params.perPage),
    });
  };

  const goToNextPage = async () => {
    setParams({...params, page: params.page + 1});
  };

  const goToPreviousPage = async () => {
    setParams({...params, page: params.page - 1});
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder/>}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch}/>
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="9">
                      <h5 className="card-category">
                        All operations in a glance
                      </h5>
                      <CardTitle tag="h3">OPERATIONS</CardTitle>
                    </Col>
                    <Col className="text-right" sm="3">
                      <Button size="sm" color="primary" onClick={toggle}>
                        New Operation
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <p className="mb-4 text-muted">
                    Enable one or several public links to let your users test
                    your host. Results are displayed below.
                  </p>
                  <Table>
                    <thead className="text-primary">
                    <tr>
                      <th className="text-left ">Planned</th>
                      <th className="text-left">Type</th>
                      <th className="text-left w-25">Name</th>
                      <th className="text-center">Status</th>
                      <th className="text-right">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {operations && operations.map((operation, key) => (
                      <tr key={key}>
                        <td className="text-left ">{DateTime.fromISO(operation.planned).toFormat("fff")}</td>
                        <td
                          className="text-left">
                          <Badge color="blue">
                            {getNameFromOperation(operation.operation)}
                          </Badge>
                        </td>
                        <td className="text-left w-25">{operation.title}</td>
                        <td className="text-center">
                          <Badge pill color={getOperationStatusColor(operation.state)}>
                            {operation.state}
                          </Badge>
                          {operation.started && operation.ended && (
                            <span className="text-muted ml-2"
                                  style={{fontSize: "12px"}}>in {getOperationDuration(operation.started, operation.ended)} minutes</span>
                          )}
                        </td>
                        <td className="text-right">
                          <Button className="actions-buttons" size="sm" color="link" onClick={() => {
                            deleteOperation(operation)
                          }}> <i className="icon cafe-delete"/></Button>
                          <Button className="actions-buttons" size="sm" onClick={() => {
                            editOperation(operation)
                          }}>
                            <i className="icon cafe-edit"/>
                          </Button>
                          <Button className="actions-buttons" size="sm" color="primary" onClick={() => {
                            startOperation(operation)
                          }}> <i className="icon cafe-play"/></Button>
                        </td>
                      </tr>
                    ))}
                    {!operations || operations.length === 0 && (
                      <tr>
                        <td colSpan="5">No operations yet</td>
                      </tr>
                    )}
                    </tbody>
                  </Table>
                  <div className="mt-2">
                    <p className="text-muted" style={{fontSize: "12px"}}>{appState.operationsCount} operations found</p>
                    <Button
                      disabled={
                        appState.operationsInProgress || detectNoPreviousPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToFirstPage}
                    >
                      <i className="icon cafe-first"/>
                    </Button>
                    <Button
                      disabled={
                        appState.operationsInProgress || detectNoPreviousPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToPreviousPage}
                    >
                      {" "}
                      <i className="icon cafe-back"/>{" "}
                    </Button>
                    <Button
                      disabled={
                        appState.operationsInProgress || detectNoNextPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToNextPage}
                    >
                      {" "}
                      <i className="icon cafe-forward"/>{" "}
                    </Button>
                    <Button
                      disabled={
                        appState.operationsInProgress || detectNoNextPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToLastPage}
                    >
                      <i className="icon cafe-last"/>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      <Modal
        isOpen={modal}
        toggle={toggle}
        unmountOnClose={unmountOnClose}
        size="lg"
      >
        <ModalHeader toggle={toggle} className="popup-title">
          Create a new Operation
        </ModalHeader>
        <ModalBody>
          <Label>Title</Label>
          <FormGroup>
            <Input
              type="text"
              className="input-color"
              placeholder="Title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </FormGroup>
          <Label>Operation</Label>
          <FormGroup>
            <Input
              name="select"
              type="select"
              className="form-control text-active"
              value={operation}
              onChange={(e, b, c) => {
                setOperation(e.target.value);
              }}
            >
              {getTypeList(OPERATIONS_TYPE)}
            </Input>
          </FormGroup>
          <label>When</label>
          <FormGroup>
            <DatePicker
              selected={planned}
              onChange={(date) => {
                setPlanned(date);
              }}
              showTimeInput
              customInput={<ExampleCustomInput/>}
            />
            <span className="ml-2 text-active">
              At{" "}
              {DateTime.fromJSDate(planned).toLocaleString(
                DateTime.TIME_SIMPLE,
              )}
            </span>
          </FormGroup>
          <Row>
            <Col md="4">
              <Label>Estimated duration</Label>
              <FormGroup>
                <Input
                  name="select"
                  type="select"
                  className="form-control text-active"
                  value={estimatedDuration}
                  onChange={(e, b, c) => {
                    setEstimatedDuration(e.target.value);
                  }}
                >
                  {getTypeList(OPERATIONS_DURATION)}
                </Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <Label>Estimated Impact</Label>
              <FormGroup>
                <Input
                  name="select"
                  type="select"
                  className="form-control text-active"
                  value={estimatedImpact}
                  onChange={(e, b, c) => {
                    setEstimatedImpact(e.target.value);
                  }}
                >
                  {getTypeList(OPERATIONS_IMPACT)}
                </Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <Label>Estimated Complexity</Label>
              <FormGroup>
                <Input
                  name="select"
                  type="select"
                  className="form-control text-active"
                  value={estimatedComplexity}
                  onChange={(e, b, c) => {
                    setEstimatedComplexity(e.target.value);
                  }}
                >
                  {getTypeList(OPERATIONS_COMPLEXITY)}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <label>Details and Instructions</label>
          <FormGroup>
            <Input
              type="textarea"
              rows={6}
              style={{minHeight: "130px"}}
              className="form-control text-active p-2"
              placeholder="Describe the tasks to do, give some hints..."
              value={instructions}
              onChange={(e) => {
                setInstructions(e.target.value);
              }}
            />
          </FormGroup>
          <Label>Owner</Label>
          <FormGroup>
            <Input
              type="text"
              className="input-color"
              placeholder="Responsible of the operation"
              value={owner}
              onChange={(e) => {
                setOwner(e.target.value);
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="modal-align-right">
          <div>
            <Button color="secondary" onClick={toggle} className="mr-2">
              Cancel
            </Button>
            <Button color="primary" onClick={() => {
              save()
            }}>
              {operationId ? "Update" : "Create"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Operations;
