import {
  updateParameters,
  getParameters,
  getNewToken,
} from "../modules/status";

import { PARAMETERS_ACTIONS } from "./constants";
import { log } from "../modules/logger";

const moduleName = "parametersActions";

export const updateParametersForUser = async (
  userId,
  params,
  token,
  dispatch
) => {
  log(moduleName, "update parameters", { userId });
  dispatch({
    type: PARAMETERS_ACTIONS.UPDATE_PARAMETERS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateParameters(userId, params, token);

    if (data) {
      dispatch({
        type: PARAMETERS_ACTIONS.UPDATE_PARAMETER_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: PARAMETERS_ACTIONS.UPDATE_PARAMETERS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: PARAMETERS_ACTIONS.UPDATE_PARAMETERS_FAILED,
      payload: { value: null },
    });
  }
};

export const regenerateToken = async (userId, token, dispatch) => {
  log(moduleName, "update token for parameters", { userId });
  dispatch({
    type: PARAMETERS_ACTIONS.UPDATE_TOKEN_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getNewToken(userId, token);

    if (data) {
      dispatch({
        type: PARAMETERS_ACTIONS.UPDATE_TOKEN_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: PARAMETERS_ACTIONS.UPDATE_TOKEN_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: PARAMETERS_ACTIONS.UPDATE_TOKEN_FAILED,
      payload: { value: null },
    });
  }
};

export const getParametersForUser = async (userId, token, dispatch) => {
  log(moduleName, "get parameters", { userId });
  dispatch({
    type: PARAMETERS_ACTIONS.GET_PARAMETERS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getParameters(userId, token);

    if (data) {
      dispatch({
        type: PARAMETERS_ACTIONS.GET_PARAMETERS_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: PARAMETERS_ACTIONS.GET_PARAMETERS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: PARAMETERS_ACTIONS.GET_PARAMETERS_FAILED,
      payload: { value: null },
    });
  }
};
