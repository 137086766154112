import { useState, useContext, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Col,
  Row,
  Table,
  Badge,
  Button,
} from "reactstrap";
import AppContext from "../../contexts/appContext";

import "./MediaChart.css";
import { HOST_SERVICE } from "../../modules/bm";
import { getLabelFromDistance } from "../../modules/geo";

const TIMEOUT = 5000;
const SERIES_PER_PAGE = 20;

function MediaChart({ dispatch }) {
  const appState = useContext(AppContext);
  const [series, setSeries] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);

  const updateSeries = () => {
    const newFullSeries = appState.testingSeries
      .filter(
        (elt) =>
          elt.category === HOST_SERVICE.MEDIA ||
          elt.category === HOST_SERVICE.ICE
      )
      .sort((a, b) => new Date(b.created) - new Date(a.created));

    setSeries(
      newFullSeries.slice(
        page * SERIES_PER_PAGE,
        page * SERIES_PER_PAGE + SERIES_PER_PAGE
      )
    );

    setCount(newFullSeries.length);
  };

  useEffect(() => {
    if (appState.testingSeries) {
      updateSeries();
    }
  }, [appState.testingSeries]);

  useEffect(() => {
    if (series.length) {
      updateSeries();
    }
  }, [page]);

  const detectNoNextPage = () => {
    return (page + 1) * SERIES_PER_PAGE >= count;
  };

  const detectNoPreviousPage = () => {
    return page === 0;
  };

  const goToFirstPage = () => {
    setPage(0);
  };

  const goToLastPage = () => {
    setPage(Math.floor(count / SERIES_PER_PAGE));
  };

  const goToNextPage = async () => {
    setPage(page + 1);
  };

  const goToPreviousPage = async () => {
    setPage(page - 1);
  };

  return (
    <Row>
      <Col lg="12" md="12" sm="12" xs="12">
        <Card className="card">
          <CardHeader>
            <Row>
              <Col className="text-left" sm="6">
                <h5 className="card-category">
                  Compute Host Score from manual test
                </h5>
                <CardTitle tag="h3">Host Score</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table>
              <thead className="text-primary">
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-left w-25">Date</th>
                  <th className="text-center">Type</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Connection Time</th>
                  <th className="text-center">Distance</th>
                  <th className="text-right">Score</th>
                </tr>
              </thead>
              <tbody>
                {series &&
                  series.length > 0 &&
                  series.map((serie, key) => (
                    <tr key={key}>
                      <td className="text-center"></td>
                      <td className="text-left w-25">
                        {DateTime.fromISO(serie.created).toFormat("fff")}
                      </td>
                      <td className="text-center">
                        <Badge
                          className={
                            serie.category === HOST_SERVICE.ICE
                              ? "yellowBadge"
                              : "orangeBadge"
                          }
                          pill
                        >
                          {serie.category}
                        </Badge>
                      </td>
                      <td className="text-center">
                        {serie.status === "error" && (
                          <Badge pill color="danger">
                            {serie.status}
                          </Badge>
                        )}
                        {serie.status !== "error" && (
                          <span>{serie.status}</span>
                        )}
                      </td>

                      <td className="text-center">
                        {serie.connectionTime === TIMEOUT ||
                        serie.gatheringTime === TIMEOUT
                          ? "timeout"
                          : `${serie.connectionTime || serie.gatheringTime} ms`}
                      </td>
                      <td className="text-center">
                        {getLabelFromDistance(serie.distance)}
                      </td>
                      <td className="text-right">{serie.score}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <div className="stats">
              <Button
                disabled={detectNoPreviousPage()}
                className="tableButtons"
                color="info"
                size="sm"
                onClick={goToFirstPage}
              >
                <i className="icon cafe-first" />
              </Button>
              <Button
                disabled={detectNoPreviousPage()}
                className="tableButtons"
                color="info"
                size="sm"
                onClick={goToPreviousPage}
              >
                <i className="icon cafe-back" />
              </Button>
              <Button
                disabled={detectNoNextPage()}
                className="tableButtons"
                color="info"
                size="sm"
                onClick={goToNextPage}
              >
                <i className="icon cafe-forward" />
              </Button>
              <Button
                disabled={detectNoNextPage()}
                className="tableButtons"
                color="info"
                size="sm"
                onClick={goToLastPage}
              >
                <i className="icon cafe-last" />
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}

export default MediaChart;
