import { useContext, useEffect, useState } from "react";
//import mapboxgl from "mapbox-gl";
import { Link, useHistory } from "react-router-dom";
import { DateTime } from "luxon";

import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Row,
  Col,
  Table,
  Badge,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import AppContext from "../contexts/appContext";

import "./MapBoard.css";
import {
  getRoleColor,
  getRoleIcon,
  getRoleTextColor,
  isCurrentInstance,
} from "../modules/helper";
import { setCurrentInstance } from "../actions/instanceActions";
import {
  HOST_SERVICE,
  isMonitored,
  isPaused,
  isPartiallyMonitored,
  displayHostStatus,
  getHostStatusColor,
} from "../modules/bm";

let index = 0;

const getRoleDisplay = (instance) => {
  return instance.role.split("/")[0].toUpperCase();
};

function MapBoard(props) {
  const appState = useContext(AppContext);
  const history = useHistory();
  const [mapChange, mapChanged] = useState(index);
  const [versions, setVersions] = useState([]);
  const [usage, setUsage] = useState([]);
  const [env, setEnv] = useState(props.env);
  const [filteredInstances, setFilteredInstances] = useState([]);

  useEffect(() => {
    setFilteredInstances(filterHostByEnv(appState.instances, props.env));
  }, [appState.instances, props]);

  useEffect(() => {
    setVersions(appState.versions);
  }, [appState.versions]);

  useEffect(() => {
    setUsage(appState.lastUsage);
  }, [appState.lastUsage]);

  useEffect(() => {
    setEnv(props.env);
  }, [props]);

  const getLastUsage = (instanceId) => {
    if (instanceId in usage && usage[instanceId]) {
      return `${DateTime.fromISO(usage[instanceId].created).toFormat("DD")}`;
    }
    return "none";
  };

  const numberOfMonitoring = (host) => {
    let count = 0;
    if (host.active) {
      if (host[HOST_SERVICE.HEALTH].frequency > -1) count++;
      if (host[HOST_SERVICE.TESTS].frequency > -1) count++;
      if (host[HOST_SERVICE.USAGE].frequency > -1) count++;
    }

    if (count === 3) {
      return "all";
    } else if (count === 0) {
      return "";
    }
    return count;
  };

  const getVersionFor = (id) => {
    const versionFound = versions.find((item) => item._id === id);
    if (versionFound) {
      return versionFound.version;
    }
    return "-";
  };

  const hasVersionFor = (id) => {
    return versions.some((item) => item._id === id);
  };

  const filterHostByEnv = (instances, env = "production") => {
    if (env !== "others") {
      return instances.filter((instance) => instance.env === env);
    } else {
      return instances.filter(
        (instance) =>
          instance.env !== "staging" && instance.env !== "production",
      );
    }
  };

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Hosts for</h5>
        <CardTitle tag="h3">
          <i className={`icon cafe-${env} action-button-image`} />
          {env.toUpperCase()}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th className="text-left">Host</th>
                  <th className="text-left">Role</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Monitoring</th>
                  <th className="text-center">Version</th>
                  <th className="text-right">Last Usage</th>
                </tr>
              </thead>
              <tbody>
                {filteredInstances &&
                  filteredInstances.length > 0 &&
                  filteredInstances.map((instance, key) => (
                    <tr key={key} className="host">
                      <td className="text-left">
                        <i className="icon cafe-computer action-button-image" />
                        <Link
                          to="/monitoring/host"
                          onClick={() => {
                            setCurrentInstance(instance, props.dispatch);
                          }}
                        >
                          <span className="mr-1">{instance.name}</span>
                        </Link>
                      </td>
                      <td className="text-left">
                        <i
                          className={`icon cafe-${getRoleIcon(
                            instance,
                          )} text-${getRoleColor(
                            instance.role,
                          )} icon-selected mr-1`}
                        ></i>
                        <span
                          className={`text-${getRoleTextColor(instance.role)}`}
                        >
                          {getRoleDisplay(instance)}
                        </span>
                      </td>
                      <td className="text-center">
                        <Badge
                          pill
                          color={getHostStatusColor(
                            instance.status,
                            instance.active,
                          )}
                        >
                          {!instance.active && <span>&#9888;&nbsp;</span>}
                          {displayHostStatus(instance.status, instance.active)}
                        </Badge>
                      </td>
                      <td className="text-center">
                        <i
                          className={`icon ${
                            isPaused(instance)
                              ? "cafe-button-pause"
                              : "cafe-monitor"
                          } text-${
                            isMonitored(instance)
                              ? "success"
                              : isPartiallyMonitored(instance)
                              ? "warning"
                              : "light"
                          } icon-monitoring`}
                        ></i>
                        <small
                          className={`text-${
                            isMonitored(instance)
                              ? "success"
                              : isPartiallyMonitored(instance)
                              ? "warning"
                              : "light"
                          }`}
                        >
                          {numberOfMonitoring(instance)}
                        </small>
                      </td>
                      <td className="text-center">
                        {hasVersionFor(instance._id) && (
                          <Badge color="primary">
                            {getVersionFor(instance._id)}
                          </Badge>
                        )}
                        {!hasVersionFor(instance._id) && <span>-</span>}
                      </td>
                      <td className="text-right">
                        {getLastUsage(instance._id)}
                      </td>
                    </tr>
                  ))}
                {(!filteredInstances || filteredInstances.length === 0) && (
                  <tr>
                    <td colSpan="6">
                      <br />
                      No host found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default MapBoard;
