const MEDIA_ERRORS = {
  19: {
    code: "Authorization Failed",
    level: "error",
    text: "The browser can't access to the devices: Possibly not found, in use, over-constrained, disabled or not compatible.",
  },
  29: {
    code: "ICE Generation Failed",
    level: "error",
    text: "The browser didn't succeed to gather all mandatory candidates. No candidate of type 'relay' has been collected.",
  },
  39: {
    code: "ICE Connection Failed",
    level: "error",
    text: "The browser didn't succeed to connect using the candidates collected. Check your host configuration.",
  },
  49: {
    code: "Media Failed",
    level: "error",
    text: "The browser didn't succeed to send the media to the remote peer through the host. Check your host configuration.",
  },
  59: {
    code: "Low quality",
    level: "warning",
    text: "The quality of the call (MOS) is low. Users may encounter a poor experience.",
  },
  69: {
    code: "High packets lost",
    level: "warning",
    text: "The number of packets loss is high. Users may encounter a poor experience.",
  },
};

export const getMediaErrorDetailsFromCode = (code) => {
  if (!code in MEDIA_ERRORS) {
    return {
      code: `${code} - Unknown`,
      text: "Please contact the support team, this error has not been listed yet.",
    };
  }
  return MEDIA_ERRORS[code];
};
