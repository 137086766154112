import {
  getAllSettings,
  updateAllSettings,
  generateKeysForInstance,
  deleteKeysForInstance,
  testSSHForInstance,
  testHealthForInstance,
} from "../modules/status";

import { ACTIONS } from "./constants";
import { log } from "../modules/logger";

const moduleName = "settingActions";

export const getSettings = async (instanceId, token, dispatch) => {
  log(moduleName, "get all settings", { instanceId });
  dispatch({
    type: ACTIONS.LIST_SETTINGS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getAllSettings(instanceId, token);

    if (data) {
      dispatch({
        type: ACTIONS.LIST_SETTINGS_SUCCESS,
        payload: { value: { settings: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.LIST_SETTINGS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.LIST_SETTINGS_FAILED,
      payload: { value: null },
    });
  }
};

export const updateSettings = async (
  settings,
  category,
  instanceId,
  token,
  dispatch
) => {
  log(moduleName, "update settings", { instanceId, settings });
  dispatch({
    type: ACTIONS.UPDATE_SETTINGS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateAllSettings(settings, category, instanceId, token);

    if (data) {
      dispatch({
        type: ACTIONS.UPDATE_SETTINGS_SUCCESS,
        payload: { value: { settings: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.UPDATE_SETTINGS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_FAILED,
      payload: { value: null },
    });
  }
};

export const generateKeys = async (
  userId,
  instanceId,
  settings,
  token,
  dispatch
) => {
  log(moduleName, "generate new keys", { userId, instanceId });
  dispatch({
    type: ACTIONS.GENERATE_KEYS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await generateKeysForInstance(userId, instanceId, settings, token);

    if (data) {
      dispatch({
        type: ACTIONS.GENERATE_KEYS_SUCCESS,
        payload: { value: { settings: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.GENERATE_KEYS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GENERATE_KEYS_FAILED,
      payload: { value: null },
    });
  }
};

export const deleteKeys = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "delete keys", { userId, instanceId });
  dispatch({
    type: ACTIONS.DELETE_KEYS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await deleteKeysForInstance(userId, instanceId, token);

    if (data) {
      dispatch({
        type: ACTIONS.DELETE_KEYS_SUCCESS,
        payload: { value: { settings: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.DELETE_KEYS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GENERATE_KEYS_FAILED,
      payload: { value: null },
    });
  }
};

export const testSSH = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "test ssh", { userId, instanceId });
  dispatch({
    type: ACTIONS.TEST_SSH_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await testSSHForInstance(userId, instanceId, token);

    if (data.success) {
      dispatch({
        type: ACTIONS.TEST_SSH_SUCCESS,
        payload: { value: { settings: data.data } },
      });
    } else {
      dispatch({
        type: ACTIONS.TEST_SSH_FAILED,
        payload: { value: data.error.errorMsg },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.TEST_SSH_FAILED,
      payload: { value: err },
    });
  }
};

export const resetSSH = (dispatch) => {
  dispatch({
    type: ACTIONS.TEST_SSH_RESET,
    payload: null,
  });
};
