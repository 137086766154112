import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Label } from "reactstrap";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import CardNumber from "../CardNumber";
import { DateTime } from "luxon";
import GlobalStatusChart from "../charts/GlobalStatusChart";
import GlobalStatusPieChart from "../charts/GlobalStatusPieChart";
import GlobalMonthTestsChart from "../charts/GlobalMonthTestsChart";
import GlobalTestsChart from "../charts/GlobalTestsChart";
import { filterCurrentMonth } from "../../modules/helper";
import BreadCrumbCusto from "../BreadCrumb";

const defaultMonthlyValue = {
  score: 0,
  execution: 0,
  success: 100,
};

function HostGlobalTests(props) {
  const appState = useContext(AppContext);
  const [summary, setSummary] = useState(appState.summaryTests.summary);
  const [details, setDetails] = useState(appState.summaryTests.details);
  const [monthly, setMonthly] = useState(
    filterCurrentMonth(appState.summaryTests.monthly, defaultMonthlyValue),
  );

  useEffect(() => {
    setSummary(appState.summaryTests.summary);
    setDetails(appState.summaryTests.details);
    setMonthly(
      filterCurrentMonth(appState.summaryTests.monthly, defaultMonthlyValue),
    );
  }, [appState.summaryTests]);

  const getAverageScore = (count, score) => {
    return count > 0 ? score.toFixed(0) : "100";
  };

  const getAverageExecution = (count, execution) => {
    return count > 0 ? execution.toFixed(0) : "0";
  };

  const getTotalSuccessRate = (count, successRate) => {
    return count > 0 ? successRate.toFixed(0) : "100";
  };

  const getTotalTestsDone = (count) => {
    return count > 0 ? count : "none";
  };

  const getMinDailyFor = (property) => {
    const max = (details || []).reduce(
      function (prev, current) {
        return prev[property] <= current[property] ? prev : current;
      },
      details && details.length > 0
        ? details[0]
        : { [property]: 0, fake: true },
    );
    if (max && !("fake" in max)) {
      return { [property]: max[property].toFixed(0), created: max.created };
    }
    return null;
  };

  const minScoreDay = getMinDailyFor("score");
  const minExecutionDay = getMinDailyFor("execution");
  const minSuccessRate = getMinDailyFor("successRate");
  const minForTestsDone = getMinDailyFor("countWithError");

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={appState.currentInstance}
              env={appState.currentInstance?.env}
              name={null}
            />
            <Row>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="SCORE"
                  value={{
                    data: {
                      score: getAverageScore(summary.count, summary.score),
                    },
                  }}
                  prop="score"
                  icon="cafe-creation"
                  unit="%"
                  last="Score"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="EXECUTION TIME"
                  value={{
                    data: {
                      execution: getAverageExecution(
                        summary.count,
                        summary.execution,
                      ),
                    },
                  }}
                  prop="execution"
                  icon="cafe-creation"
                  unit="ms"
                  last="Average Duration"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="TESTS"
                  value={{
                    data: {
                      duration: getTotalTestsDone(summary.countWithError),
                    },
                  }}
                  prop="duration"
                  icon="cafe-creation"
                  unit="u"
                  last="Number of tests done"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="SUCCESS"
                  value={{
                    data: {
                      duration: getTotalSuccessRate(
                        summary.count,
                        summary.successRate,
                      ),
                    },
                  }}
                  prop="duration"
                  icon="cafe-creation"
                  unit="%"
                  last="Success rate"
                  badge={false}
                />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Tests Status</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="9" lg="9" md="9" sm="9" xs="9">
                <GlobalStatusChart
                  status={appState.summaryStatus ? appState.summaryStatus : []}
                  property="tests"
                  title="DAILY STATUS"
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="3">
                <GlobalStatusPieChart
                  status={appState.summaryStatus ? appState.summaryStatus : []}
                  property="tests"
                  title="STATUS"
                />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Daily Statistics</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL SCORE"
                  value={{
                    data: {
                      legs: minScoreDay ? minScoreDay.score : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={
                    minScoreDay
                      ? DateTime.fromISO(minScoreDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL EXECUTION"
                  value={{
                    data: {
                      legs: minExecutionDay ? minExecutionDay.execution : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="ms"
                  last={
                    minExecutionDay
                      ? DateTime.fromISO(minExecutionDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL TESTS"
                  value={{
                    data: {
                      legs: minForTestsDone
                        ? minForTestsDone.countWithError
                        : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="u"
                  last={
                    minSuccessRate
                      ? DateTime.fromISO(minSuccessRate.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL SUCCESS"
                  value={{
                    data: {
                      legs: minSuccessRate ? minSuccessRate.successRate : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={
                    minSuccessRate
                      ? DateTime.fromISO(minSuccessRate.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <GlobalTestsChart />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Monthly Statistics</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="SCORE"
                  value={{
                    data: {
                      legs: getAverageScore(monthly.count, monthly.score),
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="EXECUTION"
                  value={{
                    data: {
                      legs: getAverageExecution(
                        monthly.count,
                        monthly.execution,
                      ),
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="ms"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="TESTS"
                  value={{
                    data: {
                      legs: getTotalTestsDone(
                        monthly.count,
                        monthly.countWithError,
                      ),
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="u"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="SUCCESS"
                  value={{
                    data: {
                      legs: getTotalSuccessRate(
                        monthly.count,
                        monthly.successRate,
                      ),
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <GlobalMonthTestsChart />
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default HostGlobalTests;
