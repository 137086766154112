import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import AppContext from "../contexts/appContext";
import { isStunConfigured, isTurnConfigured } from "../modules/helper";

function NoSetup() {
  const appState = useContext(AppContext);
  const history = useHistory();

  return (
    <div className="card card-nav-tabs">
      <div className="card-body">
        <h4 className="card-title text-danger">
          Warning: Test will fail, your host is not configured!
        </h4>
        <div className="mt-4">
          {!isStunConfigured(appState.currentInstance, appState.settings) && (
            <p className="card-text">- No STUN Url configured</p>
          )}
          {!isTurnConfigured(appState.currentInstance, appState.settings) && (
            <p className="card-text">- No TURN Url configured</p>
          )}
        </div>
        <p className="card-text mt-4">
          Please click on the Parameters button to access the Settings!
        </p>
        <Button
          className="btn btn-primary mt-4"
          size="sm"
          onClick={() => {
            history.push("/settings");
          }}
        >
          <i className="icon cafe-options icon-selected mr-2"></i>
          Parameters
        </Button>
      </div>
    </div>
  );
}

export default NoSetup;
