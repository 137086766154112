import React from "react";
import {
  Card,
  CardBody,
  Col,
  Placeholder,
  PlaceholderButton,
  Row,
} from "reactstrap";

const HostPlaceHolder = () => {
  return (
    <Row>
      <Col lg="12" md="12" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={12} style={{ height: "40px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <PlaceholderButton />
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" lg="6" md="6" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} style={{ height: "20px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder
                xs={12}
                style={{ paddingTop: "10px", height: "60px" }}
              />
            </Placeholder>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" lg="6" md="6" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} style={{ height: "20px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder
                xs={12}
                style={{ paddingTop: "10px", height: "60px" }}
              />
            </Placeholder>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" lg="6" md="6" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} style={{ height: "20px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder
                xs={12}
                style={{ paddingTop: "10px", height: "60px" }}
              />
            </Placeholder>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" lg="6" md="6" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} style={{ height: "20px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder
                xs={12}
                style={{ paddingTop: "10px", height: "60px" }}
              />
            </Placeholder>
          </CardBody>
        </Card>
      </Col>
      <Col lg="12" md="12" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} style={{ height: "20px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={12} style={{ height: "360px" }} />
            </Placeholder>
          </CardBody>
        </Card>
      </Col>
      <Col lg="12" md="12" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} style={{ height: "20px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={12} style={{ height: "200px" }} />
            </Placeholder>
          </CardBody>
        </Card>
      </Col>
      <Col lg="12" md="12" sm="12" xs="12">
        <Card style={{ width: "100%" }}>
          <CardBody>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} style={{ height: "20px" }} />
            </Placeholder>
            <div style={{ height: "10px" }} />
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={12} style={{ height: "200px" }} />
            </Placeholder>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default HostPlaceHolder;
