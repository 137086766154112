import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Spinner,
  Table,
  Row,
} from "reactstrap";
import { startCoturn, stopCoturn } from "../../actions/instanceActions";
import Control from "./Control";

function Features(props) {
  const appState = useContext(AppContext);
  const [host, setHost] = useState(appState.currentInstance);

  useEffect(() => {
    if (appState.currentInstance) {
      setHost(appState.currentInstance);
    }
  }, [appState.currentInstance]);

  const isCoturnActive = (host) => {
    if (!host) {
      return false;
    }
    return (host.monitoredStatus || "").toLowerCase() === "active";
  };

  const isCoturnStateManageable = (host) => {
    if (!host) {
      return false;
    }
    return (
      (host.monitoredStatus || "").toLowerCase() === "active" ||
      (host.monitoredStatus || "").toLowerCase() === "inactive"
    );
  };

  const isActivateOrDesactivateButtonDisabled = (host) => {
    if (!isMonitored(host)) {
      return true;
    }
    return !isCoturnStateManageable(host);
  };

  const isMonitored = (instance) => {
    return instance && instance.active;
  };

  const activateOrDesactivate = () => {
    if (isCoturnActive(host)) {
      stopCoturn(appState.user._id, host._id, appState.token, props.dispatch);
    } else {
      startCoturn(appState.user._id, host._id, appState.token, props.dispatch);
    }
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <h5 className="card-category">Controlling</h5>
                  <CardTitle tag="h3">FEATURES</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Commands</th>
                        <th className="text-right">Description</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left">TURN server activation</td>
                        <td className="text-right">
                          Change manually the status of the Coturn service
                        </td>
                        <td className="text-right">
                          {appState.updateTurnInProgress && (
                            <Spinner
                              children={null}
                              className="mr-2"
                              size="sm"
                            />
                          )}
                          <Button
                            disabled={isActivateOrDesactivateButtonDisabled(
                              host
                            )}
                            size="sm"
                            color={isCoturnActive(host) ? "danger" : "primary"}
                            onClick={() => {
                              activateOrDesactivate();
                            }}
                          >
                            {isCoturnActive(host) ? "Disable" : "Enable"}
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
    </div>
  );
}

export default Features;
