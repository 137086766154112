import {useState, useContext, useEffect, useRef} from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Col,
  Row,
  Button,
  ButtonGroup,
} from "reactstrap";
import AppContext from "../../contexts/appContext";
import {createChart, updateChart} from "../../modules/callCharts";

import "../testing/media.css";
import {HOST_SERVICE} from "../../modules/bm";
import {CHART_COLORS} from "../../modules/helper";
import {DateTime} from "luxon";

function UsageChart({dispatch}) {
  const appState = useContext(AppContext);
  const chartUsage = useRef();
  const [series, setSeries] = useState([]);
  const [props, setProps] = useState([]); // To store the list of properties from services
  const [selectedProps, setSelectedProps] = useState("users");
  const [selectedSeries, setSelectedSeries] = useState([]);

  const CHART_MAIN_COLOR = {
    users: CHART_COLORS.pink,
    conferences: CHART_COLORS.green,
    duration: CHART_COLORS.cyan,
    traffic: CHART_COLORS.orange,
    avgUsers: CHART_COLORS.yellow,
    avgTraffic: CHART_COLORS.blue,
    sent: CHART_COLORS.yellow,
    received: CHART_COLORS.orange,
    avgDuration: CHART_COLORS.cyan,
  };

  const keepProps = (array, category) => {
    const firstObject = array.length > 0 ? array[0] : null;
    if (firstObject) {
      const props = Object.keys(firstObject);
      const filteredProps = props.filter((prop) =>
        [
          "users",
          "conferences",
          "duration",
          "avgDuration",
          "avgUsers",
          "traffic",
          "avgTraffic",
          "sent",
          "received",
        ].includes(prop)
      );
      return filteredProps;
    }
    return [];
  };

  const PROPSLABEL = {
    users: "Users (number)",
    conferences: "Users (number)",
    duration: "Connection duration (in minutes)",
    traffic: "Traffic IN/OUT (in MB)",
    avgTraffic: "Average Traffic per user (in MB)",
    avgDuration: "Average Duration per conference (in minutes)",
    avgUsers: "Average Users per conference (number)",
    sent: "Data sent (in MB)",
    received: "Data redeived (in MB)",
  };

  const getLabels = (prop) => {
    switch (prop) {
      case "status":
        return ["", "success", "warning", "error", "unknown"];
      default:
        return null;
    }
  };

  const changeSeries = (selectedProps, series) => {
    if (series.length > 0) {
      const graphSeries = series.map((elt) => ({
        x: elt._id,
        y: elt[selectedProps],
      }));
      setSelectedSeries(graphSeries);
    }
  };

  const getButtonsList = (items) => {
    return items.map((item, index) => (
      <Button
        key={index}
        color="info"
        id="0"
        size="sm"
        tag="label"
        className={classNames("btn-simple", {
          active: selectedProps === item,
        })}
        onClick={() => {
          setSelectedProps(item);
        }}
      >
        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          {item}
        </span>
        <span className="d-block d-sm-none">
          <i className="tim-icons icon-single-02"/>
        </span>
      </Button>
    ));
  };

  useEffect(() => {
    if (selectedSeries.length > 0) {
      updateChart(selectedSeries, null, chartUsage, 2, 60000, false);
    }
  }, [selectedSeries]);

  useEffect(() => {
    changeSeries(selectedProps, series);
  }, [series]);

  useEffect(() => {
    createChart(document.querySelector("#chartUsage"), chartUsage, "MONTHLY", {
      prop: PROPSLABEL[selectedProps],
      colorProp: CHART_MAIN_COLOR[selectedProps],
      maxY: 60000,
      isLine: true,
      labels: getLabels(selectedProps),
      unit: "day",
    });
    changeSeries(selectedProps, series);
  }, [selectedProps]);

  useEffect(() => {
    if (appState.series[HOST_SERVICE.USAGE]) {
      setSeries(appState.series[HOST_SERVICE.USAGE]);
      setProps(
        keepProps(appState.series[HOST_SERVICE.USAGE], HOST_SERVICE.USAGE)
      );
    }
  }, [appState.series]);

  return (
    <Card className="card">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="6">
            <h5 className="card-category">Usage Metrics Trend</h5>
            <CardTitle tag="h3">Usage</CardTitle>
          </Col>
          <Col className="text-left" sm="6">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              {getButtonsList(props)}
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <div className="chart-container">
              <canvas id="chartUsage"></canvas>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default UsageChart;
