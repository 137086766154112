import { log } from "../modules/logger";
import { OPS_ACTIONS } from "./constants";
import { getOpsForHost } from "../modules/status";

const moduleName = "versionsActions";

export const getOpsOfHost = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "get last version for all hosts", { userId });
  dispatch({
    type: OPS_ACTIONS.GET_OPS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getOpsForHost(userId, instanceId, token);

    if (data !== undefined) {
      dispatch({
        type: OPS_ACTIONS.GET_OPS_SUCCESS,
        payload: { value: data.ops.count },
      });
    } else {
      dispatch({
        type: OPS_ACTIONS.GET_OPS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: OPS_ACTIONS.GET_OPS_FAILED,
      payload: { value: null },
    });
  }
};
