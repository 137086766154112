import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
  Label,
  Row,
  Col,
  InputGroup,
  Form,
  FormGroup,
} from "reactstrap";
import React, { useContext, useState } from "react";
import AppContext from "../contexts/appContext";
import "./ConfirmModal.css";

function ConfirmModal(props) {
  const [test, setInput] = useState(props.input);
  const [rSelected, setRSelected] = useState(
    props.select ? props.select.selected : null
  );
  return (
    <Modal
      isOpen={props.open}
      toggle={() => {
        props.cancel();
      }}
      size={props.size || "sm"}
    >
      <ModalHeader
        className="justify-content-center"
        toggle={() => {
          props.cancel();
        }}
      >
        {props.title}
      </ModalHeader>
      <ModalBody>
        <p className="text-light">{props.text}</p>
        <Row>
          <Col>
            <Form>
              {props.input && (
                <FormGroup row>
                  <Label for="name" sm={1}>
                    Email
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      id="name"
                      className="inputName"
                      value={test || props.input}
                      onChange={(e) => {
                        setInput(e.target.value);
                      }}
                    />
                  </Col>
                </FormGroup>
              )}
              {props.select && (
                <FormGroup row>
                  <Label for="select" sm={1}>
                    Role
                  </Label>
                  <Col sm={6}>
                    <ButtonGroup>
                      {props.select.options.map((option, key) => (
                        <Button
                          size="sm"
                          color={rSelected === option ? "primary" : "light"}
                          key={key}
                          onClick={() => setRSelected(option)}
                          active={rSelected === option}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Col>
                </FormGroup>
              )}
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={() => {
            props.cancel();
          }}
        >
          {props.cancelTitle || "Cancel"}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            props.confirm({ input: test, select: rSelected });
          }}
        >
          {props.confirmTitle || "Confirm"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmModal;
