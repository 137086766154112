import React, { useContext, useEffect, useState } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import AppContext from "../../contexts/appContext";

import IcePlaceholder from "../placeholder/IcePlaceholder";
import NoHost from "../NoHost";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { error, info } from "../../modules/logger";
import {
  createUUID,
  getValueFromSetting,
  isStunConfigured,
  isTurnConfigured,
} from "../../modules/helper";
import {
  getICESTUNServers,
  getICETURNServers,
  getProtocolFromPriority,
  getUserCredentials,
  TEST_TYPE,
} from "../../modules/rtc";
import { initiateQuick } from "../../actions/quickActions";
import CardNumber from "../CardNumber";
import { getErrorDetailsFromCode } from "../../modules/ICEErrors";
import NoSetup from "../NoSetup";
import BreadCrumbCusto from "../BreadCrumb";

const moduleName = "view:connectivity";

const TIMEOUT = 5000;

let pc1 = null;
let pc2 = null;
let timeoutId = null;
let startTime = null;
let endTime = null;
let testedLANInternet = false;
let connectedLANInternet = false;
let testedNATUDP = false;
let connectedNATUDP = false;
let testedNATTCP = false;
let connectedNATTCP = false;
let testedNATTLS = false;
let connectedNATTLS = false;
let testedUDPTCP = false;
let connectedUDPTCP = false;
let testedClass2 = false;
let connectedClass2 = false;
let testedClass3 = false;
let connectedClass3 = false;
let ttc_direct = NaN;
let ttc_class1_udp = NaN;
let ttc_class1_tcp = NaN;
let ttc_class1_tls = NaN;
let ttc_class2 = NaN;
let ttc_class3 = NaN;
let toTest = "All transports";

// Filter for tests (candidates filtering)
const filterUser1 = ["srflx", "srflx", "srflx", "srflx", "udp"];
const filterUser2 = ["host", "udp", "tcp", "tls", "rtcp"];

function Connectivity(props) {
  const [testDone, setTestDone] = useState(false);
  const [iceError, setIceError] = useState([]);
  const [testInProgress, setTestInProgress] = useStateWithCallbackLazy(false);
  const appState = useContext(AppContext);
  const [host, setHost] = useState(appState.currentInstance);
  const [sendResult, setSendResult] = useState(false);

  useEffect(() => {
    if (!host || host._id !== appState.currentInstance._id) {
      setHost(appState.currentInstance);
    }
  }, [appState.currentInstance]);

  const computeScore = () => {
    let score = "-";
    if (!testDone) {
      return score;
    }

    let points = 0;

    if (connectedClass3) {
      points += 1;
    }
    if (connectedClass2) {
      points += 1;
    }
    if (connectedUDPTCP) {
      points += 1;
    }
    if (connectedNATTLS) {
      points += 1;
    }
    if (connectedNATTCP) {
      points += 1;
    }
    if (connectedNATUDP) {
      points += 1;
    }
    if (connectedLANInternet) {
      points += 1;
    }

    const scores = ["E", "D", "C", "B", "B+", "A", "A+", "A++"];
    return scores[points];
  };

  const getScoreIconColor = (score) => {
    if (!testDone) {
      return "";
    }
    if (score.includes("A")) {
      return "text-success";
    } else if (score.includes("B")) {
      return "text-warning";
    }
    return "text-danger";
  };

  const getTestColor = (fct) => {
    if (!testDone) {
      return "";
    }

    const result = fct.call(this);
    return result === "PASSED"
      ? "text-success"
      : result === "IGNORED"
      ? "text-warning"
      : "text-danger";
  };

  const getResultForLANInternet = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedLANInternet
      ? connectedLANInternet
        ? "PASSED"
        : "FAILED"
      : "DISCARDED";
  };

  const getResultForNATUDP = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedNATUDP ? (connectedNATUDP ? "PASSED" : "FAILED") : "DISCARDED";
  };

  const getResultForNATTCP = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedNATTCP ? (connectedNATTCP ? "PASSED" : "FAILED") : "DISCARDED";
  };

  const getResultForNATTLS = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedNATTLS ? (connectedNATTLS ? "PASSED" : "FAILED") : "DISCARDED";
  };

  const getResultForUDPTCP = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedUDPTCP ? (connectedUDPTCP ? "PASSED" : "FAILED") : "DISCARDED";
  };

  const getResultForClass3 = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedClass3 ? (connectedClass3 ? "PASSED" : "FAILED") : "DISCARDED";
  };

  const getResultForClass2 = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedClass2 ? (connectedClass2 ? "PASSED" : "FAILED") : "DISCARDED";
  };

  const reset = () => {
    pc1 = null;
    pc2 = null;
    setTestDone(false);
    testedLANInternet = false;
    testedNATUDP = false;
    testedNATTCP = false;
    testedNATTLS = false;
    testedUDPTCP = false;
    testedClass2 = false;
    testedClass3 = false;
    connectedLANInternet = false;
    connectedNATUDP = false;
    connectedNATTCP = false;
    connectedNATTLS = false;
    connectedUDPTCP = false;
    connectedClass2 = false;
    connectedClass3 = false;
    startTime = null;
    endTime = null;
    ttc_direct = NaN;
    ttc_class1_udp = NaN;
    ttc_class1_tcp = NaN;
    ttc_class1_tls = NaN;
    ttc_class2 = NaN;
    ttc_class3 = NaN;
    setIceError([]);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTestInProgress(false);
    setSendResult(false);
  };

  const clearCall = () => {
    if (pc1) {
      pc1.close();
    }
    if (pc2) {
      pc2.close();
    }
  };

  const tryToConnectToPeer = (servers, servers2, filterUser1, filterUser2) => {
    return new Promise(async (resolve, reject) => {
      try {
        switch (filterUser2) {
          case "host":
            testedLANInternet = true;
            break;
          case "udp":
            testedNATUDP = true;
            break;
          case "tcp":
            testedNATTCP = true;
            break;
          case "tls":
            testedNATTLS = true;
            break;
          case "rtcp":
            testedUDPTCP = true;
            break;
        }

        timeoutId = setTimeout(() => {
          error(moduleName, `can't connect within ${TIMEOUT}s`);
          clearCall();
          reject();
          return;
        }, TIMEOUT);

        startTime = Date.now();

        pc1 = new RTCPeerConnection({
          iceServers: servers,
        });

        pc2 = new RTCPeerConnection({
          iceServers: servers2,
        });

        pc1.addEventListener("icecandidate", async (event) => {
          const candidate = event.candidate;

          if (candidate) {
            const protocol = getProtocolFromPriority(
              candidate.priority,
              filterUser2 === TEST_TYPE.TURN_TLS,
            );
            if (
              candidate.type === filterUser1 ||
              (candidate.type === "relay" && protocol === filterUser1)
            ) {
              info(
                moduleName,
                `send candidate ${candidate.type}|${protocol} to pc2`,
              );
              await pc2.addIceCandidate(candidate);
            }
          }
        });

        pc2.addEventListener("icecandidate", async (event) => {
          const reduceFilter = (filter) => {
            if (filter.length > 3) {
              return filter.substring(1);
            }
            return filter;
          };

          const candidate = event.candidate;
          const filter = reduceFilter(filterUser2);

          if (candidate) {
            const protocol = getProtocolFromPriority(
              candidate.priority,
              filterUser2 === TEST_TYPE.TURN_TLS,
            );

            if (
              candidate.type === filterUser2 ||
              (candidate.type === "relay" && protocol === filter)
            ) {
              info(
                moduleName,
                `send candidate ${candidate.type}|${protocol} to pc1`,
              );
              await pc1.addIceCandidate(candidate);
            }
          }
        });

        pc1.addEventListener("iceconnectionstatechange", async (event) => {
          info(
            moduleName,
            `pc1 ice connection state changed to ${pc1.iceConnectionState}`,
          );

          if (pc1.iceConnectionState === "connected") {
          }
        });

        info(moduleName, "create data channel");
        const channel = pc1.createDataChannel("dt");
        channel.onopen = () => {
          info(moduleName, "channel opened");
        };
        channel.addEventListener("open", () => {
          info(moduleName, "channel connected");
          endTime = Date.now();
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          clearCall();
          const executionTime = Date.now() - startTime;
          resolve(executionTime);
          return;
        });

        info(moduleName, "start connection...");
        const offer = await pc1.createOffer({ offerToReceiveAudio: true });
        await pc1.setLocalDescription(offer);
        await pc2.setRemoteDescription(offer);
        const answer = await pc2.createAnswer({ offerToReceiveAudio: true });
        await pc2.setLocalDescription(answer);
        await pc1.setRemoteDescription(answer);
      } catch (err) {
        error(moduleName, `error`, err);
      }
    });
  };

  const generateCalls = async () => {
    info(moduleName, "[click] generate");
    reset();
    setTestInProgress(true, async () => {
      const username = getValueFromSetting(
        "turnUsername",
        appState.settings,
        host._id,
      );
      const password = getValueFromSetting(
        "turnPassword",
        appState.settings,
        host._id,
      );
      const token = getValueFromSetting(
        "turnToken",
        appState.settings,
        host._id,
      );
      const STUNSettingURL = getValueFromSetting(
        "stunAddress",
        appState.settings,
        host._id,
      );
      const TURNSettingURL = getValueFromSetting(
        "turnAddress",
        appState.settings,
        host._id,
      );

      let servers = [];
      let servers2 = [];
      if (STUNSettingURL) {
        servers = servers.concat(getICESTUNServers(STUNSettingURL, toTest));
      }
      if (TURNSettingURL) {
        const cred1 = getUserCredentials(
          createUUID(),
          username,
          password,
          token,
        );
        const cred2 = getUserCredentials(
          createUUID(),
          username,
          password,
          token,
        );
        servers2 = servers.concat(
          getICETURNServers(cred2, TURNSettingURL, toTest),
        );
        servers = servers.concat(
          getICETURNServers(cred1, TURNSettingURL, "All transports"),
        );
      }

      const gotErrors = [];

      try {
        let index = 0;
        for (const filter of filterUser1) {
          try {
            info(
              moduleName,
              `--------- test ${index} --------- ${filterUser2[index]}`,
            );
            const ttc = await tryToConnectToPeer(
              servers,
              servers2,
              filter,
              filterUser2[index],
            );

            switch (filterUser2[index]) {
              case "host":
                connectedLANInternet = true;
                ttc_direct = ttc;
                break;
              case "udp":
                connectedNATUDP = true;
                ttc_class1_udp = ttc;
                break;
              case "tcp":
                connectedNATTCP = true;
                ttc_class1_tcp = ttc;
                break;
              case "tls":
                connectedNATTLS = true;
                ttc_class1_tls = ttc;
                break;
              case "rtcp":
                connectedUDPTCP = true;
                break;
            }

            info(moduleName, "test is OK");
          } catch (err) {
            info(moduleName, "test is KO");
          } finally {
            index++;
          }
        }

        const resultQuick = await initiateQuick(
          appState.user._id,
          host._id,
          appState.token,
          toTest !== "All transports" ? toTest : null,
        );

        testedClass2 = true;
        if (resultQuick.value === "success") {
          connectedClass2 = true;
          ttc_class2 = resultQuick.data.executionTime;
        } else {
          gotErrors.push(getErrorDetailsFromCode("TC13", TURNSettingURL));
        }

        if (
          !connectedLANInternet &&
          (toTest === "All transports" || toTest.startsWith("stun:"))
        ) {
          gotErrors.push(getErrorDetailsFromCode("TC10", STUNSettingURL));
        }
        if (
          (!connectedNATTLS || !connectedNATUDP || !connectedNATTCP) &&
          (toTest === "All transports" || toTest.startsWith("turn"))
        ) {
          gotErrors.push(getErrorDetailsFromCode("TC11", TURNSettingURL));
        }

        info(moduleName, "test done");
        setTestInProgress(false, null);
        setIceError(gotErrors);
        setTestDone(true);
      } catch (err) {
        error(moduleName, `test failed`, { err });
        gotErrors.push(getErrorDetailsFromCode("TC7", err.name));
        setTestInProgress(false, null);
        setTestDone(true);
      }
    });
  };

  const getOptionsForTest = () => {
    let list = ["All transports"];

    const STUNSettingURL = getValueFromSetting(
      "stunAddress",
      appState.settings,
      host._id,
    );
    const TURNSettingURL = getValueFromSetting(
      "turnAddress",
      appState.settings,
      host._id,
    );

    const stuns = STUNSettingURL.split(";");
    const turns = TURNSettingURL.split(";");
    list = list.concat(stuns).concat(turns);

    return list.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };

  const displayTimeToConnect = (duration) => {
    if (!testDone) {
      return NaN;
    }
    return duration;
  };

  const onTestsChange = (e) => {
    toTest = e.target.value;
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <IcePlaceholder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            {(!isStunConfigured(host, appState.settings) ||
              !isTurnConfigured(host, appState.settings)) && <NoSetup />}
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={host}
              env={host?.env}
              name={null}
            />
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="9">
                        <h5 className="card-category">Testing</h5>
                        <CardTitle tag="h3">CONNECTIVITY</CardTitle>
                      </Col>
                      <Col className="text-right" sm="3">
                        <h5 className="card-category">Score</h5>
                        <CardTitle
                          tag="h2"
                          className={getScoreIconColor(computeScore())}
                        >
                          {computeScore()}
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-left w-50">SUPPORT OF</th>
                          <th className="text-right">Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left w-50">
                            <span
                              className={getTestColor(getResultForLANInternet)}
                            >
                              Direct or NAT Connection (STUN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForLANInternet() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getResultForLANInternet()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForNATUDP)}>
                              Class 1 - Relay over UDP (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForNATUDP() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getResultForNATUDP()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForNATTCP)}>
                              Class 1 - Relay over TCP (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForNATTCP() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getResultForNATTCP()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForNATTLS)}>
                              Class 1 - Relay over TLS (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForNATTLS() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getResultForNATTLS()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForUDPTCP)}>
                              Class 1 - Relay-to-Relay (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForUDPTCP() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getResultForUDPTCP()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForClass2)}>
                              Class 2 - Limit connectivity to UDP/3478 (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForClass2() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getResultForClass2()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForClass3)}>
                              Class 3 - Strict connectivity to TCP/443 (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForClass3() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getResultForClass3()}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="exampleSelect">
                            Select transport to use
                          </Label>
                          <Input
                            type="select"
                            className="form-control"
                            defaultValue="All transports"
                            onChange={onTestsChange}
                            disabled={testInProgress}
                            style={{
                              padding: "0.3rem 2.25rem 0.3rem 0.75rem",
                              fontSize: "0.8rem",
                            }}
                          >
                            {getOptionsForTest()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="stats">
                      <p className="text-muted mt-2">
                        This test connects two users based on the host's
                        settings. Please wait during the test. It can take up to
                        one minute.
                      </p>
                      <Button
                        disabled={testInProgress}
                        size="sm"
                        onClick={generateCalls}
                      >
                        Test
                      </Button>
                      {testInProgress && (
                        <Spinner children={null} className="ml-2" size="sm" />
                      )}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="DIRECT"
                  value={{ data: { host: displayTimeToConnect(ttc_direct) } }}
                  prop="host"
                  icon="cafe-ice-host"
                  unit="ms"
                  last="Time to connect (ms)"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="CLASS 2"
                  value={{ data: { host: displayTimeToConnect(ttc_class2) } }}
                  prop="host"
                  icon="cafe-ice-host"
                  unit="ms"
                  last="Time to connect (ms)"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="CLASS 3"
                  value={{ data: { host: displayTimeToConnect(ttc_class3) } }}
                  prop="host"
                  icon="cafe-ice-host"
                  unit="ms"
                  last="Time to connect (ms)"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="RELAY - UDP"
                  value={{
                    data: { host: displayTimeToConnect(ttc_class1_udp) },
                  }}
                  prop="host"
                  icon="cafe-ice-host"
                  unit="ms"
                  last="Time to connect (ms)"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="RELAY - TCP"
                  value={{
                    data: { host: displayTimeToConnect(ttc_class1_tcp) },
                  }}
                  prop="host"
                  icon="cafe-ice-host"
                  unit="ms"
                  last="Time to connect (ms)"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="RELAY - TLS"
                  value={{
                    data: { host: displayTimeToConnect(ttc_class1_tls) },
                  }}
                  prop="host"
                  icon="cafe-ice-host"
                  unit="ms"
                  last="Time to connect (ms)"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5 className="card-category">
                      Connectivity warnings and errors
                    </h5>
                    <CardTitle tag="h3">TO CONSIDER</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-left">Type</th>
                          <th className="text-left">Description</th>
                          <th className="text-left">Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        {iceError.map((error, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              <span
                                className={
                                  error.level === "error"
                                    ? "text-danger"
                                    : "text-warning"
                                }
                              >
                                {error.code}
                              </span>
                            </td>
                            <td className="text-left">{error.text}</td>
                            <td className="text-left">{error.url}</td>
                          </tr>
                        ))}
                        {iceError.length === 0 && (
                          <tr>
                            <td colSpan="6">
                              <br />
                              {testDone ? "No error found" : "No result yet"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {iceError.length > 0 && (
                      <p>
                        Note: Consider these points in case of connection
                        errors.
                      </p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default Connectivity;
