import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Label } from "reactstrap";
import { DateTime, Duration } from "luxon";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import CardNumber from "../CardNumber";
import GlobalTrafficChart from "../charts/GlobalTrafficChart";
import GlobalMonthTrafficChart from "../charts/GlobalMonthTrafficChart";
import GlobalStatusChart from "../charts/GlobalStatusChart";
import GlobalStatusPieChart from "../charts/GlobalStatusPieChart";
import { filterCurrentMonth } from "../../modules/helper";
import BreadCrumbCusto from "../BreadCrumb";

const defaultMonthlyValue = {
  users: 0,
  conferences: 0,
  traffic: 0,
  duration: 0,
};

function HostGlobal(props) {
  const appState = useContext(AppContext);
  const [summary, setSummary] = useState(appState.summary.summary);
  const [details, setDetails] = useState(appState.summary.details);
  const [monthly, setMonthly] = useState(
    filterCurrentMonth(appState.summary.monthly, defaultMonthlyValue),
  );

  useEffect(() => {
    setSummary(appState.summary.summary);
    setDetails(appState.summary.details);
    setMonthly(
      filterCurrentMonth(appState.summary.monthly, defaultMonthlyValue),
    );
  }, [appState.summary]);

  const getTotalUsers = () => {
    return (summary && summary.users) || 0;
  };

  const getTotalConferences = () => {
    return (summary && summary.conferences) || 0;
  };

  const getTraffic = (traffic) => {
    if (!traffic) {
      return 0;
    }
    if (traffic > 1000) {
      return (traffic / 1000).toFixed(2);
    } else if (traffic > 1) {
      return traffic.toFixed(2);
    }
    return (traffic * 1000).toFixed(2);
  };

  const getTrafficUnits = (traffic) => {
    if (!traffic) {
      return "KB";
    }
    if (traffic > 1000) {
      return "GB";
    } else if (traffic > 1) {
      return "MB";
    }
    return "KB";
  };

  const getDuration = (duration) => {
    if (!duration) {
      return 0;
    }
    let value = Duration.fromObject({ minutes: duration }).hours;
    if (value === 0) {
      value = Math.floor(duration);
    }
    return value;
  };

  const getDurationUnits = (duration) => {
    if (!duration) {
      return "Min";
    }
    let value = Duration.fromObject({ minutes: duration }).hours;
    if (value === 0) {
      return "Min";
    }
    return "Hours";
  };

  const getMaxDailyFor = (property) => {
    const max = (details || []).reduce(
      function (prev, current) {
        return prev[property] > current[property] ? prev : current;
      },
      details && details.length > 0
        ? details[0]
        : { [property]: 0, fake: true },
    );
    if (max && !("fake" in max)) {
      return { [property]: max[property], created: max.created };
    }
    return null;
  };

  const maxUsersDay = getMaxDailyFor("users");
  const maxConferencesDay = getMaxDailyFor("conferences");
  const maxTrafficDay = getMaxDailyFor("traffic");
  const maxDurationDay = getMaxDailyFor("duration");

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={appState.currentInstance}
              env={appState.currentInstance?.env}
              name={null}
            />
            <Row>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="USERS"
                  value={{
                    data: {
                      legs: getTotalUsers(),
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="u"
                  last="Total overall"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="CONFERENCES"
                  value={{
                    data: {
                      conferences: getTotalConferences(),
                    },
                  }}
                  prop="conferences"
                  icon="cafe-creation"
                  unit="u"
                  last="Total overall"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="TRAFFIC"
                  value={{
                    data: {
                      traffic: getTraffic(summary?.traffic),
                    },
                  }}
                  prop="traffic"
                  icon="cafe-creation"
                  unit={getTrafficUnits(summary?.traffic)}
                  last="Total overall"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="DURATION"
                  value={{
                    data: {
                      duration: getDuration(summary?.duration || 0),
                    },
                  }}
                  prop="duration"
                  icon="cafe-creation"
                  unit={getDurationUnits(summary?.duration || 0)}
                  last="Total overall"
                  badge={false}
                />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Usage Status</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="9" lg="9" md="9" sm="9" xs="9">
                <GlobalStatusChart
                  status={appState.summaryStatus ? appState.summaryStatus : []}
                  property="traffic"
                  title="DAILY STATUS"
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="3">
                <GlobalStatusPieChart
                  status={appState.summaryStatus ? appState.summaryStatus : []}
                  property="traffic"
                  title="STATUS"
                />
              </Col>
            </Row>

            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Daily Statistics</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MOST DAILY USERS"
                  value={{
                    data: {
                      legs: maxUsersDay ? maxUsersDay.users : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="u"
                  last={
                    maxUsersDay
                      ? DateTime.fromISO(maxUsersDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MOST DAILY CONFERENCES"
                  value={{
                    data: {
                      legs: maxConferencesDay
                        ? maxConferencesDay.conferences
                        : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="u"
                  last={
                    maxConferencesDay
                      ? DateTime.fromISO(maxConferencesDay.created).toFormat(
                          "DD",
                        )
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MOST DAILY TRAFFIC"
                  value={{
                    data: {
                      legs: maxTrafficDay
                        ? getTraffic(maxTrafficDay.traffic)
                        : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit={getTrafficUnits(maxTrafficDay?.traffic)}
                  last={
                    maxTrafficDay
                      ? DateTime.fromISO(maxTrafficDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MOST DAILY DURATION"
                  value={{
                    data: {
                      legs: maxDurationDay
                        ? getDuration(maxDurationDay.duration)
                        : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="Min"
                  last={
                    maxDurationDay
                      ? DateTime.fromISO(maxDurationDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <GlobalTrafficChart />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Monthly Statistics</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Users"
                  value={{
                    data: {
                      users: monthly.users,
                    },
                  }}
                  prop="users"
                  icon="cafe-creation"
                  unit="u"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Conferences"
                  value={{
                    data: {
                      legs: monthly.conferences,
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="u"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Traffic"
                  value={{
                    data: {
                      traffic: getTraffic(monthly?.traffic),
                    },
                  }}
                  prop="traffic"
                  icon="cafe-creation"
                  unit={getTrafficUnits(monthly?.traffic)}
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Duration"
                  value={{
                    data: {
                      duration: getDuration(monthly?.duration || 0),
                    },
                  }}
                  prop="duration"
                  icon="cafe-creation"
                  unit={getDurationUnits(summary?.duration || 0)}
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <GlobalMonthTrafficChart />
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default HostGlobal;
