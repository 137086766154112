import { getAllEvents } from "../modules/status";

import { ACTIONS } from "./constants";
import { log } from "../modules/logger";

const moduleName = "instanceActions";

export const getEvents = async (userId, instanceId, page, token, dispatch) => {
  log(moduleName, "get all events", { userId });
  dispatch({
    type: ACTIONS.LIST_EVENTS_IN_PROGRESS,
    payload: { value: null }
  });

  let data = null;

  try {
    data = await getAllEvents(userId, instanceId, page, token);

    if (data) {
      dispatch({
        type: ACTIONS.LIST_EVENTS_SUCCESS,
        payload: { value: data }
      });
    } else {
      dispatch({
        type: ACTIONS.LIST_EVENTS_FAILED,
        payload: { value: null }
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.LIST_EVENTS_FAILED,
      payload: { value: null }
    });
  }
}
