import {
  loginWithUsername,
  loginWithTokenUserToken,
  updateProfile,
} from "../modules/authentification";
import { ACTIONS } from "./constants";
import { log } from "../modules/logger";
import {
  closeServerSideChannel,
  openServerSideChannel,
} from "../modules/realtime";

const moduleName = "userActions";

export const login = async (username, password, dispatch) => {
  log(moduleName, "log-in with", { username });
  dispatch({
    type: ACTIONS.LOGIN_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await loginWithUsername(username, password);

    if (data) {
      openServerSideChannel(data.tokenSSE, data.token, dispatch);
      dispatch({
        type: ACTIONS.LOGIN_SUCCESS,
        payload: {
          value: {
            user: data.user,
            token: data.token,
            sseToken: data.tokenSSE,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.LOGIN_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.LOGIN_FAILED,
      payload: { value: null },
    });
  }
};

export const loginWithToken = async (token, sseToken, dispatch) => {
  log(moduleName, "log-in with token");
  dispatch({
    type: ACTIONS.LOGIN_TOKEN_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await loginWithTokenUserToken(token);

    if (data) {
      openServerSideChannel(data.sseToken, data.token, dispatch);
      dispatch({
        type: ACTIONS.LOGIN_TOKEN_SUCCESS,
        payload: {
          value: {
            user: data.user,
            token: data.token,
            sseToken: data.sseToken,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.LOGIN_TOKEN_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.LOGIN_TOKEN_FAILED,
      payload: { value: null },
    });
  }
};

export const resetSignedError = async (dispatch) => {
  log(moduleName, "reset account");
  dispatch({
    type: ACTIONS.LOGIN_FAILED_RESET,
    payload: { value: null },
  });
};

export const logout = async (dispatch) => {
  log(moduleName, "log-out");
  closeServerSideChannel();
  dispatch({
    type: ACTIONS.LOGOUT,
    payload: { value: null },
  });
};

export const updateUserProfile = async (userId, profile, token, dispatch) => {
  log(moduleName, "update profile");

  dispatch({
    type: ACTIONS.UPDATE_PROFILE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateProfile(userId, profile, token);

    if (data) {
      dispatch({
        type: ACTIONS.UPDATE_PROFILE_SUCCESS,
        payload: { value: { user: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.UPDATE_PROFILE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.UPDATE_PROFILE_FAILED,
      payload: { value: null },
    });
  }
};
