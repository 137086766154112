const allRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "icon cafe-dashboard-1",
  },
  {
    path: "/production",
    name: "Production",
    icon: "icon cafe-production",
  },
  {
    path: "/staging",
    name: "Staging",
    icon: "icon cafe-staging",
  },
  {
    path: "/others",
    name: "Others",
    icon: "icon cafe-others",
  },
];

export default allRoutes;
