export const ACTIONS = {
  CALL_IN_PROGRESS: "CALL_IN_PROGRESS",
  CALL_AUTHORIZATION_SUCCESS: "CALL_AUTHORIZATION_SUCCESS",
  CALL_AUTHORIZATION_FAILED: "CALL_AUTHORIZATION_FAILED",
  CALL_CANDIDATE_SUCCESS: "CALL_CANDIDATE_SUCCESS",
  CALL_CANDIDATE_FAILED: "CALL_CANDIDATE_FAILED",
  CALL_ICE_SUCCESS: "CALL_ICE_SUCCESS",
  CALL_ICE_FAILED: "CALL_ICE_FAILED",
  VIDEO_SUCCESS: "VIDEO_SUCCESS",
  VIDEO_ADDRESS: "VIDEO_ADDRESS",
  VIDEO_FAILED: "VIDEO_FAILED",
  TERMINATE_CALL: "TERMINATE_CALL",
  RESET_CALL: "RESET_CALL",

  GOT_STATS_SENDER: "GOT_STATS_SENDER",
  GOT_STATS_RECEIVER: "GOT_STATS_RECEIVER",
  GOT_STATS_FINAL_SENDER: "GOT_STATS_FINAL_SENDER",
  GOT_STATS_FINAL_RECEIVER: "GOT_STATS_FINAL_RECEIVER",

  LOGIN_IN_PROGRESS: "LOGIN_IN_PROGRESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  LOGIN_FAILED_RESET: "LOGIN_FAILED_RESET",

  LOGIN_TOKEN_IN_PROGRESS: "LOGIN_TOKEN_IN_PROGRESS",
  LOGIN_TOKEN_FAILED: "LOGIN_TOKEN_FAILED",
  LOGIN_TOKEN_SUCCESS: "LOGIN_TOKEN_SUCCESS",

  LIST_CALLS_IN_PROGRESS: "LIST_CALLS_IN_PROGRESS",
  LIST_CALLS_FAILED: "LIST_CALLS_FAILED",
  LIST_CALLS_SUCCESS: "LIST_CALLS_SUCCESS",

  GET_TRAFFIC_CALLS_IN_PROGRESS: "GET_TRAFFIC_CALLS_IN_PROGRESS",
  GET_TRAFFIC_CALLS_FAILED: "GET_TRAFFIC_CALLS_FAILED",
  GET_TRAFFIC_CALLS_SUCCESS: "GET_TRAFFIC_CALLS_SUCCESS",

  GET_TRAFFIC_CALLS_BYTES_IN_PROGRESS: "GET_TRAFFIC_CALLS_BYTES_IN_PROGRESS",
  GET_TRAFFIC_CALLS_BYTES_FAILED: "GET_TRAFFIC_CALLS_BYTES_FAILED",
  GET_TRAFFIC_CALLS_BYTES_SUCCESS: "GET_TRAFFIC_CALLS_BYTES_SUCCESS",

  LIST_SETTINGS_IN_PROGRESS: "LIST_SETTINGS_IN_PROGRESS",
  LIST_SETTINGS_FAILED: "LIST_SETTINGS_FAILED",
  LIST_SETTINGS_SUCCESS: "LIST_SETTINGS_SUCCESS",

  LIST_INSTANCES_IN_PROGRESS: "LIST_INSTANCES_IN_PROGRESS",
  LIST_INSTANCES_FAILED: "LIST_INSTANCES_FAILED",
  LIST_INSTANCES_SUCCESS: "LIST_INSTANCES_SUCCESS",

  LIST_EVENTS_IN_PROGRESS: "LIST_EVENTS_IN_PROGRESS",
  LIST_EVENTS_FAILED: "LIST_EVENTS_FAILED",
  LIST_EVENTS_SUCCESS: "LIST_EVENTS_SUCCESS",

  GET_INSTANCE_IN_PROGRESS: "GET_INSTANCE_IN_PROGRESS",
  GET_INSTANCE_FAILED: "GET_INSTANCE_FAILED",
  GET_INSTANCE_SUCCESS: "GET_INSTANCE_SUCCESS",

  UPDATE_INSTANCE_IN_PROGRESS: "UPDATE_INSTANCE_IN_PROGRESS",
  UPDATE_INSTANCE_FAILED: "UPDATE_INSTANCE_FAILED",
  UPDATE_INSTANCE_SUCCESS: "UPDATE_INSTANCE_SUCCESS",

  UPDATE_FREQUENCY_IN_PROGRESS: "UPDATE_FREQUENCY_IN_PROGRESS",
  UPDATE_FREQUENCY_FAILED: "UPDATE_FREQUENCY_FAILED",
  UPDATE_FREQUENCY_SUCCESS: "UPDATE_FREQUENCY_SUCCESS",

  UPDATE_SETTINGS_IN_PROGRESS: "UPDATE_SETTINGS_IN_PROGRESS",
  UPDATE_SETTINGS_FAILED: "UPDATE_SETTINGS_FAILED",
  UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",

  GENERATE_KEYS_IN_PROGRESS: "GENERATE_KEYS_IN_PROGRESS",
  GENERATE_KEYS_FAILED: "GENERATE_KEYS_FAILED",
  GENERATE_KEYS_SUCCESS: "GENERATE_KEYS_SUCCESS",

  DELETE_KEYS_IN_PROGRESS: "DELETE_KEYS_IN_PROGRESS",
  DELETE_KEYS_FAILED: "DELETE_KEYS_FAILED",
  DELETE_KEYS_SUCCESS: "DELETE_KEYS_SUCCESS",

  ADD_INSTANCE_IN_PROGRESS: "ADD_INSTANCE_IN_PROGRESS",
  ADD_INSTANCE_FAILED: "ADD_INSTANCE_FAILED",
  ADD_INSTANCE_SUCCESS: "ADD_INSTANCE_SUCCESS",

  REMOVE_INSTANCE_IN_PROGRESS: "REMOVE_INSTANCE_IN_PROGRESS",
  REMOVE_INSTANCE_FAILED: "REMOVE_INSTANCE_FAILED",
  REMOVE_INSTANCE_SUCCESS: "REMOVE_INSTANCE_SUCCESS",

  RESET_INSTANCE_IN_PROGRESS: "RESET_INSTANCE_IN_PROGRESS",
  RESET_INSTANCE_FAILED: "RESET_INSTANCE_FAILED",
  RESET_INSTANCE_SUCCESS: "RESET_INSTANCE_SUCCESS",

  MONITOR_INSTANCE_IN_PROGRESS: "MONITOR_INSTANCE_IN_PROGRESS",
  MONITOR_INSTANCE_FAILED: "MONITOR_INSTANCE_FAILED",
  MONITOR_INSTANCE_SUCCESS: "MONITOR_INSTANCE_SUCCESS",

  UNMONITOR_INSTANCE_IN_PROGRESS: "UNMONITOR_INSTANCE_IN_PROGRESS",
  UNMONITOR_INSTANCE_FAILED: "UNMONITOR_INSTANCE_FAILED",
  UNMONITOR_INSTANCE_SUCCESS: "UNMONITOR_INSTANCE_SUCCESS",

  SET_CURRENT_INSTANCE: "SET_CURRENT_INSTANCE",

  STOP_COTURN_IN_PROGRESS: "STOP_COTURN_IN_PROGRESS",
  STOP_COTURN_FAILED: "STOP_COTURN_FAILED",
  STOP_COTURN_SUCCESS: "STOP_COTURN_SUCCESS",

  START_COTURN_IN_PROGRESS: "START_COTURN_IN_PROGRESS",
  START_COTURN_FAILED: "START_COTURN_FAILED",
  START_COTURN_SUCCESS: "START_COTURN_SUCCESS",

  TURN_ACTION_IN_PROGRESS: "TURN_ACTION_IN_PROGRESS",
  TURN_ACTION_FAILED: "TURN_ACTION_FAILED",
  TURN_ACTION_SUCCESS: "TURN_ACTION_SUCCESS",

  RESTART_COTURN_IN_PROGRESS: "RESTART_COTURN_IN_PROGRESS",
  RESTART_COTURN_FAILED: "RESTART_COTURN_FAILED",
  RESTART_COTURN_SUCCESS: "RESTART_COTURN_SUCCESS",

  LIST_OUTAGES_IN_PROGRESS: "LIST_OUTAGES_IN_PROGRESS",
  LIST_OUTAGES_FAILED: "LIST_OUTAGES_FAILED",
  LIST_OUTAGES_SUCCESS: "LIST_OUTAGES_SUCCESS",

  GET_OUTAGES_FOR_INSTANCE_IN_PROGRESS: "GET_OUTAGES_FOR_INSTANCE_IN_PROGRESS",
  GET_OUTAGES_FOR_INSTANCE_FAILED: "GET_OUTAGES_FOR_INSTANCE_FAILED",
  GET_OUTAGES_FOR_INSTANCE_SUCCESS: "GET_OUTAGES_FOR_INSTANCE_SUCCESS",

  GET_SERIES_FOR_INSTANCE_IN_PROGRESS: "GET_SERIES_FOR_INSTANCE_IN_PROGRESS",
  GET_SERIES_FOR_INSTANCE_FAILED: "GET_SERIES_FOR_INSTANCE_FAILED",
  GET_SERIES_FOR_INSTANCE_SUCCESS: "GET_SERIES_FOR_INSTANCE_SUCCESS",

  GET_TIMELINE_FOR_INSTANCE_IN_PROGRESS: "GET_TIMELINE_FOR_INSTANCE_IN_PROGRESS",
  GET_TIMELINE_FOR_INSTANCE_FAILED: "GET_TIMELINE_FOR_INSTANCE_FAILED",
  GET_TIMELINE_FOR_INSTANCE_SUCCESS: "GET_TIMELINE_FOR_INSTANCE_SUCCESS",

  GET_SERIES_FOR_USER_IN_PROGRESS: "GET_SERIES_FOR_USER_IN_PROGRESS",
  GET_SERIES_FOR_USER_FAILED: "GET_SERIES_FOR_USER_FAILED",
  GET_SERIES_FOR_USER_SUCCESS: "GET_SERIES_FOR_USER_SUCCESS",

  GET_PAGED_SERIES_FOR_INSTANCE_IN_PROGRESS:
    "GET_PAGED_SERIES_FOR_INSTANCE_IN_PROGRESS",
  GET_PAGED_SERIES_FOR_INSTANCE_FAILED: "GET_PAGED_SERIES_FOR_INSTANCE_FAILED",
  GET_PAGED_SERIES_FOR_INSTANCE_SUCCESS:
    "GET_PAGED_SERIES_FOR_INSTANCE_SUCCESS",

  GET_SUMMARY_FOR_INSTANCE_IN_PROGRESS: "GET_SUMMARY_FOR_INSTANCE_IN_PROGRESS",
  GET_SUMMARY_FOR_INSTANCE_FAILED: "GET_SUMMARY_FOR_INSTANCE_FAILED",
  GET_SUMMARY_FOR_INSTANCE_SUCCESS: "GET_SUMMARY_FOR_INSTANCE_SUCCESS",

  GET_SUMMARY_OVERALL_IN_PROGRESS: "GET_SUMMARY_OVERALL_IN_PROGRESS",
  GET_SUMMARY_OVERALL_FAILED: "GET_SUMMARY_OVERALL_FAILED",
  GET_SUMMARY_OVERALL_SUCCESS: "GET_SUMMARY_OVERALL_SUCCESS",

  GET_LAST_USAGE_HOSTS_IN_PROGRESS: "GET_LAST_USAGE_HOSTS_IN_PROGRESS",
  GET_LAST_USAGE_HOSTS_FAILED: "GET_LAST_USAGE_HOSTS_FAILED",
  GET_LAST_USAGE_HOSTS_SUCCESS: "GET_LAST_USAGE_HOSTS_SUCCESS",

  GET_TODAY_STATUS_HOSTS_IN_PROGRESS: "GET_TODAY_STATUS_HOSTS_IN_PROGRESS",
  GET_TODAY_STATUS_HOSTS_FAILED: "GET_TODAY_STATUS_HOSTS_FAILED",
  GET_TODAY_STATUS_HOSTS_SUCCESS: "GET_TODAY_STATUS_HOSTS_SUCCESS",

  GET_SERIES_TESTING_FOR_INSTANCE_IN_PROGRESS:
    "GET_SERIES_TESTING_FOR_INSTANCE_IN_PROGRESS",
  GET_SERIES_TESTING_FOR_INSTANCE_FAILED:
    "GET_SERIES_TESTING_FOR_INSTANCE_FAILED",
  GET_SERIES_TESTING_FOR_INSTANCE_SUCCESS:
    "GET_SERIES_TESTING_FOR_INSTANCE_SUCCESS",

  CREATE_SERIES_FOR_INSTANCE_IN_PROGRESS:
    "CREATE_SERIES_FOR_INSTANCE_IN_PROGRESS",
  CREATE_SERIES_FOR_INSTANCE_FAILED: "CREATE_SERIES_FOR_INSTANCE_FAILED",
  CREATE_SERIES_FOR_INSTANCE_SUCCESS: "CREATE_SERIES_FOR_INSTANCE_SUCCESS",

  GET_SLA_IN_PROGRESS: "GET_SLA_IN_PROGRESS",
  GET_SLA_FAILED: "GET_SLA_FAILED",
  GET_SLA_SUCCESS: "GET_SLA_SUCCESS",

  UPDATE_PROFILE_IN_PROGRESS: "UPDATE_PROFILE_IN_PROGRESS",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",

  TEST_SSH_IN_PROGRESS: "TEST_SSH_IN_PROGRESS",
  TEST_SSH_FAILED: "TEST_SSH_FAILED",
  TEST_SSH_SUCCESS: "TEST_SSH_SUCCESS",

  TEST_SSH_RESET: "TEST_SSH_RESET",

  TEST_HEALTH_IN_PROGRESS: "TEST_HEALTH_IN_PROGRESS",
  TEST_HEALTH_FAILED: "TEST_HEALTH_FAILED",
  TEST_HEALTH_SUCCESS: "TEST_HEALTH_SUCCESS",

  TEST_HEALTH_RESET: "TEST_HEALTH_RESET",

  LAUNCH_SERVICE_IN_PROGRESS: "LAUNCH_SERVICE_IN_PROGRESS",
  LAUNCH_SERVICE_FAILED: "LAUNCH_SERVICE_FAILED",
  LAUNCH_SERVICE_SUCCESS: "LAUNCH_SERVICE_SUCCESS",
  LAUNCH_SERVICE_RESET: "LAUNCH_SERVICE_RESET",
};

export const ADMIN_ACTIONS = {
  GET_GLOBAL_METRICS_IN_PROGRESS: "GET_GLOBAL_METRICS_IN_PROGRESS",
  GET_GLOBAL_METRICS_FAILED: "GET_GLOBAL_METRICS_FAILED",
  GET_GLOBAL_METRICS_SUCCESS: "GET_GLOBAL_METRICS_SUCCESS",

  GET_USERS_IN_PROGRESS: "GET_USERS_IN_PROGRESS",
  GET_USERS_FAILED: "GET_USERS_FAILED",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",

  CREATE_USER_IN_PROGRESS: "CREATE_USER_IN_PROGRESS",
  CREATE_USER_FAILED: "CREATE_USER_FAILED",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",

  GET_JOBS_IN_PROGRESS: "GET_JOBS_IN_PROGRESS",
  GET_JOBS_FAILED: "GET_JOBS_FAILED",
  GET_JOBS_SUCCESS: "GET_JOBS_SUCCESS",

  SEND_COMMAND_IN_PROGRESS: "SEND_COMMAND_IN_PROGRESS",
  SEND_COMMAND_FAILED: "SEND_COMMAND_FAILED",
  SEND_COMMAND_SUCCESS: "SEND_COMMAND_SUCCESS",
};

export const APP_ACTIONS = {
  USER_DATA_LOADING_FAILED: "USER_DATA_LOADING_FAILED",
  USER_DATA_LOADING_SUCCESS: "USER_DATA_LOADING_SUCCESS",
  USER_INSTANCE_DATA_LOADING_SUCCESS: "USER_INSTANCE_DATA_LOADING_SUCCESS",
  USER_DATA_LOADING_RESET: "USER_DATA_LOADING_RESET",

  GET_CUSTOMIZATION_IN_PROGRESS: "GET_CUSTOMIZATION_IN_PROGRESS",
  GET_CUSTOMIZATION_SUCCESS: "GET_CUSTOMIZATION_SUCCESS",
  GET_CUSTOMIZATION_FAILED: "GET_CUSTOMIZATION_FAILED",

  DISPLAY_SELECTOR: "DISPLAY_SELECTOR",
  HIDE_SELECTOR: "HIDE_SELECTOR",
};

export const QUICK_ACTIONS = {
  START_QUICK_TEST_IN_PROGRESS: "START_QUICK_TEST_IN_PROGRESS",
  START_QUICK_TEST_IN_FAILED: "START_QUICK_TEST_IN_FAILED",
  START_QUICK_TEST_IN_SUCCESS: "START_QUICK_TEST_IN_SUCCESS",
};

export const LINK_ACTIONS = {
  CREATE_LINK_IN_PROGRESS: "CREATE_LINK_TEST_IN_PROGRESS",
  CREATE_LINK_FAILED: "CREATE_LINK_FAILED",
  CREATE_LINK_SUCCESS: "CREATE_LINK_SUCCESS",

  GET_LINKS_IN_PROGRESS: "GET_LINKS_IN_PROGRESS",
  GET_LINKS_FAILED: "GET_LINKS_FAILED",
  GET_LINKS_SUCCESS: "GET_LINKS_SUCCESS",

  UPDATE_LINK_IN_PROGRESS: "UPDATE_LINK_IN_PROGRESS",
  UPDATE_LINK_FAILED: "UPDATE_LINK_IN_FAILED",
  UPDATE_LINK_SUCCESS: "UPDATE_LINK_IN_SUCCESS",

  DELETE_LINK_IN_PROGRESS: "DELETE_LINK_IN_PROGRESS",
  DELETE_LINK_FAILED: "DELETE_LINK_FAILED",
  DELETE_LINK_SUCCESS: "DELETE_LINK_SUCCESS",
};

export const PARAMETERS_ACTIONS = {
  UPDATE_PARAMETERS_IN_PROGRESS: "UPDATE_PARAMETERS_IN_PROGRESS",
  UPDATE_PARAMETERS_FAILED: "UPDATE_PARAMETERS_FAILED",
  UPDATE_PARAMETER_SUCCESS: "UPDATE_PARAMETERS_SUCCESS",

  GET_PARAMETERS_IN_PROGRESS: "GET_PARAMETERS_IN_PROGRESS",
  GET_PARAMETERS_FAILED: "GET_PARAMETERS_FAILED",
  GET_PARAMETERS_SUCCESS: "GET_PARAMETERS_SUCCESS",

  UPDATE_TOKEN_IN_PROGRESS: "UPDATE_TOKEN_IN_PROGRESS",
  UPDATE_TOKEN_FAILED: "UPDATE_TOKEN_FAILED",
  UPDATE_TOKEN_SUCCESS: "UPDATE_TOKEN_SUCCESS",
};

export const CDR_ACTIONS = {
  GET_CDR_IN_PROGRESS: "GET_CDR_IN_PROGRESS",
  GET_CDR_FAILED: "GET_CDR_FAILED",
  GET_CDR_SUCCESS: "GET_CDR_SUCCESS",
};

export const VERSIONS_ACTIONS = {
  GET_LAST_VERSION_FOR_ALL_HOSTS_IN_PROGRESS:
    "GET_LAST_VERSION_FOR_ALL_HOSTS_IN_PROGRESS",
  GET_LAST_VERSION_FOR_ALL_HOSTS_SUCCESS:
    "GET_LAST_VERSION_FOR_ALL_HOSTS_SUCCESS",
  GET_LAST_VERSION_FOR_ALL_HOSTS_FAILED:
    "GET_LAST_VERSION_FOR_ALL_HOSTS_FAILED",

  GET_ALL_VERSIONS_FOR_HOST_IN_PROGRESS:
    "GET_ALL_VERSIONS_FOR_HOST_IN_PROGRESS",
  GET_ALL_VERSIONS_FOR_HOST_SUCCESS:
    "GET_ALL_VERSIONS_FOR_HOST_SUCCESS",
  GET_ALL_VERSIONS_FOR_HOST_FAILED:
    "GET_ALL_VERSIONS_FOR_HOST_FAILED",
};

export const OPS_ACTIONS = {
  GET_OPS_IN_PROGRESS: "GET_OPS_IN_PROGRESS",
  GET_OPS_SUCCESS: "GET_OPS_SUCCESS",
  GET_OPS_FAILED: "GET_OPS_FAILED",
};

export const OPERATIONS = {
  CREATE_OPERATIONS_IN_PROGRESS: "CREATE_OPERATIONS_IN_PROGRESS",
  CREATE_OPERATIONS_SUCCESS: "CREATE_OPERATIONS_SUCCESS",
  CREATE_OPERATIONS_FAILED: "CREATE_OPERATIONS_FAILED",

  UPDATE_OPERATIONS_IN_PROGRESS: "UPDATE_OPERATIONS_IN_PROGRESS",
  UPDATE_OPERATIONS_SUCCESS: "UPDATE_OPERATIONS_SUCCESS",
  UPDATE_OPERATIONS_FAILED: "UPDATE_OPERATIONS_FAILED",

  DELETE_OPERATIONS_IN_PROGRESS: "DELETE_OPERATIONS_IN_PROGRESS",
  DELETE_OPERATIONS_SUCCESS: "DELETE_OPERATIONS_SUCCESS",
  DELETE_OPERATIONS_FAILED: "DELETE_OPERATIONS_FAILED",

  GET_OPERATIONS_IN_PROGRESS: "GET_OPERATIONS_IN_PROGRESS",
  GET_OPERATIONS_SUCCESS: "GET_OPERATIONS_SUCCESS",
  GET_OPERATIONS_FAILED: "GET_OPERATIONS_FAILED",

  GET_OPERATIONS_PAGED_IN_PROGRESS: "GET_OPERATIONS_PAGED_IN_PROGRESS",
  GET_OPERATIONS_PAGED_SUCCESS: "GET_OPERATIONS_PAGED_SUCCESS",
  GET_OPERATIONS_PAGED_FAILED: "GET_OPERATIONS_PAGED_FAILED",

  SET_CURRENT_OPERATION_SUCCESS: "SET_CURRENT_OPERATION_SUCCESS",
};
