import ReconnectingEventSource from "reconnecting-eventsource";

import CONFIG from "./environment";
import {DateTime} from "luxon";
import {error, log} from "../modules/logger";
import {
  refreshInstance,
  terminateLaunchService,
} from "../actions/instanceActions";
import {
  getOutages,
  getOutagesForInstance,
  getSLA,
} from "../actions/OutageActions";
import {
  getSeriesForAllInstances,
  getSeriesForInstance,
  getSeriesTestingForInstance, getTimelineForInstance,
} from "../actions/seriesActions";
import {
  getLastUsageForAllHosts,
  getSummaryForInstance,
  getSummaryOverall,
  getTodayStatusForAllHosts,
} from "../actions/summaryActions";
import {getCDRForUser} from "../actions/cdrActions";
import {getAllVersionsforHost, getLastVersionOfAllHosts} from "../actions/versionsActions";
import {getOpsOfHost} from "../actions/opsActions";
import {getAllOperationsForAHost} from "../actions/operationActions";

const moduleName = "mod:realtime";
let source;
let currentInstanceId = null;
let connected = false;
let autoReconnect = true;

const refreshInstanceData = (userId, instanceId, token, dispatch) => {
  refreshInstance(userId, instanceId, token, dispatch);
  getSeriesForInstance(userId, instanceId, token, dispatch);
  getSeriesTestingForInstance(userId, instanceId, token, dispatch);
  getSummaryForInstance(userId, instanceId, token, dispatch);
  getSeriesForAllInstances(userId, token, dispatch);
  getSummaryOverall(userId, token, dispatch);
  getLastVersionOfAllHosts(userId, token, dispatch);
  getLastUsageForAllHosts(userId, token, dispatch);
  getOpsOfHost(userId, instanceId, token, dispatch);
  getSLA(userId, null, token, dispatch);
  getOutages(userId, token, dispatch);
  getTodayStatusForAllHosts(userId, token, dispatch);
  getAllOperationsForAHost(userId, instanceId, token, dispatch);
  getTimelineForInstance(userId, instanceId, token, dispatch);
  getAllVersionsforHost(userId, instanceId, token, dispatch);
};

const refreshAllInstancesData = (userId, token, dispatch) => {
  getSeriesForAllInstances(userId, token, dispatch);
  getSummaryOverall(userId, token, dispatch);
  getLastVersionOfAllHosts(userId, token, dispatch);
  getLastUsageForAllHosts(userId, token, dispatch);
  getSLA(userId, null, token, dispatch);
  getOutages(userId, token, dispatch);
  getTodayStatusForAllHosts(userId, token, dispatch);
};

const refreshOutageData = (userId, instanceId, token, dispatch) => {
  getOutages(userId, token, dispatch);
  getOutagesForInstance(
    userId,
    instanceId,
    DateTime.now().minus({months: 3}),
    DateTime.now().endOf("month"),
    token,
    dispatch,
  );
  getSeriesForInstance(userId, instanceId, token, dispatch);
  getSeriesTestingForInstance(userId, instanceId, token, dispatch);
  getSLA(userId, null, token, dispatch);
  getSeriesForAllInstances(userId, token, dispatch);
};

export const openServerSideChannel = (sseToken, token, dispatch) => {
  source = new ReconnectingEventSource(
    `${CONFIG.restUrl}/events?token=${sseToken}`,
    {
      // indicating if CORS should be set to include credentials, default `false`
      withCredentials: false,

      // the maximum time to wait before attempting to reconnect in ms, default `3000`
      // note: wait time is randomised to prevent all clients from attempting to reconnect simultaneously
      max_retry_time: 2000,

      // underlying EventSource class, default `EventSource`
      eventSourceClass: EventSource,
    },
  );

  source.onerror = (err) => {
    error(moduleName, "sse-error", {err});
    connected = false;
  };

  source.onopen = () => {
    log(moduleName, "sse-open");
    connected = true;
  };

  source.onmessage = (message) => {
    log(moduleName, "sse-message", {data: message.data});
    const index = message.data.indexOf(":");
    const command = message.data.substring(0, index);
    const params = message.data.substring(index + 1).split("|");
    const param1 = params[0];
    const param2 = params.length > 0 ? params[1] : null;
    const param3 = params.length > 1 ? params[2] : null;
    const param4 = params.length > 2 ? params[3] : null;

    if (!command) {
      return;
    }

    log(moduleName, "sse-message structured", {
      command,
      userId: param1,
      instanceId: param2,
      param3,
      param4,
      currentInstanceId,
    });

    // For instance and outage commands : refresh only if it is the instance in used
    switch (command) {
      case "reset":
      case "instance":
        if (param2 === currentInstanceId) {
          log(moduleName, "sse-message refresh instance data", {
            currentInstanceId,
          });
          refreshInstanceData(param1, param2, token, dispatch);
        } else {
          // Refresh only aggregated data for all instances
          refreshAllInstancesData(param1, token, dispatch);
        }
        break;
      case "outage":
        if (param2 === currentInstanceId) {
          log(moduleName, "sse-message refresh instance outage", {
            currentInstanceId,
          });
          refreshOutageData(param1, param2, token, dispatch);
        }
        break;
      case "info":
        log(moduleName, "sse-message info", {info: param2});
        break;
      case "event":
        break;
      case "mur":
        getCDRForUser(param1, token, dispatch);
        break;
      case "job":
        if (param1 === "terminated") {
          terminateLaunchService(param3, param4, param2, dispatch);
        }
        break;
      case "operations":
        getAllOperationsForAHost(param1, param2, token, dispatch);
        break;
      default:
        log(moduleName, "sse-message command not found", {
          data: message.data,
        });
        break;
    }
  };
};

export const closeServerSideChannel = () => {
  if (source) {
    source.close();
    source.onerror = null;
    source.onopen = null;
    source.onmessage = null;
    source = null;
  }
};

export const changeInstanceId = (id) => {
  log(moduleName, "sse-message change current instance used", {id});
  currentInstanceId = id;
};

export const isRealTimeConnected = () => {
  return connected;
};

export const manualRefreshInstance = (userId, instanceId, token, dispatch) => {
  refreshInstanceData(userId, instanceId, token, dispatch);
};
