import { Row, Col } from "reactstrap";
import CardNumber from "../CardNumber";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import CONFIG from "../../modules/environment";
import { getAdminMetrics } from "../../actions/adminActions";
import AdminNewUser from "./AdminNewUser";
import "./Admin.css";
import AdminAccounts from "./AdminAccounts";
import AdminDatabase from "./AdminDatabase";
import AdminJobs from "./AdminJobs";
import AdminCommand from "./AdminCommand";
import BreadCrumbCusto from "../BreadCrumb";

function Admin({ dispatch }) {
  const appState = useContext(AppContext);
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    getAdminMetrics(appState.token, dispatch);
  }, []);

  useEffect(() => {
    setMetrics(appState.metrics);
  }, [appState.metrics]);

  const countNumberOfInstances = () => {
    if (!metrics) {
      return 0;
    }
    const instances = metrics.find((item) => item.name === "instances");
    return instances?.count || "-";
  };

  const countDBSize = () => {
    return (
      (metrics?.reduce((a, c) => a + c.size, 0) || 0) /
      (1024 * 1024)
    ).toFixed(2);
  };

  const countNumberOfUsers = () => {
    if (!metrics) {
      return 0;
    }
    const users = metrics.find((item) => item.name === "users");
    return users?.count || "-";
  };

  const getVersion = () => {
    return CONFIG.appVersion;
  };

  return (
    <div className="content-top">
      <>
        <BreadCrumbCusto
          dispatch={dispatch}
          instance={null}
          env={null}
          name={"administration"}
        />

        <Row>
          <Col lg="3" md="3" sm="12" xs="12">
            <CardNumber
              name="USERS"
              value={{ data: { usersCount: countNumberOfUsers() } }}
              prop="usersCount"
              icon="cafe-accounts"
              unit="u"
              last="Registered Users"
            />
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <CardNumber
              name="HOSTS"
              value={{ data: { instancesCount: countNumberOfInstances() } }}
              prop="instancesCount"
              icon="cafe-host"
              unit="u"
              last="Registered Hosts"
            />
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <CardNumber
              name="DB SIZE"
              value={{ data: { size: countDBSize() } }}
              prop="size"
              icon="cafe-host"
              unit="Mb"
              last="Registered Hosts"
            />
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <CardNumber
              name="VERSION"
              value={{ data: { version: getVersion() } }}
              prop="version"
              icon="cafe-version"
              unit="u"
              last="Information"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <AdminAccounts dispatch={dispatch} />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <AdminNewUser dispatch={dispatch} />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <AdminCommand dispatch={dispatch} />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <AdminJobs dispatch={dispatch} />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <AdminDatabase dispatch={dispatch} metrics={metrics} />
          </Col>
        </Row>
      </>
    </div>
  );
}

export default Admin;
