import { Card, CardBody, CardTitle, Row, Col, Label, Badge } from "reactstrap";
import { CHART_COLORS } from "../modules/helper";

function CardCallStat(props) {
  const getAvgColor = (value, threshold, inverse) => {
    if (value === null || value === undefined) {
      return "secondary";
    }

    if (inverse) {
      if (value < threshold[0]) {
        return "success";
      }
      if (value < threshold[1]) {
        return "warning";
      }
      return "danger";
    }

    if (value > threshold[0]) {
      return "success";
    }
    if (value > threshold[1]) {
      return "warning";
    }
    return "danger";
  };

  const avg = Number.isFinite(props.avg)
    ? `${props.avg.toFixed(props.fixed)} ${props.unit}`
    : "n/a";
  const variation = props.vol ? `${props.vol.toFixed(props.fixed)} %` : "n/a";
  const hasVariation = props.hasOwnProperty("vol");

  return (
    <>
      <CardTitle tag="h5" style={{ textAlign: "left" }}>
        {props.name}
      </CardTitle>
      <Card className="card-stats card-dashed">
        <CardBody style={{ padding: "10px" }}>
          <Row>
            <Col>
              <div xs="6" className="numbers">
                <p className="card-category" style={{ textAlign: "center" }}>
                  <small>Average</small>
                </p>
                <CardTitle tag="h5" style={{ textAlign: "center" }}>
                  <Badge
                    color={getAvgColor(
                      props.avg,
                      props.thresholds,
                      props.thresholdInverse
                    )}
                    style={{
                      color: Number.isFinite(props.avg) ? "#fff" : "#444",
                      textTransform: "none",
                    }}
                  >
                    {avg}
                  </Badge>
                </CardTitle>
              </div>
            </Col>
            <Col xs="6">
              {hasVariation && (
                <div className="numbers">
                  <p className="card-category" style={{ textAlign: "center" }}>
                    <small>Variation</small>
                  </p>
                  <CardTitle
                    tag="h5"
                    style={{
                      fontWeight: 400,
                      textAlign: "center",
                      fontSize: "0.62475rem",
                      lineHeight: "19px",
                    }}
                  >
                    {variation}
                  </CardTitle>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default CardCallStat;
