import {
  getAllInstances,
  getSLAForAllInstances,
  createNewInstance,
  removeInstance,
  updateMonitoring,
  updateAnInstance,
  updateCoturn,
  updateServiceFrequencyForInstance,
  launchService,
  executeTURNAction,
  resetInstanceStatistics,
} from "../modules/status";

import { ACTIONS, APP_ACTIONS } from "./constants";
import { log } from "../modules/logger";
import { HOST_ROLE, SERVICE_STATUS } from "../modules/bm";
import { changeInstanceId } from "../modules/realtime";

const moduleName = "instanceActions";

export const getInstances = async (userId, token, dispatch) => {
  log(moduleName, "get all instances", { userId });
  dispatch({
    type: ACTIONS.LIST_INSTANCES_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getAllInstances(userId, null, token);

    if (data) {
      dispatch({
        type: ACTIONS.LIST_INSTANCES_SUCCESS,
        payload: { value: { instances: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.LIST_INSTANCES_FAILED,
        payload: { value: null },
      });
    }
    return data;
  } catch (err) {
    dispatch({
      type: ACTIONS.LIST_INSTANCES_FAILED,
      payload: { value: null },
    });
  }
};

export const refreshInstance = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "refresh instance", { userId, instanceId });
  dispatch({
    type: ACTIONS.GET_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getAllInstances(userId, instanceId, token);

    if (data) {
      let instance = data;
      if (data.length === 1) {
        instance = data[0];
      }
      dispatch({
        type: ACTIONS.GET_INSTANCE_SUCCESS,
        payload: { value: { instance: instance } },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_INSTANCE_FAILED,
      payload: { value: null },
    });
  }
};

export const updateInstance = async (
  userId,
  instanceId,
  params,
  token,
  dispatch,
) => {
  log(moduleName, "update instance", { userId, instanceId });
  dispatch({
    type: ACTIONS.UPDATE_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateAnInstance(userId, instanceId, params, token);

    if (data) {
      let instance = data;
      if (data.length === 1) {
        instance = data[0];
      }
      dispatch({
        type: ACTIONS.UPDATE_INSTANCE_SUCCESS,
        payload: { value: { instance: instance } },
      });
    } else {
      dispatch({
        type: ACTIONS.UPDATE_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.UPDATE_INSTANCE_FAILED,
      payload: { value: null },
    });
  }
};

export const updateInstanceServiceFrequency = async (
  userId,
  instanceId,
  service,
  frequency,
  token,
  dispatch,
) => {
  log(moduleName, "update instance service frequency", { userId, instanceId });
  dispatch({
    type: ACTIONS.UPDATE_FREQUENCY_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateServiceFrequencyForInstance(
      userId,
      instanceId,
      service,
      frequency,
      token,
    );

    if (data) {
      let instance = data;
      if (data.length === 1) {
        instance = data[0];
      }
      dispatch({
        type: ACTIONS.UPDATE_FREQUENCY_SUCCESS,
        payload: { value: { instance: instance } },
      });
    } else {
      dispatch({
        type: ACTIONS.UPDATE_FREQUENCY_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.UPDATE_FREQUENCY_FAILED,
      payload: { value: null },
    });
  }
};

export const createInstance = async (
  userId,
  token,
  dispatch,
  name = `host_${Date.now()}`,
  role = HOST_ROLE.COTURN,
) => {
  log(moduleName, "create new instance", { userId });
  dispatch({
    type: ACTIONS.ADD_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await createNewInstance(userId, token, name, role);

    if (data) {
      dispatch({
        type: ACTIONS.ADD_INSTANCE_SUCCESS,
        payload: {
          value: { instances: data.instances, instance: data.instance },
        },
      });

      return data;
    } else {
      dispatch({
        type: ACTIONS.ADD_INSTANCE_FAILED,
        payload: { value: null },
      });
      return null;
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.ADD_INSTANCE_FAILED,
      payload: { value: null },
    });
    return null;
  }
};

export const deleteInstance = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "delete instance", { userId, instanceId });
  dispatch({
    type: ACTIONS.REMOVE_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await removeInstance(userId, instanceId, token);

    if (data) {
      dispatch({
        type: ACTIONS.REMOVE_INSTANCE_SUCCESS,
        payload: { value: { instanceId } },
      });
    } else {
      dispatch({
        type: ACTIONS.REMOVE_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.REMOVE_INSTANCE_FAILED,
      payload: { value: null },
    });
  }
};

export const resetInstance = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "reset instance", { userId, instanceId });
  dispatch({
    type: ACTIONS.RESET_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await resetInstanceStatistics(userId, instanceId, token);

    if (data) {
      dispatch({
        type: ACTIONS.RESET_INSTANCE_SUCCESS,
        payload: { value: { instanceId } },
      });
    } else {
      dispatch({
        type: ACTIONS.RESET_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.RESET_INSTANCE_FAILED,
      payload: { value: null },
    });
  }
};

export const setCurrentInstance = async (instance, dispatch) => {
  log(moduleName, "set current instance", { instance: instance || null });
  changeInstanceId(instance?._id || null);
  dispatch({
    type: ACTIONS.SET_CURRENT_INSTANCE,
    payload: { value: { instance } },
  });
  return instance;
};

export const monitorInstance = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "enable monitoring instance", { userId, instanceId });
  dispatch({
    type: ACTIONS.MONITOR_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateMonitoring(userId, instanceId, true, token);

    if (data && data.success) {
      const instance = data.data;
      dispatch({
        type: ACTIONS.MONITOR_INSTANCE_SUCCESS,
        payload: { value: { instance } },
      });
    } else {
      dispatch({
        type: ACTIONS.MONITOR_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
    return data;
  } catch (err) {
    dispatch({
      type: ACTIONS.MONITOR_INSTANCE_FAILED,
      payload: { value: null },
    });
    return data;
  }
};

export const unmonitorInstance = async (
  userId,
  instanceId,
  token,
  dispatch,
) => {
  log(moduleName, "disable monitoring instance", { userId, instanceId });
  dispatch({
    type: ACTIONS.UNMONITOR_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateMonitoring(userId, instanceId, false, token);

    if (data && data.success) {
      const instance = data.data;
      dispatch({
        type: ACTIONS.UNMONITOR_INSTANCE_SUCCESS,
        payload: { value: { instance } },
      });
    } else {
      dispatch({
        type: ACTIONS.UNMONITOR_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.UNMONITOR_INSTANCE_FAILED,
      payload: { value: null },
    });
  }
};

export const stopCoturn = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "disable coturn", { userId, instanceId });
  dispatch({
    type: ACTIONS.STOP_COTURN_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateCoturn(userId, instanceId, false, token);

    if (data) {
      dispatch({
        type: ACTIONS.STOP_COTURN_SUCCESS,
        payload: { value: { instance: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.STOP_COTURN_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.STOP_COTURN_FAILED,
      payload: { value: null },
    });
  }
};

export const startCoturn = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "enable coturn", { userId, instanceId });
  dispatch({
    type: ACTIONS.START_COTURN_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateCoturn(userId, instanceId, true, token);

    if (data) {
      dispatch({
        type: ACTIONS.START_COTURN_SUCCESS,
        payload: { value: { instance: data } },
      });
    } else {
      dispatch({
        type: ACTIONS.START_COTURN_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.START_COTURN_FAILED,
      payload: { value: null },
    });
  }
};

export const executeActionOnTurn = async (
  userId,
  instanceId,
  action,
  token,
  dispatch,
) => {
  log(moduleName, `execute coturn action ${action}`, { userId, instanceId });
  dispatch({
    type: ACTIONS.TURN_ACTION_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await executeTURNAction(userId, instanceId, action, token);

    if (data) {
      dispatch({
        type: ACTIONS.TURN_ACTION_SUCCESS,
        payload: { value: { configuration: data.status } },
      });
    } else {
      dispatch({
        type: ACTIONS.TURN_ACTION_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.TURN_ACTION_FAILED,
      payload: { value: null },
    });
  }
};

export const executeLocalActionOnTurn = async (content, dispatch) => {
  log(moduleName, `execute local coturn action`);

  const parseConfiguration = (content) => {
    const lines = content.split("\n");
    const parsed = {};
    let lineNumber = 0;
    lines.forEach((line) => {
      if (line.length > 0 && !line.startsWith("#")) {
        const indexOfSeparator = line.indexOf("=");
        if (indexOfSeparator > -1) {
          // case prop=value
          let propName = line.substring(0, indexOfSeparator);
          if (propName in parsed) {
            propName = `${propName}#${lineNumber}`;
          }
          const value = line.substring(indexOfSeparator + 1);
          parsed[propName] = value;
        } else {
          // case prop (without value = true by default)
          parsed[line] = true;
        }
        lineNumber++;
      }
    });
    return parsed;
  };

  dispatch({
    type: ACTIONS.TURN_ACTION_SUCCESS,
    payload: { value: { configuration: parseConfiguration(content) } },
  });
};

export const manualLaunchService = async (
  userId,
  instanceId,
  serviceName,
  token,
  dispatch,
  toReset = false,
) => {
  if (toReset) {
    log(moduleName, `manual reset of ${serviceName} for ${instanceId}`);
  } else {
    log(moduleName, `manual launch of ${serviceName} for ${instanceId}`);
  }

  dispatch({
    type: ACTIONS.LAUNCH_SERVICE_IN_PROGRESS,
    payload: { value: serviceName },
  });

  try {
    launchService(userId, instanceId, serviceName, token, toReset);
  } catch (err) {
    dispatch({
      type: ACTIONS.LAUNCH_SERVICE_FAILED,
      payload: { value: null },
    });
  }
};

export const terminateLaunchService = async (
  result,
  details,
  service,
  dispatch,
) => {
  if (result === SERVICE_STATUS.SUCCESS || result === SERVICE_STATUS.UNKNOWN) {
    dispatch({
      type: ACTIONS.LAUNCH_SERVICE_SUCCESS,
      payload: { value: null },
    });
    return;
  }
  dispatch({
    type: ACTIONS.LAUNCH_SERVICE_FAILED,
    payload: { value: { details, service } },
  });
};

export const resetLaunchService = async (dispatch) => {
  dispatch({
    type: ACTIONS.LAUNCH_SERVICE_RESET,
    payload: { value: null },
  });
};
