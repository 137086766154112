import { useContext, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";

import AppContext from "../contexts/appContext";

import "./MapBoard.css";
import { getAbbr, getDuration } from "../modules/helper";
import { getOutages } from "../actions/OutageActions";
import { DateTime } from "luxon";

import "./Outages.css";

function Outages({ dispatch }) {
  const appState = useContext(AppContext);

  const [outages, setOutages] = useState([]);

  const getInstanceName = (instanceId) => {
    if (appState.instances) {
      const instance = appState.instances.find(
        (instance) => instance._id === instanceId
      );
      if (instance) {
        return instance.name;
      }
      return instanceId;
    }
  };

  const isSelectedHost = (instanceId) => {
    return (
      appState.currentInstance && appState.currentInstance._id === instanceId
    );
  };

  const getDate = (startedDate) => {
    const dateOfOutage = DateTime.fromISO(startedDate);
    if (DateTime.now().hasSame(dateOfOutage, "day")) {
      return dateOfOutage.toRelative();
    }
    return DateTime.fromISO(startedDate).toLocaleString(
      DateTime.DATETIME_FULL_WITH_SECONDS
    );
  };

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Closed incidents for hosts</h5>
        <CardTitle tag="h3">LAST OUTAGES</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="12" md="12" xl="12">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th className="text-left">Host</th>
                  <th className="text-left">Date</th>
                  <th className="text-left">Duration</th>
                </tr>
              </thead>
              <tbody>
                {appState.outages &&
                  appState.outages.map((outage, key) => (
                    <tr key={key}>
                      <td className="text-left">
                        {getInstanceName(outage.instanceId)}
                      </td>
                      <td className="text-left">{getDate(outage.started)}</td>
                      <td className="text-left">
                        <Badge
                          color={outage.duration > 1800 ? "danger" : "warning"}
                        >
                          {getDuration(outage.duration)}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                {(!appState.outages || appState.outages.length === 0) && (
                  <tr>
                    <td colSpan="5">
                      <br />
                      No outages found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default Outages;
