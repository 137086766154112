import { useRef, useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import "../testing/media.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import { createChart, updateChart } from "../../modules/callCharts";
import CardCallStat from "../CardCallStat";
import { CHART_COLORS } from "../../modules/helper";

function JitterStat({ dispatch }) {
  const appState = useContext(AppContext);
  const statsJitterChart = useRef();
  const [jitterAudio, setJitterAudio] = useState({});
  const [jitterVideo, setJitterVideo] = useState({});

  useEffect(() => {
    createChart(
      document.querySelector("#chartJitter"),
      statsJitterChart,
      "JITTER"
    );
  }, []);

  useEffect(() => {
    updateChart(
      appState.statsJitter,
      appState.statsJitterVideo,
      statsJitterChart,
      50
    );
  }, [appState.statsJitter]);

  useEffect(() => {
    setJitterAudio(appState.finalJitter);
  }, [appState.finalJitter]);

  useEffect(() => {
    setJitterVideo(appState.finalJitterVideo);
  }, [appState.finalJitterVideo]);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Network disturbance measurement</h5>
        <CardTitle tag="h3">JITTER</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <CardCallStat
              min={jitterAudio.min}
              avg={jitterAudio.avg}
              max={jitterAudio.max}
              vol={jitterAudio.volatility}
              unit="ms"
              fixed={0}
              valueColor={CHART_COLORS.pink}
              name="Audio"
              thresholds={[15, 30]}
              thresholdInverse={true}
            />
          </Col>
          <Col md="6">
            <CardCallStat
              min={jitterVideo.min}
              avg={jitterVideo.avg}
              max={jitterVideo.max}
              vol={jitterVideo.volatility}
              unit="ms"
              fixed={0}
              valueColor={CHART_COLORS.teal}
              name="Video"
              thresholds={[20, 40]}
              thresholdInverse={true}
            />
          </Col>
        </Row>
        <div className="graph">
          <canvas id="chartJitter"></canvas>
        </div>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default JitterStat;
