import React, { useContext, useState } from "react";
import AppContext from "../../contexts/appContext";
import {
  Button,
  CardHeader,
  CardTitle,
  CardBody,
  Card,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { createUser } from "../../actions/adminActions";
import { HOST_SERVICE } from "../../modules/bm";

function AdminNewUser({ dispatch }) {
  const appState = useContext(AppContext);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [profile, setProfile] = useState("free");
  const [admin, setAdmin] = useState(false);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Quick creation of a new user</h5>
        <CardTitle tag="h3">CREATE</CardTitle>
      </CardHeader>
      <CardBody>
        <Form>
          <Row>
            <Col>
              <Label>Username</Label>
              <FormGroup>
                <Input
                  autoComplete="new-password"
                  type="text"
                  id="username"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
                <span
                  className="form-description"
                  style={{ display: "block", marginBottom: "4px" }}
                >
                  Used to log-in
                </span>
              </FormGroup>
            </Col>
            <Col>
              <Label>Password</Label>
              <FormGroup>
                <Input
                  autoComplete="new-password"
                  type="text"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <span
                  className="form-description"
                  style={{ display: "block", marginBottom: "4px" }}
                >
                  Used to log-in
                </span>
              </FormGroup>
            </Col>
            <Col>
              <Label>Profile</Label>
              <FormGroup>
                <Input
                  type="select"
                  name="select"
                  id="inputState"
                  className="form-control"
                  defaultValue={profile}
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    borderColor: "#2b3553",
                    fontSize: "0.75rem",
                    boxShadow: "none",
                    height: "calc(2.25rem + 2px)",
                  }}
                  onChange={(e) => {
                    setProfile(e.target.value);
                  }}
                >
                  <option value="free">Free</option>
                  <option value="startup">Startup</option>
                  <option value="enterprise">Enterprise</option>
                  <option value="demo">Demo</option>
                </Input>
                <span
                  className="form-description"
                  style={{ display: "block", marginBottom: "4px" }}
                >
                  Used to log-in
                </span>
              </FormGroup>
            </Col>
            <Col>
              <Label>Role</Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    defaultChecked={admin}
                    onChange={(e) => {
                      setAdmin(!admin);
                    }}
                  />{" "}
                  Has administrator rights
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
                <span
                  className="form-description"
                  style={{ display: "block", marginBottom: "4px" }}
                >
                  Check to give an admin role
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Button
            type="button"
            color="primary"
            size="sm"
            disabled={
              !userName ||
              userName.length === 0 ||
              !password ||
              password.length === 0
            }
            onClick={() => {
              createUser(
                userName,
                password,
                profile,
                admin,
                appState.token,
                dispatch
              );
              setUserName("");
              setPassword("");
              setProfile("free");
              setAdmin(false);
            }}
          >
            Save
          </Button>{" "}
          {appState.createUserInProgress && (
            <Spinner className="mt-1 ml-1" size="sm" children={null} />
          )}
        </Form>
      </CardBody>
    </Card>
  );
}

export default AdminNewUser;
