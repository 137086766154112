import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  Row,
  Col,
  Table,
  Button,
  Badge,
  Spinner,
  Input,
  Label,
} from "reactstrap";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import {
  executeActionOnTurn,
  executeLocalActionOnTurn,
} from "../../actions/instanceActions";
import { getPropertyDetailsFromName } from "../../modules/coturnProperties";
import { analyzeCoturnConfiguration } from "../../modules/bm";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import CardNumber from "../CardNumber";

let fileReader = null;

function Control(props) {
  const appState = useContext(AppContext);
  const [host, setHost] = useState(appState.currentInstance);
  const [configuration, setConfiguration] = useState({});
  const [report, setReport] = useState([]);
  const [testDone, setTestDone] = useState(false);
  const [testInProgress, setTestInProgress] = useStateWithCallbackLazy(false);

  useEffect(() => {
    if (appState.currentInstance) {
      setHost(appState.currentInstance);
    }
  }, [appState.currentInstance]);

  useEffect(() => {
    if (appState.configuration) {
      const report = analyzeCoturnConfiguration(appState.configuration);
      setConfiguration(appState.configuration);
      setReport(report);
      setTestInProgress(false);
      setTestDone(true);
    }
  }, [appState.configuration]);

  const getKeyWithoutSuffix = (key) => {
    return key.split("#")[0];
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    setTestInProgress(true);
    executeLocalActionOnTurn(content, props.dispatch);
  };

  const handleFileChoosen = (file) => {
    if (file) {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    }
  };

  const getErrorOrWarningForExistingProperty = (key) => {
    const existingErrorOfWarnings = report.filter(
      (item) => item.type === "existing" && item.property === key
    );

    const tags = existingErrorOfWarnings.map((error) => (
      <p>
        <small
          className={error.level === "error" ? "text-danger" : "text-warning"}
        >
          {error.description}
        </small>
      </p>
    ));

    return tags;
  };

  const hasWarningForExistingProperty = (key) => {
    const existingWarning = report.find(
      (item) =>
        item.type === "existing" &&
        item.property === key &&
        item.level === "warning"
    );

    return existingWarning ? true : false;
  };

  const hasErrorForExistingProperty = (key) => {
    const existingError = report.find(
      (item) =>
        item.type === "existing" &&
        item.property === key &&
        item.level === "error"
    );

    return existingError ? true : false;
  };

  const getResultForSyntax = () => {
    if (!testDone) {
      return "N/A";
    }
    return Object.keys(configuration).length > 0 ? "PASSED" : "FAILED";
  };

  const getDetailsForSyntax = () => {
    if (!testDone) {
      return "N/A";
    }
    if (Object.keys(configuration).length > 0) {
      return "PASSED";
    }
    return "FAILED";
  };

  const getResultForFunctionalities = () => {
    if (!testDone) {
      return "N/A";
    }

    if (
      !report.find(
        (item) => item.property === "no-stun" || item.property === "stun-only"
      )
    ) {
      return "PASSED";
    }
    return "FAILED";
  };

  const getDetailsForFunctionalities = () => {
    if (!testDone) {
      return "N/A";
    }

    if (
      !report.find(
        (item) => item.property === "no-stun" || item.property === "stun-only"
      )
    ) {
      return "PASSED";
    }
    return "LIMITED";
  };

  const getResultForProtocols = () => {
    if (!testDone) {
      return "N/A";
    }

    if (
      !report.find(
        (item) =>
          item.property === "no-tls" ||
          item.property === "no-tcp" ||
          item.property === "no-udp" ||
          item.property === "no-dtls" ||
          item.property === "no-udp-relay" ||
          item.property === "no-tcp-relay"
      )
    ) {
      return "PASSED";
    }
    return "FAILED";
  };

  const getDetailsForProtocols = () => {
    if (!testDone) {
      return "N/A";
    }

    if (
      !report.find(
        (item) =>
          item.property === "no-tls" ||
          item.property === "no-tcp" ||
          item.property === "no-udp" ||
          item.property === "no-dtls" ||
          item.property === "no-udp-relay" ||
          item.property === "no-tcp-relay"
      )
    ) {
      return "PASSED";
    }
    return "LIMITED";
  };

  const getResultForSecurity = () => {
    if (!testDone) {
      return "N/A";
    }
    if (report.filter((item) => item.type === "security").length === 0) {
      return "PASSED";
    }
    return "FAILED";
  };

  const getSecurityList = () => {
    return report.filter((item) => item.type === "security");
  };

  const getMissingList = () => {
    return report.filter((item) => item.type === "missing");
  };

  const getDetailsForSecurity = () => {
    if (!testDone) {
      return "N/A";
    }
    const alerts = report.filter((item) => item.type === "security");
    if (alerts.length === 0) {
      return "PASSED";
    }
    return `FAILED (${alerts.length})`;
  };

  const getNbErrorsForSecurity = () => {
    return report.filter(
      (item) => item.type === "security" && item.level === "error"
    ).length;
  };

  const getNbWarningsForSecurity = () => {
    return report.filter(
      (item) => item.type === "security" && item.level === "warning"
    ).length;
  };

  const getResultForMissing = () => {
    if (!testDone) {
      return "N/A";
    }
    if (report.filter((item) => item.type === "missing").length === 0) {
      return "PASSED";
    }
    return "FAILED";
  };

  const getDetailsForMissing = () => {
    if (!testDone) {
      return "N/A";
    }
    const missing = report.filter((item) => item.type === "missing");
    if (missing.length === 0) {
      return "PASSED";
    }
    return `FAILED (${missing.length})`;
  };

  const getNbErrorsForMissing = () => {
    return report.filter(
      (item) => item.type === "missing" && item.level === "error"
    ).length;
  };

  const getNbWarningsForMissing = () => {
    return report.filter(
      (item) => item.type === "missing" && item.level === "warning"
    ).length;
  };

  const getResultForOthers = () => {
    if (!testDone) {
      return "N/A";
    }
    if (report.filter((item) => item.type === "existing").length === 0) {
      return "PASSED";
    }
    return "FAILED";
  };

  const getDetailsForOthers = () => {
    if (!testDone) {
      return "N/A";
    }
    const missing = report.filter((item) => item.type === "existing");
    if (missing.length === 0) {
      return "PASSED";
    }
    return `FAILED (${missing.length})`;
  };

  const getNbErrorsForOther = () => {
    return report.filter(
      (item) => item.type === "existing" && item.level === "error"
    ).length;
  };

  const getNbWarningsForOther = () => {
    return report.filter(
      (item) => item.type === "existing" && item.level === "warning"
    ).length;
  };

  const getScoreIconColor = (score) => {
    if (!testDone) {
      return "";
    }
    if (score.includes("A")) {
      return "text-success";
    } else if (score.includes("B")) {
      return "text-warning";
    }
    return "text-danger";
  };

  const computeScoreForConfiguration = () => {
    let score = "-";
    if (!testDone) {
      return score;
    }

    if (getResultForSyntax() === "PASSED") {
      if (
        getNbWarningsForSecurity() +
          getNbErrorsForSecurity() +
          getNbErrorsForOther() +
          getNbWarningsForOther() +
          getNbErrorsForMissing() +
          getNbWarningsForMissing() ===
        0
      ) {
        return "A++";
      }
      if (
        getNbErrorsForSecurity() +
          getNbErrorsForOther() +
          getNbErrorsForMissing() +
          getNbWarningsForSecurity() +
          getNbWarningsForMissing() ===
        0
      ) {
        return "A+";
      }
      if (
        getNbErrorsForSecurity() +
          getNbErrorsForOther() +
          getNbErrorsForMissing() +
          getNbWarningsForSecurity() ===
        0
      ) {
        return "A";
      }
      if (
        getNbErrorsForSecurity() +
          getNbErrorsForOther() +
          getNbErrorsForMissing() ===
        0
      ) {
        return "B";
      }
      if (getNbErrorsForSecurity() + getNbErrorsForMissing() === 0) {
        return "B-";
      }
      if (getNbErrorsForSecurity() === 0) {
        return "C";
      }
      return "D";
    } else {
      return "E";
    }
  };

  const getFeatures = (report) => {
    if (!testDone) {
      return "-";
    }

    if (
      report.find(
        (item) => item.property === "no-stun" && item.property === "stun-only"
      )
    ) {
      return "NONE";
    }

    if (
      !report.find(
        (item) => item.property === "no-stun" || item.property === "stun-only"
      )
    ) {
      return "STUN/TURN";
    }

    if (report.find((item) => item.property === "no-stun")) {
      return "TURN ONLY";
    }

    return "STUN ONLY";
  };

  const getProtocols = () => {
    if (!testDone) {
      return "-";
    }

    let hasUDP = true;
    if (
      report.find(
        (item) => item.property === "no-udp" || item.property === "no-udp-relay"
      )
    ) {
      hasUDP = false;
    }

    let hasTCP = true;
    if (
      report.find(
        (item) => item.property === "no-tcp" || item.property === "no-tcp-relay"
      )
    ) {
      hasTCP = false;
    }

    let hasTLS = true;
    if (
      report.find(
        (item) =>
          item.property === "no-tls" ||
          item.property === "no-dtls" ||
          item.property === "cert" ||
          item.property === "pkey"
      )
    ) {
      hasTLS = false;
    }

    if (hasTCP && hasTLS && hasUDP) {
      return "UDP/TCP/TLS";
    }

    if (hasTCP) {
      if (hasTLS) {
        return "TCP/TLS";
      }
      if (hasUDP) {
        return "UDP/TCP";
      }
    }

    if (hasUDP) {
      if (hasTCP) {
        return "UDP/TCP";
      }
      if (hasTLS) {
        return "UDP/TLS";
      }
      return "UDP";
    }

    if (hasTLS) {
      return "TLS";
    }

    return "NONE";
  };

  const getLogs = (configuration) => {
    const useSyslog = Object.keys(configuration).includes("syslog");

    if (useSyslog) {
      return "SYSLOG";
    }
    return "LOG FILE";
  };

  const getAuthentication = (configuration) => {
    const useOauth = Object.keys(configuration).includes("oauth");
    if (useOauth) {
      return "OAUTH";
    }
    const useAuthSecret =
      Object.keys(configuration).includes("use-auth-secret");
    if (useAuthSecret) {
      return "SECRET TOKEN";
    }
    const useLTCredentials =
      Object.keys(configuration).includes("lt-cred-mech");
    if (useLTCredentials) {
      return "LT CRED";
    }
    return "NONE";
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="9">
                      <h5 className="card-category">Controlling</h5>
                      <CardTitle tag="h3">CONFIGURATION</CardTitle>
                    </Col>
                    <Col className="text-right" sm="3">
                      <h5 className="card-category">Score</h5>
                      <CardTitle
                        tag="h2"
                        className={getScoreIconColor(
                          computeScoreForConfiguration()
                        )}
                      >
                        {computeScoreForConfiguration()}
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left w-50">ITEMS</th>
                        <th className="text-right">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        bgcolor={
                          getResultForSyntax() === "FAILED" ? "warning" : ""
                        }
                      >
                        <td className="text-left w-50">
                          <span>Syntax check</span>
                        </td>
                        <td className="text-right">
                          {getResultForSyntax() === "PASSED" && (
                            <i className="icon cafe-selected icon-selected text-success mr-2" />
                          )}
                          {getDetailsForSyntax()}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left w-50">
                          <span>Functionalities</span>
                        </td>
                        <td className="text-right">
                          {getResultForFunctionalities() === "PASSED" && (
                            <i className="icon cafe-selected icon-selected text-success mr-2" />
                          )}
                          {getDetailsForFunctionalities()}
                        </td>
                      </tr>
                      <tr
                        bgcolor={
                          getResultForProtocols() === "FAILED" ? "warning" : ""
                        }
                      >
                        <td className="text-left w-50">
                          <span>Protocols</span>
                        </td>
                        <td className="text-right">
                          {getResultForProtocols() === "PASSED" && (
                            <i className="icon cafe-selected icon-selected text-success mr-2" />
                          )}
                          {getDetailsForProtocols()}
                        </td>
                      </tr>
                      <tr
                        bgcolor={
                          getResultForSecurity() === "FAILED" ? "warning" : ""
                        }
                      >
                        <td className="text-left w-50">
                          <span>Security breaches</span>
                        </td>
                        <td className="text-right">
                          {getResultForSecurity() === "PASSED" && (
                            <i className="icon cafe-selected icon-selected text-success mr-2" />
                          )}
                          {getDetailsForSecurity()}
                        </td>
                      </tr>
                      <tr
                        bgcolor={
                          getResultForMissing() === "FAILED" ? "warning" : ""
                        }
                      >
                        <td className="text-left w-50">
                          <span>Missing properties</span>
                        </td>
                        <td className="text-right">
                          {getResultForMissing() === "PASSED" && (
                            <i className="icon cafe-selected icon-selected text-success mr-2" />
                          )}
                          {getDetailsForMissing()}
                        </td>
                      </tr>
                      <tr
                        bgcolor={
                          getResultForOthers() === "FAILED" ? "warning" : ""
                        }
                      >
                        <td className="text-left w-50">
                          <span>Other consideration points</span>
                        </td>
                        <td className="text-right">
                          {getResultForOthers() === "PASSED" && (
                            <i className="icon cafe-selected icon-selected text-success mr-2" />
                          )}
                          {getDetailsForOthers()}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <p className="text-muted">
                      This test checks the configuration properties in order to
                      detect mismatch, incompatibility or security issues.
                    </p>
                    <Button
                      disabled={testInProgress}
                      size="sm"
                      onClick={() => {
                        document.querySelector("#fileControl").click();
                      }}
                    >
                      Test local configuration file
                      <Input
                        id="fileControl"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          handleFileChoosen(e.target.files[0]);
                        }}
                      />
                    </Button>
                    <Button
                      size="sm"
                      disabled={testInProgress}
                      color="warning"
                      onClick={() => {
                        setTestInProgress(true);
                        executeActionOnTurn(
                          appState.user._id,
                          appState.currentInstance._id,
                          "get_configuration",
                          appState.token,
                          props.dispatch
                        );
                      }}
                    >
                      Test hosted configuration file (SSH)
                    </Button>
                    {testInProgress && (
                      <Spinner children={null} className="ml-2" size="sm" />
                    )}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="12" xs="12">
              <CardNumber
                name="MODE"
                value={{ data: { host: getFeatures(report) } }}
                prop="host"
                icon="cafe-ice-host"
                unit="u"
                last="Features configured"
              />
            </Col>
            <Col lg="3" md="6" sm="12" xs="12">
              <CardNumber
                name="PROTOCOLS"
                value={{ data: { stun: getProtocols(report) } }}
                prop="stun"
                icon="cafe-ice-stun"
                unit="u"
                last="Protocols configured"
              />
            </Col>
            <Col lg="3" md="6" sm="12" xs="12">
              <CardNumber
                name="LOG"
                value={{ data: { relay: getLogs(configuration) } }}
                prop="relay"
                icon="cafe-ice-turn"
                unit="u"
                last="Logging Method"
              />
            </Col>
            <Col lg="3" md="6" sm="12" xs="12">
              <CardNumber
                name="AUTHENTICATION"
                value={{ data: { relay: getAuthentication(configuration) } }}
                prop="relay"
                icon="cafe-ice-turn"
                unit="u"
                last="Authentication method"
              />
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <h5 className="card-category">Security points to consider</h5>
                  <CardTitle tag="h3">
                    SECURITY BREACHES <small>(Potential)</small>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {report.length > 0 && (
                    <>
                      <p>
                        These properties are missing from the Configuration
                        file. Consider to add them to your configuration file to
                        avoid potential security breaches.
                      </p>
                      <br />
                    </>
                  )}
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">properties</th>
                        <th className="text-left" style={{ width: "66%" }}>
                          TO CONSIDER
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {testDone &&
                        getSecurityList().map((item, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              <span
                                className={`${
                                  item.level === "warning"
                                    ? "text-warning"
                                    : "text-danger"
                                }`}
                              >
                                {item.property}
                              </span>
                            </td>
                            <td className="text-left" style={{ width: "66%" }}>
                              {item.description}
                            </td>
                          </tr>
                        ))}
                      {testDone &&
                        getNbErrorsForSecurity() +
                          getNbWarningsForSecurity() ===
                          0 && (
                          <tr>
                            <td colSpan="2">
                              <br />
                              <p className="text-success">
                                Well done! There is no security points to
                                consider.
                              </p>
                            </td>
                          </tr>
                        )}
                      {!testDone && (
                        <tr>
                          <td colSpan="2">
                            <br />
                            <p>
                              <Label>No result yet.</Label>
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <h5 className="card-category">Other points to consider</h5>
                  <CardTitle tag="h3">
                    MISSING PROPERTIES <small>(Potential)</small>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {report.length > 0 && (
                    <>
                      <p>
                        These properties are potentially missing from the
                        Configuration file. Consider to add them to your
                        configuration file.
                      </p>
                      <br />
                    </>
                  )}
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">properties</th>
                        <th className="text-left" style={{ width: "66%" }}>
                          TO CONSIDER
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {testDone &&
                        getMissingList().map((item, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              <span
                                className={`${
                                  item.level === "warning"
                                    ? "text-warning"
                                    : "text-danger"
                                }`}
                              >
                                {item.property}
                              </span>
                            </td>
                            <td className="text-left" style={{ width: "66%" }}>
                              {item.description}
                            </td>
                          </tr>
                        ))}
                      {testDone &&
                        getNbErrorsForMissing() + getNbWarningsForMissing() ===
                          0 && (
                          <tr>
                            <td colSpan="2">
                              <br />
                              <p className="text-success">
                                Well done! There is no missing property to
                                consider.
                              </p>
                            </td>
                          </tr>
                        )}
                      {!testDone && (
                        <tr>
                          <td colSpan="2">
                            <br />
                            <p>
                              <Label>No result yet.</Label>
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <h5 className="card-category">TURN Configuration details</h5>
                  <CardTitle tag="h3">CONFIGURED PROPERTIES</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">properties</th>
                        <th className="text-left" style={{ width: "33%" }}>
                          Description
                        </th>
                        <th className="text-right">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testDone &&
                        Object.keys(configuration).map((name, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              <span
                                className={
                                  hasWarningForExistingProperty(
                                    getKeyWithoutSuffix(name)
                                  )
                                    ? "text-warning"
                                    : hasErrorForExistingProperty(
                                        getKeyWithoutSuffix(name)
                                      )
                                    ? "text-danger"
                                    : ""
                                }
                              >
                                {" "}
                                {getKeyWithoutSuffix(name)}
                              </span>
                            </td>
                            <td className="text-left" style={{ width: "33%" }}>
                              <p>
                                <small>
                                  {getPropertyDetailsFromName(
                                    getKeyWithoutSuffix(name)
                                  )}
                                </small>
                              </p>
                              {getErrorOrWarningForExistingProperty(name)}
                            </td>
                            <td className="text-right">
                              <Badge
                                style={{
                                  textTransform: "lowercase",
                                  fontWeight: "400",
                                  color: "#111",
                                  letterSpacing: "1px",
                                }}
                              >
                                {configuration[name].toString()}
                              </Badge>
                            </td>
                          </tr>
                        ))}
                      {testDone && Object.keys(configuration).length === 0 && (
                        <tr>
                          <td colSpan="3">
                            <br />
                            <p>
                              <Label className="text-danger">
                                Error when loading the Configuration file.
                                Please check the content of the file.
                              </Label>
                            </p>
                          </td>
                        </tr>
                      )}
                      {!testDone && (
                        <tr>
                          <td colSpan="3">
                            <br />
                            <p>
                              <Label>No result yet.</Label>
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
        )}
    </div>
  );
}

export default Control;
