import { useRef, useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import "../testing/media.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import { createChart, updateChart } from "../../modules/callCharts";
import CardCallStat from "../CardCallStat";
import { CHART_COLORS } from "../../modules/helper";

function BandwidthStat({ dispatch }) {
  const appState = useContext(AppContext);
  const statsBandwidthInChart = useRef();
  const [bandwidthIn, setBandwidthIn] = useState({});
  const [bandwidthOut, setBandwidthOut] = useState({});

  useEffect(() => {
    createChart(
      document.querySelector("#chartBandwidthIn"),
      statsBandwidthInChart,
      "BANDWIDTH"
    );
  }, []);

  useEffect(() => {
    updateChart(
      appState.statsIncomingBwt,
      appState.statsOutgoingBwt,
      statsBandwidthInChart,
      250
    );
  }, [appState.statsIncomingBwt]);

  useEffect(() => {
    setBandwidthIn(appState.finalBitrateIn);
  }, [appState.finalBitrateIn]);

  useEffect(() => {
    setBandwidthOut(appState.finalBitrateOut);
  }, [appState.finalBitrateOut]);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Transport speed measurement</h5>
        <CardTitle tag="h3">BANDWIDTH</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <CardCallStat
              min={bandwidthIn.min}
              avg={bandwidthIn.avg}
              max={bandwidthIn.max}
              vol={bandwidthIn.volatility}
              unit="kbs"
              fixed={0}
              name="In"
              thresholds={[2500, 1500]}
              thresholdInverse={false}
            />
          </Col>
          <Col md="6">
            <CardCallStat
              min={bandwidthOut.min}
              avg={bandwidthOut.avg}
              max={bandwidthOut.max}
              vol={bandwidthOut.volatility}
              unit="kbs"
              fixed={0}
              name="Out"
              thresholds={[2000, 1200]}
              thresholdInverse={false}
            />
          </Col>
        </Row>
        <div className="graph">
          <canvas id="chartBandwidthIn"></canvas>
        </div>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default BandwidthStat;
