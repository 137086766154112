import {createLink, updateLink, getLinks} from "../modules/status";

import { LINK_ACTIONS } from "./constants";
import { log } from "../modules/logger";

const moduleName = "instanceActions";

export const createLinkForInstance = async (
  userId,
  instanceId,
  linkId,
  token,
  dispatch
) => {
  log(moduleName, "create and activate new link", { userId });
  dispatch({
    type: LINK_ACTIONS.CREATE_LINK_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await createLink(userId, instanceId, linkId, token);

    if (data) {
      dispatch({
        type: LINK_ACTIONS.CREATE_LINK_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: LINK_ACTIONS.CREATE_LINK_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: LINK_ACTIONS.CREATE_LINK_FAILED,
      payload: { value: null },
    });
  }
};

export const updateLinkForInstance = async (
  userId,
  instanceId,
  linkId,
  json,
  token,
  dispatch
) => {
  log(moduleName, "update Link", { userId, instanceId, linkId, json });
  dispatch({
    type: LINK_ACTIONS.UPDATE_LINK_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateLink(userId, instanceId, linkId, json, token);

    if (data) {
      dispatch({
        type: LINK_ACTIONS.UPDATE_LINK_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: LINK_ACTIONS.UPDATE_LINK_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: LINK_ACTIONS.UPDATE_LINK_FAILED,
      payload: { value: null },
    });
  }
};

export const getLinksForInstance = async (
  userId,
  instanceId,
  token,
  dispatch
) => {
  log(moduleName, "get Link", { userId });
  dispatch({
    type: LINK_ACTIONS.GET_LINKS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getLinks(userId, instanceId, token);

    if (data) {
      dispatch({
        type: LINK_ACTIONS.GET_LINKS_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: LINK_ACTIONS.GET_LINKS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: LINK_ACTIONS.GET_LINKS_FAILED,
      payload: { value: null },
    });
  }
};

export const deleteLinkForInstance = async (
  userId,
  instanceId,
  linkId,
  token,
  dispatch
) => {
  log(moduleName, "delete Link", { userId, instanceId, linkId });
  dispatch({
    type: LINK_ACTIONS.DELETE_LINK_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await updateLink(userId, instanceId, linkId, token);

    if (data) {
      dispatch({
        type: LINK_ACTIONS.DELETE_LINK_IN_PROGRESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: LINK_ACTIONS.DELETE_LINK_SUCCESS,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: LINK_ACTIONS.DELETE_LINK_FAILED,
      payload: { value: null },
    });
  }
};
