const config = {
  restUrl: process.env.REACT_APP_restUrl,
  stunUrl: process.env.REACT_APP_stunUrl,
  turnUrl: process.env.REACT_APP_turnUrl,
  turnToken: process.env.REACT_APP_turnToken,
  callDurationMs: process.env.REACT_APP_callDurationMs,
  appVersion: process.env.REACT_APP_appVersion,
  appName: process.env.REACT_APP_appName,
  appCopyright: process.env.REACT_APP_appCopyright,
  appUrl: process.env.REACT_APP_appUrl,
  appUrlAbout: process.env.REACT_APP_appUrlAbout,
  mode: process.env.NODE_ENV,
};

export default config;
