import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Spinner,
  Table,
  Row,
  Badge,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { copyToClipboard } from "../../modules/clipboard";
import {
  deleteLinkForInstance,
  updateLinkForInstance,
} from "../../actions/linkActions";

import "./Users.css";

function Users(props) {
  const appState = useContext(AppContext);
  const [host, setHost] = useState(appState.currentInstance);
  const [links, setLinks] = useState(appState.link);

  const [modal, setModal] = useState(false);
  const [unmountOnClose, setUnmountOnClose] = useState(true);

  const [duration, setDuration] = useState("day");
  const [customId, setCustomId] = useState(Date.now().toString());
  const [customName, setCustomName] = useState("");

  const toggle = () => setModal(!modal);
  const changeUnmountOnClose = (e) => {
    let { value } = e.target;
    setUnmountOnClose(JSON.parse(value));
  };

  useEffect(() => {
    if (appState.currentInstance) {
      setHost(appState.currentInstance);
      setCustomName(appState.currentInstance.name);
    }
  }, [appState.currentInstance]);

  useEffect(() => {
    setLinks(appState.links);
  }, [appState.links]);

  const getLinkUrl = (token) => {
    return `${window.location.origin}/#/public?token=${token}`;
  };

  const onCopyToClipboard = (link) => {
    copyToClipboard(getLinkUrl((link && link.token) || ""));
  };

  const onActiveUnactive = (link) => {
    updateLinkForInstance(
      appState.user._id,
      host._id,
      link._id,
      { enabled: !link.active },
      appState.token,
      props.dispatch
    );
  };

  const onDelete = (link) => {
    deleteLinkForInstance(
      appState.user._id,
      host._id,
      link._id,
      appState.token,
      props.dispatch
    );
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="9">
                      <h5 className="card-category">Testing</h5>
                      <CardTitle tag="h3">USERS</CardTitle>
                    </Col>
                    <Col className="text-right" sm="3">
                      <Button size="sm" color="primary" onClick={toggle}>
                        Create Link
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <p className="mb-4 text-muted">
                    Enable one or several public links to let your users test
                    your host. Results are displayed below.
                  </p>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left w-25">ID</th>
                        <th className="text-right">Expiration</th>
                        <th className="text-right">Activation</th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {links &&
                        links.length > 0 &&
                        links.map((link, key) => (
                          <tr key={key}>
                            <td className="text-left w-25">
                              {link.custom || "Public Link"}
                              <span className="ml-2">
                                <Badge
                                  pill
                                  color={
                                    link && link.active ? "success" : "light"
                                  }
                                >
                                  {link && link.active ? "active" : "inactive"}
                                </Badge>
                              </span>
                            </td>
                            <td className="text-right">Never</td>
                            <td className="text-right">
                              <Button
                                size="sm"
                                color={
                                  link && link.active ? "danger" : "success"
                                }
                                onClick={() => {
                                  onActiveUnactive(link);
                                }}
                              >
                                {link && link.active ? "Disable" : "Enable"}
                              </Button>
                            </td>
                            <td className="text-right">
                              {appState.updateTurnInProgress && (
                                <Spinner
                                  children={null}
                                  className="mr-2"
                                  size="sm"
                                />
                              )}
                              <Button
                                className="ml-2"
                                size="sm"
                                color="primary"
                                onClick={() => {
                                  onCopyToClipboard(link);
                                }}
                              >
                                Copy
                              </Button>
                              <Button
                                className="ml-2"
                                size="sm"
                                color="danger"
                                onClick={() => {
                                  onDelete(link);
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      {links && links.length === 0 && (
                        <tr>
                          <td colSpan="4">
                            <br />
                            <Badge color="primary">No public link found</Badge>

                            <br />
                            <br />
                            <p className="text-muted">
                              Please, click on the new button to create your
                              first public link.
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <p>
                    Note: Disabling your link prevents receiving unexpected
                    results.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      <Modal isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose}>
        <ModalHeader toggle={toggle} className="popup-title">
          Create a public Link
        </ModalHeader>
        <ModalBody>
          <Label>Custom ID</Label>
          <FormGroup>
            <Input
              type="text"
              className="input-color"
              placeholder="Custom ID"
              value={customId}
              onChange={(e) => {
                setCustomId(e.target.value);
              }}
            />
          </FormGroup>
          <Label>Name</Label>
          <FormGroup>
            <Input
              type="text"
              placeholder="Name"
              className="input-color"
              value={customName}
              onChange={(e) => {
                setCustomName(e.target.value);
              }}
            />
            <p className="text-muted">
              <small>This name will be presented to the users</small>
            </p>
          </FormGroup>
          <Label className="label-duration">Active for</Label>
          <div className="radio-position">
            <FormGroup check>
              <Input
                type="radio"
                name="radio1"
                value="hour"
                checked={duration === "hour"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDuration("hour");
                  }
                }}
              />
              <Label check className="radio-duration">
                1 hour
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                type="radio"
                name="radio1"
                value="day"
                checked={duration === "day"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDuration("day");
                  }
                }}
              />
              <Label check className="radio-duration">
                1 day
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                type="radio"
                name="radio1"
                value="month"
                checked={duration === "month"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDuration("month");
                  }
                }}
              />
              <Label check className="radio-duration">
                1 month
              </Label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="modal-align-right">
          <div>
            <Button color="secondary" onClick={toggle} className="mr-2">
              Cancel
            </Button>
            <Button color="primary" onClick={toggle}>
              Create
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Users;
