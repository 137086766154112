import { useRef, useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import "../testing/media.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import { createChart, updateChart } from "../../modules/callCharts";
import CardCallStat from "../CardCallStat";
import { CHART_COLORS } from "../../modules/helper";

function BytesStat({ dispatch }) {
  const appState = useContext(AppContext);
  const statsBytesChart = useRef();
  const [trafficIn, setTrafficIn] = useState({});
  const [trafficOut, setTrafficOut] = useState({});

  useEffect(() => {
    createChart(
      document.querySelector("#chartBytes"),
      statsBytesChart,
      "BYTES"
    );
  }, []);

  useEffect(() => {
    updateChart(
      appState.statsIncomingReceived,
      appState.statsOutgoingSent,
      statsBytesChart,
      100
    );
  }, [appState.statsIncomingReceived]);

  useEffect(() => {
    setTrafficIn(appState.finalTrafficIn);
  }, [appState.finalTrafficIn]);

  useEffect(() => {
    setTrafficOut(appState.finalTrafficOut);
  }, [appState.finalTrafficOut]);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Data exchanged measurement</h5>
        <CardTitle tag="h3">TRAFFIC</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <CardCallStat
              min={trafficIn.min}
              avg={trafficIn.avg}
              max={trafficIn.max}
              vol={trafficIn.volatility}
              unit="KB"
              fixed={0}
              name="In"
              thresholds={[1000, 200]}
              thresholdInverse={false}
            />
          </Col>
          <Col md="6">
            <CardCallStat
              min={trafficOut.min}
              avg={trafficOut.avg}
              max={trafficOut.max}
              vol={trafficOut.volatility}
              unit="KB"
              fixed={0}
              name="Out"
              thresholds={[1000, 200]}
              thresholdInverse={false}
            />
          </Col>
        </Row>
        <div className="graph">
          <canvas id="chartBytes"></canvas>
        </div>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default BytesStat;
