import { log } from "../modules/logger";
import { ACTIONS, ADMIN_ACTIONS } from "./constants";
import {
  getGlobalMetrics,
  getAccounts,
  createAccount,
  getAllJobs,
  sendAdminCommand,
} from "../modules/admin";

const moduleName = "adminActions";

export const getAdminMetrics = async (token, dispatch) => {
  log(moduleName, "execute action", { action: "adminMetrics" });
  dispatch({
    type: ADMIN_ACTIONS.GET_GLOBAL_METRICS_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const metrics = await getGlobalMetrics(token);

    dispatch({
      type: ADMIN_ACTIONS.GET_GLOBAL_METRICS_SUCCESS,
      payload: { value: metrics },
    });
  } catch {
    dispatch({
      type: ADMIN_ACTIONS.GET_GLOBAL_METRICS_FAILED,
      payload: { value: null },
    });
  }
};

export const getUsers = async (page, token, dispatch) => {
  log(moduleName, "execute action", { action: "getAccounts" });
  dispatch({
    type: ADMIN_ACTIONS.GET_USERS_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const data = await getAccounts(page, token);

    dispatch({
      type: ADMIN_ACTIONS.GET_USERS_SUCCESS,
      payload: { value: data },
    });
  } catch {
    dispatch({
      type: ADMIN_ACTIONS.GET_USERS_FAILED,
      payload: { value: null },
    });
  }
};

export const getJobs = async (token, dispatch) => {
  log(moduleName, "execute action", { action: "getJobs" });
  dispatch({
    type: ADMIN_ACTIONS.GET_JOBS_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const data = await getAllJobs(token);

    dispatch({
      type: ADMIN_ACTIONS.GET_JOBS_SUCCESS,
      payload: { value: data },
    });
  } catch {
    dispatch({
      type: ADMIN_ACTIONS.GET_JOBS_FAILED,
      payload: { value: null },
    });
  }
};

export const createUser = async (
  userName,
  password,
  profile,
  role,
  token,
  dispatch
) => {
  log(moduleName, "execute action", { action: "createUser" });
  dispatch({
    type: ADMIN_ACTIONS.CREATE_USER_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const data = await createAccount(userName, password, profile, role, token);

    dispatch({
      type: ADMIN_ACTIONS.CREATE_USER_SUCCESS,
      payload: { value: data },
    });
  } catch {
    dispatch({
      type: ADMIN_ACTIONS.CREATE_USER_FAILED,
      payload: { value: null },
    });
  }
};

export const sendCommand = async (command, token, dispatch) => {
  log(moduleName, "execute command", { action: "custom command" });
  dispatch({
    type: ADMIN_ACTIONS.SEND_COMMAND_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const data = await sendAdminCommand(command, token);

    dispatch({
      type: ADMIN_ACTIONS.SEND_COMMAND_SUCCESS,
      payload: { value: data },
    });
  } catch {
    dispatch({
      type: ADMIN_ACTIONS.SEND_COMMAND_FAILED,
      payload: { value: null },
    });
  }
};
