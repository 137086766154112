import React, {useContext, useEffect, useState} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
  Button,
  Badge,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import {DateTime} from "luxon";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import CardNumber from "../CardNumber";
import {
  getDuration,
  getValueFromSetting,
} from "../../modules/helper";
import {
  displayHostStatus,
  getHostStatusColor,
  ENVIRONMENT,
  lastCheckForHost, getServiceStatusColor, HOST_SERVICE, SERVICE_STATUS,
} from "../../modules/bm";
import {Link} from "react-router-dom";
import {getLastGithubRelease} from "../../modules/github";
import BreadCrumbCusto from "../BreadCrumb";

import "./Board.css";

function Board(props) {
  const appState = useContext(AppContext);
  const [host, setHost] = useState(appState.currentInstance);
  const [environment, setEnvironment] = useState("");
  const [group, setGroup] = useState("");
  const [area, setArea] = useState("");
  const [hostVersion, setVersion] = useState(null);
  const [location, setLocation] = useState("unknown");
  const [link, setLink] = useState(null);
  const [lastRelease, setLastRelease] = useState(null);

  useEffect(() => {
    if (appState.currentInstance) {
      setHost(appState.currentInstance);

      if (appState.currentInstance.city) {
        setLocation(host.city);
      }
    }
  }, [appState.currentInstance]);

  useEffect(() => {
    if (host && appState.settings) {
      setEnvironment(
        getValueFromSetting(
          "identityHostEnvironment",
          appState.settings,
          host._id,
        ),
      );
      setGroup(
        getValueFromSetting("identityHostGroup", appState.settings, host._id) ||
        "None",
      );
      setArea(
        getValueFromSetting("identityHostArea", appState.settings, host._id) ||
        "None",
      );
    }
  }, [appState.settings]);

  useEffect(() => {
    if (host && appState.settings) {
      const githubUrl = getValueFromSetting(
        "identityOpenSourceLink",
        appState.settings,
        host._id,
      );
      setLink(githubUrl);
    }
  }, [appState.settings, host]);

  useEffect(() => {
    if (link) {
      getLastGithubRelease(link).then((release) => {
        setLastRelease(release);
      });
    }
  }, [link]);

  useEffect(() => {
    if (appState.versions && appState.versions.length > 0 && host) {
      const version = appState.versions.find(
        (item) => item.instanceId === host._id,
      );
      setVersion(version);
    }
  }, [appState.versions, host]);

  const getColorFromEnvironment = (env) => {
    switch (env) {
      case ENVIRONMENT.PRODUCTION:
        return "warning";
      case ENVIRONMENT.STAGING:
        return "warning";
      case ENVIRONMENT.DEV:
        return "blue";
      default:
        return "gray";
    }
  };

  const getVersionColor = () => {
    if (hostVersion) {
      return "blue";
    }
    return "gray";
  };

  const displayHostVersion = () => {
    return (hostVersion && hostVersion.version) || "Unknown";
  };

  const getVersionSince = () => {
    return (hostVersion && hostVersion.since) || null;
  };

  const displayVersionSince = () => {
    if (hostVersion && hostVersion.since) {
      return `Since ${DateTime.fromISO(hostVersion.since).toFormat("ff")}`;
    }

    return "No version information";
  };

  const lastCheckVersionForHost = () => {
    return (hostVersion && hostVersion.lastSeen) || null;
  };

  const getNextOperation = () => {
    return "None";
  };

  const getOperationColor = () => {
    return "gray";
  };

  const getLastOperation = () => {
    return null;
  };

  const getUptime = (from, inDays = false) => {
    if (!from) {
      return "None";
    }
    const secondsToNow = Math.abs(
      Math.floor(DateTime.fromISO(from).diffNow(["seconds"]).seconds),
    );

    return getDuration(secondsToNow, true, false, inDays);
  };

  const getVersionLaste = (from) => {
    if (!from) {
      return "No information";
    }
    const daysToNow = Math.abs(
      Math.floor(DateTime.fromISO(from).diffNow(["days"]).days),
    );

    if (daysToNow < 1) {
      return "Less than 24 hours";
    } else if (daysToNow === 1) {
      return `24 hours`;
    }
    return `${daysToNow} days`;
  };

  const onClickUrl = (openSourceUrl) => {
    const newWindow = window.open(
      openSourceUrl,
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  };

  const getIconForService = (service) => {
    switch (service) {
      case HOST_SERVICE.HEALTH:
        return "cafe-monitor";
      case HOST_SERVICE.TESTS:
        return "cafe-chemistry";
      case HOST_SERVICE.USAGE:
        return "cafe-monitoring";
    }
  };

  const noCheckVersionSinceOneMonth = (lastSeen) => {
    if (!lastSeen) {
      return null;
    }
    const diffMonth = Math.abs(
      DateTime.fromISO(lastSeen).diffNow(["days"]).days,
    );
    return diffMonth > 30;
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder/>}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch}/>
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={host}
              env={host?.env}
            />
            <Row>
              <Col xl="4" lg="12" md="12" sm="12" xs="12">
                <CardNumber
                  name="STATUS"
                  value={{
                    data: {
                      status: displayHostStatus(host.status, host.active),
                    },
                  }}
                  color="icon-danger"
                  prop="status"
                  icon="cafe-environment"
                  unit="u"
                  last={`Since ${DateTime.fromISO(host.statusLast).toFormat(
                    "ff",
                  )}`}
                  badge={true}
                  badgeColor={getHostStatusColor(host.status, host.active)}
                />
              </Col>
              <Col xl="4" lg="12" md="12" sm="12" xs="12">
                <CardNumber
                  name="VERSION"
                  value={{
                    data: {version: displayHostVersion()},
                  }}
                  color="icon-white"
                  prop="version"
                  icon={`cafe-group`}
                  unit="u"
                  last={displayVersionSince()}
                  badge={true}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="4" lg="12" md="12" sm="12" xs="12">
                <CardNumber
                  name="NEXT OPERATIONS"
                  value={{
                    data: {next: getNextOperation()},
                  }}
                  prop="next"
                  color="icon-white"
                  icon={`cafe-area`}
                  unit="u"
                  last={"No previous operation done"}
                  badge={true}
                  badgeColor="gray"
                />
              </Col>
            </Row>
            {host && !host.active && (
              <UncontrolledAlert color="secondary" className="text-muted">
                Be careful, the host is not currently monitored. Status can be
                wrong!
                <Link to={"/host/monitor"} className="ml-2">
                  Go to Monitoring...
                </Link>
              </UncontrolledAlert>
            )}
            {!hostVersion && (
              <UncontrolledAlert color="secondary" className="text-muted">
                Be careful, there is no version gathered. Check your
                configuration!
                <Link to={"/host/settings"} className="ml-2">
                  Go to Settings...
                </Link>
              </UncontrolledAlert>
            )}
            {hostVersion &&
              noCheckVersionSinceOneMonth(hostVersion.lastSeen) && (
                <UncontrolledAlert color="secondary" className="text-muted">
                  Be careful, the version of the host has not been checked for
                  more than a month!
                  <Link to={"/host/monitor"} className="ml-2">
                    Go to Monitoring...
                  </Link>
                </UncontrolledAlert>
              )}
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">STATUS</span>
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="4">
                        <p className="card-category">Uptime/Downtime</p>
                        <Badge
                          color={getHostStatusColor(host.status, host.active)}
                          style={{
                            fontSize: "12px",
                            marginTop: "6px",
                          }}
                        >
                          {getUptime(host.statusLast)}
                        </Badge>
                      </Col>
                      <Col className="text-left" sm="4">
                        <p className="card-category">Last Check</p>
                        <Badge
                          color="nocolor"
                          style={{
                            fontSize: "12px",
                            padding: "9px 0px",
                            fontWeight: "400",
                          }}
                        >
                          {DateTime.fromISO(
                            lastCheckForHost(host),
                          ).toRelative() || "none"}
                        </Badge>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="mt-2">
                      <Col>
                        <p className="card-category">Latest notable changes</p>
                        {appState.timelineSeries && appState.timelineSeries.length > 0 && (
                          <ol className="timeline">
                            {appState.timelineSeries.map((item, key) => (
                              <li className="timeline-item" key={key}>
                              <span className="before-timeline"
                                    style={{fontSize: "13px"}}>{DateTime.fromISO(item.created).toFormat("DD")}</span>
                                <span className="timeline-item-icon | avatar-icon">
                                <i className={`font-weight-bold avatar icon ${getIconForService(item.category)}`}></i>
                              </span>
                                <div className="timeline-item-wrapper" style={{fontSize: "13px"}}>
                                  <div className="timeline-item-description">
                                    <p><span className="text-info font-weight-bold">{item.category}</span><span
                                      className="text-muted"> status changed to</span> <Badge pill
                                                                                              color={getServiceStatusColor(item.status)}
                                                                                              className={`ml-1`}>{item.status}</Badge><span
                                      className="text-muted"> at </span>
                                      <time
                                        className="">{DateTime.fromISO(item.created).toFormat("t")}</time>
                                    </p>
                                  </div>
                                  {item.status !== SERVICE_STATUS.SUCCESS && (
                                    <div style={{fontSize: "12px"}}
                                         className={`mt-0 text-${getServiceStatusColor(item.status)}`}>{item.details}</div>
                                  )}
                                </div>
                              </li>
                            ))}
                          </ol>
                        )}
                        {!appState.timelineSeries || appState.timelineSeries.length === 0 && (
                          <p className="text-muted" style={{fontSize: "12px"}}>No recent activity</p>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">VERSION</span>
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="4">
                        <p className="card-category">In use</p>
                        <Badge
                          color={getVersionColor()}
                          style={{
                            fontSize: "12px",
                            marginTop: "6px",
                          }}
                        >
                          {getVersionLaste(getVersionSince())}
                        </Badge>
                        <span>
                          <Button size="sm" color="link" onClick={() => {
                          }}>
                            Update manually
                          </Button>
                        </span>
                      </Col>
                      <Col className="text-left" sm="4">
                        <p className="card-category">Last Check</p>
                        <Badge
                          color="nocolor"
                          style={{
                            fontSize: "13px",
                            padding: "9px 0px",
                            fontWeight: "400",
                            marginTop: "6px",
                          }}
                        >
                          {(hostVersion &&
                              DateTime.fromISO(
                                lastCheckVersionForHost(),
                              ).toRelative()) ||
                            "none"}
                        </Badge>
                      </Col>
                      <Col className="text-left" sm="4">
                        <p className="card-category">GitHub Version</p>
                        <Badge
                          color="primary"
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            marginTop: "6px",
                          }}
                        >
                          {(lastRelease && lastRelease.name) || "none"}
                        </Badge>
                        <span
                          className="ml-2 text-muted"
                          style={{fontSize: "12px"}}
                        >
                          {lastRelease && lastRelease.published && (
                            <span>
                              on{" "}
                              {DateTime.fromISO(lastRelease.published).toFormat(
                                "DDD",
                              )}
                            </span>
                          )}
                        </span>
                        {link && (
                          <Button
                            size="sm"
                            color="link"
                            onClick={() => {
                              onClickUrl(link);
                            }}
                          >
                            Open GitHub
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="mt-2">
                      <Col>
                        <p className="card-category">Latest notable changes</p>
                        {appState.allVersions && appState.allVersions.length > 0 && (
                          <ol className="timeline">
                            {appState.allVersions.map((item, key) => (
                              <li className="timeline-item" key={key}>
                              <span className="before-timeline"
                                    style={{fontSize: "13px"}}>{DateTime.fromISO(item.since).toFormat("DD")}</span>
                                <span className="timeline-item-icon | avatar-icon">
                                <i className={`font-weight-bold avatar icon cafe-group`}></i>
                              </span>
                                <div className="timeline-item-wrapper" style={{fontSize: "13px"}}>
                                  <div className="timeline-item-description">
                                    <p><span className="text-info font-weight-bold">{item.category}</span><span
                                      className="text-muted"> version changed to</span> <Badge pill
                                                                                               color={item.version ? "blue" : "light"}
                                                                                               className={`ml-1`}>{item.version || "unknown"}</Badge><span
                                      className="text-muted"> at </span>
                                      <time
                                        className="">{DateTime.fromISO(item.since).toFormat("t")}</time>
                                      {item.mode !== "auto" && (
                                        <Badge className="ml-2"
                                               color="yellow">{item.mode}</Badge>
                                      )}
                                    </p>
                                  </div>
                                  {item.status !== SERVICE_STATUS.SUCCESS && (
                                    <div style={{fontSize: "12px"}}
                                         className={`mt-0 text-${getServiceStatusColor(item.status)}`}>{item.details}</div>
                                  )}
                                </div>
                              </li>
                            ))}
                          </ol>
                        )}
                        {!appState.allVersions || appState.allVersions.length === 0 && (
                          <p className="text-muted" style={{fontSize: "12px"}}>No recent activity</p>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">OPERATIONS</span>
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="4">
                        <p className="card-category">Planned</p>
                        <Badge
                          color={getOperationColor() || "None"}
                          style={{
                            fontSize: "12px",
                            marginTop: "6px",
                          }}
                        >
                          {getNextOperation() || "None"}
                        </Badge>
                      </Col>
                      <Col className="text-left" sm="4">
                        <p className="card-category">Last done</p>
                        <Badge
                          pill
                          color="nocolor"
                          style={{
                            fontSize: "13px",
                            padding: "9px 0px",
                            fontWeight: "400",
                            marginTop: "6px",
                          }}
                        >
                          {getLastOperation() || "None"}
                        </Badge>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="mt-2">
                      <Col>
                        <p className="card-category">Latest notable changes</p>
                        {appState.operations && appState.operations.length > 0 && (
                          <ol className="timeline">
                            {appState.operations.map((item, key) => (
                              <li className="timeline-item" key={key}>
                              <span className="before-timeline"
                                    style={{fontSize: "13px"}}>{DateTime.fromISO(item.started || item.planned).toFormat("DD")}</span>
                                <span className="timeline-item-icon | avatar-icon">
                                <i className={`font-weight-bold avatar icon cafe-area`}></i>
                              </span>
                                <div className="timeline-item-wrapper" style={{fontSize: "13px"}}>
                                  <div className="timeline-item-description">
                                    <p><span className="text-info font-weight-bold">{item.title}</span><span
                                      className="text-muted"></span> <Badge pill
                                                                            color={item.version ? "blue" : "light"}
                                                                            className={`ml-1`}>{item.operation}</Badge><span
                                      className="text-muted ml-1">{item.state === "planned" ? "planned at" : "done at"}</span>
                                      <time
                                        className="ml-1">{DateTime.fromISO(item.started || item.planned).toFormat("t")}</time>
                                      {item.state !== "planned" && (
                                        <Badge className="ml-2"
                                               color={item.state === "done" ? "success" : item.state === "failed" ? "danger" : "warning"}>{item.state}</Badge>
                                      )}
                                    </p>
                                  </div>
                                  {item.status !== SERVICE_STATUS.SUCCESS && (
                                    <div style={{fontSize: "12px"}}
                                         className={`mt-0 text-${getServiceStatusColor(item.status)}`}>{item.details}</div>
                                  )}
                                </div>
                              </li>
                            ))}
                          </ol>
                        )}
                        {!appState.operations || appState.operations.length === 0 && (
                          <p className="text-muted" style={{fontSize: "12px"}}>No recent activity</p>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">DETAILS</span>
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                      <tr>
                        <th className="text-left">Properties</th>
                        <th className="text-left">Values</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td className="text-left">Role</td>
                        <td className="text-left">
                          <Badge color="primary">{host.role}</Badge>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">Environment</td>
                        <td className="text-left">
                          <Badge color="blue">{environment}</Badge>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">Group</td>
                        <td className="text-left">{group}</td>
                      </tr>
                      <tr>
                        <td className="text-left">Area</td>
                        <td className="text-left">{area}</td>
                      </tr>
                      <tr>
                        <td className="text-left">Location</td>
                        <td className="text-left">{location}</td>
                      </tr>
                      <tr>
                        <td className="text-left">Operations done</td>
                        <td className="text-left">None</td>
                      </tr>
                      <tr>
                        <td className="text-left">Created</td>
                        <td className="text-left">
                          {`${DateTime.fromISO(host.created).toFormat(
                            "DDD",
                          )} at ${DateTime.fromISO(host.created).toFormat(
                            "T",
                          )}`}
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default Board;
