import React, {useContext, useEffect, useState, forwardRef} from "react";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Row,
  Col,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import AppContext from "../../contexts/appContext";
import {getServiceStatusColor, HOST_SERVICE} from "../../modules/bm";
import {getPagedSeriesForInstance} from "../../actions/seriesActions";

import "./HostHistory.css";
import "react-datepicker/dist/react-datepicker.css";
import {DateTime} from "luxon";
import BreadCrumbCusto from "../BreadCrumb";

function HostHistory(props) {
  const appState = useContext(AppContext);
  const [series, setSeries] = useState(appState.series);
  const [ddServiceOpen, setDdServiceOpen] = useState(false);
  const [ddPageOpen, setDdPageOpen] = useState(false);
  const [ddResultOpen, setDdResultOpen] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    perPage: 25,
    service: "all",
    result: "all",
    upTo: new Date(),
  });

  // Update table
  useEffect(() => {
    if (
      appState.pagedSeries &&
      appState.pagedSeries.data &&
      appState.currentInstance
    ) {
      setSeries(appState.pagedSeries.data);
    }
  }, [appState.pagedSeries, appState.currentInstance]);

  // Detect update on series (new result)
  useEffect(() => {
    if (appState.series && appState.currentInstance) {
      updateSeries();
    }
  }, [appState.series, appState.currentInstance]);

  useEffect(() => {
    if (appState.currentInstance) {
      updateSeries();
    }
  }, [params, appState.currentInstance]);

  const updateSeries = async () => {
    await getPagedSeriesForInstance(
      appState.user._id,
      appState.currentInstance._id,
      params.page,
      params.perPage,
      params.service,
      params.result,
      params.upTo.toJSON(),
      appState.token,
      props.dispatch,
    );
  };

  const getData = (item) => {
    if (!item.values) {
      return "No result available";
    }
    switch (item.category) {
      case HOST_SERVICE.TESTS:
        return `Score: ${item.values.score.toFixed(2) || 0}% - Exe: ${
          item.values.executionTime
        }ms`;
      case HOST_SERVICE.HEALTH:
        return `Memory: ${item.values.memoryFree}% available, Disk: ${item.values.diskFree}% available, CPU: ${item.values.cpuFree}% available - Connection: ${item.values.connectionTime}ms - Execution: ${item.values.executionTime}ms - Status: ${item.values.service}`;
      case HOST_SERVICE.USAGE:
        return `Users: ${item.values.users} - Conferences: ${
          item.values.conferences
        } - Duration: ${
          (item.values.duration && item.values.duration.toFixed(2)) || "0"
        }mn - Sent: ${
          (item.values.sent && item.values.sent.toFixed(2)) || "0"
        }MB - Recv: ${
          (item.values.received && item.values.received.toFixed(2)) || "0"
        }MB`;
    }
  };

  const toggleDdService = () => setDdServiceOpen((prevState) => !prevState);
  const toggleDdPage = () => setDdPageOpen((prevState) => !prevState);
  const toggleDdResult = () => setDdResultOpen((prevState) => !prevState);

  const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
    <Button innerRef={ref} size="sm" color="secondary" onClick={onClick}>
      {value}
    </Button>
  ));

  const detectNoNextPage = () => {
    return (
      (params.page + 1) * appState.pagedSeriesPerPage >=
      appState.pagedSeriesCount
    );
  };

  const detectNoPreviousPage = () => {
    return params.page === 0;
  };

  const goToFirstPage = () => {
    setParams({...params, page: 0});
  };

  const goToLastPage = () => {
    setParams({
      ...params,
      page: Math.floor(Math.max(appState.pagedSeriesCount - 1, 0) / params.perPage),
    });
  };

  const goToNextPage = async () => {
    setParams({...params, page: params.page + 1});
  };

  const goToPreviousPage = async () => {
    setParams({...params, page: params.page - 1});
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder/>}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch}/>
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={appState.currentInstance}
              env={appState.currentInstance?.env}
              name={null}
            />

            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5
                      className="card-category"
                      style={{textTransform: "none"}}
                    >
                      List all services results received
                    </h5>
                    <CardTitle tag="h3">HISTORY</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl="2" lg="2" md="3" sm="12" xs="12">
                        <label>Services</label>
                        <Dropdown
                          size="sm"
                          isOpen={ddServiceOpen}
                          toggle={toggleDdService}
                          direction="down"
                        >
                          <DropdownToggle color="secondary" caret={true}>
                            {params.service}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  service: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              all
                            </DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  service: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              {HOST_SERVICE.HEALTH}
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  service: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              {HOST_SERVICE.TESTS}
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  service: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              {HOST_SERVICE.USAGE}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                      <Col xl="2" lg="2" md="3" sm="12" xs="12">
                        <label>Status</label>
                        <Dropdown
                          size="sm"
                          isOpen={ddResultOpen}
                          toggle={toggleDdResult}
                          direction="down"
                        >
                          <DropdownToggle color="secondary" caret={true}>
                            {params.result}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  result: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              all
                            </DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  result: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              success
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  result: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              warning
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  page: 0,
                                  result: e.target.textContent.toLowerCase(),
                                });
                              }}
                            >
                              error
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                      <Col xl="2" lg="2" md="3" sm="12" xs="12">
                        <FormGroup>
                          <label>Up to</label>
                          <br/>
                          <DatePicker
                            selected={params.upTo}
                            onChange={(date) => {
                              setParams({
                                ...params,
                                page: 0,
                                upTo: date,
                              });
                            }}
                            customInput={<ExampleCustomInput/>}
                          />
                        </FormGroup>
                      </Col>
                      <Col xl="4" lg="4" md="0" sm="12" xs="12"></Col>
                      <Col xl="2" lg="2" md="3" sm="12" xs="12">
                        <label>Results per page</label>
                        <Dropdown
                          size="sm"
                          isOpen={ddPageOpen}
                          toggle={toggleDdPage}
                          direction="down"
                        >
                          <DropdownToggle color="secondary" caret={true}>
                            {params.perPage}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  perPage: Number(e.target.textContent),
                                });
                              }}
                            >
                              10
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  perPage: Number(e.target.textContent),
                                });
                              }}
                            >
                              25
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  perPage: Number(e.target.textContent),
                                });
                              }}
                            >
                              50
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  perPage: Number(e.target.textContent),
                                });
                              }}
                            >
                              100
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  perPage: Number(e.target.textContent),
                                });
                              }}
                            >
                              200
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  perPage: Number(e.target.textContent),
                                });
                              }}
                            >
                              500
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setParams({
                                  ...params,
                                  perPage: Number(e.target.textContent),
                                });
                              }}
                            >
                              1000
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Table style={{minHeight: "300px"}}>
                      <thead className="text-primary">
                      <tr>
                        <th className="text-left">Last Update</th>
                        <th className="text-left">Services</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Data</th>
                      </tr>
                      </thead>
                      <tbody>
                      {series &&
                        series.length > 0 &&
                        series.map((item, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              {DateTime.fromISO(item.created).toFormat("ff")}
                            </td>
                            <td className="text-left">
                              <Badge className={`service-${item.category}`}>
                                {item.category}
                              </Badge>
                            </td>
                            <td className="text-left">
                              <Badge
                                pill
                                color={
                                  getServiceStatusColor(item.status)
                                }
                              >
                                {item.status}
                              </Badge>
                            </td>
                            <td className="text-left">
                              {getData(item)}
                              {item.details.length > 0 && (
                                <>
                                  <br/>
                                  <span
                                    className={
                                      item.status === "error"
                                        ? `text-danger`
                                        : "text-warning"
                                    }
                                  >
                                      {item.details}
                                    </span>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      {!series ||
                        (series.length === 0 && (
                          <tr className="text-left">
                            <td colSpan="4">No data</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                  <div className="stats">
                    <Button
                      disabled={appState.pagedSeriesInProgress}
                      className="tableButtons"
                      color="primary"
                      size="sm"
                      onClick={() => {
                        updateSeries();
                      }}
                    >
                      {" "}
                      <i className="icon cafe-refresh"/>{" "}
                    </Button>
                    <Button
                      disabled={
                        appState.pagedSeriesInProgress || detectNoPreviousPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToFirstPage}
                    >
                      <i className="icon cafe-first"/>
                    </Button>
                    <Button
                      disabled={
                        appState.pagedSeriesInProgress || detectNoPreviousPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToPreviousPage}
                    >
                      {" "}
                      <i className="icon cafe-back"/>{" "}
                    </Button>
                    <Button
                      disabled={
                        appState.pagedSeriesInProgress || detectNoNextPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToNextPage}
                    >
                      {" "}
                      <i className="icon cafe-forward"/>{" "}
                    </Button>
                    <Button
                      disabled={
                        appState.pagedSeriesInProgress || detectNoNextPage()
                      }
                      className="tableButtons"
                      color="info"
                      size="sm"
                      onClick={goToLastPage}
                    >
                      <i className="icon cafe-last"/>
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default HostHistory;
