import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import { createInstance } from "../actions/instanceActions";
import React, { useContext, useState } from "react";
import AppContext from "../contexts/appContext";
import ConfirmModal from "./ConfirmModal";
import {
  adjectives,
  animals,
  colors,
  uniqueNamesGenerator,
} from "unique-names-generator";
import { HOST_ROLE } from "../modules/bm";

const customConfig = {
  dictionaries: [adjectives, colors, animals],
  separator: "-",
  length: 3,
};

function NoHost(props) {
  const appState = useContext(AppContext);
  const [name, setName] = useState(
    uniqueNamesGenerator({
      dictionaries: [colors, animals],
    })
  );
  const [role, setRole] = useState(HOST_ROLE.COTURN);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const onCreateNewHost = () => {
    setModal(false);
    createInstance(
      appState.user._id,
      appState.token,
      props.dispatch,
      name,
      role
    );
  };

  return (
    <div className="card card-nav-tabs">
      <div className="card-header card-header-warning">Ready to start?</div>
      <div className="card-body">
        <h4 className="card-title">Create your first host</h4>
        <p className="card-text">
          You don't have any hosts. Please create your first host and start to
          monitor it!
        </p>
        <Button
          className="btn btn-primary"
          onClick={async () => {
            if (!appState.addNewInstanceInProgress) {
              setName(
                uniqueNamesGenerator({
                  dictionaries: [colors, animals],
                })
              );
              setRole(HOST_ROLE.COTURN);
              setModal(true);
            }
          }}
        >
          Add a new host
          {appState.addNewInstanceInProgress && (
            <Spinner className="ml-2" size="sm" children={null} />
          )}
        </Button>
      </div>
        <Modal isOpen={modal} toggle={toggleModal} className="sidebar-modal">
          <ModalHeader>Create a new host</ModalHeader>
          <ModalBody>
            <p className="sidebar-modal-text">
              Enter the information and click on 'Create' to have your new host
              ready!
            </p>
            <br />
            <Form>
              <FormGroup>
                <Label for="hostName">Name</Label>
                <Input
                  name="hostName"
                  placeholder="Name of the host"
                  value={name}
                  className="sidebar-modal-input"
                  onChange={(e) => setName(e.currentTarget.value)}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label for="hostCategory">Category</Label>
                <Input
                  name="hostCategory"
                  type="select"
                  value={role}
                  className="sidebar-modal-select"
                  onChange={(e) => setRole(e.currentTarget.value)}
                >
                  <option>{HOST_ROLE.COTURN}</option>
                  <option>{HOST_ROLE.ETURNAL}</option>
                  <option>{HOST_ROLE.JITSI}</option>
                  <option>{HOST_ROLE.SIG}</option>
                  <option>{HOST_ROLE.JSONGLE}</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="" onClick={() => onCreateNewHost()}>
              Create
            </Button>
          </ModalFooter>
        </Modal>
    </div>
  );
}

export default NoHost;
