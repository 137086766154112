import React, { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Label,
  Form,
  FormGroup,
  Input,
  Spinner,
  Button,
  Placeholder,
} from "reactstrap";

import AppContext from "../contexts/appContext";
import { updateUserProfile } from "../actions/userActions";
import CardNumber from "./CardNumber";
import ProfilePlaceHolder from "./placeholder/ProfilePlaceHolder";
import BreadCrumbCusto from "./BreadCrumb";

function Profile({ dispatch }) {
  const appState = useContext(AppContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [username, setUsername] = useState(
    (appState.user && appState.user.username) || "",
  );
  const [firstname, setFirstname] = useState(
    (appState.user && appState.user.firstname) || "",
  );
  const [lastname, setLastname] = useState(
    (appState.user && appState.user.lastname) || "",
  );
  const [company, setCompany] = useState(
    (appState.user && appState.user.company) || "",
  );
  const [address, setAddress] = useState(
    (appState.user && appState.user.address) || "",
  );
  const [postalCode, setPostalCode] = useState(
    (appState.user && appState.user.postalCode) || "",
  );
  const [city, setCity] = useState((appState.user && appState.user.city) || "");
  const [state, setCountryState] = useState(
    appState.user && appState.user.state,
  );
  const [country, setCountry] = useState(
    (appState.user && appState.user.country) || "",
  );
  const [created, setCreated] = useState(
    (appState.user && appState.user.created) || "",
  );

  useEffect(() => {
    if (appState.user) {
      setCreated(appState.user.created);
      setUsername(appState.user.username);
      setFirstname(appState.user.firstname);
      setLastname(appState.user.lastname);
      setCompany(appState.user.company);
      setAddress(appState.user.address);
      setPostalCode(appState.user.postalCode);
      setCity(appState.user.city);
      setCountry(appState.user.country);
      setCountryState(appState.user.state);
      setHasChanges(false);
    }
  }, [appState.user]);

  const saveChanges = (e) => {
    e.preventDefault();
    updateUserProfile(
      appState.user._id,
      {
        username,
        firstname,
        lastname,
        company,
        address,
        city,
        postalCode,
        state,
        country,
      },
      appState.token,
      dispatch,
    );
  };

  const deleteAccount = (e) => {
    e.preventDefault();
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <ProfilePlaceHolder />}
      {appState.firstTimeUserAndInstanceLoaded && (
        <>
          <BreadCrumbCusto
            dispatch={dispatch}
            instance={null}
            env={null}
            name={"profile"}
          />

          <Row>
            <Col lg="4" md="4" sm="12" xs="12">
              <CardNumber
                name="Profile"
                value={{
                  data: {
                    profile: (appState.user && appState.user.profile) || "free",
                  },
                }}
                prop="profile"
                icon="cafe-creation"
                unit="u"
                last="Your current profile"
                badge={true}
                badgeColor="blue"
              />
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <CardNumber
                name="Role"
                value={{
                  data: {
                    role: (appState.user && appState.user.role) || "",
                  },
                }}
                prop="role"
                icon="cafe-creation"
                unit="u"
                last="Your role"
                badge={true}
                badgeColor="blue"
              />
            </Col>

            <Col lg="4" md="4" sm="12" xs="12">
              <CardNumber
                name="Member Since"
                value={{
                  data: {
                    since:
                      (appState.user &&
                        DateTime.fromISO(appState.user.created).toLocaleString(
                          DateTime.DATETIME_MED,
                        )) ||
                      "-",
                  },
                }}
                prop="since"
                icon="cafe-creation"
                unit="u"
                last="Enrolled since"
                badge={true}
                badgeColor="blue"
              />
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card>
                <CardHeader>
                  <h5 className="card-category">Settings</h5>
                  <CardTitle tag="h3">Profile</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#">
                    <Row>
                      <Col lg="12" md="12" sm="12" xs="12">
                        <Row>
                          <div className="col-md-6">
                            <Label>Username</Label>
                            <FormGroup>
                              <Input
                                type="email"
                                id="email"
                                disabled={true}
                                value={username}
                                onChange={(e) => {
                                  setUsername(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your email
                              </span>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <Label>Company</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                id="company"
                                value={company}
                                onChange={(e) => {
                                  setCompany(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your Company
                              </span>
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6">
                            <Label>Firstname</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                id="firstname"
                                value={firstname}
                                onChange={(e) => {
                                  setFirstname(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your firstname
                              </span>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <Label>Lastname</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                id="lastname"
                                value={lastname}
                                onChange={(e) => {
                                  setLastname(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your Lastname
                              </span>
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6">
                            <Label>Address</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                id="address"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your address
                              </span>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <Label>City</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                id="city"
                                value={city}
                                onChange={(e) => {
                                  setCity(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your City
                              </span>
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-3">
                            <Label>Zip Code</Label>
                            <FormGroup>
                              <Input
                                type="number"
                                id="zipcode"
                                value={postalCode}
                                onChange={(e) => {
                                  setPostalCode(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your Zip Code
                              </span>
                            </FormGroup>
                          </div>
                          <div className="col-md-3">
                            <Label>Province/State</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                id="province"
                                value={state}
                                onChange={(e) => {
                                  setCountryState(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your Province or State
                              </span>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <Label>Country</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                id="country"
                                value={country}
                                onChange={(e) => {
                                  setCountry(e.target.value);
                                  setHasChanges(true);
                                }}
                              />
                              <span className="form-description">
                                Your Country
                              </span>
                            </FormGroup>
                          </div>
                        </Row>
                        <div className="mt-5">
                          <Button
                            color={hasChanges ? "primary" : "secondary"}
                            disabled={appState.updateProfileInProgress}
                            onClick={(e) => saveChanges(e)}
                          >
                            Save
                          </Button>
                          {appState.updateProfileInProgress && (
                            <Spinner
                              children={null}
                              color="primary"
                              size="sm"
                              className="ml-2"
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <hr className="card-separator" />
                  <div style={{ display: "flex" }}>
                    {!hasChanges && (
                      <p className="text-info mt-4" style={{ flex: 1 }}>
                        <i className="icon cafe-bullet" /> No pending changes
                      </p>
                    )}
                    {hasChanges && (
                      <p className="text-warning mt-4" style={{ flex: 1 }}>
                        <i className="icon cafe-change" /> Some changes have
                        been done
                      </p>
                    )}
                    <div></div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Profile;
