export const SETTINGS_CATEGORIES = [
  "identification",
  "ssh",
  "health",
  "usage",
  "tests",
];

export const SERIES_TRENDS_DAYS = 1;

export const HOST_SERVICE = {
  HEALTHCHECK: "healthCheck",
  TELEMETRY: "telemetry",
  MEDIA: "media",
  USAGE: "usage",
  HEALTH: "health",
  TESTS: "tests",
  CLEANER: "cleaner",
};

export const HOST_CATEGORY = {
  FREE: 0,
  STARTUP: 3,
  ENTERPRISE: 8,
  HOSTED: 13
};

export const HOST_CATEGORY_NAME = {
  FREE: "FREE",
  STARTUP: "STARTUP",
  ENTERPRISE: "ENTERPRISE",
};

export const SERVICE_STATUS = {
  UNKNOWN: "unknown",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  OBSERVATION: "observation",
};

export const ENVIRONMENT = {
  PRODUCTION: "production",
  STAGING: "staging",
  DEV: "dev",
  OTHER: "other",
  NONE: "none",
};

export const USER_PROFILE = {
  FREE: "free",
};

export const HOST_STATUS = {
  UNKNOWN: "unknown",
  OPERATIONAL: "operational",
  DEGRADED: "degraded",
  OUTAGE: "outage",
  OBSERVATION: "observation",
};

export const displayHostStatus = (status) => {
  switch (status) {
    case HOST_STATUS.OUTAGE:
      return "IN FAILURE";
    case HOST_STATUS.DEGRADED:
      return "DEGRADED";
    case HOST_STATUS.OPERATIONAL:
      return "STABLE";
    case HOST_STATUS.OBSERVATION:
      return "UNDER OBSERVATION";
    default:
      return "UNKNOWN";
  }
};

export const getHostStatusColor = (status) => {
  switch (status) {
    case HOST_STATUS.OPERATIONAL:
      return "success";
    case HOST_STATUS.DEGRADED:
      return "warning";
    case HOST_STATUS.OUTAGE:
      return "danger";
    case HOST_STATUS.OBSERVATION:
      return "blue"
    case HOST_STATUS.UNKNOWN:
      return "light";
    default:
      return "light";
  }
};

export const getServiceStatusColor = (status) => {
  switch (status) {
    case SERVICE_STATUS.SUCCESS:
      return "success";
    case SERVICE_STATUS.WARNING:
      return "warning";
    case SERVICE_STATUS.ERROR:
      return "danger";
    case SERVICE_STATUS.OBSERVATION:
      return "blue"
    case HOST_STATUS.UNKNOWN:
      return "light";
    default:
      return "light";
  }
};

export const HOST_ROLE = {
  COTURN: "relay/coturn",
  ETURNAL: "relay/eturnal",
  JITSI: "media/jitsi",
  JSONGLE: "sig/jsongle",
  SIG: "sig/other",
};

export const ROLE = {
  SIG: "sig",
  MEDIA: "media",
  RELAY: "relay",
};

export const ROUTES = {
  DASHBOARD: "/",
  BOARD: "/host/:hostId/board",
  MONITOR: "/host/:hostId/monitor",
  SETTINGS: "/host/:hostId/settings",
  TRENDS: "/monitoring/:hostId/trends",
  USAGE: "/monitoring/:hostId/usage",
  HEALTH: "/monitoring/:hostId/health",
  TESTS: "/monitoring/:hostId/tests",
  HISTORY: "/monitoring/:hostId/history",
  TRANSPORT: "/testing/:hostId/transport",
  CONNECTIVITY: "/testing/:hostId/connectivity",
  MEDIA: "/testing/:hostId/media",
  OPERATIONS: "/host/:hostId/operations",
  OPERATION: "/host/:hostId/operations/:operationId/operate",
  LOGIN: "/login",
  PRODUCTION: "/production",
  STAGING: "/staging",
  OTHERS: "/others",
  PROFILE: "/profile",
  PARAMETERS: "/parameters",
  ADMIN: "/admin",
  PUBLIC: "/public",
  CDR: "/cdr",
};

export const HOST_FREQUENCY = {
  [HOST_CATEGORY.FREE]: [
    {value: -1, label: "Never"},
    //{ value: 0, label: "Once per week" },
    {value: 1, label: "Once per day"},
  ],
  [HOST_CATEGORY.STARTUP]: [
    {value: -1, label: "Never"},
    //{ value: 0, label: "Once per week" },
    {value: 1, label: "Once per day"},
    {value: 2, label: "Twice per day"},
    {value: 3, label: "Every hours"},
  ],
  [HOST_CATEGORY.ENTERPRISE]: [
    {value: -1, label: "Never"},
    //{ value: 0, label: "Once per week" },
    {value: 1, label: "Once per day"},
    {value: 2, label: "Twice per day"},
    {value: 3, label: "Every hours"},
    {value: 5, label: "Every 15 minutes"},
    {value: 8, label: "Every 5 minutes"},
  ],
  [HOST_CATEGORY.HOSTED]: [
    {value: -1, label: "Never"},
    //{ value: 0, label: "Once per week" },
    {value: 1, label: "Once per day"},
    {value: 2, label: "Twice per day"},
    {value: 3, label: "Every hours"},
    {value: 5, label: "Every 15 minutes"},
    {value: 8, label: "Every 5 minutes"},
    {value: 13, label: "Every minutes"},
  ],
};

export const OPERATIONS_TYPE = [
  {value: "upgrade", label: "Upgrade"},
  {value: "system", label: "System Maintenance"},
  {value: "hardware", label: "Hardware Maintenance"},
  {value: "reboot", label: "System Reboot"},
  {value: "fireman", label: "Fireman"},
  {value: "logs", label: "Analyze logs"},
];

export const OPERATIONS_DURATION = [
  {value: 1, label: "Few minutes"},
  {value: 2, label: "Less than 1 hour"},
  {value: 5, label: "Half a day"},
  {value: 8, label: "A day"},
  {value: 13, label: "Several days"},
];

export const OPERATIONS_IMPACT = [
  {value: 1, label: "Seamless"},
  {value: 2, label: "Downgraded performance"},
  {value: 5, label: "Downgraded service"},
  {value: 8, label: "Major disruptions"},
  {value: 13, label: "Complete service interruption"},
];

export const OPERATIONS_COMPLEXITY = [
  {value: 1, label: "Trivial"},
  {value: 2, label: "Usual"},
  {value: 5, label: "Complicated"},
  {value: 8, label: "Highly complicated"},
  {value: 13, label: "Critical"},
];

export const OPERATIONS_STATE = [
  {value: "planned", label: "Planned"},
  {value: "done", label: "Done"},
  {value: "failed", label: "Failed"},
  {value: "canceled", label: "Canceled"},
];

export const computeMos = (avg_jitter, avg_rtt, avg_loss) => {
  const rtt = avg_rtt || 100;
  const jitter = avg_jitter || 10; // Default value if no value;
  const loss = avg_loss || 0;

  const rx = 93.2 - loss;
  const ry = 0.18 * rx * rx - 27.9 * rx + 1126.62;

  const d = (rtt + jitter) / 2;
  const h = d - 177.3 < 0 ? 0 : 1;
  const id = 0.024 * d + 0.11 * (d - 177.3) * h;
  const r = ry - id;
  if (r < 0) {
    return 1;
  }

  if (r > 100) {
    return 4.5;
  }
  return 1 + 0.035 * r + (7.0 / 1000000) * r * (r - 60) * (100 - r);
};

export const analyzeCoturnConfiguration = (properties) => {
  const report = [];

  // Existing
  if ("listening-device" in properties) {
    report.push({
      level: "warning",
      type: "existing",
      property: "listening-device",
      description:
        "This property increases the performance but can cause issues. This property needs root privileges to bind the listening endpoint to the device and work only on Linux. Remove it in case of issues.",
    });
  }
  if ("relay-device" in properties) {
    report.push({
      level: "warning",
      type: "existing",
      property: "relay-device",
      description:
        "This property increases performance but can cause issues. This property needs root privileges and work only on Linux.Remove it in case of issues.",
    });
  }
  if ("verbose" in properties) {
    report.push({
      level: "warning",
      type: "existing",
      property: "verbose",
      description:
        "Remove this property if the TURN server works fine. It can degrade the performance and use a lot of disk space.",
    });
  }
  if ("Verbose" in properties) {
    report.push({
      level: "error",
      type: "existing",
      property: "Verbose",
      description:
        "This property should not used in production. It can degrade the performance and can consume a lot if disk space.",
    });
  }
  if ("lt-cred-mech" in properties) {
    report.push({
      level: "warning",
      type: "existing",
      property: "lt-cred-mech",
      description:
        "Long term credentials authentication offers a low security level. Replace this property by 'use-auth-secret' and implements a time limited token based authentication.",
    });
  }
  if ("no-auth" in properties) {
    report.push({
      level: "error",
      type: "existing",
      property: "no-auth",
      description:
        "No authentication method is used. Any user can connect. Put in place an authentication method using a long term credentials authentication, a time limited token authentication or a oauth authentication.",
    });
  }
  if ("lt-cred-mech" in properties && "use-auth-secret" in properties) {
    report.push({
      level: "error",
      type: "existing",
      property: "lt-cred-mech",
      description:
        "Two authentication methods are configured at the same time. Users may have difficulties to connect. Keep only the authentication method that suits. Remove the other.",
    });
  }
  if ("log-file" in properties && "syslog" in properties) {
    report.push({
      level: "warning",
      property: "log-file",
      type: "existing",
      description:
        "This property is inactive due to the use of 'syslog'. Remove it.",
    });
  }
  if ("allow-loopback-peers" in properties) {
    report.push({
      level: "error",
      property: "allow-loopback-peers",
      type: "existing",
      description:
        "This property should not be used in production. Remove it to avoid any security vulnerabilities.",
    });
  }
  if ("keep-address-family" in properties) {
    report.push({
      level: "error",
      property: "keep-address-family",
      type: "existing",
      description:
        "This property is deprecated and forces IPv4 even if IPv6 is configured. Remove it.",
    });
  }
  if ("web-admin-listen-on-workers" in properties) {
    report.push({
      level: "error",
      property: "web-admin-listen-on-workers",
      type: "existing",
      description:
        "This property should not be used in production. Remove it for security reason.",
    });
  }

  // Missing
  if (!("fingerprint" in properties)) {
    report.push({
      level: "warning",
      type: "missing",
      property: "fingerprint",
      description:
        "This property allows to use fingerprinting in TURN message. Add this property in case of issue.",
    });
  }
  if ("lt-cred-mech" in properties && !("user" in properties)) {
    report.push({
      level: "error",
      type: "missing",
      property: "user",
      description:
        "No static user account defined for the long term credentials mechanism used. Add property 'user=username:password'.",
    });
  }
  if (!("external-ip" in properties)) {
    report.push({
      level: "warning",
      type: "missing",
      property: "external-ip",
      description:
        "This property is needed when the TURN server is behind a NAT (such as when hosted on Amazon EC2). Add this property and set the value to the public IP address used if a NAT is used. Use the 'relay-ip' to set the private address.",
    });
  }
  if (
    "use-auth-secret" in properties &&
    !("static-auth-secret" in properties)
  ) {
    report.push({
      level: "error",
      property: "static-auth-secret",
      type: "missing",
      description:
        "Property 'static-auth-secret' is missing from the configuration. Add the property 'static-auth-secret' with the secret to use.",
    });
  }
  if (
    !("lt-cred-mech" in properties) &&
    !("use-auth-secret" in properties) &&
    !("oauth" in properties)
  ) {
    report.push({
      level: "error",
      type: "missing",
      property: "lt-cred-mech",
      description:
        "No authentication method found. Any user can connect. Put in place an authentication method using a long term credentials authentication, a time limited token authentication or a oauth authentication.",
    });
  }
  if (!("cert" in properties)) {
    report.push({
      level: "warning",
      property: "cert",
      type: "missing",
      description:
        "No certificate file provided for using TLS protocol. Add this property to support TLS.",
    });
  }
  if (!("pkey" in properties)) {
    report.push({
      level: "warning",
      property: "pkey",
      type: "missing",
      description:
        "No private key file provided for using TLS protocol. Add this property to support TLS. Possibly add property 'pkey-pwd' if the private key file is encoded.",
    });
  }
  if (!("no-rfc5780" in properties)) {
    report.push({
      level: "warning",
      property: "no-rfc5780",
      type: "missing",
      description:
        "This property decreases gain factor in STUN binding responses. Add this property if there is one listener address from the same address family (IPv4/IPv6).",
    });
  }
  if (!("no-stun-backward-compatibility" in properties)) {
    report.push({
      level: "warning",
      property: "no-stun-backward-compatibility",
      type: "missing",
      description:
        "This property decreases gain factor in STUN binding responses. Add this property to disable handling old STUN Binding requests.",
    });
  }
  if (!("response-origin-only-with-rfc5780" in properties)) {
    report.push({
      level: "warning",
      property: "response-origin-only-with-rfc5780",
      type: "missing",
      description:
        "This property decreases gain factor in STUN binding responses. Add this property to send RESPONSE-ORIGIN attribute in binding response only if RFC5780 is enabled.",
    });
  }

  // Security
  if ("stale-nonce" in properties && !properties["stale-nonce"]) {
    report.push({
      level: "warning",
      property: "stale-nonce",
      type: "security",
      description:
        "Extra security measure for limiting the nonce lifetime. Do not use 0 because this duration allows an unlimited lifetime.",
    });
  }
  if (!("no-software-attribute" in properties)) {
    report.push({
      level: "warning",
      property: "no-software-attribute",
      type: "security",
      description:
        "Extra security measure for hiding the software version. Add this property to limit the vulnerability to attacks against software.",
    });
  }
  if (!("no-multicast-peers" in properties)) {
    report.push({
      level: "error",
      property: "no-multicast-peers",
      type: "security",
      description:
        "Extra security measure to disallow peers on well-known broadcast addresses. Add this property to block certain attacks.",
    });
  }
  if (!("no-cli" in properties) && !("cli-password" in properties)) {
    report.push({
      level: "error",
      property: "cli-password",
      type: "security",
      description:
        "CLI is enabled but no password has been set. Add this property to secure CLI access.",
    });
  }
  if (!("denied-peer-ip" in properties)) {
    report.push({
      level: "error",
      property: "denied-peer-ip",
      type: "security",
      description:
        "Extra security measure for adding access control rules. Add this property to block non-public addresses. If the server is behind a NAT, add the property 'allow-peer-ip' if the private address is part of the banned range of addresses.",
    });
  }

  // Features
  if ("stun-only" in properties) {
    report.push({
      level: "warning",
      property: "stun-only",
      type: "feature",
      description:
        "This property disables TURN functionality. Remove it to support TURN.",
    });
  }
  if ("no-stun" in properties) {
    report.push({
      level: "warning",
      property: "no-stun",
      type: "feature",
      description:
        "This property disables STUN functionality. Remove it to support STUN.",
    });
  }
  if ("no-tls" in properties) {
    report.push({
      level: "warning",
      property: "no-tls",
      type: "feature",
      description:
        "This property disables TLS protocol. Remove it to support TLS.",
    });
  }
  if ("no-udp" in properties) {
    report.push({
      level: "warning",
      property: "no-udp",
      type: "feature",
      description:
        "This property disables UDP protocol. Remove it to support UDP.",
    });
  }
  if ("no-tcp" in properties) {
    report.push({
      level: "warning",
      property: "no-tcp",
      type: "feature",
      description:
        "This property disables TCP protocol. Remove it to support TCP.",
    });
  }
  if ("no-dtls" in properties) {
    report.push({
      level: "warning",
      property: "no-dtls",
      type: "feature",
      description:
        "This property disables DTLS protocol. Remove it to support DTLS.",
    });
  }
  if ("no-udp-relay" in properties) {
    report.push({
      level: "warning",
      property: "no-udp-relay",
      type: "feature",
      description:
        "This property disables UDP relay endpoints protocol. Remove it to support UDP relay.",
    });
  }
  if ("no-tcp-relay" in properties) {
    report.push({
      level: "warning",
      property: "no-tcp-relay",
      type: "feature",
      description:
        "This property disables TCP relay endpoints protocol. Remove it to support TCP relay.",
    });
  }
  if ("secure-stun" in properties) {
    report.push({
      level: "warning",
      property: "secure-stun",
      type: "feature",
      description:
        "This property requires authentication of the STUN Binding request. Remove it in case of issues.",
    });
  }
  if ("mobility" in properties) {
    report.push({
      level: "warning",
      property: "mobility",
      type: "feature",
      description:
        "This property allows to keep the session when the client moves between networks (mobility). Remove it in case of issues.",
    });
  }

  return report;
};

export const isMonitored = (instance) => {
  return (
    instance &&
    instance.active &&
    instance[HOST_SERVICE.HEALTH].frequency > -1 &&
    instance[HOST_SERVICE.TESTS].frequency > -1 &&
    instance[HOST_SERVICE.USAGE].frequency > -1
  );
};

export const isPaused = (instance) => {
  return instance && !instance.active;
};

export const isPartiallyMonitored = (instance) => {
  return (
    instance &&
    instance.active &&
    (instance[HOST_SERVICE.HEALTH].frequency > -1 ||
      instance[HOST_SERVICE.TESTS].frequency > -1 ||
      instance[HOST_SERVICE.USAGE].frequency > -1)
  );
};

export const isPendingMonitoring = (instance) => {
  return (
    instance &&
    instance.active &&
    (instance[HOST_SERVICE.HEALTH].frequency === -1 ||
      instance[HOST_SERVICE.TESTS].frequency === -1 ||
      instance[HOST_SERVICE.USAGE].frequency === -1)
  );
};

export const lastCheckForHost = (instance) => {
  if (instance.statusLast === instance.created) {
    return;
  }
  let recent = instance[HOST_SERVICE.HEALTH].last;
  if (recent < instance[HOST_SERVICE.TESTS].last) {
    recent = instance[HOST_SERVICE.TESTS].last;
  }
  if (recent < instance[HOST_SERVICE.USAGE].last) {
    recent = instance[HOST_SERVICE.USAGE].last;
  }

  return recent;
};
