import {useEffect, useReducer, useState, useRef} from "react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import AppContext from "../contexts/appContext";
import {
  appReducer,
  initialAppState,
  USER_STATE,
} from "../reducers/appReducer";
import {
  checkTokenIsValid,
  checkUserIsAdmin,
} from "../modules/authentification";
import CONFIG from "../modules/environment";

import AdminNavbar from "./AdminNavbar";
import Sidebar from "./Sidebar.js";
import Main from "./Main";
import Ice from "./testing/Ice";
import Login from "./Login";
import {loginWithToken} from "../actions/userActions";

import Calls from "./Calls";
import Call from "./testing/Call";
import Settings from "./Settings";
import Host from "./monitoring/Host";
import Profile from "./Profile";
import Admin from "./admin/Admin";
import HostServices from "./testing/HostServices";
import {
  bypassInstanceData,
  getCustomization,
  initializeInstanceData,
  initializeUserData,
} from "../actions/initActions";
import HostStatistics from "./monitoring/HostStatistics";

import "./App.css";
import HostGlobal from "./monitoring/HostGlobal";
import Control from "./controlling/Control";
import Connectivity from "./testing/Connectivity";
import Features from "./controlling/Features";
import Users from "./testing/Users";
import Public403 from "./testing/Public403";
import Public from "./Public";
import HostHistory from "./monitoring/HostHistory";
import HostGlobalHealth from "./monitoring/HostGlobalHealth";
import HostGlobalTests from "./monitoring/HostGlobalTests";
import Parameters from "./Parameters";
import CDR from "./CDR";
import Board from "./monitoring/Board";
import {ROUTES} from "../modules/bm";
import DashboardPlaceHolder from "./placeholder/DashboardPlaceHolder";
import Dashboard from "./monitoring/Dashboard";
import Operations from "./operations/Operations";
import RunOperation from "./operations/RunOperation";

let instanceId = null; // Store the current instance ID

function App(props) {
  const [appState, dispatch] = useReducer(appReducer, initialAppState);
  const [sidebarMini, setSidebarMini] = useState(true);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [activeColor, setActiveColor] = useState("blue");
  const mainPanelRef = useRef(null);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (appState.token && appState.userState !== USER_STATE.SIGNED) {
      loginWithToken(appState.token, appState.sseToken, dispatch);
    }
  }, [appState.token]);

  useEffect(() => {
    if (appState.userState === USER_STATE.SIGNED) {
      getCustomization(appState.token, dispatch);
      initializeUserData(appState.user._id, appState.token, dispatch);
    }
  }, [appState.userState]);

  useEffect(() => {
    if (
      appState.currentInstance &&
      appState.currentInstance._id !== instanceId
    ) {
      instanceId = appState.currentInstance._id;
      initializeInstanceData(
        appState.user._id,
        instanceId,
        appState.token,
        dispatch,
      );
    }
  }, [appState.currentInstance]);

  useEffect(() => {
    if (appState.firstTimeUserLoaded && appState.instances.length === 0) {
      bypassInstanceData(dispatch);
    }
  }, [appState.firstTimeUserAndInstanceLoaded, appState.instances]);

  useEffect(() => {
  }, [appState.firstTimeUserAndInstanceLoaded]);

  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };

  const buildPathWithHostId = (path) => {
    if (!appState.currentInstance) {
      return path;
    }

    return path.replace(":hostId", appState.currentInstance._id);
  };

  return (
    <>
      {!appState.firstTimeUserAndInstanceLoaded && appState.token && (
        <DashboardPlaceHolder/>
      )}
      {(appState.firstTimeUserAndInstanceLoaded || !appState.token) && (
        <AppContext.Provider value={appState}>
          <Router>
            {appState.userState !== USER_STATE.SIGNED &&
              appState.userState !== USER_STATE.SIGNIN_IN_PROGRESS && (
                <Switch>
                  <Route
                    exact
                    path="/login"
                    render={(props) => {
                      return (
                        <Login dispatch={dispatch} brandText={CONFIG.appName}/>
                      );
                    }}
                  ></Route>
                  <Route
                    exact
                    path="/public"
                    render={(props) => {
                      return <Public dispatch={dispatch}/>;
                    }}
                  ></Route>
                  <Route
                    path="*"
                    render={(props) => {
                      return <Redirect to={ROUTES.LOGIN}/>;
                    }}
                  ></Route>
                </Switch>
              )}
            {appState.userState !== USER_STATE.SIGNIN_IN_PROGRESS && (
              <div></div>
            )}
            {appState.userState === USER_STATE.SIGNED && (
              <div className="wrapper">
                <div
                  className="navbar-minimize-fixed"
                  style={{opacity: opacity}}
                >
                  <button
                    className="minimize-sidebar btn btn-link btn-just-icon"
                    onClick={handleMiniClick}
                  >
                    <i className="icon cafe-align-center visible-on-sidebar-regular text-muted"/>
                    <i className="icon cafe-bullet visible-on-sidebar-mini text-muted"/>
                  </button>
                </div>

                <Sidebar
                  {...props}
                  dispatch={dispatch}
                  instances={appState.instances}
                  activeColor={activeColor}
                  logo={{
                    outterLink: "/production",
                    text: "TURNcafe",
                  }}
                  closeSidebar={closeSidebar}
                />
                <div className="main-panel" ref={mainPanelRef}>
                  <AdminNavbar
                    {...props}
                    dispatch={dispatch}
                    handleMiniClick={handleMiniClick}
                    brandText={CONFIG.appName}
                    sidebarOpened={sidebarOpened}
                    toggleSidebar={toggleSidebar}
                  />
                  <div
                    className={
                      appState.displaySelector
                        ? "content-selector"
                        : "content-selector-with"
                    }
                  ></div>

                  <div className="content">
                    <Switch>
                      <Route
                        path={ROUTES.LOGIN}
                        render={(props) => {
                          if (!checkTokenIsValid(appState.token)) {
                            return (
                              <Login
                                dispatch={dispatch}
                                brandText={CONFIG.appName}
                              />
                            );
                          }
                          return <Redirect to={ROUTES.DASHBOARD}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.BOARD}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Board dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.MONITOR}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Host dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.TRENDS}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <HostStatistics dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.USAGE}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <HostGlobal dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.HEALTH}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <HostGlobalHealth dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.TESTS}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <HostGlobalTests dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.HISTORY}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <HostHistory dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path="/testing/scores"
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <HostServices dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.TRANSPORT}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Ice dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path="/testing/users"
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Users dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path="/controlling/control"
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Control dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path="/controlling/features"
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Features dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path="/calls"
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Calls dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.CONNECTIVITY}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Connectivity dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.MEDIA}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Call dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.SETTINGS}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Settings dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        exact
                        path={ROUTES.OPERATIONS}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <Operations dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.OPERATION}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (!appState.currentInstance) {
                              return <Redirect to={ROUTES.DASHBOARD}/>;
                            }
                            return <RunOperation dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.CDR}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (appState.currentInstance) {
                              return (
                                <Redirect
                                  to={buildPathWithHostId(ROUTES.BOARD)}
                                />
                              );
                            }
                            return <CDR dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.PARAMETERS}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (appState.currentInstance) {
                              return (
                                <Redirect
                                  to={buildPathWithHostId(ROUTES.BOARD)}
                                />
                              );
                            }
                            return <Parameters dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.PROFILE}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            return <Profile dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.PUBLIC}
                        render={(props) => {
                          return <Public403/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.ADMIN}
                        render={(props) => {
                          if (
                            checkTokenIsValid(appState.token) &&
                            checkUserIsAdmin(appState.user)
                          ) {
                            return <Admin dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.DASHBOARD}
                        exact
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (appState.currentInstance) {
                              return (
                                <Redirect
                                  to={buildPathWithHostId(ROUTES.BOARD)}
                                />
                              );
                            }
                            return <Dashboard dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        path={ROUTES.PRODUCTION}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (appState.currentInstance) {
                              return (
                                <Redirect
                                  to={buildPathWithHostId(ROUTES.BOARD)}
                                />
                              );
                            }
                            return <Main dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        exact
                        path={ROUTES.STAGING}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (appState.currentInstance) {
                              return (
                                <Redirect
                                  to={buildPathWithHostId(ROUTES.BOARD)}
                                />
                              );
                            }
                            return <Main dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        exact
                        path={ROUTES.OTHERS}
                        render={(props) => {
                          if (checkTokenIsValid(appState.token)) {
                            if (appState.currentInstance) {
                              return (
                                <Redirect
                                  to={buildPathWithHostId(ROUTES.BOARD)}
                                />
                              );
                            }
                            return <Main dispatch={dispatch}/>;
                          }
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                      <Route
                        exact
                        path="*"
                        render={(props) => {
                          return <Redirect to={ROUTES.LOGIN}/>;
                        }}
                      ></Route>
                    </Switch>
                  </div>
                </div>
              </div>
            )}
          </Router>
        </AppContext.Provider>
      )}
    </>
  );
}

export default App;
