import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Spinner,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { copyToClipboard } from "../../modules/clipboard";
import { updateLinkForInstance } from "../../actions/linkActions";

function Public403(props) {
  const appState = useContext(AppContext);
  const [host, setHost] = useState(appState.currentInstance);
  const [link, setLink] = useState(appState.link);

  useEffect(() => {
    if (appState.currentInstance) {
      setHost(appState.currentInstance);
    }
  }, [appState.currentInstance]);

  useEffect(() => {
    setLink(appState.link);
  }, [appState.link]);

  const getLinkUrl = (token) => {
    return `${window.location.origin}/#/public?token=${token}`;
  };

  const onCopyToClipboard = () => {
    copyToClipboard(getLinkUrl((link && link.token) || ""));
  };

  const onActiveUnactive = () => {
    updateLinkForInstance(
      appState.user._id,
      host._id,
      link._id,
      { enabled: !link.active },
      appState.token,
      props.dispatch
    );
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardHeader>
                  <h5 className="card-category">Testing</h5>
                  <CardTitle tag="h3">USERS</CardTitle>
                </CardHeader>
                <CardBody>
                  <p className="mb-4 text-danger">
                    You can't access to the public link when you are logged to
                    the site.
                  </p>
                  <p>Log-out and then click on the public link again</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
    </div>
  );
}

export default Public403;
