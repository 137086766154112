export const report = (reporter, module, message, param) => {
  if (param) {
    reporter.log(`${module} - ${message}`, param);
  } else {
    reporter.log(`${module} - ${message}`);
  }
};

export const download = (reporter) => {
  reporter.downloadLog();
};
