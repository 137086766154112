import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Table,
  Badge,
} from "reactstrap";

import AppContext from "../contexts/appContext";
import ProfilePlaceHolder from "./placeholder/ProfilePlaceHolder";
import "./Parameters.css";
import { DateTime } from "luxon";
import { getRoleColor, getRoleTextColor } from "../modules/helper";
import { Base64 } from "js-base64";
import BreadCrumbCusto from "./BreadCrumb";

function CDR({ dispatch }) {
  const appState = useContext(AppContext);

  const [cdr, setCdr] = useState([]);

  useEffect(() => {
    setCdr(appState.cdr);
  }, [appState.cdr]);

  const getDisplayPeriod = (year, month) => {
    let month2Digits = month < 10 ? `0${month}` : `${month}`;
    const date = DateTime.fromISO(`${year}-${month2Digits}`);
    return date.toFormat("LLLL, yyyy");
  };

  const getInstanceName = (instanceId) => {
    const instance = appState.instances.find(
      (instance) => instance._id === instanceId,
    );
    if (!instance) {
      return { name: instanceId, color: "" };
    }
    return { name: instance.name, color: getRoleTextColor(instance.role) };
  };

  const downloadFile = (cdr) => {
    const downloadLink = document.createElement("a");
    const content = Base64.decode(cdr.base64content);
    const blob = new Blob([content], {
      type: "data:application/octet-stream;base64",
    });

    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `mur_${cdr.instanceId}_${cdr.year}-${cdr.month}.csv`;
    downloadLink.click();
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <ProfilePlaceHolder />}
      {appState.firstTimeUserAndInstanceLoaded && (
        <>
          <BreadCrumbCusto
            dispatch={dispatch}
            instance={null}
            env={null}
            name={"Monthly usage reports"}
          />
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card>
                <CardHeader>
                  <h5 className="card-category">Archive</h5>
                  <CardTitle tag="h3">Monthly Usage Reports</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Period</th>
                        <th className="text-left">Instance</th>
                        <th className="text-center">Generated</th>
                        <th className="text-center">Updated</th>
                        <th className="text-right">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cdr.length > 0 &&
                        cdr.map((item, key) => (
                          <tr key={key}>
                            <td className="text-left">{`${getDisplayPeriod(
                              item.year,
                              item.month,
                            )}`}</td>
                            <td className="text-left">
                              <span
                                className={`text-${
                                  getInstanceName(item.instanceId).color
                                }`}
                              >
                                {getInstanceName(item.instanceId).name}
                              </span>
                            </td>
                            <td className="text-center">
                              {DateTime.fromISO(item.created).toFormat("ff")}
                            </td>
                            <td className="text-center">
                              {item.updated
                                ? DateTime.fromISO(item.updated).toFormat("ff")
                                : "-"}
                            </td>
                            <td className="text-right">
                              <Button
                                size="sm"
                                onClick={() => {
                                  downloadFile(item);
                                }}
                              >
                                Download
                              </Button>
                            </td>
                          </tr>
                        ))}
                      {cdr.length === 0 && (
                        <tr className="text-left">
                          <td colSpan="4">No data</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default CDR;
