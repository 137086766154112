export const copyToClipboard = (value) => {
  if (!navigator.clipboard) {
    console.log("copy/past not working");
    return;
  }

  navigator.clipboard
    .writeText(value)
    .then(() => {
      console.log("Text copied to clipboard...", value);
    })
    .catch((err) => {
      console.log("Something went wrong", err);
    });
};
