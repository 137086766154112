import { useRef, useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import "../testing/media.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import { createChart, updateChart } from "../../modules/callCharts";
import CardCallStat from "../CardCallStat";
import { CHART_COLORS } from "../../modules/helper";

function PaquetsLostStat({ dispatch }) {
  const appState = useContext(AppContext);
  const statsLostChart = useRef();
  const [packetsLostAudio, setPacketsLostAudio] = useState({});
  const [packetsLostVideo, setPacketsLostVideo] = useState({});

  useEffect(() => {
    createChart(document.querySelector("#chartLost"), statsLostChart, "LOST");
  }, []);

  useEffect(() => {
    updateChart(
      appState.statsLostAudio,
      appState.statsLostVideo,
      statsLostChart,
      5
    );
  }, [appState.statsLostAudio, appState.statsLostVideo]);

  useEffect(() => {
    if ("avg" in appState.finalPacketsLostAudio) {
      setPacketsLostAudio(appState.finalPacketsLostAudio);
    }
  }, [appState.finalPacketsLostAudio]);

  useEffect(() => {
    if ("avg" in appState.finalPacketsLostVideo)
      setPacketsLostVideo(appState.finalPacketsLostVideo);
  }, [appState.finalPacketsLostVideo]);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Transmission errors measurement</h5>
        <CardTitle tag="h3">PACKETS LOST</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <CardCallStat
              avg={packetsLostAudio.avg}
              unit="%"
              fixed={2}
              valueColor={CHART_COLORS.pink}
              name="Audio"
              thresholds={[0.5, 2]}
              thresholdInverse={true}
            />
          </Col>
          <Col md="6">
            <CardCallStat
              avg={packetsLostVideo.avg}
              unit="%"
              fixed={2}
              valueColor={CHART_COLORS.teal}
              name="Video"
              thresholds={[1, 4]}
              thresholdInverse={true}
            />
          </Col>
        </Row>
        <div className="graph">
          <canvas id="chartLost"></canvas>
        </div>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default PaquetsLostStat;
