import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Table,
  Button,
} from "reactstrap";
import React, { useContext } from "react";
import { manualLaunchService } from "../../actions/instanceActions";
import AppContext from "../../contexts/appContext";
import { HOST_SERVICE } from "../../modules/bm";
import { getAdminMetrics } from "../../actions/adminActions";

function AdminAccounts({ dispatch, metrics }) {
  const appState = useContext(AppContext);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Database Usage</h5>
        <CardTitle tag="h3">METRICS</CardTitle>
      </CardHeader>
      <CardBody>
        <Table>
          <thead className="text-primary">
            <tr>
              <th className="text-left">Collection</th>
              <th className="text-right">Count</th>
              <th className="text-right">Size</th>
            </tr>
          </thead>
          <tbody>
            {metrics &&
              metrics.map((metric, key) => (
                <tr key={key}>
                  <td className="text-left">{metric.name}</td>
                  <td className="text-right">{metric.count}</td>
                  <td className="text-right">{metric.size}</td>
                </tr>
              ))}
            {!metrics ||
              (metrics.length === 0 && (
                <tr>
                  <td colSpan="7">
                    <br />
                    No metrics found.
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Button
          className="tableButtons"
          color="primary"
          size="sm"
          onClick={() => {
            getAdminMetrics(appState.token, dispatch);
          }}
        >
          {" "}
          <i className="icon cafe-refresh" />
        </Button>
        <Button
          size="sm"
          color="danger"
          onClick={() => {
            manualLaunchService(
              appState.user._id,
              "all",
              HOST_SERVICE.CLEANER,
              appState.token,
              dispatch
            );
          }}
        >
          Erase old Series
        </Button>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default AdminAccounts;
