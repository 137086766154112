import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Label } from "reactstrap";
import AppContext from "../../contexts/appContext";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import CardNumber from "../CardNumber";
import GlobalTrafficChart from "../charts/GlobalTrafficChart";
import GlobalMonthHealthChart from "../charts/GlobalMonthHealthChart";
import GlobalHealthChart from "../charts/GlobalHealthChart";
import { DateTime } from "luxon";
import GlobalStatusChart from "../charts/GlobalStatusChart";
import GlobalStatusPieChart from "../charts/GlobalStatusPieChart";
import { filterCurrentMonth } from "../../modules/helper";
import BreadCrumbCusto from "../BreadCrumb";

const defaultMonthlyValue = {
  cpuFree: 0,
  memoryFree: 0,
  diskFree: 0,
  success: 100,
};

function HostGlobalHealth(props) {
  const appState = useContext(AppContext);
  const [summary, setSummary] = useState(appState.summaryHealth.summary);
  const [details, setDetails] = useState(appState.summaryHealth.details);
  const [monthly, setMonthly] = useState(
    filterCurrentMonth(appState.summaryHealth.monthly, defaultMonthlyValue),
  );

  useEffect(() => {
    setSummary(appState.summaryHealth.summary);
    setDetails(appState.summaryHealth.details);
    setMonthly(
      filterCurrentMonth(appState.summaryHealth.monthly, defaultMonthlyValue),
    );
  }, [appState.summaryHealth]);

  const getFreeMemory = (count, memoryFree) => {
    return count > 0 ? memoryFree.toFixed(0) : "100";
  };

  const getFreeDisk = (count, diskFree) => {
    return count > 0 ? diskFree.toFixed(0) : "100";
  };

  const getFreeCpu = (count, cpuFree) => {
    return count > 0 ? cpuFree.toFixed(0) : "100";
  };

  const getSuccessRate = (count, successRate) => {
    return count > 0 ? successRate.toFixed(0) : "100";
  };

  const getMinDailyFor = (property) => {
    const max = (details || []).reduce(
      function (prev, current) {
        return prev[property] <= current[property] ? prev : current;
      },
      details && details.length > 0
        ? details[0]
        : { [property]: 0, fake: true },
    );
    if (max && !("fake" in max)) {
      return { [property]: max[property].toFixed(0), created: max.created };
    }
    return null;
  };

  const minMemoryDay = getMinDailyFor("memoryFree");
  const minDiskDay = getMinDailyFor("diskFree");
  const minCPUDay = getMinDailyFor("cpuFree");
  const minSuccessRate = getMinDailyFor("successRate");

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={appState.currentInstance}
              env={appState.currentInstance?.env}
              name={null}
            />
            <Row>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MEMORY"
                  value={{
                    data: {
                      legs: getFreeMemory(summary?.count, summary?.memoryFree),
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last="Free memory available"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="DISK"
                  value={{
                    data: {
                      conferences: getFreeDisk(
                        summary?.count,
                        summary?.diskFree,
                      ),
                    },
                  }}
                  prop="conferences"
                  icon="cafe-creation"
                  unit="%"
                  last="Free disk available"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="CPU"
                  value={{
                    data: {
                      traffic: getFreeCpu(summary?.count, summary?.cpuFree),
                    },
                  }}
                  prop="traffic"
                  icon="cafe-creation"
                  unit="%"
                  last="Free Cpu available"
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="SUCCESS"
                  value={{
                    data: {
                      duration: getSuccessRate(
                        summary?.count,
                        summary?.successRate,
                      ),
                    },
                  }}
                  prop="duration"
                  icon="cafe-creation"
                  unit="%"
                  last="Success rate"
                  badge={false}
                />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Health Status</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="9" lg="9" md="9" sm="9" xs="9">
                <GlobalStatusChart
                  status={appState.summaryStatus ? appState.summaryStatus : []}
                  property="health"
                  title="DAILY STATUS"
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="3">
                <GlobalStatusPieChart
                  status={appState.summaryStatus ? appState.summaryStatus : []}
                  property="health"
                  title="STATUS"
                />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Daily Statistics</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL MEMORY"
                  value={{
                    data: {
                      legs: minMemoryDay ? minMemoryDay.memoryFree : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={
                    minMemoryDay
                      ? DateTime.fromISO(minMemoryDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL DISK"
                  value={{
                    data: {
                      legs: minDiskDay ? minDiskDay.diskFree : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={
                    minDiskDay
                      ? DateTime.fromISO(minDiskDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL CPU"
                  value={{
                    data: {
                      legs: minCPUDay ? minCPUDay.cpuFree : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={
                    minCPUDay
                      ? DateTime.fromISO(minCPUDay.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MINIMAL SUCCESS"
                  value={{
                    data: {
                      legs: minSuccessRate ? minSuccessRate.successRate : "-",
                    },
                  }}
                  prop="legs"
                  icon="cafe-creation"
                  unit="%"
                  last={
                    minSuccessRate
                      ? DateTime.fromISO(minSuccessRate.created).toFormat("DD")
                      : "No data"
                  }
                  badge={false}
                />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <GlobalHealthChart />
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category">Monthly Statistics</span>
                <div style={{ borderBottom: "4px solid #28293d" }} />
              </Label>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Free Memory"
                  value={{
                    data: {
                      users: getFreeMemory(monthly.count, monthly.memoryFree),
                    },
                  }}
                  prop="users"
                  icon="cafe-creation"
                  unit="%"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Free Disk"
                  value={{
                    data: {
                      users: getFreeDisk(monthly.count, monthly.diskFree),
                    },
                  }}
                  prop="users"
                  icon="cafe-creation"
                  unit="%"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Free CPU"
                  value={{
                    data: {
                      users: getFreeCpu(monthly.count, monthly.cpuFree),
                    },
                  }}
                  prop="users"
                  icon="cafe-creation"
                  unit="%"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Success"
                  value={{
                    data: {
                      users: getSuccessRate(monthly.count, monthly.successRate),
                    },
                  }}
                  prop="users"
                  icon="cafe-creation"
                  unit="%"
                  last={DateTime.now().toFormat("LLLL, yyyy")}
                  badge={false}
                />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <GlobalMonthHealthChart />
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default HostGlobalHealth;
