import { ACTIONS } from "./constants";
import { log } from "../modules/logger";
import {
  getOutagesForUser,
  getLastMonthSLA,
  getOutagesForInstanceForAPeriod,
} from "../modules/status";

const moduleName = "outageActions";

export const getOutages = async (userId, token, dispatch) => {
  log(moduleName, "get outages for all instances", { userId });
  dispatch({
    type: ACTIONS.LIST_OUTAGES_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getOutagesForUser(userId, token);

    if (data) {
      dispatch({
        type: ACTIONS.LIST_OUTAGES_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: ACTIONS.LIST_OUTAGES_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.LIST_OUTAGES_FAILED,
      payload: { value: null },
    });
  }
};

export const getOutagesForInstance = async (
  userId,
  instanceId,
  startTime,
  endTime,
  token,
  dispatch
) => {
  log(moduleName, "get outages for instance", { instanceId });
  dispatch({
    type: ACTIONS.GET_OUTAGES_FOR_INSTANCE_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getOutagesForInstanceForAPeriod(
      userId,
      instanceId,
      startTime,
      endTime,
      token
    );

    const outages = data.outages;
    const degradations = data.degradations;
    const healthchecks = data.health;

    if (outages) {
      const outagesForChart = outages.map((outage) => ({
        x: outage.started,
        y: +(outage.duration / 3600).toFixed(2),
      }));

      const degradationsForChart = degradations.map((degradation) => ({
        x: degradation.started,
        y: +(degradation.duration / 3600).toFixed(2),
      }));

      const healthCheckForChart = healthchecks.map((health) => ({
        x: health.started,
        y: health.value,
      }));

      let currentDay = startTime.startOf("day");
      while (currentDay < endTime) {
        const existingDay = healthCheckForChart.find(
          (health) => health.x === currentDay.toUTC().toISO()
        );
        if (!existingDay) {
          healthCheckForChart.push({
            x: currentDay.toUTC().toISO(),
            y: 100,
          });
        }
        currentDay = currentDay.plus({ days: 1 });
      }

      healthCheckForChart.sort((a, b) => new Date(a.x) - new Date(b.x));

      dispatch({
        type: ACTIONS.GET_OUTAGES_FOR_INSTANCE_SUCCESS,
        payload: {
          value: {
            outages: outagesForChart,
            degradations: degradationsForChart,
            healthChecks: healthCheckForChart,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_OUTAGES_FOR_INSTANCE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_OUTAGES_FOR_INSTANCE_FAILED,
      payload: { value: null },
    });
  }
};

export const getSLA = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "get SLA for instance", { userId, instanceId });
  dispatch({
    type: ACTIONS.GET_SLA_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getLastMonthSLA(userId, instanceId, token);

    if (data) {
      dispatch({
        type: ACTIONS.GET_SLA_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_SLA_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_SLA_FAILED,
      payload: { value: null },
    });
  }
};
