import { useState, useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Col,
  Row,
  Button,
  ButtonGroup,
} from "reactstrap";
import AppContext from "../../contexts/appContext";
import { createChart, updateChart } from "../../modules/callCharts";

import "../testing/media.css";
import { HOST_SERVICE } from "../../modules/bm";
import { CHART_COLORS } from "../../modules/helper";

const keepProps = (array) => {
  const firstObject = array && array.length > 0 ? array[0] : null;
  if (firstObject) {
    const props = Object.keys(firstObject);
    const filteredProps = props.filter(
      (prop) =>
        prop !== "category" &&
        prop !== "created" &&
        prop !== "days" &&
        prop !== "success" &&
        prop !== "warning" &&
        prop !== "error" &&
        prop !== "count" &&
        prop !== "instanceId" &&
        prop !== "_id" &&
        prop !== "__v" &&
        prop !== "traffic" &&
        prop !== "rate" &&
        prop !== "userId" &&
        prop !== "countWithError"
    );
    return filteredProps;
  }
  return [];
};

function GlobalTestsChart({ dispatch }) {
  const appState = useContext(AppContext);
  const chartGlobalTraffic = useRef();
  const [series, setSeries] = useState([]);
  const [props, setProps] = useState([]); // To store the list of properties from services
  const [selectedProps, setSelectedProps] = useState("score");
  const [selectedSeries, setSelectedSeries] = useState([]);

  const CHART_MAIN_COLOR = {
    score: CHART_COLORS.pink,
    execution: CHART_COLORS.yellow,
    successRate: CHART_COLORS.orange,
  };

  const PROPSLABEL = {
    score: "Daily Score (in percent)",
    execution: "Daily Execution Time (in millisecond)",
    successRate: "Daily Success Rate (in percent)",
  };

  const getLabels = (prop) => {
    switch (prop) {
      case "status":
        return ["", "success", "warning", "error", "unknown"];
      default:
        return null;
    }
  };

  const changeSeries = (selectedProps, series) => {
    if (series.length > 0) {
      const graphSeries = series.map((elt) => ({
        x: elt.created,
        y: elt[selectedProps],
      }));
      setSelectedSeries(graphSeries);
    }
  };

  const getButtonsList = (items) => {
    return items.map((item, index) => (
      <Button
        key={index}
        color="info"
        id="0"
        size="sm"
        tag="label"
        className={classNames("btn-simple", {
          active: selectedProps === item,
        })}
        onClick={() => {
          setSelectedProps(item);
        }}
      >
        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          {item}
        </span>
        <span className="d-block d-sm-none">
          <i className="tim-icons icon-single-02" />
        </span>
      </Button>
    ));
  };

  useEffect(() => {
    if (selectedSeries.length > 0) {
      updateChart(selectedSeries, null, chartGlobalTraffic, 2, 60000, true);
    }
  }, [selectedSeries]);

  useEffect(() => {
    changeSeries(selectedProps, series);
  }, [series]);

  useEffect(() => {
    createChart(
      document.querySelector("#chartGlobalTraffic"),
      chartGlobalTraffic,
      "MONTHLY",
      {
        prop: PROPSLABEL[selectedProps],
        colorProp: CHART_MAIN_COLOR[selectedProps],
        maxY: 60000,
        labels: getLabels(selectedProps),
        unit: "day",
      }
    );
    changeSeries(selectedProps, series);
  }, [selectedProps]);

  useEffect(() => {
    if (appState.summaryTests && appState.summaryTests.details) {
      setSeries(appState.summaryTests.details);
      setProps(keepProps(appState.summaryTests.details));
    }
  }, [appState.summaryTests]);

  return (
    <Card className="card">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="6">
            <h5 className="card-category">Cumulative Tests per day</h5>
            <CardTitle tag="h3">DAILY TESTS</CardTitle>
          </Col>
          <Col className="text-left" sm="6">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              {getButtonsList(props)}
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <div className="chart-container">
              <canvas id="chartGlobalTraffic"></canvas>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default GlobalTestsChart;
