import React, { useState, useContext, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Label,
  Form,
  FormGroup,
  Input,
  TabPane,
  NavItem,
  NavLink,
  Button,
  Nav,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import {
  updateSettings,
  generateKeys,
  deleteKeys,
  testSSH,
  resetSSH,
  testHealth,
  resetHealth,
} from "../actions/settingActions";
import {
  createInstance,
  deleteInstance,
  refreshInstance,
  resetInstance,
  updateInstance,
} from "../actions/instanceActions";
import AppContext from "../contexts/appContext";
import CONFIG from "../modules/environment";

import "./Settings.css";
import { titleCase } from "../modules/helper";
import NoHost from "./NoHost";
import ConfirmModal from "./ConfirmModal";
import SettingsPlaceHolder from "./placeholder/SettingsPlaceHolder";
import { copyToClipboard } from "../modules/clipboard";
import { SETTINGS_CATEGORIES } from "../modules/bm";
import BreadCrumbCusto from "./BreadCrumb";

function Settings({ dispatch }) {
  const appState = useContext(AppContext);
  const [verticalTabs, setVerticalTabs] = useState("identification");
  const [settings, setSettings] = useState(appState.settings);
  const [hasChanges, setHasChanges] = useState(false);
  const [alert, setAlert] = useState(null);
  const [singleSelect, setsingleSelect] = useState(null);
  const [modal, setModal] = useStateWithCallbackLazy(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalObject, setModalObject] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmObject, setConfirmObject] = useState(null);

  useEffect(() => {
    setSettings(appState.settings);
    if (hasChanges) {
      refreshInstance(
        appState.user._id,
        appState.currentInstance._id,
        appState.token,
        dispatch,
      );
      setHasChanges(false);
    }
  }, [appState.settings]);

  useEffect(() => {
    if (appState.testSSHResult && appState.testSSHResult.length > 0) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [appState.testSSHResult]);

  useEffect(() => {
    if (appState.testHealthResult && appState.testHealthResult.length > 0) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [appState.testHealthResult]);

  const changeActiveTab = (e, tabName) => {
    e.preventDefault();
    if (hasChanges) {
      setModalObject({
        text:
          " You can't view other settings because you have pending changes in that " +
          "category. Discard these changes to switch to the selected tab or " +
          "Cancel to review your changes.",
        title: "You have pending changes!",
        confirmTitle: "Discard Changes",
        callbackCancel: () => {},
        cancelTitle: "Cancel",
        callback: () => discardAndSwitchToTab(tabName),
      });
      setModalIsOpen(true);
    } else {
      switchToTab(tabName);
    }
  };

  const updateASetting = (setting, newValue) => {
    const newList = settings.map((item) => {
      if (
        item.name === setting.name &&
        item.instanceId === setting.instanceId
      ) {
        const updatedItem = {
          ...item,
          value: newValue,
        };
        return updatedItem;
      }
      return item;
    });
    setSettings(newList);
  };

  const discardAndSwitchToTab = (tabName) => {
    setSettings(appState.settings);
    switchToTab(tabName);
  };

  const switchToTab = (tabName) => {
    setVerticalTabs(tabName);
    setHasChanges(false);
    hideAlert();
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const saveChanges = async (e) => {
    const updatedSettings = settings.filter(
      (item) =>
        item.category === verticalTabs &&
        item.instanceId === appState.currentInstance._id,
    );
    await updateSettings(
      updatedSettings,
      verticalTabs,
      appState.currentInstance._id,
      appState.token,
      dispatch,
    );
    if (e) {
      e.preventDefault();
    }
  };

  const addNewInstance = (e) => {
    createInstance(appState.user._id, appState.token, dispatch);
  };

  const renameInstance = (name) => {
    updateInstance(
      appState.user._id,
      appState.currentInstance._id,
      { name },
      appState.token,
      dispatch,
    );
  };

  const restoreDefault = () => {
    const newList = settings.map((item) => {
      if (item.category === verticalTabs) {
        const updatedItem = {
          ...item,
          value: item.defaultValue,
        };
        return updatedItem;
      }
      return item;
    });
    setSettings(newList);
    setHasChanges(true);
  };

  const getEnumList = (list) => {
    return list.map((item, key) => {
      return (
        <option value={item} key={key}>
          {item}
        </option>
      );
      //return { value: item, label: item };
    });
  };

  const onGenerateKeys = async () => {
    const keySettings = settings.filter(
      (setting) => setting.settingType === "key",
    );
    await saveChanges();
    generateKeys(
      appState.user._id,
      appState.currentInstance._id,
      keySettings,
      appState.token,
      dispatch,
    );
  };

  const onCopyPublicKey = (setting) => {
    copyToClipboard(setting.value);
  };

  const onDeleteKeys = () => {
    deleteKeys(
      appState.user._id,
      appState.currentInstance._id,
      appState.token,
      dispatch,
    );
  };

  const onTestSSH = async () => {
    await saveChanges();
    testSSH(
      appState.user._id,
      appState.currentInstance._id,
      appState.token,
      dispatch,
    );
  };

  const getCategory = () => {
    return SETTINGS_CATEGORIES.map((category, index) => (
      <NavItem key={index}>
        <NavLink
          data-toggle="tab"
          href={`#${category}`}
          className={verticalTabs === category ? "active" : ""}
          onClick={(e) => changeActiveTab(e, category)}
        >
          {titleCase(category)}
        </NavLink>
      </NavItem>
    ));
  };

  const getSettingsFromCategory = () => {
    const role =
      appState.currentInstance && appState.currentInstance.role
        ? appState.currentInstance.role.split("/")[0]
        : "none";
    const settingsForCategory = settings
      .filter(
        (setting) =>
          setting.category === verticalTabs &&
          setting.associatedTo.includes(role),
      )
      .map((setting, index) => (
        <div className="mt-4" key={index}>
          {(setting.settingType === "text" ||
            setting.settingType === "password" ||
            setting.settingType === "number") &&
            setting.visibility === "public" && (
              <>
                <Label>{setting.label} </Label>
                <FormGroup>
                  {setting.settingType === "number" && (
                    <Input
                      autoComplete="new-password"
                      type={setting.settingType}
                      id={setting.name}
                      min={setting.min}
                      max={setting.max}
                      value={setting.value}
                      onChange={(e) => {
                        updateASetting(setting, Number(e.target.value));
                        setHasChanges(true);
                      }}
                    />
                  )}

                  {(setting.settingType === "text" ||
                    setting.settingType === "password") && (
                    <Input
                      autoComplete="new-password"
                      type={setting.settingType}
                      id={setting.name}
                      maxLength={setting.max}
                      value={setting.value}
                      onChange={(e) => {
                        updateASetting(setting, e.target.value);
                        setHasChanges(true);
                      }}
                    />
                  )}
                  <span className="form-description">
                    {setting.description}
                  </span>
                </FormGroup>
              </>
            )}

          {setting.settingType === "key" && setting.visibility === "public" && (
            <>
              <Label>{setting.label}</Label>
              <FormGroup>
                <Input
                  autoComplete="new-password"
                  type="text"
                  id={setting.name}
                  value={setting.value}
                  disabled
                />
                <span
                  className="form-description"
                  style={{ display: "block", marginBottom: "4px" }}
                >
                  {setting.description}
                </span>
                <div style={{ display: "flex" }}>
                  {setting.value.length === 0 && (
                    <Button
                      onClick={(e) => onGenerateKeys(setting)}
                      color="success"
                    >
                      Generate
                    </Button>
                  )}
                  {setting.value.length > 0 && (
                    <>
                      <Button
                        onClick={(e) => onCopyPublicKey(setting)}
                        color="secondary"
                      >
                        Copy
                      </Button>
                      <Button
                        onClick={(e) => onDeleteKeys(setting)}
                        color="secondary"
                      >
                        Remove
                      </Button>
                    </>
                  )}
                  {(appState.deleteKeysInProgress ||
                    appState.generateKeysInProgress) && (
                    <span className="ml-2 mr-2">
                      <Spinner
                        children={null}
                        color="primary"
                        size="sm"
                        className="mt-3"
                      />
                    </span>
                  )}
                </div>
              </FormGroup>
            </>
          )}

          {setting.settingType === "boolean" &&
            setting.visibility === "public" && (
              <>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id={setting.name}
                      checked={setting.value}
                      onClick={(e) => {}}
                      onChange={(e) => {
                        updateASetting(setting, !setting.value);
                        setHasChanges(true);
                      }}
                    />
                    <span className="form-check-sign" />
                    {setting.label}
                  </Label>
                </FormGroup>
                <span className="form-description">{setting.description}</span>
              </>
            )}

          {setting.settingType === "enum" &&
            setting.visibility === "public" && (
              <>
                <Label>{setting.label}</Label>
                <FormGroup>
                  <Input
                    name="select"
                    type="select"
                    className="form-control"
                    value={setting.value}
                    onChange={(e) => {
                      setsingleSelect(e.target.value);
                      updateASetting(setting, e.target.value);
                      setHasChanges(true);
                    }}
                  >
                    {getEnumList(setting.enum)}
                  </Input>
                </FormGroup>
                <span className="form-description">{setting.description}</span>
              </>
            )}
        </div>
      ));

    return (
      <TabPane tabId={verticalTabs}>
        <h3>
          {verticalTabs.toUpperCase()}
          {verticalTabs === "ssh" && (
            <>
              <Button
                className="ml-2"
                size="sm"
                color="secondary"
                onClick={() => {
                  onTestSSH();
                }}
              >
                Test
              </Button>
              {appState.testSSHInProgress && (
                <Spinner
                  children={null}
                  color="primary"
                  size="sm"
                  className="ml-2 mb-2"
                />
              )}
              <Modal
                isOpen={modal}
                toggle={() => {
                  setModal(false, () => {
                    setTimeout(() => {
                      resetSSH(dispatch);
                    }, 1000);
                  });
                }}
                size={appState.testSSHResult === "ok" ? "sm" : "lg"}
              >
                <ModalHeader
                  className="justify-content-center"
                  toggle={() => {
                    setModal(false, () => {
                      setTimeout(() => {
                        resetSSH(dispatch);
                      }, 1000);
                    });
                  }}
                >
                  {appState.testSSHResult === "ok"
                    ? "Well done!"
                    : "Whoops, something goes wrong!"}
                </ModalHeader>
                <ModalBody>
                  {appState.testSSHResult === "ok" && (
                    <>
                      <p className="text-light">
                        Your SSH connection to the host is operational!
                      </p>
                    </>
                  )}
                  {appState.testSSHResult !== "ok" && (
                    <>
                      <p className="text-danger">
                        Your SSH connection failed to connect to the host!
                      </p>
                      <blockquote>
                        <i className="text-danger">{appState.testSSHResult}</i>
                      </blockquote>
                      <p>
                        Please check your settings as well as your host
                        configuration.
                      </p>
                      <p className="text-light">
                        <small>
                          <b>Workaround</b>: SSH version > 8.7 is not yet
                          supported. To overcome this issue:
                          <br />
                          - Connect to the server
                          <br />- Edit the file `/etc/ssh/sshd_config`, set the
                          property <i>PubkeyAcceptedKeyTypes=+ssh-rsa</i>
                          <br />
                          - Restart the service sshd.
                          <br />
                        </small>
                      </p>
                    </>
                  )}
                </ModalBody>
              </Modal>
            </>
          )}
        </h3>
        <Form action="#">{settingsForCategory}</Form>
        <div className="mt-5">
          <Button color="success" onClick={(e) => restoreDefault(e)}>
            Restore default values
          </Button>
          <Button
            color={hasChanges ? "primary" : "secondary"}
            disabled={appState.updateSettingsInProgress}
            onClick={(e) => saveChanges(e)}
          >
            Save
          </Button>
          {appState.updateSettingsInProgress && (
            <Spinner
              children={null}
              color="primary"
              size="sm"
              className="ml-2"
            />
          )}
        </div>
      </TabPane>
    );
  };

  const onDeleteInstance = async (e) => {
    await deleteInstance(
      appState.user._id,
      appState.currentInstance._id,
      appState.token,
      dispatch,
    );
  };

  const onResetInstance = async (e) => {
    await resetInstance(
      appState.user._id,
      appState.currentInstance._id,
      appState.token,
      dispatch,
    );
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <SettingsPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={dispatch}
              instance={appState.currentInstance}
              env={appState.currentInstance?.env}
              name={null}
            />
            {alert}
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Card>
                  <CardHeader>
                    <h5
                      className="card-category"
                      style={{ textTransform: "none" }}
                    >
                      Settings
                    </h5>
                    <CardTitle tag="h3">Main</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="3" lg="2">
                        <Nav className="nav-pills-info flex-column" pills>
                          <TabPane tabId={verticalTabs}>
                            <Form action="#">
                              <NavItem key={0}>
                                <NavLink data-toggle="tab" className="active">
                                  Global
                                </NavLink>
                              </NavItem>
                            </Form>
                          </TabPane>
                        </Nav>
                      </Col>
                      <Col md="1" lg="1"></Col>
                      <Col md="8" lg="9">
                        <>
                          <Label>Name</Label>
                          <FormGroup>
                            <Input
                              autoComplete="new-password"
                              type="text"
                              disabled={true}
                              maxLength="64"
                              value={appState.currentInstance.name}
                              onChange={(e) => {}}
                            />
                            <span
                              className="form-description"
                              style={{ display: "block", marginBottom: "4px" }}
                            >
                              Name of the host. Could be anything.
                            </span>
                            <Button
                              size="sm"
                              color="warning"
                              onClick={() => {
                                setModalObject({
                                  text: "Enter a new name for this host?",
                                  title: "Host Name",
                                  callback: renameInstance,
                                  input: appState.currentInstance.name,
                                });
                                setModalIsOpen(true);
                              }}
                            >
                              rename
                            </Button>
                          </FormGroup>
                        </>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="4" lg="3"></Col>
                      <Col md="8" lg="9">
                        <>
                          <Label>Role</Label>
                          <FormGroup>
                            <Input
                              type="text"
                              disabled={true}
                              maxLength="64"
                              value={appState.currentInstance.role}
                              onChange={(e) => {}}
                            />
                            <span
                              className="form-description"
                              style={{ display: "block", marginBottom: "4px" }}
                            >
                              Role of the Host.
                            </span>
                          </FormGroup>
                        </>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Card>
                  <CardHeader>
                    <h5
                      className="card-category"
                      style={{ textTransform: "none" }}
                    >
                      Settings
                    </h5>
                    <CardTitle tag="h3">Parameters</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p className="mb-5 text-muted">
                      Configure your host here and let TurnCafe do the rest for
                      you!
                    </p>
                    <Row>
                      <Col md="3" lg="2">
                        <Nav className="nav-pills-info flex-column" pills>
                          <TabPane tabId={verticalTabs}>
                            <Form action="#">{getCategory()}</Form>
                          </TabPane>
                        </Nav>
                      </Col>
                      <Col md="1" lg="1"></Col>
                      <Col md="8" lg="9">
                        <h4>
                          {appState.updateInstanceInProgress && (
                            <Spinner
                              children={null}
                              className="mr-2"
                              size="sm"
                            />
                          )}
                        </h4>
                        {getSettingsFromCategory()}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr className="card-separator" />
                    <div style={{ display: "flex" }}>
                      {!hasChanges && (
                        <p className="text-info mt-4" style={{ flex: 1 }}>
                          <i className="icon cafe-bullet changeButton" /> No
                          pending changes
                        </p>
                      )}
                      {hasChanges && (
                        <p className="text-warning mt-4" style={{ flex: 1 }}>
                          <i className="icon cafe-change changeButton" /> Some
                          changes have been done
                        </p>
                      )}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Card>
                  <CardHeader>
                    <h5
                      className="card-category"
                      style={{ textTransform: "none" }}
                    >
                      Special actions
                    </h5>
                    <CardTitle tag="h3">Danger Zone</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-left">Commands</th>
                          <th className="text-right">Description</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Reset statistics</td>
                          <td className="text-right">
                            Remove all statistics of this host. This action is
                            not reversible.
                          </td>
                          <td className="text-right">
                            {appState.removeInstanceInProgress && (
                              <Spinner
                                className="ml-2"
                                size="sm"
                                children={null}
                              />
                            )}
                            <Button
                              color="warning"
                              size="sm"
                              onClick={() => {
                                setModalObject({
                                  text: "The statistics of this host will be deleted. There is no going back. Are you sure?",
                                  title: "Delete Statistics",
                                  callback: onResetInstance,
                                });
                                setModalIsOpen(true);
                              }}
                            >
                              Reset Statistics
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Delete this host</td>
                          <td className="text-right">
                            Delete this host and all the associated data. This
                            action is not reversible.
                          </td>
                          <td className="text-right">
                            {appState.removeInstanceInProgress && (
                              <Spinner
                                className="ml-2"
                                size="sm"
                                children={null}
                              />
                            )}
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => {
                                setModalObject({
                                  text: "This host and all associated data will be deleted. There is no going back. Are you sure?",
                                  title: "Delete Host",
                                  callback: onDeleteInstance,
                                });
                                setModalIsOpen(true);
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      {modalIsOpen && (
        <ConfirmModal
          open={modalIsOpen}
          size={"lg"}
          input={modalObject ? modalObject.input : null}
          text={modalObject ? modalObject.text : ""}
          title={modalObject ? modalObject.title : ""}
          cancelTitle={modalObject ? modalObject.cancelTitle : ""}
          cancel={(value) => {
            setModalIsOpen(false);
            if (modalObject && modalObject.callbackCancel) {
              modalObject.callbackCancel(value);
            }
          }}
          confirmTitle={modalObject ? modalObject.confirmTitle : ""}
          confirm={(value) => {
            setModalIsOpen(false);
            if (modalObject && modalObject.callback) {
              modalObject.callback(value.input);
            }
          }}
        />
      )}
    </div>
  );
}

export default Settings;
