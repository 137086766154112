import { log } from "../modules/logger";
import { CDR_ACTIONS } from "./constants";
import { getCdr, getParameters } from "../modules/status";

const moduleName = "cdrActions";

export const getCDRForUser = async (userId, token, dispatch) => {
  log(moduleName, "get cdr", { userId });
  dispatch({
    type: CDR_ACTIONS.GET_CDR_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getCdr(userId, token);

    if (data) {
      dispatch({
        type: CDR_ACTIONS.GET_CDR_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: CDR_ACTIONS.GET_CDR_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: CDR_ACTIONS.GET_CDR_FAILED,
      payload: { value: null },
    });
  }
};
