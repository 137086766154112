import {error, log} from "../modules/logger";
import {createHeader} from "./helper";
import CONFIG from "./environment";
import axios from "axios";

const moduleName = "mod-status";

export const getCalls = async (page, token) => {
  try {
    const response = await axios.get(`${CONFIG.restUrl}/calls?page=${page}`, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "calls received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/calls?page=${page}`, {error: err});
    return null;
  }
};

export const getAllCallsTraffic = async (from, token) => {
  try {
    const response = await axios.get(
      `${CONFIG.restUrl}/calls/last?from=${from}`,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "calls traffic received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/calls/last?from=${from}`, {error: err});
    return null;
  }
};

export const getAllCallsBytesTraffic = async (from, token) => {
  try {
    const response = await axios.get(
      `${CONFIG.restUrl}/calls/byteslast?from=${from}`,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "calls traffic bytes received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/calls/byteslast?from=${from}`, {
      error: err,
    });
    return null;
  }
};

export const getAllSettings = async (instanceId, token) => {
  try {
    const response = await axios.get(
      `${CONFIG.restUrl}/instances/${instanceId}/settings`,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "settings received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/instances/${instanceId}/settings`, {
      error: err,
    });
    return null;
  }
};

export const getAllCustomization = async (token) => {
  try {
    const response = await axios.get(`${CONFIG.restUrl}/customization`, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "customization received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/customization`, {
      error: err,
    });
    return null;
  }
};

export const launchService = async (
  userId,
  instanceId,
  serviceName,
  token,
  toReset = false,
) => {
  try {
    let url = `${CONFIG.restUrl}/instances/${instanceId}/services/${serviceName}`;
    if (toReset) {
      url = `${url}?reset=true`;
    }
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      if (toReset) {
        log(moduleName, "service reset", {data: response.data.data});
      } else {
        log(moduleName, "service started", {data: response.data.data});
      }
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/instances/${instanceId}/services/${serviceName}`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const getAllInstances = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances${
      instanceId ? `/${instanceId}` : ""
    }`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "instances received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/instances`, {
      error: err,
    });
    return null;
  }
};

export const getOutagesForUser = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/outages`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Outages received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/outages`, {
      error: err,
    });
    return null;
  }
};

export const getOutagesForInstanceForAPeriod = async (
  userId,
  instanceId,
  startDate,
  endDate,
  token,
) => {
  try {
    const url = `${
      CONFIG.restUrl
    }/users/${userId}/outages/${instanceId}?startdate=${startDate
      .toUTC()
      .toISO()}&endDate=${endDate.toUTC().toISO()}`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Outages received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/outages/${instanceId}`, {
      error: err,
    });
    return null;
  }
};

export const getLastMonthSLA = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/${
      instanceId ? "instances/" + instanceId + "/sla" : "sla"
    }`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "SLAs received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/instances/${
        instanceId ? instanceId + "/sla" : "sla"
      }`,
      {error: err},
    );
    return null;
  }
};

export const createNewInstance = async (userId, token, name, role) => {
  try {
    const response = await axios.post(
      `${CONFIG.restUrl}/users/${userId}/instances`,
      {name, role},
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "instances received", {data: response.data.data});
      return {
        instances: response.data.data,
        instance: response.data.instance,
      };
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't POST /api/users/${userId}/instances`, {
      error: err,
    });
    return null;
  }
};

export const removeInstance = async (userId, instanceId, token) => {
  try {
    const response = await axios.delete(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}`,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "instances received", {data: response.data.data});
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't DELETE /api/users/${userId}/instances/${instanceId}`,
      {error: err},
    );
    return null;
  }
};

export const resetInstanceStatistics = async (userId, instanceId, token) => {
  try {
    const response = await axios.delete(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/reset`,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "instances received", {data: response.data.data});
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}/reset`,
      {error: err},
    );
    return null;
  }
};

export const updateAnInstance = async (userId, instanceId, params, token) => {
  try {
    const response = await axios.put(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}`,
      params,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "update instance result", {data: response.data.data});
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}`,
      {error: err},
    );
    return null;
  }
};

export const updateServiceFrequencyForInstance = async (
  userId,
  instanceId,
  service,
  frequency,
  token,
) => {
  try {
    const params = {
      service,
      frequency,
    };

    const response = await axios.put(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/service/frequency`,
      params,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "update instance service frequency result", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}`,
      {error: err},
    );
    return null;
  }
};

export const updateMonitoring = async (
  userId,
  instanceId,
  toMonitor,
  token,
) => {
  try {
    const response = await axios.put(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/monitor`,
      {enable: toMonitor},
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "monitoring result received", {
        data: response.data.data,
      });
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}/monitor`,
      {error: err},
    );
    return err.response.data;
  }
};

export const updateAllSettings = async (
  settings,
  category,
  instanceId,
  token,
) => {
  const data = {
    settings: settings.map((item) => ({
      _id: item._id,
      name: item.name,
      value: item.value,
    })),
    category,
  };

  try {
    const response = await axios.put(
      `${CONFIG.restUrl}/instances/${instanceId}/settings`,
      data,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "settings received", {data: response.data.data});
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't PUT /api/instances/${instanceId}/settings`, {
      error: err,
    });
    return null;
  }
};

export const generateKeysForInstance = async (
  userId,
  instanceId,
  settings,
  token,
) => {
  const data = settings;

  try {
    const response = await axios.post(
      `${CONFIG.restUrl}/instances/${instanceId}/settings/keys`,
      data,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "keys generated, settings received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't POST /api/instances/${instanceId}/settings/keys`, {
      error: err,
    });
    return null;
  }
};

export const deleteKeysForInstance = async (userId, instanceId, token) => {
  try {
    const response = await axios.delete(
      `${CONFIG.restUrl}/instances/${instanceId}/settings/keys`,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "keys deleted, settings received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't DELETE /api/instances/${instanceId}/settings/keys`,
      {error: err},
    );
    return null;
  }
};

export const testSSHForInstance = async (userId, instanceId, token) => {
  try {
    const response = await axios.get(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/turn`,
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "tested ssh ok", {
        data: response.data.data,
      });
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/instances/${instanceId}/turn`,
      {error: err.response.data.error},
    );
    return err.response.data;
  }
};

export const getAllEvents = async (userId, instanceId, page, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/events?page=${page}`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "events received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/instances${instanceId}/events`,
      {error: err},
    );
    return [];
  }
};

export const updateCoturn = async (userId, instanceId, toStart, token) => {
  try {
    const response = await axios.put(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/enable`,
      {enable: toStart},
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "coturn result received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}/monitor`,
      {error: err},
    );
    return [];
  }
};

export const executeTURNAction = async (userId, instanceId, action, token) => {
  try {
    const response = await axios.put(
      `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/action`,
      {action},
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "coturn result received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}/action`,
      {error: err},
    );
    return [];
  }
};

export const getSeriesTrendsForInstance = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/series/${instanceId}/trends`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Series received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/series/${instanceId}/trends`, {
      error: err,
    });
    return null;
  }
};

export const getSeriesTimelineForInstance = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/series/${instanceId}/timeline`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "timeline received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/series/${instanceId}/timeline`, {
      error: err,
    });
    return null;
  }
};

export const getPagedSeriesForAnInstance = async (
  userId,
  instanceId,
  page,
  perPage,
  service,
  result,
  upTo,
  token,
) => {
  try {
    const url = `${
      CONFIG.restUrl
    }/users/${userId}/series/${instanceId}?page=${page}&perpage=${perPage}${
      service !== "all" ? `&service=${service}` : ""
    }${result !== "all" ? `&result=${result}` : ""}&upto=${upTo}`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Paged series received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/series/${instanceId}`, {
      error: err,
    });
    return null;
  }
};

export const getSeriesForInstances = async (userId, result = 30, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/series?result=${result}`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Paged series received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}`, {
      error: err,
    });
    return null;
  }
};

export const getSeriesTestingForInstanceForAllPeriod = async (
  userId,
  instanceId,
  token,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/series/${instanceId}/testing`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Series received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/series/${instanceId}/testing`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const createSeriesForInstanceForAPeriod = async (
  userId,
  instanceId,
  ts,
  category,
  status,
  series,
  token,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/series/${instanceId}`;
    const response = await axios.post(
      url,
      {
        ts,
        category,
        status,
        series,
      },
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Series received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't POST /api/users/${userId}/series/${instanceId}`, {
      error: err,
    });
    return null;
  }
};

export const getSummaryForInstanceForAPeriod = async (
  userId,
  instanceId,
  token,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/summary/${instanceId}/usage`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Summary usage received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/summary/${instanceId}/usage`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const getSummaryForAPeriod = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/summary/status`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Summary usage overall received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/summary/status`, {
      error: err,
    });
    return null;
  }
};

export const getSummaryHealthForInstanceForAPeriod = async (
  userId,
  instanceId,
  token,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/summary/${instanceId}/health`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Summary health received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/summary/${instanceId}/health`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const getSummaryTestsForInstanceForAPeriod = async (
  userId,
  instanceId,
  token,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/summary/${instanceId}/tests`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Summary tests received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/summary/${instanceId}/tests`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const getSummaryResultForAPeriod = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/summary/${instanceId}/status`;
    const response = await axios.get(url, {headers: createHeader(token)});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Summary result received", {data: response.data.data});
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/summary/${instanceId}/status`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const launchQuickTest = async (
  userId,
  instanceId,
  token,
  limitedUrl,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/quick`;

    const response = await axios.post(
      url,
      {url: limitedUrl},
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Quick result received", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't POST /api/users/${userId}/instances/${instanceId}/quick`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const createLink = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/links`;

    const response = await axios.post(
      url,
      {},
      {headers: createHeader(token)},
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Link result received", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't POST /api/users/${userId}/instances/${instanceId}/links`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const updateLink = async (userId, instanceId, linkId, json, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/links/${linkId}`;

    const response = await axios.put(url, json, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Link result received", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}/links/${linkId}`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const getLinks = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/links`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Link result received", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/instances/${instanceId}/links`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const deleteLink = async (userId, instanceId, linkId, json, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/links/${linkId}`;

    const response = await axios.delete(url, json, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Link deleted", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't DELETE /api/users/${userId}/instances/${instanceId}/links/${linkId}`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const updateParameters = async (userId, params, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/parameters`;

    const response = await axios.put(url, params, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Parameters result received", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't PUT /api/users/${userId}/parameters`, {
      error: err,
    });
    return null;
  }
};

export const getNewToken = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/parameters/token`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Parameters token result received", {
        data: response.data,
      });
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/parameters/token`, {
      error: err,
    });
    return null;
  }
};

export const getParameters = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/parameters`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Parameters result received", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/parameters`, {
      error: err,
    });
    return null;
  }
};

export const getCdr = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/cdr`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "CDR result received", {data: response.data});
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/cdr`, {
      error: err,
    });
    return null;
  }
};

export const getLastVersionForAllHosts = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/versions/last`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Last version for all hosts result received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/versions/last`, {
      error: err,
    });
    return null;
  }
};

export const getVersionsForHost = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/versions`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "All versions for host result received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/instances/${instanceId}/versions`, {
      error: err,
    });
    return null;
  }
};

export const getLastUsageHosts = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/summary/last`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Last usage for all hosts result received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/summary/last`, {
      error: err,
    });
    return null;
  }
};

export const getTodayStatusHosts = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/summary/today`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "today status received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/summary/today`, {
      error: err,
    });
    return null;
  }
};

export const getOpsForHost = async (userId, instanceId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/ops`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });

    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Last ops for for host result received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/instances/${instanceId}/ops`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const createOperation = async (userId, instanceId, data, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/operations`;

    const response = await axios.post(url, data, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Operation result received", {data: response.data});
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't POST /api/users/${userId}/instances/${instanceId}/operations`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const updateOperation = async (
  userId,
  instanceId,
  operationId,
  data,
  token,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/operations/${operationId}`;

    const response = await axios.put(url, data, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Operation result received", {data: response.data});
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't PUT /api/users/${userId}/instances/${instanceId}/operations/${operationId}`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const deleteOperation = async (
  userId,
  instanceId,
  operationId,
  perPage,
  token,
) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/operations/${operationId}?page=0&perPage=${perPage}`;

    const response = await axios.delete(url, {
      headers: createHeader(token),
    });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Operation result received", {data: response.data});
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't DELETE /api/users/${userId}/instances/${instanceId}/operations/${operationId}`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const getHostOperations = async (userId, instanceId, page = -1, perPage = 20, token) => {
  try {
    let url = `${CONFIG.restUrl}/users/${userId}/instances/${instanceId}/operations`;

    if (page !== -1) {
      url += `?page=${page}&perPage=${perPage}`;
    }

    const response = await axios.get(url, {
      headers: createHeader(token),
    });

    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Operations result received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(
      moduleName,
      `can't GET /api/users/${userId}/instances/${instanceId}/operations`,
      {
        error: err,
      },
    );
    return null;
  }
};

export const getAllOperations = async (userId, token) => {
  try {
    const url = `${CONFIG.restUrl}/users/${userId}/operations`;

    const response = await axios.get(url, {
      headers: createHeader(token),
    });

    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Operations for all hosts result received", {
        data: response.data.data,
      });
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/users/${userId}/operations`, {
      error: err,
    });
    return null;
  }
};
