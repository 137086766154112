import React, { useContext, useState } from "react";
import classNames from "classnames";

import {
  Button,
  UncontrolledTooltip,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Label,
} from "reactstrap";

import AppContext from "../contexts/appContext";
import { logout } from "../actions/userActions";
import { USER_STATE } from "../reducers/appReducer";

import "./AdminNavbar.css";
import { Link, useHistory } from "react-router-dom";

const AdminNavbar = (props) => {
  const [color, setColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const history = useHistory();
  const appState = useContext(AppContext);

  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };

  const onLogout = () => {
    logout(props.dispatch);
  };

  return (
    <>
      <Navbar
        style={{ position: "fixed" }}
        className={classNames("navbar-absolute", {
          [color]: false,
        })}
        expand="lg"
      >
        <div className="navbar-wrapper">
          <div
            className={classNames("navbar-toggle d-inline", {
              toggled: props.sidebarOpened,
            })}
          >
            <button
              className="navbar-toggler"
              type="button"
              onClick={props.toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <NavbarBrand className="brandIcon">
            <Nav>
              <div>
                {`${props.brandText}`}
                <i className="icon cafe-cafe pt-2 ml-1 mr-0" />
              </div>
            </Nav>
          </NavbarBrand>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        {appState.userState === USER_STATE.SIGNED && (
          <>
            <Collapse navbar isOpen={collapseOpen}>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="photo">
                      <Label className="photoName">
                        {appState.user.username.substring(0, 1)}
                      </Label>
                    </div>
                    <p className="d-lg-none">Log out</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" end tag="ul">
                    <DropdownItem header>Settings</DropdownItem>
                    <DropdownItem
                      className="nav-item"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        history.push("/profile");
                      }}
                    >
                      <i className="icon cafe-profile action-button-image" />{" "}
                      Account
                    </DropdownItem>
                    {appState &&
                      appState.userState === USER_STATE.SIGNED &&
                      appState.user.role === "admin" && (
                        <>
                          <DropdownItem header>Administration</DropdownItem>
                          <DropdownItem
                            className="nav-item"
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: "smooth" });
                              history.push("/admin");
                            }}
                          >
                            <i className="icon cafe-admin action-button-image" />{" "}
                            Admin
                          </DropdownItem>
                        </>
                      )}
                    <DropdownItem divider />
                    <DropdownItem
                      className="nav-item"
                      onClick={() => {
                        onLogout();
                      }}
                    >
                      Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </>
        )}
      </Navbar>
    </>
  );
};

export default AdminNavbar;
