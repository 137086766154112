import { Row, Col } from "reactstrap";

import CallsTable from './CallsTable';
import CallLegsTraffic from './CallLegsTraffic';
import CallBytesTraffic from './CallBytesTraffic';


function Calls({ dispatch }) {

  return (
    <div className="content">
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <CallLegsTraffic dispatch={dispatch} />
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <CallBytesTraffic dispatch={dispatch} />
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <CallsTable dispatch={dispatch} />
        </Col>
      </Row>
    </div>
  )
}

export default Calls;
