import logdown from "logdown";
import { report, download } from "./reporter";

window.localStorage.debug = "*";

const addTimestamp = (str) => {
  let d = new Date().toLocaleTimeString();
  return `${d} ${str}`;
};

const addHeader = (modName, str) => {
  return `[coturn] ${modName} ${str}`;
};

let _reporter = null;

export const setReporter = (reporter) => {
  _reporter = reporter;
};

export const log = (mod, text, data) => {
  if (data) {
    logdown(addTimestamp(addHeader(mod, ""))).log(text, data);
  } else {
    logdown(addTimestamp(addHeader(mod, ""))).log(text);
  }

  if (_reporter) {
    report(_reporter, mod, text, data);
  }
};

export const info = (mod, text, data) => {
  if (data) {
    logdown(addTimestamp(addHeader(mod, ""))).info(text, data);
  } else {
    logdown(addTimestamp(addHeader(mod, ""))).info(text);
  }
  if (_reporter) {
    report(_reporter, mod, text, data);
  }
};

export const error = (mod, text, data) => {
  if (data) {
    logdown(addTimestamp(addHeader(mod, ""))).error(text, data);
  } else {
    logdown(addTimestamp(addHeader(mod, ""))).error(text);
  }

  if (_reporter) {
    report(_reporter, mod, text, data);
  }
};

export const warning = (mod, text, data) => {
  if (data) {
    logdown(addTimestamp(addHeader(mod, ""))).warn(text, data);
  } else {
    logdown(addTimestamp(addHeader(mod, ""))).warn(text);
  }

  if (_reporter) {
    report(_reporter, mod, text, data);
  }
};

export const saveLogsToFile = () => {
  download(_reporter);
};
