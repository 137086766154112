import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import AppContext from "../../contexts/appContext";

import NoHost from "../NoHost";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";

import UsageChart from "../charts/UsageChart";

import "./Host.css";
import CardNumber from "../CardNumber";
import { DateTime, Duration } from "luxon";
import HealthChart from "../charts/HealthChart";
import TestsChart from "../charts/TestsChart";
import { HOST_SERVICE } from "../../modules/bm";
import BreadCrumbCusto from "../BreadCrumb";

function HostStatistics(props) {
  const appState = useContext(AppContext);
  const [health, setHealth] = useState([]);
  const [usage, setUsage] = useState([]);
  const [tests, setTests] = useState([]);
  const [rate, setRate] = useState(null);

  useEffect(() => {
    if (appState.series) {
      setHealth(appState.series[HOST_SERVICE.HEALTH] || []);
      setUsage(appState.series[HOST_SERVICE.USAGE] || []);
      setTests(appState.series[HOST_SERVICE.TESTS] || []);
      setRate(appState.series["rate"] || null);
    }
  }, [appState.series]);

  const averageDailyUsers = (s) => {
    return s.reduce((a, c) => a + c.users, 0);
  };

  const averageDailyTraffic = (s) => {
    return +s.reduce((a, c) => a + c.sent + c.received, 0).toFixed(0);
  };

  const averageDailyDuration = (s) => {
    const minutes = s.reduce((a, c) => a + c.duration, 0);
    const duration = Duration.fromObject({ minutes });
    return +duration.as("minutes").toFixed(0);
  };

  const averageDailyConferences = (s) => {
    return s.reduce((a, c) => a + c.conferences, 0);
  };

  const averageExecutionTime = (series) => {
    if (series.length === 0) {
      return "-";
    }
    return +(
      series.reduce((a, c) => a + c.executionTime, 0) / series.length
    ).toFixed(0);
  };

  const averageScore = (series) => {
    if (series.length === 0) {
      return "-";
    }
    return +(series.reduce((a, c) => a + c.score, 0) / series.length).toFixed(
      0,
    );
  };

  const getUptime = (series) => {
    if (series.length === 0) {
      return "-";
    }

    let from = null;
    series.forEach((serie) => {
      if (serie.uptime) {
        const last = DateTime.fromJSDate(new Date(serie.uptime));
        if (last > from) {
          from = last;
        }
      }
    });

    const diff = DateTime.now().diff(from, "days");
    return +diff.toObject().days.toFixed(0);
  };

  const getSuccessRate = (rate, service) => {
    if (!rate) {
      return 100;
    }
    const result = rate[service];
    return +Math.min(result.rate * 100, 100).toFixed(2);
  };

  const getVersion = (series) => {
    if (series.length === 0) {
      return "-";
    }
    let version = "-";
    series.forEach((serie) => {
      if (serie.version !== "-") {
        version = serie.version.split("-")[0];
      }
    });
    return version;
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={appState.currentInstance}
              env={appState.currentInstance?.env}
              name={null}
            />
            <Row>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Up time"
                  value={{
                    data: {
                      time: getUptime(health),
                    },
                  }}
                  prop="time"
                  icon="cafe-creation"
                  unit="d"
                  last="Uptime of the host"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Version"
                  value={{
                    data: {
                      version: getVersion(health),
                    },
                  }}
                  prop="version"
                  icon="cafe-creation"
                  unit="u"
                  last="Version of the host"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Execution Time"
                  value={{
                    data: {
                      rtt: averageExecutionTime(health),
                    },
                  }}
                  prop="rtt"
                  icon="cafe-creation"
                  unit="ms"
                  last="Average Execution Time"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Success"
                  value={{
                    data: {
                      success: getSuccessRate(rate, HOST_SERVICE.HEALTH),
                    },
                  }}
                  prop="success"
                  icon="cafe-creation"
                  unit="p"
                  last="Average Success Rate"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <HealthChart />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Users"
                  value={{
                    data: {
                      users: averageDailyUsers(usage),
                    },
                  }}
                  prop="users"
                  icon="cafe-creation"
                  unit="u"
                  last="Last 5 days (number)"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Conferences"
                  value={{
                    data: {
                      conferences: averageDailyConferences(usage),
                    },
                  }}
                  prop="conferences"
                  icon="cafe-creation"
                  unit="u"
                  last="Last 5 days (number)"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Duration"
                  value={{
                    data: {
                      duration: averageDailyDuration(usage),
                    },
                  }}
                  prop="duration"
                  icon="cafe-creation"
                  unit="min"
                  last="Last 5 days (hours)"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Bandwidth"
                  value={{
                    data: {
                      traffic: averageDailyTraffic(usage),
                    },
                  }}
                  prop="traffic"
                  icon="cafe-creation"
                  unit="MB"
                  last="Last 5 days (MBytes)"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <UsageChart />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12"></Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Execution Time"
                  value={{
                    data: {
                      time: averageExecutionTime(tests),
                    },
                  }}
                  prop="time"
                  icon="cafe-creation"
                  unit="ms"
                  last="Average Execution Time"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Score"
                  value={{
                    data: {
                      time: averageScore(tests),
                    },
                  }}
                  prop="time"
                  icon="cafe-creation"
                  unit="p"
                  last="Average Score"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="Success"
                  value={{
                    data: {
                      time: getSuccessRate(rate, HOST_SERVICE.TESTS),
                    },
                  }}
                  prop="time"
                  icon="cafe-creation"
                  unit="p"
                  last="Average Success Rate"
                  badge={false}
                  badgeColor="blue"
                />
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <TestsChart />
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default HostStatistics;
