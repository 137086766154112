import { log } from "../modules/logger";
import { initialize, call, hangup } from "../modules/p2p";
import {
  getAllCallsBytesTraffic,
  getAllCallsTraffic,
  getAllSettings,
  getCalls,
  getStatus,
  updateAllSettings,
  getAllInstances,
  createNewInstance,
  removeInstance,
} from "../modules/status";
import { ACTIONS, APP_ACTIONS } from "./constants";

const moduleName = "appActions";

export const launchCall = async (
  local,
  remote,
  settings,
  instanceId,
  testType,
  limitedUrl,
  dispatch,
) => {
  log(moduleName, "execute action", { action: "status" });
  dispatch({
    type: ACTIONS.CALL_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    await initialize(local, remote, settings, instanceId);
  } catch {
    hangup();
    dispatch({
      type: ACTIONS.CALL_AUTHORIZATION_FAILED,
      payload: { value: null },
    });
  }

  dispatch({
    type: ACTIONS.CALL_AUTHORIZATION_SUCCESS,
    payload: { value: null },
  });

  try {
    await call(testType, limitedUrl, dispatch);
  } catch {
    hangup();
    dispatch({
      type: ACTIONS.CALL_CANDIDATE_FAILED,
      payload: { value: null },
    });
  }
};

export const terminateCall = async (withError, dispatch) => {
  hangup();
  dispatch({
    type: ACTIONS.TERMINATE_CALL,
    payload: { value: { withError } },
  });
};

export const failCall = async (dispatch) => {
  dispatch({
    type: ACTIONS.CALL_CANDIDATE_FAILED,
    payload: { value: {} },
  });
};

export const resetCall = async (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_CALL,
    payload: { value: null },
  });
};

export const listCalls = async (page, token, dispatch) => {
  dispatch({
    type: ACTIONS.LIST_CALLS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getCalls(page, token);

    if (data) {
      dispatch({
        type: ACTIONS.LIST_CALLS_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: ACTIONS.LIST_CALLS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.LIST_CALLS_FAILED,
      payload: { value: null },
    });
  }
};

export const getCallTraffic = async (from, token, dispatch) => {
  dispatch({
    type: ACTIONS.GET_TRAFFIC_CALLS_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getAllCallsTraffic(from, token);

    if (data) {
      dispatch({
        type: ACTIONS.GET_TRAFFIC_CALLS_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_TRAFFIC_CALLS_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_TRAFFIC_CALLS_FAILED,
      payload: { value: null },
    });
  }
};

export const getCallBytesTraffic = async (from, token, dispatch) => {
  dispatch({
    type: ACTIONS.GET_TRAFFIC_CALLS_BYTES_IN_PROGRESS,
    payload: { value: null },
  });

  let data = null;

  try {
    data = await getAllCallsBytesTraffic(from, token);

    if (data) {
      dispatch({
        type: ACTIONS.GET_TRAFFIC_CALLS_BYTES_SUCCESS,
        payload: { value: data },
      });
    } else {
      dispatch({
        type: ACTIONS.GET_TRAFFIC_CALLS_BYTES_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_TRAFFIC_CALLS_BYTES_FAILED,
      payload: { value: null },
    });
  }
};

export const displaySelector = async (dispatch) => {
  dispatch({
    type: APP_ACTIONS.DISPLAY_SELECTOR,
    payload: { value: {} },
  });
};

export const hideSelector = async (dispatch) => {
  dispatch({
    type: APP_ACTIONS.HIDE_SELECTOR,
    payload: { value: {} },
  });
};
