import React, {useContext, useEffect, useRef, useState} from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  Row,
  Col,
  Table,
  Button,
  Badge,
  Spinner,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {DateTime} from "luxon";
import Notify from "react-notification-alert";

import CardNumber from "../CardNumber";
import AppContext from "../../contexts/appContext";

import {
  manualLaunchService,
  monitorInstance,
  resetLaunchService,
  terminateLaunchService,
  unmonitorInstance,
  updateInstanceServiceFrequency,
} from "../../actions/instanceActions";
import {getEvents} from "../../actions/eventActions";
import Select from "react-select";
import NoHost from "../NoHost";
import {
  HOST_CATEGORY,
  HOST_FREQUENCY,
  HOST_SERVICE,
  HOST_STATUS,
  SERVICE_STATUS,
  isMonitored,
  isPaused,
  USER_PROFILE,
  HOST_ROLE,
  isPartiallyMonitored,
  isPendingMonitoring,
  displayHostStatus,
  lastCheckForHost,
  getHostStatusColor,
  getServiceStatusColor
} from "../../modules/bm";

import "./Host.css";
import {getRoleIcon, getValueFromSetting} from "../../modules/helper";
import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import {useStateWithCallbackLazy} from "use-state-with-callback";
import GlobalStatusChart from "../charts/GlobalStatusChart";
import GlobalStatusPieChart from "../charts/GlobalStatusPieChart";
import {
  isRealTimeConnected,
  manualRefreshInstance,
} from "../../modules/realtime";
import BreadCrumbCusto from "../BreadCrumb";

let serviceChange = null;
let timeoutId = null;

function Host(props) {
  const appState = useContext(AppContext);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [host, setHost] = useState(appState.currentInstance);
  const [testsFrequency, setTestsFrequency] = useState(-1);
  const [usageFrequency, setUsageFrequency] = useState(-1);
  const [healthFrequency, setHealthFrequency] = useState(-1);
  const [modal, setModal] = useStateWithCallbackLazy(false);
  const [status, setStatus] = useState({});
  const [ops, setOps] = useState(0);

  const notify = useRef();

  const updateEvents = () => {
    if (host) {
      getEvents(
        appState.user._id,
        host._id,
        page,
        appState.token,
        props.dispatch,
      );
    }
  };

  useEffect(() => {
    if (appState.currentInstance) {
      setHost(appState.currentInstance);
      setTestsFrequency(
        "frequency" in appState.currentInstance[HOST_SERVICE.TESTS]
          ? appState.currentInstance[HOST_SERVICE.TESTS].frequency
          : -1,
      );
      setUsageFrequency(
        "frequency" in appState.currentInstance[HOST_SERVICE.USAGE]
          ? appState.currentInstance[HOST_SERVICE.USAGE].frequency
          : -1,
      );
      if (HOST_SERVICE.HEALTH in appState.currentInstance) {
        setHealthFrequency(
          "frequency" in appState.currentInstance[HOST_SERVICE.HEALTH]
            ? appState.currentInstance[HOST_SERVICE.HEALTH].frequency
            : -1,
        );
      } else {
        setHealthFrequency(-1);
      }

      serviceChange = null;
    }
  }, [appState.currentInstance]);

  useEffect(() => {
    updateEvents();
  }, [host]);

  useEffect(() => {
    updateEvents();
  }, [page]);

  useEffect(() => {
    if (appState.events) {
      setEvents(appState.events);
    }
  }, [appState.events]);

  useEffect(() => {
    if (appState.launchServiceDetails) {
      setModal(true);
    }
  }, [appState.launchServiceDetails]);

  useEffect(() => {
    setStatus(appState.summaryStatus);
  }, [appState.summaryStatus]);

  useEffect(() => {
    if (!appState.launchServiceInProgress) {
      clearTimeout(timeoutId);
    }
  }, [appState.launchServiceInProgress]);

  useEffect(() => {
    setOps(appState.ops);
  }, [appState.ops]);

  const updateMonitoring = async () => {
    if (!host.active) {
      const result = await monitorInstance(
        appState.user._id,
        host._id,
        appState.token,
        props.dispatch,
      );
      if (result && !result.success) {
        var options = {
          place: "tc",
          message: (
            <div>
              <h4>Oups!</h4>
              <p>{result.error.errorMsg}</p>
            </div>
          ),
          type: "warning",
          autoDismiss: 5,
        };
        notify.current.notificationAlert(options);
      }
    } else {
      unmonitorInstance(
        appState.user._id,
        host._id,
        appState.token,
        props.dispatch,
      );
    }
  };

  const isFreeWithMonitoringActivatedOnOtherHost = (host) => {
    if (appState.user.profile !== USER_PROFILE.FREE) {
      return false;
    }

    let hasAnActiveHost = false;
    appState.instances.forEach((instance) => {
      if (instance._id !== host._id && instance.active) {
        hasAnActiveHost = true;
        return;
      }
    });

    return hasAnActiveHost;
  };

  const isIncident = (event) => {
    return event.incident ? "icon cafe-warning text-danger icon-selected" : "";
  };

  const detectNoNextPage = () => {
    return (page + 1) * appState.eventsPerPage >= appState.eventsCount;
  };

  const detectNoPreviousPage = () => {
    return page === 0;
  };

  const goToFirstPage = () => {
    setPage(0);
  };

  const goToLastPage = () => {
    setPage(Math.floor(Math.max(appState.eventsCount - 1, 0) / appState.eventsPerPage));
  };

  const goToNextPage = async () => {
    setPage(page + 1);
  };

  const goToPreviousPage = async () => {
    setPage(page - 1);
  };

  const getConfidence = (host) => {
    const lastCheck = lastCheckForHost(host);
    const lastDate = DateTime.fromISO(lastCheck);
    const diff = lastDate.diffNow(["seconds"]).toObject();
    return Math.abs(diff.seconds);
  };

  const getConfidenceLabel = (lastCheckSeconds) => {
    if (lastCheckSeconds < 600) {
      return "Very High";
    } else if (lastCheckSeconds < 3600 * 2) {
      return "High";
    } else if (lastCheckSeconds < 3600 * 24) {
      return "Medium";
    } else if (lastCheckSeconds < 3600 * 24 * 3) {
      return "Low";
    } else {
      return "No";
    }
  };

  const getConfidenceColor = (lastCheckSeconds) => {
    if (lastCheckSeconds < 3600 * 2) {
      return "blue";
    } else if (lastCheckSeconds < 3600 * 24) {
      return "primary";
    } else if (lastCheckSeconds < 3600 * 24 * 3) {
      return "danger";
    } else {
      return "danger";
    }
  };

  const getLastVersionSeen = (version) => {
    if (version) {
      return `Verified ${DateTime.fromISO(version.lastSeen).toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS,
      )}`;
    }
    return "Not computed yet";
  };

  const getLastMonitoredDateStateForHost = (host) => {
    if (!host) {
      return "Never started";
    }
    return `Since ${DateTime.fromISO(host.activeLast).toLocaleString(
      DateTime.DATETIME_MED_WITH_SECONDS,
    )}`;
  };

  const getLastStatusForHost = (host) => {
    if (!host) {
      return "Never started";
    }
    return `Since ${DateTime.fromISO(host.statusLast).toLocaleString(
      DateTime.DATETIME_MED_WITH_SECONDS,
    )}`;
  };

  const getStatusForHost = (host) => {
    if (!host) {
      return HOST_STATUS.UNKNOWN;
    }

    return displayHostStatus(host.status, host.active);
  };

  const getBadgeColorForHostStatus = (host) => {
    if (!host) {
      return "warning";
    }

    return getHostStatusColor(host.status, host.active);
  };

  const isTestsServiceUsable = (host) => {
    return host && host.active;
  };

  const isUsageServiceUsable = (host) => {
    return host && host.active;
  };

  const isHealthServiceUsable = (host) => {
    return host && host.active;
  };

  const isPartialFrequency = (host) => {
    if (!host) {
      return true;
    }
    return host.category <= HOST_CATEGORY.STARTUP;
  };

  const isFree = (host) => {
    if (!host) {
      return true;
    }
    return host.category === HOST_CATEGORY.FREE;
  };

  const getOptions = (host) => {
    if (!host) {
      return [];
    }
    return HOST_FREQUENCY[host.category];
  };

  const options = getOptions(host);

  const setFrequencyForService = (service, frequency) => {
    if (appState.currentInstance[service].frequency !== frequency) {
      serviceChange = service;
      updateInstanceServiceFrequency(
        appState.user._id,
        appState.currentInstance._id,
        service,
        frequency,
        appState.token,
        props.dispatch,
      );
    }
  };

  const getValueForService = (host, value) => {
    if (!host) {
      return;
    }
    const list = HOST_FREQUENCY[host.category];
    return list.find((option) => option.value === value);
  };

  const getColorForStatus = (host, service) => {
    if (!host) {
      return "light";
    }

    if (host[service].last === host.created) {
      return "light";
    }

    let value = SERVICE_STATUS.UNKNOWN;
    if (service in host) {
      value = host[service].value;
    }

    return getServiceStatusColor(value);
  };

  const getLastRunForAService = (host, service) => {
    if (!host || !(service in host)) {
      return "";
    }
    if (host[service].last === host.created) {
      return "";
    }
    return `at ${DateTime.fromISO(host[service].last).toFormat("ff")}`;
  };

  const getStatusForService = (host, service) => {
    if (!host || !(service in host)) {
      return;
    }
    if (host[service].last === host.created) {
      return "Never launched";
    }
    return host[service].value;
  };

  const customStyles = {
    menuPortal: (provided, state) => ({
      ...provided,
      fontSize: "11px",
    }),
  };

  const manualRun = (host, serviceName, toReset = false) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    if (!isRealTimeConnected()) {
      timeoutId = setTimeout(() => {
        manualRefreshInstance(
          appState.user._id,
          host._id,
          appState.token,
          props.dispatch,
        );
        terminateLaunchService(
          SERVICE_STATUS.UNKNOWN,
          "",
          serviceName,
          props.dispatch,
        );
      }, 10000);
    }

    manualLaunchService(
      appState.user._id,
      host._id,
      serviceName,
      appState.token,
      props.dispatch,
      toReset,
    );
  };

  const isHealthConfigured = (host) => {
    // if (!host) {
    //   return false;
    // }
    // const setting1 = getValueFromSetting(
    //   "serviceabilityHealthCommand",
    //   appState.settings,
    //   host._id
    // );
    //
    // const setting2 = getValueFromSetting(
    //   "serviceabilityHealthUrl",
    //   appState.settings,
    //   host._id
    // );
    //
    // return (
    //   (setting1 && setting1.length > 0) || (setting2 && setting2.length > 0)
    // );
    return true;
  };

  const isTestsConfigured = (host) => {
    if (!host) {
      return false;
    }
    //
    // if (host.role.includes(ROLE.RELAY)) {
    //   const setting1 = getValueFromSetting(
    //     "stunAddress",
    //     appState.settings,
    //     host._id
    //   );
    //
    //   const setting2 = getValueFromSetting(
    //     "turnAddress",
    //     appState.settings,
    //     host._id
    //   );
    //   return setting1 && setting1.length > 0 && setting2 && setting2.length > 0;
    // } else if (host.role.includes(ROLE.MEDIA) || host.role.includes(ROLE.SIG)) {
    //   const setting1 = getValueFromSetting(
    //     "commandUrl",
    //     appState.settings,
    //     host._id
    //   );
    //
    //   const setting2 = getValueFromSetting(
    //     "commandTest",
    //     appState.settings,
    //     host._id
    //   );
    //   return (
    //     (setting1 && setting1.length > 0) || (setting2 && setting2.length > 0)
    //   );
    // }

    return true;
  };

  const isUsageConfigured = (host) => {
    if (!host) {
      return false;
    }
    //
    // const setting1 = getValueFromSetting(
    //   "usageCommand",
    //   appState.settings,
    //   host._id
    // );
    //
    // const setting2 = getValueFromSetting(
    //   "usageUrl",
    //   appState.settings,
    //   host._id
    // );
    //
    // return (
    //   (setting1 && setting1.length > 0) || (setting2 && setting2.length > 0)
    // );
    return true;
  };

  const getEventDetails = (details) => {
    const regexp =
      /changed to ok|changed to success|changed to warning|changed to observation|changed to error|changed to unknown/g;
    const successRegexp = /changed to ok|changed to success/g;
    const warningRegexp = / warning/g;
    const observationRegexp = / observation/g;
    const unknownRegexp = / unknown/g;

    if (regexp.test(details)) {
      const result = successRegexp.test(details)
        ? "success"
        : warningRegexp.test(details)
          ? "warning"
          : observationRegexp.test(details)
            ? "blue"
            : unknownRegexp.test(details)
              ? "light"
              : "danger";
      const label =
        result === "danger" ? "error" : result === "light" ? "unknown" : result === "blue" ? "observation" : result;

      const lastIndex = details.lastIndexOf("changed to");
      const textBefore = details.substring(0, lastIndex + 10);
      const textAfter = details.substring(
        label === "warning" || label === "unknown"
          ? lastIndex + 18
          : label === "error"
            ? lastIndex + 16
            : label === "observation"
              ? lastIndex + 22
              : details.length,
      );
      return (
        <>
          {textBefore}
          <Badge className="ml-2 noMarginBottom" color={result} pill>
            {label}
          </Badge>
          {textAfter}
        </>
      );
    }
    return <span>{details}</span>;
  };

  return (
    <div className="content-top">
      <Notify ref={notify}/>
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder/>}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch}/>
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={host}
              env={host?.env}
            />
            <Row>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="STATUS"
                  value={{
                    data: {status: getStatusForHost(host).toUpperCase()},
                  }}
                  prop="status"
                  icon="cafe-status"
                  unit="u"
                  last={getLastStatusForHost(host)}
                  badge={true}
                  badgeColor={getBadgeColorForHostStatus(host)}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="CONFIDENCE"
                  value={{
                    data: {version: getConfidenceLabel(getConfidence(host))},
                  }}
                  prop="version"
                  icon="cafe-creation"
                  unit="u"
                  last={`Last check: ${
                    DateTime.fromISO(lastCheckForHost(host)).toRelative() ||
                    "none"
                  }`}
                  badge={true}
                  badgeColor={getConfidenceColor(getConfidence(host))}
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="MONITORING"
                  value={{
                    data: {
                      monitored: isMonitored(host)
                        ? "ALL"
                        : isPartiallyMonitored(host)
                          ? "PARTIAL"
                          : isPendingMonitoring(host)
                            ? "PENDING"
                            : "DISABLED",
                    },
                  }}
                  prop="monitored"
                  icon="cafe-monitor"
                  unit="u"
                  last={getLastMonitoredDateStateForHost(host)}
                  badge={true}
                  badgeColor={
                    isMonitored(host)
                      ? "success"
                      : isPartiallyMonitored(host) || isPendingMonitoring(host)
                        ? "warning"
                        : "light"
                  }
                />
              </Col>
              <Col xl="3" lg="6" md="6" sm="12" xs="12">
                <CardNumber
                  name="OPS"
                  value={{
                    data: {ops: ops},
                  }}
                  prop="ops"
                  icon={`cafe-${getRoleIcon(host)}`}
                  unit="u"
                  last="Services executed"
                  badge={true}
                  badgeColor="gray"
                />
              </Col>
              <Col xl="9" lg="9" md="9" sm="9" xs="9">
                <GlobalStatusChart
                  status={status ? status : {}}
                  property="all"
                  title="DAILY HOST STATUS"
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="3" xs="3">
                <GlobalStatusPieChart
                  status={status ? status : {}}
                  property="all"
                  title="HOST STATUS"
                />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5
                      className="card-category"
                      style={{textTransform: "none"}}
                    >
                      Select the services to use
                    </h5>
                    <CardTitle tag="h3">
                      MONITORING{" "}
                      <Button
                        disabled={isFreeWithMonitoringActivatedOnOtherHost(
                          host,
                        )}
                        className="ml-2"
                        size="sm"
                        color={isPaused(host) ? "primary" : "danger"}
                        onClick={() => {
                          updateMonitoring();
                        }}
                      >
                        {isPaused(host) ? "Start" : "Stop"}
                      </Button>
                      {appState.updateInstanceInProgress && (
                        <Spinner
                          className="ml-2 mb-2"
                          size="sm"
                          children={null}
                        />
                      )}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                      <tr>
                        <th className="text-left">Services</th>
                        <th className="text-left">Last Update</th>
                        <th className="text-left">Frequency</th>
                        <th className="text-right">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td className="text-left w-25">
                          Health
                          <span
                            className="ml-1 mr-2 tooltip-icon"
                            id="iconHealth"
                          >
                              ?
                            </span>
                          <UncontrolledTooltip target="iconHealth">
                            Check regularly the health of the host (telemetry,
                            version and status)
                          </UncontrolledTooltip>
                        </td>
                        <td className="text-left">
                          <Badge
                            pill
                            color={getColorForStatus(
                              host,
                              HOST_SERVICE.HEALTH,
                            )}
                          >
                            {getStatusForService(host, HOST_SERVICE.HEALTH)}
                          </Badge>
                          <span
                            className="text-muted ml-2"
                            style={{fontSize: "12px"}}
                          >
                              {getLastRunForAService(host, HOST_SERVICE.HEALTH)}
                            </span>
                        </td>
                        <td className="text-center">
                          <div className="d-sm-flex">
                            <Select
                              styles={customStyles}
                              className="myClass"
                              onChange={(event) => {
                                setFrequencyForService(
                                  HOST_SERVICE.HEALTH,
                                  event.value,
                                );
                              }}
                              options={options}
                              isClearable={false}
                              isSearchable={false}
                              placeholder={null}
                              value={getValueForService(
                                host,
                                healthFrequency,
                              )}
                              isDisabled={
                                !isHealthServiceUsable(host) ||
                                !isHealthConfigured(host)
                              }
                              menuPortalTarget={document.body}
                            ></Select>

                            {!isHealthConfigured(host) && (
                              <>
                                <UncontrolledTooltip target="healthNotConfigured">
                                  Configure the serviceability Health API URL
                                  parameter in the Settings to enable the
                                  Health service
                                </UncontrolledTooltip>
                                <Badge
                                  pill
                                  color="light"
                                  className="ml-1 mt-1 tooltip-badge"
                                  id="healthNotConfigured"
                                >
                                  not configured
                                </Badge>
                              </>
                            )}
                            {isPartialFrequency(host) &&
                              isHealthConfigured(host) && (
                                <Badge
                                  pill
                                  color="light"
                                  className={"ml-1 mt-1"}
                                >
                                  limited
                                </Badge>
                              )}
                            {appState.updateServiceFrequencyInProgress &&
                              serviceChange === HOST_SERVICE.HEALTH && (
                                <Spinner
                                  className="mt-1"
                                  size="sm"
                                  children={null}
                                />
                              )}
                          </div>
                        </td>
                        <td className="text-right">
                          {appState.launchServiceInProgress &&
                            appState.launchServiceName ===
                            HOST_SERVICE.HEALTH && (
                              <Spinner
                                className="mt-1"
                                size="sm"
                                children={null}
                              />
                            )}
                          <Button
                            disabled={
                              appState.launchServiceInProgress ||
                              !isHealthConfigured(host) ||
                              host[HOST_SERVICE.HEALTH].value ===
                              SERVICE_STATUS.UNKNOWN
                            }
                            className="ml-2"
                            style={{fontSize: "12px"}}
                            size="sm"
                            color="dark"
                            onClick={() => {
                              manualRun(host, HOST_SERVICE.HEALTH, true);
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            disabled={
                              appState.launchServiceInProgress ||
                              !isHealthConfigured(host)
                            }
                            className="ml-2"
                            style={{fontSize: "12px"}}
                            size="sm"
                            color="primary"
                            onClick={() => {
                              manualRun(host, HOST_SERVICE.HEALTH);
                            }}
                          >
                            Dry Run
                          </Button>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-left w-25">
                          Tests
                          <span
                            className="ml-1 mr-2 tooltip-icon"
                            id="iconTelemetry"
                          >
                              ?
                            </span>
                          <UncontrolledTooltip target="iconTelemetry">
                            Tests regularly the host
                          </UncontrolledTooltip>
                        </td>
                        <td className="text-left">
                          <Badge
                            pill
                            color={getColorForStatus(
                              host,
                              HOST_SERVICE.TESTS,
                            )}
                          >
                            {getStatusForService(host, HOST_SERVICE.TESTS)}
                          </Badge>
                          <span
                            className="text-muted ml-2"
                            style={{fontSize: "12px"}}
                          >
                              {getLastRunForAService(host, HOST_SERVICE.TESTS)}
                            </span>
                        </td>
                        <td className="text-center">
                          <div className="d-sm-flex">
                            <Select
                              styles={customStyles}
                              className="myClass"
                              onChange={(event) => {
                                setFrequencyForService(
                                  HOST_SERVICE.TESTS,
                                  event.value,
                                );
                              }}
                              options={options}
                              isClearable={false}
                              isSearchable={false}
                              placeholder={null}
                              value={getValueForService(host, testsFrequency)}
                              isDisabled={
                                !isTestsServiceUsable(host) ||
                                !isTestsConfigured(host)
                              }
                              menuPortalTarget={document.body}
                            ></Select>
                            {!isTestsConfigured(host) && (
                              <>
                                <UncontrolledTooltip target="testsNotConfigured">
                                  Configure the SSH Host IP address parameter
                                  in the Settings to enable the Telemetry
                                  service
                                </UncontrolledTooltip>
                                <Badge
                                  pill
                                  color="light"
                                  className="ml-1 mt-1 tooltip-badge"
                                  id="testsNotConfigured"
                                >
                                  not configured
                                </Badge>
                              </>
                            )}
                            {isPartialFrequency(host) &&
                              isTestsConfigured(host) && (
                                <Badge
                                  pill
                                  color="light"
                                  className={"ml-1 mt-1"}
                                >
                                  limited
                                </Badge>
                              )}
                            {appState.updateServiceFrequencyInProgress &&
                              serviceChange === HOST_SERVICE.TESTS && (
                                <Spinner
                                  className="mt-1"
                                  size="sm"
                                  children={null}
                                />
                              )}
                          </div>
                        </td>
                        <td className="text-right">
                          {appState.launchServiceInProgress &&
                            appState.launchServiceName ===
                            HOST_SERVICE.TESTS && (
                              <Spinner
                                className="mt-1"
                                size="sm"
                                children={null}
                              />
                            )}
                          <Button
                            disabled={
                              appState.launchServiceInProgress ||
                              !isTestsConfigured(host) ||
                              host[HOST_SERVICE.TESTS].value ===
                              SERVICE_STATUS.UNKNOWN
                            }
                            className="ml-2"
                            style={{fontSize: "12px"}}
                            size="sm"
                            color="dark"
                            onClick={() => {
                              manualRun(host, HOST_SERVICE.TESTS, true);
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            disabled={
                              appState.launchServiceInProgress ||
                              !isTestsConfigured(host)
                            }
                            className="ml-2"
                            style={{fontSize: "12px"}}
                            size="sm"
                            color="primary"
                            onClick={() => {
                              manualRun(host, HOST_SERVICE.TESTS);
                            }}
                          >
                            Dry Run
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left w-25">
                          Usage
                          <span
                            className="ml-1 mr-2 tooltip-icon"
                            id="iconUsage"
                          >
                              ?
                            </span>
                          <UncontrolledTooltip target="iconUsage">
                            Check regularly the traffic
                          </UncontrolledTooltip>
                          {!isFree(host) &&
                            host.role === HOST_ROLE.COTURN && (
                              <a
                                download
                                href="/files/redis-connector.zip"
                                className="ml-5"
                              >
                                download
                              </a>
                            )}
                        </td>
                        <td className="text-left w-25">
                          <Badge
                            pill
                            color={getColorForStatus(
                              host,
                              HOST_SERVICE.USAGE,
                            )}
                          >
                            {getStatusForService(host, HOST_SERVICE.USAGE)}
                          </Badge>
                          <span
                            className="text-muted ml-2"
                            style={{fontSize: "12px"}}
                          >
                              {getLastRunForAService(host, HOST_SERVICE.USAGE)}
                            </span>
                        </td>
                        <td className="text-center">
                          <div className="d-sm-flex">
                            <Select
                              styles={customStyles}
                              className="myClass"
                              onChange={(event) => {
                                setFrequencyForService(
                                  HOST_SERVICE.USAGE,
                                  event.value,
                                );
                              }}
                              options={options}
                              isClearable={false}
                              isSearchable={false}
                              placeholder={null}
                              value={getValueForService(host, usageFrequency)}
                              isDisabled={
                                isFree(host) ||
                                !isUsageServiceUsable(host) ||
                                !isUsageConfigured(host)
                              }
                              menuPortalTarget={document.body}
                            ></Select>
                            {isFree(host) && (
                              <Badge pill color="light" className="ml-1 mt-1">
                                Not available
                              </Badge>
                            )}
                            {!isUsageConfigured(host) && !isFree(host) && (
                              <Badge pill color="light" className="ml-1 mt-1">
                                not configured
                              </Badge>
                            )}

                            {appState.updateServiceFrequencyInProgress &&
                              serviceChange === HOST_SERVICE.USAGE && (
                                <Spinner
                                  className="mt-1"
                                  size="sm"
                                  children={null}
                                />
                              )}
                          </div>
                        </td>
                        <td className="text-right">
                          {appState.launchServiceInProgress &&
                            appState.launchServiceName ===
                            HOST_SERVICE.USAGE && (
                              <Spinner
                                className="mt-1"
                                size="sm"
                                children={null}
                              />
                            )}
                          <Button
                            disabled={
                              appState.launchServiceInProgress ||
                              !isUsageConfigured(host) ||
                              host[HOST_SERVICE.USAGE].value ===
                              SERVICE_STATUS.UNKNOWN
                            }
                            className="ml-2"
                            style={{fontSize: "12px"}}
                            size="sm"
                            color="dark"
                            onClick={() => {
                              manualRun(host, HOST_SERVICE.USAGE, true);
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            disabled={
                              appState.launchServiceInProgress ||
                              !isUsageConfigured(host)
                            }
                            className="ml-2"
                            style={{fontSize: "12px"}}
                            size="sm"
                            color="primary"
                            onClick={() => {
                              manualRun(host, HOST_SERVICE.USAGE);
                            }}
                          >
                            Dry Run
                          </Button>
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5 className="card-category">Host events generated</h5>
                    <CardTitle tag="h3">HISTORY</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-left">Date</th>
                        <th className="text-left w-50">Event</th>
                        <th className="text-right">Category</th>
                      </tr>
                      </thead>
                      <tbody>
                      {events.map((event, key) => (
                        <tr key={key}>
                          <td className="text-center">
                            <i className={isIncident(event)}/>
                          </td>
                          <td className="text-left">
                            {DateTime.fromISO(event.created).toLocaleString(
                              DateTime.DATETIME_MED_WITH_SECONDS,
                            )}
                          </td>
                          <td className="text-left w-50">
                            {getEventDetails(event.details)}
                          </td>
                          <td className="text-right">{event.category}</td>
                        </tr>
                      ))}
                      {events.length === 0 &&
                        !appState.listEventsInProgress && (
                          <tr>
                            <td colSpan="4">
                              <br/>
                              No event found.
                              <br/>
                              <br/>
                              Please, have a Ice or Media test to have at
                              least one entry.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <hr/>
                    <div className="stats">
                      <Button
                        disabled={appState.listEventsInProgress}
                        className="tableButtons"
                        color="primary"
                        size="sm"
                        onClick={() => {
                          updateEvents();
                        }}
                      >
                        {" "}
                        <i className="icon cafe-refresh"/>{" "}
                      </Button>
                      <Button
                        disabled={
                          appState.listEventsInProgress ||
                          detectNoPreviousPage()
                        }
                        className="tableButtons"
                        color="info"
                        size="sm"
                        onClick={goToFirstPage}
                      >
                        <i className="icon cafe-first"/>
                      </Button>
                      <Button
                        disabled={
                          appState.listEventsInProgress ||
                          detectNoPreviousPage()
                        }
                        className="tableButtons"
                        color="info"
                        size="sm"
                        onClick={goToPreviousPage}
                      >
                        {" "}
                        <i className="icon cafe-back"/>{" "}
                      </Button>
                      <Button
                        disabled={
                          appState.listEventsInProgress || detectNoNextPage()
                        }
                        className="tableButtons"
                        color="info"
                        size="sm"
                        onClick={goToNextPage}
                      >
                        {" "}
                        <i className="icon cafe-forward"/>{" "}
                      </Button>
                      <Button
                        disabled={
                          appState.listEventsInProgress || detectNoNextPage()
                        }
                        className="tableButtons"
                        color="info"
                        size="sm"
                        onClick={goToLastPage}
                      >
                        <i className="icon cafe-last"/>
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
                <Modal
                  isOpen={modal}
                  toggle={() => {
                    setModal(false, () => {
                      resetLaunchService(props.dispatch);
                    });
                  }}
                  size="lg"
                >
                  <ModalHeader
                    className="justify-content-center"
                    toggle={() => {
                      setModal(false, () => {
                        resetLaunchService(props.dispatch);
                      });
                    }}
                  >
                    {`Oops! Something went wrong!`}
                  </ModalHeader>
                  <ModalBody>
                    <>
                      <p className="text-danger">
                        {`Dry run for service ${appState.launchServiceName} encountered a problem!`}
                      </p>
                      <blockquote>
                        <i className="text-danger">
                          {appState.launchServiceDetails}
                        </i>
                      </blockquote>
                      <p>
                        Please check your settings as well as your host
                        configuration.
                      </p>
                    </>
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default Host;
