import { useState, useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Button,
  CardTitle,
  ButtonGroup,
} from "reactstrap";
import AppContext from "../../contexts/appContext";
import { createChart, updateChart } from "../../modules/callCharts";

import "../testing/media.css";
import { HOST_SERVICE } from "../../modules/bm";
import { CHART_COLORS } from "../../modules/helper";

function IceChart({ dispatch }) {
  const appState = useContext(AppContext);
  const chartIce = useRef();
  const [series, setSeries] = useState([]);
  const [series2, setSeries2] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [selectedSeries2, setSelectedSeries2] = useState([]);

  const getLabels = (prop) => {
    switch (prop) {
      case "score":
        return ["E", "D", "C", "B-", "B", "B+", "A", "A+", "A++"];
      default:
        return null;
    }
  };

  const changeSeries = (series, series2) => {
    if (series.length > 0) {
      const counts = {};
      series.forEach((serie) => {
        counts[serie.score] = (counts[serie.score] || 0) + 1;
      });

      const graphSeries = [];
      Object.keys(counts).forEach((key) => {
        graphSeries.push({
          x: key,
          y: counts[key],
        });
      });

      setSelectedSeries(graphSeries);
    } else {
      setSelectedSeries([]);
    }

    if (series2.length > 0) {
      const counts = {};
      series2.forEach((serie) => {
        counts[serie.score] = (counts[serie.score] || 0) + 1;
      });

      const graphSeries = [];
      Object.keys(counts).forEach((key) => {
        graphSeries.push({
          x: key,
          y: counts[key],
        });
      });

      setSelectedSeries2(graphSeries);
    } else {
      setSelectedSeries2([]);
    }
  };

  useEffect(() => {
    if (selectedSeries.length > 0 || selectedSeries2.length > 0) {
      updateChart(selectedSeries, selectedSeries2, chartIce, 10, 60000, true);
    }
  }, [selectedSeries, selectedSeries2]);

  useEffect(() => {
    createChart(document.querySelector("#chartIce"), chartIce, "ICE", {
      prop: "Score",
      colorProp: CHART_COLORS.yellow,
      colorProp2: CHART_COLORS.orange,
      maxY: 60000,
      labels: getLabels("score"),
      isLine: false,
      xLabels: true,
    });
    changeSeries(series, series2);
  }, [series, series2]);

  useEffect(() => {
    if (appState.testingSeries) {
      setSeries(
        appState.testingSeries.filter(
          (elt) => elt.category === HOST_SERVICE.ICE
        )
      );
      setSeries2(
        appState.testingSeries.filter(
          (elt) => elt.category === HOST_SERVICE.MEDIA
        )
      );
    }
  }, [appState.testingSeries]);

  return (
    <Card className="card">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="12">
            <h5 className="card-category">
              Connectivity and Media Score summary
            </h5>
            <CardTitle tag="h3">Host Score Summary</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <div className="chart-container">
              <canvas id="chartIce"></canvas>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default IceChart;
