import { DateTime, Duration } from "luxon";

import {
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { convert } from "../modules/helper";

import "./CardNumber.css";

function CardNumber(props) {
  const value = props.value ? props.value.data : null;
  const prop = props.prop;
  const prop2 = props.prop2;
  const badge = props.badge;
  const badgeColor = props.badgeColor;
  const last_prop = `last_${props.prop}`;
  let label = 0;
  let label2 = 0;
  let last_label = 0;
  let color = "icon_warning";
  let icon = "";

  if (value !== undefined) {
    label = prop in value ? value[prop] : 0;
    label2 = prop2 in value ? value[prop2] || "-" : "";
    last_label = last_prop in value ? value[last_prop] : props.last || 0;

    color = props.color || "icon-success";
    icon =
      last_label < label
        ? "icon-minimal-up"
        : last_label > label
        ? "icon-minimal-down"
        : "icon-minimal-right";
  }

  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col xs="3">
            <div className={`info-icon ${color}`}>
              <i className={`icon ${props.icon}`} />
            </div>
          </Col>
          <Col xs="9">
            <div className="numbers">
              <p className="card-category">{props.name}</p>
              {badge && (
                <CardTitle tag="h3">
                  <Badge
                    style={{
                      fontSize: "18px",
                      padding: "9px 12px",
                      fontWeight: "600",
                      marginTop: "6px",
                    }}
                    color={badgeColor}
                    pill
                  >
                    {props.unit === "kb"
                      ? convert(label)
                      : props.unit === "p"
                      ? `${label}${typeof label === "string" ? "" : "%"}`
                      : props.unit !== "u" && props.unit !== "simple"
                      ? `${label} ${props.unit}`
                      : label}
                  </Badge>
                </CardTitle>
              )}
              {!badge && (
                <CardTitle tag="h3">
                  {props.unit === "kb"
                    ? convert(label)
                    : props.unit === "p"
                    ? `${label} ${typeof label === "string" ? "" : "%"}`
                    : props.unit !== "u" && props.unit !== "simple"
                    ? !isFinite(label)
                      ? "-"
                      : `${label} ${props.unit}`
                    : label}
                  {label2 && (
                    <small className="ml-1">
                      <sup className="secondary">{label2}</sup>
                    </small>
                  )}
                </CardTitle>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        <hr />
        <div className="stats">
          {props.unit !== "simple" && (
            <p>
              <i className={`tim-icons ${icon}`} />{" "}
              {props.unit === "kb"
                ? convert(last_label)
                : props.unit === "p"
                ? `${last_label}`
                : last_label}
            </p>
          )}
          {props.unit === "simple" && (
            <p>
              <br />
            </p>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}

export default CardNumber;
