const testingRoutes = [
  {
    path: "/testing/:hostId/transport",
    name: "ICE Candidates",
    icon: "icon cafe-ice",
  },
  {
    path: "/testing/:hostId/connectivity",
    name: "Connectivity",
    icon: "icon cafe-ice-stun",
  },
  {
    path: "/testing/:hostId/media",
    name: "Media",
    icon: "icon cafe-media",
  },
  // {
  //   path: "/testing/users",
  //   name: "Users",
  //   icon: "icon cafe-meeting",
  // },
  // {
  //   path: "/testing/scores",
  //   name: "Scores",
  //   icon: "icon cafe-monitoring",
  // },
];

export default testingRoutes;
