import CryptoJS from "crypto-js";
import {v4 as uuidv4} from "uuid";
import "chartjs-adapter-luxon";
import {color, valueOrDefault} from "chart.js/helpers";
import {DateTime, Duration} from "luxon";
import {OPERATIONS_TYPE, ROLE} from "./bm";
import React from "react";

export const PERIOD_TYPE = {
  WEEK: "week",
  MONTH: "month",
  LAST_3_MONTHS: "last3months",
};

/// Charts
// Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
var _seed = Date.now();

export function srand(seed) {
  _seed = seed;
}

export function rand(min, max) {
  min = valueOrDefault(min, 0);
  max = valueOrDefault(max, 0);
  _seed = (_seed * 9301 + 49297) % 233280;
  return min + (_seed / 233280) * (max - min);
}

export function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return color(value).alpha(alpha).rgbString();
}

export function titleCase(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const getNameFromOperation = (value) => {
  return OPERATIONS_TYPE.find(op => op.value === value).label;
}

export const getLabelFromTypeList = (value, typeList) => {
  return typeList.find(item => item.value === value).label;
}

export const getTypeList = (list) => {
  return list.map((item, key) => {
    return (
      <option value={item.value} key={key}>
        {item.label}
      </option>
    );
  });
};

export const CHART_COLORS = {
  red: "#dc3545",
  orange: "#fd7e14",
  warning: "#ff8d72",
  yellow: "#ffd600",
  green: "#28a745",
  blue: "#037BFF",
  darkblue: "#344675",
  purple: "#6f42c1",
  grey: "#6c757d",
  cyan: "#2bffc6",
  teal: "#11cdef",
  pink: "#e14eca",
  indigo: "#5603ad",
  white: "#fff",
};

export const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
  CHART_COLORS.indigo,
  CHART_COLORS.pink,
  CHART_COLORS.teal,
];

export function namedColor(index) {
  return NAMED_COLORS[index % NAMED_COLORS.length];
}

export const getMaxYValueOfSerie = (stats) => {
  if (stats.length >= 2) {
    const maxValue = stats.reduce((a, b) => {
      let acc = 0;
      if (typeof a === "object") {
        acc = a.y;
      } else {
        acc = a;
      }
      return Math.max(acc, b.y);
    });
    return maxValue;
  } else if (stats.length === 1) {
    return stats[0].y;
  }
  return 100;
};

export const getMostRecentDateFromSeries = (stats) => {
  if (stats.length > 0 && typeof stats[0] === "object") {
    if (stats.length >= 2) {
      const mostRecent = stats.reduce((a, b) => {
        const aTime = new Date(a.x).getTime();
        const bTime = new Date(b.x).getTime();
        if (aTime > bTime) {
          return a;
        }
        return b;
      });
      return mostRecent;
    } else {
      return stats[0];
    }
  }
  return null;
}

export const getMostOldDateFromSeries = (stats) => {
  if (stats.length > 0 && typeof stats[0] === "object") {
    if (stats.length >= 2) {
      const mostOld = stats.reduce((a, b) => {
        const aTime = new Date(a.x).getTime();
        const bTime = new Date(b.x).getTime();
        if (aTime < bTime) {
          return a;
        }
        return b;
      });
      return mostOld;
    } else {
      return stats[0];
    }
  }
  return null;
}

export const createRTTGraph = () => {
  const data = {
    datasets: [
      {
        label: "Audio RTT (ms)",
        backgroundColor: transparentize(CHART_COLORS.pink, 0.5),
        borderColor: CHART_COLORS.pink,
        tension: 0.2,
        data: [],
      },
      {
        label: "Video RTT (ms)",
        backgroundColor: transparentize(CHART_COLORS.teal, 0.5),
        borderColor: CHART_COLORS.teal,
        tension: 0.2,
        data: [],
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TT",
          },
        },
        y: {
          min: 0,
          max: 400,
        },
      },
    },
  };

  return config;
};

export const createLostGraph = () => {
  const data = {
    datasets: [
      {
        label: "Audio Lost (Unit)",
        backgroundColor: transparentize(CHART_COLORS.pink, 0.5),
        borderColor: CHART_COLORS.pink,
        tension: 0.2,
        data: [],
      },
      {
        label: "Video Lost (Unit)",
        backgroundColor: transparentize(CHART_COLORS.teal, 0.5),
        borderColor: CHART_COLORS.teal,
        tension: 0.2,
        data: [],
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TT",
          },
        },
        y: {
          min: 0,
          max: 400,
        },
      },
    },
  };

  return config;
};

export const createBandwidthGraph = () => {
  const data = {
    datasets: [
      {
        label: "Incoming bitrate (kbs)",
        backgroundColor: transparentize(CHART_COLORS.orange, 0.5),
        borderColor: CHART_COLORS.orange,
        tension: 0.2,
        data: [],
      },
      {
        label: "Outgoing bitrate (kbs)",
        backgroundColor: transparentize(CHART_COLORS.purple, 0.5),
        borderColor: CHART_COLORS.purple,
        tension: 0.2,
        data: [],
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TT",
          },
        },
        y: {
          min: 0,
          max: 1000,
        },
      },
    },
  };

  return config;
};

export const createBytesGraph = () => {
  const data = {
    datasets: [
      {
        label: "In (KB)",
        backgroundColor: transparentize(CHART_COLORS.orange, 0.5),
        borderColor: CHART_COLORS.orange,
        tension: 0.2,
        data: [],
      },
      {
        label: "Out (KB)",
        backgroundColor: transparentize(CHART_COLORS.purple, 0.5),
        borderColor: CHART_COLORS.purple,
        tension: 0.2,
        data: [],
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TT",
          },
        },
        y: {
          min: 0,
          max: 1000,
        },
      },
    },
  };

  return config;
};

export const createJitterGraph = () => {
  const data = {
    datasets: [
      {
        label: "Audio Jitter (ms)",
        backgroundColor: transparentize(CHART_COLORS.pink, 0.5),
        borderColor: CHART_COLORS.pink,
        tension: 0.2,
        data: [],
      },
      {
        label: "Video Jitter (ms)",
        backgroundColor: transparentize(CHART_COLORS.teal, 0.5),
        borderColor: CHART_COLORS.teal,
        tension: 0.2,
        data: [],
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TT",
          },
        },
        y: {
          min: 0,
          max: 20,
        },
      },
    },
  };

  return config;
};

export const createMOSGraph = () => {
  const data = {
    datasets: [
      {
        label: "MOS EModel (score)",
        backgroundColor: transparentize(CHART_COLORS.yellow, 0.5),
        borderColor: CHART_COLORS.yellow,
        tension: 0.2,
        data: [],
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TT",
          },
        },
        y: {
          min: 0,
          max: 6,
        },
      },
    },
  };

  return config;
};

export const createResolutionGraph = () => {
  const data = {
    datasets: [
      {
        label: "Resolution (720P, 640P...)",
        backgroundColor: transparentize(CHART_COLORS.orange, 0.5),
        borderColor: CHART_COLORS.orange,
        tension: 0.2,
        data: [],
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TT",
          },
        },
        y: {
          min: 0,
          max: 800,
        },
      },
    },
  };

  return config;
};

export const createOutagesGraph = (options) => {
  const colorOutages = CHART_COLORS.red;
  const colorDegradations = CHART_COLORS.orange;

  const data = {
    datasets: [
      {
        label: "Unavailability (in hours)",
        type: "line",
        stepped: false,
        backgroundColor: transparentize(colorOutages, 0.4),
        tension: 0.2,
        data: [],
        borderColor: colorOutages,
        pointBackgroundColor: colorOutages,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        fill: true,
        borderWidth: 0,
        order: 2,
        stack: "combined",
      },
      {
        label: "Degradations (in hours)",
        type: "line",
        stepped: false,
        backgroundColor: transparentize(colorDegradations, 0.4),
        tension: 0.2,
        data: [],
        borderColor: colorDegradations,
        pointBorderColor: colorDegradations,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        fill: true,
        borderWidth: 0,
        order: 1,
        stack: "combined",
      },
    ],
  };

  const min = DateTime.now().minus({months: 2}).startOf("month").toJSON();
  const max = DateTime.now().endOf("month").toJSON();

  const config = {
    data: data,
    options: {
      responsive: true,
      radius: 0,
      maintainAspectRatio: false,
      fontColor: "#fff",
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TTT",
          },
          min: min,
          max: max,
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          ticks: {color: "#999"},
        },
        y: {
          min: 0,
          max: 50,
          stacked: true,
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
            color: "rgba(255,99,132,0.2)",
          },
          ticks: {color: transparentize(colorOutages, 0.5)},
        },
      },
      plugins: {
        zoom: {
          limits: {
            y: {min: 0, max: 24},
          },
          zoom: {
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "xy",
          },
        },
      },
    },
  };

  return config;
};

export const createHealthCheckGraph = (options) => {
  const colorOutages = CHART_COLORS.green;
  const data = {
    datasets: [
      {
        label: "Availability (%)",
        type: "line",
        stepped: false,
        backgroundColor: transparentize(colorOutages, 0.4),
        tension: 0.2,
        data: [],
        borderColor: colorOutages,
        pointBackgroundColor: colorOutages,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        fill: true,
        borderWidth: 0,
        order: 2,
      },
    ],
  };

  const min = DateTime.now().minus({months: 2}).startOf("month").toJSON();
  const max = DateTime.now().endOf("month").toJSON();

  const config = {
    data: data,
    options: {
      responsive: true,
      radius: 0,
      maintainAspectRatio: false,
      fontColor: "#fff",
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TTT",
          },
          min: min,
          max: max,
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          ticks: {color: "#999"},
        },
        y: {
          stacked: true,
          min: 0,
          max: 50,
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
            color: "rgba(255,99,132,0.2)",
          },
          ticks: {color: transparentize(colorOutages, 0.5)},
        },
      },
      plugins: {
        zoom: {
          limits: {
            y: {min: 0, max: 24},
          },
          zoom: {
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "xy",
          },
        },
      },
    },
  };

  return config;
};

export const createServiceGraph = ({
                                     prop,
                                     colorProp,
                                     maxY,
                                     labels,
                                     isLine = true,
                                     xLabels = false,
                                   }) => {
  const data = {
    datasets: [
      {
        label: prop,
        type: labels && !xLabels ? "line" : "bar",
        stepped: false,
        backgroundColor: transparentize(colorProp, 0.4),
        tension: 0.2,
        data: [],
        borderColor: colorProp,
        pointBackgroundColor: colorProp,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        fill: true,
        borderWidth: 0,
        order: 2,
      },
    ],
  };

  if (!isLine) {
    data.datasets[0].radius = 3;
  }

  const config = {
    data: data,
    options: {
      responsive: true,
      radius: 0,
      maintainAspectRatio: false,
      fontColor: "#fff",
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TTT",
            displayFormats: {
              hour: "EEE d T",
            },
          },
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          /*
          ticks: {
            color: "#999",
            callback: function (val, index) {
              const value = this.getLabelForValue(val);
              if (value.length < 10) {
                return value;
              }

              return index % 2 === 0
                ? DateTime.fromISO(value).toFormat("LLL dd, T")
                : "";
            },
          },

           */
        },
        y: {
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
            color: "rgba(255,99,132,0.2)",
          },
          ticks: {color: transparentize(colorProp, 0.5)},
        },
      },
    },
  };

  if (labels) {
    if (xLabels) {
      config.options.scales.x.labels = labels;
      config.options.scales.x.type = "category";
    } else {
      config.options.scales.y.labels = labels;
      config.options.scales.y.type = "category";
    }
  } else {
    config.options.scales.y.type = "linear";
  }

  config.options.scales.x.type = "time";
  config.options.scales.y.min = 0;
  config.options.scales.y.max = maxY;

  return config;
};

export const createIceGraph = ({
                                 colorProp,
                                 colorProp2,
                                 maxY,
                                 labels,
                                 isLine = true,
                                 xLabels = false,
                               }) => {
  const data = {
    datasets: [
      {
        label: "Ice",
        type: labels && !xLabels ? "line" : "bar",
        stepped: false,
        backgroundColor: transparentize(colorProp, 0.4),
        tension: 0.2,
        data: [],
        borderColor: colorProp,
        pointBackgroundColor: colorProp,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        fill: true,
        borderWidth: 0,
        order: 0,
        stack: "usage",
      },
      {
        label: "Media",
        type: labels && !xLabels ? "line" : "bar",
        stepped: false,
        backgroundColor: transparentize(colorProp2, 0.4),
        tension: 0.2,
        data: [],
        borderColor: colorProp2,
        pointBackgroundColor: colorProp2,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        fill: true,
        borderWidth: 0,
        order: 1,
        stack: "usage",
      },
    ],
  };

  if (!isLine) {
    data.datasets[0].radius = 3;
  }

  const config = {
    data: data,
    options: {
      responsive: true,
      radius: 0,
      maintainAspectRatio: false,
      fontColor: "#fff",
      scales: {
        x: {
          type: "time",
          time: {
            tooltipFormat: "TTT",
          },
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          ticks: {
            color: "#999",
            callback: function (val, index) {
              const value = this.getLabelForValue(val);
              if (value.length < 10) {
                return value;
              }

              return index % 2 === 0
                ? DateTime.fromISO(value).toFormat("LLL dd, T")
                : "";
            },
          },
        },
        y: {
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
            color: "rgba(255,99,132,0.2)",
          },
          ticks: {color: transparentize(colorProp, 0.5)},
        },
      },
      /*plugins: {
        zoom: {
          limits: {
            //y: { min: 0, max: maxY },
          },
          zoom: {
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "xy",
          },
        },
      },*/
    },
  };

  if (labels) {
    if (xLabels) {
      config.options.scales.x.labels = labels;
      config.options.scales.x.type = "category";
    } else {
      config.options.scales.y.labels = labels;
      config.options.scales.y.type = "category";
    }
  } else {
    config.options.scales.y.type = "linear";
  }

  config.options.scales.x.type = "category";
  config.options.scales.y.min = 0;
  config.options.scales.y.max = maxY;
  //config.options.scales.y.stacked = true;

  return config;
};

export const createMonthlyGraph = ({
                                     prop,
                                     colorProp,
                                     maxY,
                                     labels,
                                     isLine = true,
                                     xLabels = false,
                                     unit = "day",
                                   }) => {
  const data = {
    datasets: [
      {
        label: prop,
        type: isLine ? "line" : "bar",
        stepped: false,
        backgroundColor: transparentize(colorProp, 0.4),
        tension: 0,
        data: [],
        borderColor: colorProp,
        pointBackgroundColor: colorProp,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 4,
        fill: false,
        borderWidth: isLine ? 1 : 0,
        radius: 3,
      },
    ],
  };

  if (!isLine) {
    data.datasets[0].radius = 3;
  }

  const config = {
    data: data,
    options: {
      responsive: true,
      radius: 0,
      maintainAspectRatio: false,
      fontColor: "#fff",
      scales: {
        x: {
          type: "time",
          time: {
            unit: unit,
          },
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          ticks: {
            color: "#999",
          },
        },
        y: {
          grid: {
            color: "#333",
            borderColor: "#333",
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
            color: "rgba(255,99,132,0.2)",
          },
          ticks: {color: transparentize(colorProp, 0.5)},
        },
      },
    },
  };

  //config.options.scales.y.type = "linear";
  config.options.scales.y.min = 0;
  config.options.scales.y.max = maxY;

  return config;
};

export const createStatusPieGraph = () => {
  const data = {
    datasets: [
      {
        data: [],
        backgroundColor: [
          transparentize(CHART_COLORS.green, 0.4),
          transparentize(CHART_COLORS.orange, 0.4),
          transparentize(CHART_COLORS.red, 0.4),
        ],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Success", "Warning", "Error"],
  };

  const config = {
    data: data,
    type: "doughnut",

    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  return config;
};

export const createStatusGraph = ({unit = "day"}) => {
  const data = {
    datasets: [
      {
        label: "Success",
        type: "bar",
        backgroundColor: transparentize(CHART_COLORS.green, 0.4),
        data: [],
      },
      {
        label: "Warning",
        type: "bar",
        backgroundColor: transparentize(CHART_COLORS.orange, 0.4),
        data: [],
      },
      {
        label: "Error",
        type: "bar",
        backgroundColor: transparentize(CHART_COLORS.red, 0.4),
        data: [],
      },
    ],
  };

  const config = {
    data: data,
    options: {
      responsive: true,
      radius: 0,
      maintainAspectRatio: false,
      fontColor: "#fff",
      scales: {
        x: {
          type: "time",
          stacked: true,
          time: {
            unit: unit,
          },
          grid: {
            display: false,
            color: "#333",
            borderColor: "#333",
          },
          gridLines: {
            display: false,
          },
          ticks: {
            color: "#999",
            //display: false,
          },
        },
        y: {
          stacked: true,
          display: false,
          grid: {
            display: false,
            color: "#333",
            borderColor: "#333",
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
            color: "rgba(255,99,132,0.2)",
          },
          ticks: {
            color: transparentize(CHART_COLORS.grey, 0.5),
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  config.options.scales.y.min = 0;
  config.options.scales.y.max = 100;

  return config;
};

export const createUUID = () => {
  return uuidv4();
};

export const createHeader = (token) => {
  let headers = null;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return headers;
};

export const convert = (value) => {
  if (value > 1073741824) {
    return `${(value / 1073741824).toFixed(2)} GB`;
  }
  if (value > 1048576) {
    return `${(value / 1048576).toFixed(1)} MB`;
  } else if (value > 1024) {
    return `${(value / 1024).toFixed(0)} KB`;
  } else {
    return `${value} bytes`;
  }
};

export const getFromDateFromPeriod = (period) => {
  switch (period) {
    case PERIOD_TYPE.WEEK:
      return DateTime.now().minus({days: 7}).startOf("day").toJSON();
    case PERIOD_TYPE.MONTH:
      return DateTime.now().minus({months: 1}).startOf("day").toJSON();
    case PERIOD_TYPE.LAST_3_MONTHS:
      return DateTime.now().minus({months: 3}).startOf("day").toJSON();
    default:
      return DateTime.now().minus({days: 7}).startOf("day").toJSON();
  }
};

const getEndOfDay = () => DateTime.now().endOf("day").toJSON();

export const CHART_OPTIONS = (from) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      y: {
        stacked: true,
        grid: {
          drawBorder: false,
          color: "#1d8cf826",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 10,
          fontColor: "#fff",
        },
      },
      x: {
        stacked: true,
        grid: {
          drawBorder: false,
          color: "#1d8cf826",
          zeroLineColor: "transparent",
        },
        type: "time",
        time: {
          unit: "day",
        },
        min: new Date(from),
        max: new Date(getEndOfDay()),
      },
    },
  };
};

export const getValueFromSetting = (name, list, instanceId) => {
  const found = list.find(
    (item) => name === item.name && item.instanceId === instanceId,
  );
  return found ? found.value : null;
};

export const getAbbr = (instanceName) => {
  if (instanceName) {
    const arr = instanceName.split("-");
    let abbr = "";
    if (arr.length > 1) {
      arr.forEach((part) => (abbr += part.substr(0, 1)));
      return abbr;
    }
    return instanceName.substr(0, 3);
  }
  return "";
};

export const isCurrentInstance = (instance, current) => {
  return current && instance._id === current._id;
};

export const getDuration = (
  duration,
  useShort = false,
  inHours = false,
  inDays = false,
) => {
  let durationObject = {};
  const durationInMs = duration * 1000;

  if (inDays) {
    durationObject = Duration.fromMillis(durationInMs).shiftTo("days");
  } else if (inHours) {
    durationObject = Duration.fromMillis(durationInMs).shiftTo("hours");
  } else {
    if (duration > 3600 * 24) {
      durationObject = Duration.fromMillis(durationInMs).shiftTo(
        "days",
        "hours",
        "minutes",
        "seconds",
      );
    } else if (duration > 3600) {
      durationObject = Duration.fromMillis(durationInMs).shiftTo(
        "hours",
        "minutes",
        "seconds",
      );
    } else if (duration > 60) {
      durationObject = Duration.fromMillis(durationInMs).shiftTo(
        "minutes",
        "seconds",
      );
    } else {
      durationObject = Duration.fromMillis(durationInMs).shiftTo("seconds");
    }
  }

  if (useShort) {
    return durationObject.toHuman({unitDisplay: inHours ? "long" : "short"});
  }
  return durationObject.toHuman();
};

export const isStunConfigured = (host, settings) => {
  if (!host) {
    return false;
  }
  const setting = getValueFromSetting("stunAddress", settings, host._id);

  return setting && setting.length > 0;
};

export const isTurnConfigured = (host, settings) => {
  if (!host) {
    return false;
  }
  const setting = getValueFromSetting("turnAddress", settings, host._id);

  return setting && setting.length > 0;
};

export const getRoleIcon = (host) => {
  if (!host) {
    return "";
  }
  const role = host.role;
  if (role.includes(ROLE.SIG)) {
    return "sig";
  } else if (role.includes(ROLE.RELAY)) {
    return "relay";
  } else {
    return "media-1";
  }
};

export const generateUniqueID = () => {
  return uuidv4();
};

export const filterCurrentMonth = (data, defaultValue) => {
  if (!data) {
    return defaultValue;
  }
  const currentMonth = data.find(
    (month) =>
      month._id &&
      month._id.year === DateTime.now().year &&
      month._id.month === DateTime.now().month,
  );

  if (!currentMonth) {
    return defaultValue;
  }
  return currentMonth;
};

export const getRoleColor = (role) => {
  if (role.includes(ROLE.SIG)) {
    return "info";
  } else if (role.includes(ROLE.RELAY)) {
    return "warning";
  } else {
    return "primary";
  }
};

export const getRoleTextColor = (role) => {
  if (role.includes(ROLE.SIG)) {
    return "info";
  } else if (role.includes(ROLE.RELAY)) {
    return "warning";
  } else {
    return "primary";
  }
};

export const PALETTE_COLORS = {
  0: "#efefef",
  1: "#aad576",
  //2: "#73a942",
  // 3: "#538d22",
  // 4: "#245501",
  // 5: "#1a4301",
  // 6: "#143601",
  2: "#ffb600",
  // 1001: "#ff9e00",
  // 1002: "#ff8500",
  // 1003: "#ff6d00",
  // 1004: "#ff5400",
  // 10000: "#ff7b7b",
  // 10010: "#ff5252",
  3: "#e01e37",
  //3: "#c71f37",
  //3: "#bd1f36",
  //: "#a71e34",
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
