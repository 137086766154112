import {log} from "../modules/logger";
import {VERSIONS_ACTIONS} from "./constants";
import {getLastVersionForAllHosts, getVersionsForHost} from "../modules/status";

const moduleName = "versionsActions";

export const getLastVersionOfAllHosts = async (userId, token, dispatch) => {
  log(moduleName, "get last version for all hosts", {userId});
  dispatch({
    type: VERSIONS_ACTIONS.GET_LAST_VERSION_FOR_ALL_HOSTS_IN_PROGRESS,
    payload: {value: null},
  });

  let data = null;

  try {
    data = await getLastVersionForAllHosts(userId, token);

    if (data) {
      dispatch({
        type: VERSIONS_ACTIONS.GET_LAST_VERSION_FOR_ALL_HOSTS_SUCCESS,
        payload: {value: data},
      });
    } else {
      dispatch({
        type: VERSIONS_ACTIONS.GET_LAST_VERSION_FOR_ALL_HOSTS_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: VERSIONS_ACTIONS.GET_LAST_VERSION_FOR_ALL_HOSTS_FAILED,
      payload: {value: null},
    });
  }
};

export const getAllVersionsforHost = async (userId, instanceId, token, dispatch) => {
  log(moduleName, "get all versions for a hosts", {userId, instanceId});
  dispatch({
    type: VERSIONS_ACTIONS.GET_ALL_VERSIONS_FOR_HOST_IN_PROGRESS,
    payload: {value: null},
  });

  let data = null;

  try {
    data = await getVersionsForHost(userId, instanceId, token);

    if (data) {
      dispatch({
        type: VERSIONS_ACTIONS.GET_ALL_VERSIONS_FOR_HOST_SUCCESS,
        payload: {value: data},
      });
    } else {
      dispatch({
        type: VERSIONS_ACTIONS.GET_ALL_VERSIONS_FOR_HOST_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: VERSIONS_ACTIONS.GET_ALL_VERSIONS_FOR_HOST_FAILED,
      payload: {value: null},
    });
  }
};


