import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";
import { DateTime } from "luxon";
import AppContext from "../../contexts/appContext";
import { getUsers } from "../../actions/adminActions";

function AdminAccounts({ dispatch }) {
  const appState = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers(page, appState.token, dispatch);
  }, []);

  useEffect(() => {
    if (appState.users) {
      setUsers(appState.users);
    }
  }, [appState.users]);

  const detectNoNextPage = () => {
    return (page + 1) * appState.usersPerPage >= appState.usersCount;
  };

  const detectNoPreviousPage = () => {
    return page === 0;
  };

  const goToFirstPage = () => {
    setPage(0);
  };

  const goToLastPage = () => {
    setPage(Math.floor(appState.usersCount / appState.usersPerPage));
  };

  const goToNextPage = async () => {
    setPage(page + 1);
  };

  const goToPreviousPage = async () => {
    setPage(page - 1);
  };

  const updateUsers = () => {
    getUsers(page, appState.token, dispatch);
  };

  const isAdmin = (user) =>
    user.role === "admin" ? "icon cafe-star text-success" : "";

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">List of users</h5>
        <CardTitle tag="h3">ACCOUNTS</CardTitle>
      </CardHeader>
      <CardBody>
        <Table>
          <thead className="text-primary">
            <tr>
              <th className="text-center">#</th>
              <th className="text-left">Email</th>
              <th className="text-left">Name</th>
              <th className="text-left">Company</th>
              <th className="text-center">Hosts</th>
              <th className="text-center">Role</th>
              <th className="text-center">Profile</th>
              <th className="text-left">Country</th>
              <th className="text-left">Created</th>
              <th className="text-right">[ID]</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, key) => (
              <tr key={key}>
                <td className="text-center">
                  <i className={`${isAdmin(user)} icon-selected`} />
                </td>
                <td className="text-left">{user.username}</td>
                <td className="text-left">
                  {(user.firstname || "") + " " + (user.lastname || "")}
                </td>
                <td className="text-left">{user.company}</td>
                <td className="text-center">{user.hosts}</td>
                <td className="text-center">{user.role}</td>
                <td className="text-center">{user.profile}</td>
                <td className="text-left">{user.country}</td>
                <td className="text-left">
                  {DateTime.fromISO(user.created).toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS
                  )}
                </td>
                <td className="text-right">{user._id}</td>
              </tr>
            ))}
            {users.length === 0 && !appState.listUsersInProgress && (
              <tr>
                <td colSpan="7">
                  <br />
                  No users found.
                  <br />
                  <br />
                  Please, strange, you should see at least yourself!.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Button
          disabled={appState.listUsersInProgress}
          className="tableButtons"
          color="primary"
          size="sm"
          onClick={() => {
            updateUsers();
          }}
        >
          {" "}
          <i className="icon cafe-refresh" />
        </Button>
        <Button
          disabled={appState.listUsersInProgress || detectNoPreviousPage()}
          className="tableButtons"
          color="info"
          size="sm"
          onClick={goToFirstPage}
        >
          <i className="icon cafe-first" />
        </Button>
        <Button
          disabled={appState.listUsersInProgress || detectNoPreviousPage()}
          className="tableButtons"
          color="info"
          size="sm"
          onClick={goToPreviousPage}
        >
          <i className="icon cafe-back" />
        </Button>
        <Button
          disabled={appState.listUsersInProgress || detectNoNextPage()}
          className="tableButtons"
          color="info"
          size="sm"
          onClick={goToNextPage}
        >
          <i className="icon cafe-forward" />
        </Button>
        <Button
          disabled={appState.listUsersInProgress || detectNoNextPage()}
          className="tableButtons"
          color="info"
          size="sm"
          onClick={goToLastPage}
        >
          <i className="icon cafe-last" />
        </Button>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default AdminAccounts;
