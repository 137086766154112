import React, { useContext } from "react";

import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";

import AppContext from "../contexts/appContext";

import "./MapBoard.css";
import { DateTime } from "luxon";

function Series({ dispatch }) {
  const appState = useContext(AppContext);

  const getInstanceName = (instanceId) => {
    if (appState.instances) {
      const instance = appState.instances.find(
        (instance) => instance._id === instanceId
      );
      if (instance) {
        return instance.name;
      }
      return instanceId;
    }
  };

  const isSelectedHost = (instanceId) => {
    return (
      appState.currentInstance && appState.currentInstance._id === instanceId
    );
  };

  const getDate = (startedDate) => {
    return DateTime.fromISO(startedDate).toLocaleString(
      DateTime.DATETIME_FULL_WITH_SECONDS
    );
  };

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Latest Services generated</h5>
        <CardTitle tag="h3">LAST SERVICES</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="12" md="12" xl="12">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th className="text-left">Host</th>
                  <th className="text-left">Date</th>
                  <th className="text-left">Services</th>
                  <th className="text-left">Result</th>
                </tr>
              </thead>
              <tbody>
                {appState.seriesForAllInstances &&
                  appState.seriesForAllInstances.map((series, key) => (
                    <tr key={key}>
                      <td className="text-left">
                        {getInstanceName(series.instanceId)}
                      </td>

                      <td className="text-left">{getDate(series.created)}</td>
                      <td className="text-left">
                        <Badge className={`service-${series.category}`}>
                          {series.category}
                        </Badge>
                      </td>
                      <td className="text-left">
                        <Badge
                          color={
                            series.status === "success"
                              ? "success"
                              : series.status === "error"
                              ? "danger"
                              : "warning"
                          }
                        >
                          {series.details || "OK"}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                {(!appState.seriesForAllInstances ||
                  appState.seriesForAllInstances.length === 0) && (
                  <tr>
                    <td colSpan="5">
                      <br />
                      No series found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default Series;
