import React, { useState, useContext, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Table,
  Button,
  Spinner,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import CardNumber from "../CardNumber";
import { error, info, log, warning } from "../../modules/logger";
import {
  createUUID,
  getValueFromSetting,
  isStunConfigured,
  isTurnConfigured,
} from "../../modules/helper";
import AppContext from "../../contexts/appContext";
import {
  getProtocolFromPriority,
  isBehindAProxy,
  CANDIDATE_TYPE,
  getICETURNServers,
  getUserCredentials,
  getICESTUNServers,
  getICEHostServers,
  detectTypeOfICETest,
  TEST_TYPE,
} from "../../modules/rtc";
import NoHost from "../NoHost";

import "./Ice.css";
import IcePlaceholder from "../placeholder/IcePlaceholder";
import { getErrorDetailsFromCode } from "../../modules/ICEErrors";
import NoSetup from "../NoSetup";
import BreadCrumbCusto from "../BreadCrumb";

const moduleName = "view:ice";

let pc = null;
let startTime = null;
let endTime = null;
let address = null;

let testedHost = false;
let testedStun = false;
let testedTurnUDP = false;
let testedTurnTCP = false;
let testedTurnTLS = false;
let hasSrflx = false;
let hasRelay = false;
let hasTimeouted = false;
let toTest = "All transports";
let timeoutTest = true;

let timeoutId = null;
const TIMEOUT = 5000;

const onGatheringChange = (event) => {
  log(
    moduleName,
    "ice gathering state changed to",
    event.target.iceGatheringState,
  );
};

function Ice(props) {
  const [ices, setIces] = useState([]);
  const [testDone, setTestDone] = useState(false);
  const [iceError, setIceError] = useState([]);
  const [testInProgress, setTestInProgress] = useStateWithCallbackLazy(false);
  const appState = useContext(AppContext);
  const [proxy, setProxy] = useState(false);
  const [host, setHost] = useState(appState.currentInstance);
  const [sendResult, setSendResult] = useState(false);

  useEffect(() => {
    isBehindAProxy().then((result) => setProxy(result));
  }, []);

  useEffect(() => {
    if (!host || host._id !== appState.currentInstance._id) {
      setHost(appState.currentInstance);
    }
  }, [appState.currentInstance]);

  const reset = () => {
    return new Promise((resolve, __reject) => {
      pc = null;
      setTestDone(false);
      hasSrflx = false;
      hasRelay = false;
      setIces([]);
      setIceError([]);
      testedHost = false;
      testedStun = false;
      testedTurnTCP = false;
      testedTurnUDP = false;
      testedTurnTLS = false;
      startTime = null;
      endTime = null;
      address = null;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = null;
      hasTimeouted = false;

      setTestInProgress(false);
      setSendResult(false);
      resolve();
    });
  };

  const tryToConnectToServer = (server, ices, errors) => {
    return new Promise(async (resolve, reject) => {
      try {
        const test_type = detectTypeOfICETest(server);
        info(moduleName, `running test: ${test_type}`);

        if (toTest !== "All transports" && toTest !== server.urls) {
          info(moduleName, "test discarded");
          resolve();
          return;
        }

        switch (test_type) {
          case TEST_TYPE.HOST:
            testedHost = true;
            break;
          case TEST_TYPE.STUN:
            testedStun = true;
            break;
          case TEST_TYPE.TURN_UDP:
            testedTurnUDP = true;
            break;
          case TEST_TYPE.TURN_TCP:
            testedTurnTCP = true;
            break;
          case TEST_TYPE.TURN_TLS:
            testedTurnTLS = true;
            break;
        }

        startTime = Date.now();
        pc = new RTCPeerConnection({
          iceServers: test_type === TEST_TYPE.HOST ? [] : [server],
        });

        pc.addEventListener("icegatheringstatechange", onGatheringChange);

        pc.addEventListener("icecandidate", async (event) => {
          const candidate = event.candidate;

          if (!candidate) {
            info(moduleName, "no more candidate");
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            endTime = Date.now();
            closePC();
            resolve();
          } else {
            const ice = {
              priority: candidate.priority,
              address: candidate.address,
              port: candidate.port,
              protocol:
                candidate.type === "relay"
                  ? getProtocolFromPriority(
                      candidate.priority,
                      test_type === TEST_TYPE.TURN_TLS,
                    )
                  : candidate.protocol,
              type: candidate.type,
              gatherTime: Date.now() - startTime,
            };
            log(moduleName, "got ice", ice);

            if (candidate.type === CANDIDATE_TYPE.RELAY) {
              hasRelay = true;
            } else if (candidate.type === CANDIDATE_TYPE.SRFLX) {
              address = candidate.address;
              hasSrflx = true;
            }

            if (
              (candidate.type === CANDIDATE_TYPE.SRFLX &&
                test_type === TEST_TYPE.STUN) ||
              (candidate.type === CANDIDATE_TYPE.HOST &&
                test_type === TEST_TYPE.HOST) ||
              candidate.type === CANDIDATE_TYPE.RELAY ||
              // When testing TURN/UDP, if there is no stun test already done, add the srflx candidate generated
              (candidate.type === CANDIDATE_TYPE.SRFLX &&
                test_type === TEST_TYPE.TURN_UDP &&
                !testedStun) ||
              (candidate.type === CANDIDATE_TYPE.HOST &&
                test_type !== TEST_TYPE.HOST &&
                !testedHost)
            ) {
              // Store only candidates specific to test in progress
              if (
                candidate.type === CANDIDATE_TYPE.SRFLX &&
                test_type === TEST_TYPE.TURN_UDP
              ) {
                testedStun = true;
              }

              if (
                candidate.type === CANDIDATE_TYPE.HOST &&
                test_type !== TEST_TYPE.HOST
              ) {
                testedHost = true;
              }
              const found = ices.find(
                (ice) =>
                  ice.address === candidate.address &&
                  ice.port === candidate.port,
              );
              if (!found) {
                ices.push(ice);
              }
            }
          }
        });

        pc.addEventListener("icecandidateerror", async (event) => {
          if (!testDone) {
            const existingError = errors.find((error) => {
              return (
                error.code.includes(event.errorCode.toString()) &&
                error.url === event.url
              );
            });

            if (!existingError && server.urls === event.url) {
              if (event.errorCode >= 300 && event.errorCode <= 699) {
                errors.push(
                  getErrorDetailsFromCode(event.errorCode, event.url),
                );
                error(moduleName, "got an ice error", {
                  code: event.errorCode,
                  url: event.url,
                });
              } else if (event.errorCode >= 700 && event.errorCode <= 799) {
                errors.push(
                  getErrorDetailsFromCode(event.errorCode, event.url),
                );
                error(moduleName, "got an ice warning", {
                  code: event.errorCode,
                  url: event.url,
                });
              }
            }
          }
        });

        info(moduleName, "start connection...");
        if (timeoutTest) {
          timeoutId = setTimeout(() => {
            warning(
              moduleName,
              `timeout - ICE Gathering 'Complete' event not received in ${TIMEOUT}s`,
            );
            closePC();
            hasTimeouted = true;
            resolve();
          }, TIMEOUT);
        }

        const offer = await pc.createOffer({ offerToReceiveAudio: true });
        await pc.setLocalDescription(offer);
      } catch (err) {
        error(moduleName, "impossible to generate ice", err);
        closePC();
        reject(err);
      }
    });
  };

  const closePC = () => {
    if (pc) {
      pc.close();
      pc.removeEventListener("icegatheringstatechange", onGatheringChange);
      pc.onicegatheringstatechange = null;
      pc = null;
    }
  };

  const generateIceCandidate = async () => {
    info(moduleName, "[click] generate");
    await reset();
    setTestInProgress(true, async () => {
      const username = getValueFromSetting(
        "turnUsername",
        appState.settings,
        host._id,
      );
      const password = getValueFromSetting(
        "turnPassword",
        appState.settings,
        host._id,
      );
      const token = getValueFromSetting(
        "turnToken",
        appState.settings,
        host._id,
      );
      const STUNSettingURL = getValueFromSetting(
        "stunAddress",
        appState.settings,
        host._id,
      );
      const TURNSettingURL = getValueFromSetting(
        "turnAddress",
        appState.settings,
        host._id,
      );

      const gotIces = [];
      const gotErrors = [];
      let serversForUser1 = [];
      try {
        // Get Host servers (= empty)
        serversForUser1.push(getICEHostServers());

        // Get STUN servers
        if (STUNSettingURL) {
          serversForUser1 = serversForUser1.concat(
            getICESTUNServers(STUNSettingURL, toTest),
          );
        }

        if (TURNSettingURL) {
          const cred1 = getUserCredentials(
            createUUID(),
            username,
            password,
            token,
          );
          serversForUser1 = serversForUser1.concat(
            getICETURNServers(cred1, TURNSettingURL, toTest),
          );
        }

        // Goal is to run one test per topology (per item in stunServers and turnServers
        let index = 0;
        for (const server of serversForUser1) {
          try {
            info(
              moduleName,
              `--------- test ${index} --------- with ${server.urls}`,
            );
            await tryToConnectToServer(server, gotIces, gotErrors);
            info(moduleName, "test is OK");
          } catch (err) {
            info(moduleName, "test is KO");
            gotErrors.push(
              getErrorDetailsFromCode("TC12", server.urls, err.toString()),
            );
          } finally {
            index++;
          }
        }

        if (!testedStun && toTest === "All transports") {
          gotErrors.push(getErrorDetailsFromCode("TC8", ""));
        }

        if (!testedTurnUDP && toTest === "All transports") {
          gotErrors.push(getErrorDetailsFromCode("TC9", ""));
        }

        if (!testedTurnTCP && toTest === "All transports") {
          gotErrors.push(getErrorDetailsFromCode("TC1", ""));
        }

        if (!testedTurnTLS && toTest === "All transports") {
          gotErrors.push(getErrorDetailsFromCode("TC3", ""));
        }

        if (testedTurnTLS && !hasTCPAndPort80InURL()) {
          gotErrors.push(getErrorDetailsFromCode("TC2", ""));
        }

        if (testedTurnTLS && !hasTLSAndPort443InURL()) {
          gotErrors.push(getErrorDetailsFromCode("TC4", ""));
        }

        if (password) {
          gotErrors.push(getErrorDetailsFromCode("TC5", ""));
        }

        if (hasTimeouted) {
          gotErrors.push(getErrorDetailsFromCode("TC6", ""));
        }

        setTestInProgress(false);
        setIces(gotIces);
        setIceError(gotErrors);
        setTestDone(true);
      } catch (err) {
        error(moduleName, `can't generate ice`, { err });
        gotErrors.push(getErrorDetailsFromCode("TC7", err.name));
        setIceError(gotErrors);
        setTestInProgress(false);
        setTestDone(true);
      }
    });
  };

  const getNbHostCandidates = () => {
    return ices.filter((ice) => ice.type === "host").length;
  };

  const getNbSTUNCandidates = () => {
    return ices.filter((ice) => ice.type === "srflx").length;
  };

  const getNbRelayCandidates = () => {
    return ices.filter((ice) => ice.type === "relay").length;
  };

  const behindSymmetricNat = () => {
    return ices.filter((ice) => ice.type === "srflx").length > 1;
  };

  const hasHostCandidates = () => {
    return ices.some((ice) => ice.type === "host");
  };

  const hasStunCandidates = () => {
    return ices.some((ice) => ice.type === "srflx");
  };

  const hasRelayTCP = () => {
    return ices.some((ice) => ice.type === "relay" && ice.protocol === "tcp");
  };

  const hasRelayUDP = () => {
    return ices.some((ice) => ice.type === "relay" && ice.protocol === "udp");
  };

  const hasRelayTLS = () => {
    return ices.some((ice) => ice.type === "relay" && ice.protocol === "tls");
  };

  const hasTCPAndPort80InURL = () => {
    return getValueFromSetting(
      "turnAddress",
      appState.settings,
      host._id,
    ).includes(":80?transport=tcp");
  };

  const hasTLSProtocolInURL = () => {
    return getValueFromSetting(
      "turnAddress",
      appState.settings,
      host._id,
    ).includes("turns");
  };

  const hasTLSAndPort443InURL = () => {
    return (
      getValueFromSetting("turnAddress", appState.settings, host._id).includes(
        "turns",
      ) &&
      getValueFromSetting("turnAddress", appState.settings, host._id).includes(
        ":443?transport=tcp",
      )
    );
  };

  const getPriority = (priority) => {
    return priority >> 24;
  };

  const protocolsManaged = () => {
    const protocols = [];
    if (hasSrflx) {
      protocols.push("STUN");
    }
    if (hasRelay && (testedTurnTLS || testedTurnUDP || testedTurnTCP)) {
      protocols.push("TURN");
    }

    if (!protocols.length) {
      return "NONE";
    }
    return protocols.join("/");
  };

  const getResultForStun = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedStun ? (hasStunCandidates() ? "PASSED" : "FAILED") : "IGNORED";
  };

  const getResultForHost = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedHost ? (hasHostCandidates() ? "PASSED" : "FAILED") : "IGNORED";
  };

  const getResultForTurnUDP = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedTurnUDP ? (hasRelayUDP() ? "PASSED" : "FAILED") : "IGNORED";
  };

  const getResultForTurnTCP = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedTurnTCP ? (hasRelayTCP() ? "PASSED" : "FAILED") : "IGNORED";
  };

  const getResultForTurnTLS = () => {
    if (!testDone) {
      return "N/A";
    }
    return testedTurnTLS ? (hasRelayTLS() ? "PASSED" : "FAILED") : "IGNORED";
  };

  const getDetailsForStun = () => {
    if (!testDone) {
      return "N/A";
    }
    if (hasStunCandidates()) {
      return "PASSED";
    }
    return testedStun ? "FAILED" : "DISCARDED";
  };

  const getDetailsForHost = () => {
    if (!testDone) {
      return "N/A";
    }
    if (hasHostCandidates()) {
      return "PASSED";
    }
    return "FORBIDDEN";
  };

  const getDetailsForTurnUDP = () => {
    if (!testDone) {
      return "N/A";
    }
    if (hasRelayUDP()) {
      return "PASSED";
    }
    return testedTurnUDP ? "FAILED" : "DISCARDED";
  };

  const getDetailsForTurnTCP = () => {
    if (!testDone) {
      return "N/A";
    }
    if (hasRelayTCP()) {
      return "PASSED";
    }
    return testedTurnTCP ? "FAILED" : "DISCARDED";
  };

  const getDetailsForTurnTLS = () => {
    if (!testDone) {
      return "N/A";
    }

    if (hasRelayTLS()) {
      return "PASSED";
    }
    return testedTurnTLS ? "FAILED" : "DISCARDED";
  };

  const computeScore = () => {
    let score = "-";
    if (!testDone) {
      return score;
    }

    if (
      hasRelayUDP() &&
      hasRelayTCP() &&
      hasRelayTLS() &&
      hasStunCandidates() &&
      (hasTCPAndPort80InURL() || hasTLSAndPort443InURL())
    ) {
      return "A++";
    }
    if (
      hasRelayUDP() &&
      hasRelayTCP() &&
      hasRelayTLS() &&
      hasStunCandidates()
    ) {
      return "A+";
    }
    if (
      hasRelayTLS() &&
      hasStunCandidates() &&
      (hasRelayUDP() || hasRelayTCP())
    ) {
      return "A";
    }
    if (hasRelayUDP() && hasRelayTCP() && hasStunCandidates()) {
      return "B+";
    }
    if (
      hasStunCandidates() &&
      (hasRelayUDP() || hasRelayTCP() || hasRelayTLS())
    ) {
      return "B";
    }
    if (hasHostCandidates() && hasStunCandidates()) {
      return "C";
    }
    if (hasHostCandidates()) {
      return "D";
    }
    return "E";
  };

  const getScoreIconColor = (score) => {
    if (!testDone) {
      return "";
    }
    if (score.includes("A")) {
      return "text-success";
    } else if (score.includes("B")) {
      return "text-warning";
    }
    return "text-danger";
  };

  const getTestColor = (fct) => {
    if (!testDone) {
      return "";
    }

    const result = fct.call(this);
    return result === "PASSED"
      ? "text-success"
      : result === "IGNORED"
      ? "text-warning"
      : "text-danger";
  };

  /*
  const saveTestResult = () => {
    const candidates = ices.length;
    const score = computeScore();
    const isSuccess = (score) =>
      ["A", "A+", "A++", "B", "B+", "B-"].includes(score);
    const isWarning = (score) => ["C"].includes(score);
    let gatheringTime = TIMEOUT;
    if (endTime && startTime) {
      gatheringTime = endTime - startTime;
    }
    createNewSeries(
      appState.user._id,
      host._id,
      "ice",
      new Date().toJSON(),
      isSuccess(score) ? "success" : isWarning(score) ? "warning" : "error",
      {
        gatheringTime,
        score,
        candidates,
        address,
        fromLatitude: host.latitude,
        fromLongitude: host.longitude,
      },
      appState.token,
      props.dispatch
    );
  };
   */

  const onTestsChange = (e) => {
    toTest = e.target.value;
  };

  const onTimeoutChange = (e) => {
    timeoutTest = e.target.checked;
  };

  const getOptionsForTest = () => {
    let list = ["All transports"];

    const STUNSettingURL = getValueFromSetting(
      "stunAddress",
      appState.settings,
      host._id,
    );
    const TURNSettingURL = getValueFromSetting(
      "turnAddress",
      appState.settings,
      host._id,
    );

    let stuns = [];
    let turns = [];
    if (STUNSettingURL) {
      stuns = STUNSettingURL.split(";");
    }
    if (TURNSettingURL) {
      turns = TURNSettingURL.split(";");
    }
    list = list.concat(stuns).concat(turns);

    return list.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <IcePlaceholder />}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch} />
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            {(!isStunConfigured(host, appState.settings) ||
              !isTurnConfigured(host, appState.settings)) && <NoSetup />}
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={host}
              env={host?.env}
              name={null}
            />
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="9">
                        <h5 className="card-category">testing</h5>
                        <CardTitle tag="h3">ICE CANDIDATES</CardTitle>
                      </Col>
                      <Col className="text-right" sm="3">
                        <h5 className="card-category">Score</h5>
                        <CardTitle
                          tag="h2"
                          className={getScoreIconColor(computeScore())}
                        >
                          {computeScore()}
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-left w-50">SUPPORT OF</th>
                          <th className="text-right">Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForHost)}>
                              Local Network (Host)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForHost() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getDetailsForHost()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForStun)}>
                              NAT Traversal (STUN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForStun() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getDetailsForStun()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForTurnUDP)}>
                              Relayed Media over UDP (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForTurnUDP() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getDetailsForTurnUDP()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForTurnTCP)}>
                              Relayed Media over TCP (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForTurnTCP() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getDetailsForTurnTCP()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left w-50">
                            <span className={getTestColor(getResultForTurnTLS)}>
                              Relayed Media over TLS (TURN)
                            </span>
                          </td>
                          <td className="text-right">
                            {getResultForTurnTLS() === "PASSED" && (
                              <i className="icon cafe-selected icon-selected text-success mr-2" />
                            )}
                            {getDetailsForTurnTLS()}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="exampleSelect">
                            Select transport to use
                          </Label>
                          <Input
                            type="select"
                            className="form-control"
                            defaultValue="All transports"
                            onChange={onTestsChange}
                            disabled={testInProgress}
                            style={{
                              padding: "0.3rem 2.25rem 0.3rem 0.75rem",
                              fontSize: "0.8rem",
                            }}
                          >
                            {getOptionsForTest()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <Label>Limit test duration</Label>
                        <FormGroup className="ml-4">
                          <Input
                            defaultChecked={timeoutTest}
                            disabled={testInProgress}
                            type="checkbox"
                            style={{
                              height: "18px",
                              width: "18px",
                            }}
                            value={timeoutTest}
                            onChange={onTimeoutChange}
                          />
                          <Label className="ml-1 pt-1">
                            Wait 5 seconds before timing-out
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="stats">
                      <p className="text-muted mt-2">
                        This test generates candidates based on the host's
                        settings. Please wait during the test. It can take up to
                        one minute.
                      </p>
                      <Button
                        disabled={testInProgress}
                        size="sm"
                        onClick={generateIceCandidate}
                      >
                        Test
                      </Button>
                      {testInProgress && (
                        <Spinner children={null} className="ml-2" size="sm" />
                      )}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="HOST CANDIDATES"
                  value={{ data: { host: getNbHostCandidates() } }}
                  prop="host"
                  icon="cafe-ice-host"
                  unit="u"
                  last="For Private Networks only (LAN)"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="STUN CANDIDATES"
                  value={{ data: { stun: getNbSTUNCandidates() } }}
                  prop="stun"
                  icon="cafe-ice-stun"
                  unit="u"
                  last="For Public Networks (NAT)"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <CardNumber
                  name="TURN CANDIDATES"
                  value={{ data: { relay: getNbRelayCandidates() } }}
                  prop="relay"
                  icon="cafe-ice-turn"
                  unit="u"
                  last="For Restricted networks (RELAY)"
                />
              </Col>
            </Row>
            {1 === 2 && (
              <Row>
                <Col lg="4" md="4" sm="12" xs="12">
                  <CardNumber
                    name="PROXY DETECTION"
                    value={{ data: { proxy: proxy ? "YES" : "NO" } }}
                    prop="proxy"
                    icon="cafe-proxy"
                    unit="u"
                    last="Protected Network"
                  />
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <CardNumber
                    name="SYMMETRIC NAT DETECTION"
                    value={{
                      data: { symmetric: behindSymmetricNat() ? "YES" : "NO" },
                    }}
                    prop="symmetric"
                    icon="cafe-nat"
                    unit="u"
                    last="Specific NAT Network"
                  />
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <CardNumber
                    name="FUNCTIONS"
                    value={{ data: { features: protocolsManaged() } }}
                    prop="features"
                    icon="cafe-protocols"
                    unit="u"
                    last="Host functionalities"
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5 className="card-category">ICE Connectivity</h5>
                    <CardTitle tag="h3">CANDIDATES</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-left">Type</th>
                          <th className="text-center">Protocol</th>
                          <th className="text-right">Priority</th>
                          <th className="text-right">Address</th>
                          <th className="text-center">Port</th>
                          <th className="text-right">Gathering Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ices
                          .sort((a, b) => {
                            if (
                              getPriority(a.priority) > getPriority(b.priority)
                            )
                              return -1;
                            if (
                              getPriority(a.priority) < getPriority(b.priority)
                            )
                              return 1;
                            if (a.gatherTime > b.gatherTime) return 1;
                            if (a.gatherTime <= b.gatherTime) return -1;
                          })
                          .map((ice, key) => (
                            <tr
                              key={key}
                              bgcolor={
                                ice.type === CANDIDATE_TYPE.RELAY ||
                                ice.type === CANDIDATE_TYPE.SRFLX
                                  ? "success"
                                  : ""
                              }
                            >
                              <td className="text-left">{ice.type}</td>
                              <td className="text-center">{ice.protocol}</td>
                              <td className="text-right">
                                {getPriority(ice.priority)}
                              </td>
                              <td className="text-right">{ice.address}</td>
                              <td className="text-center">{ice.port}</td>
                              <td className="text-right">{ice.gatherTime}ms</td>
                            </tr>
                          ))}
                        {ices.length === 0 && (
                          <tr>
                            <td colSpan="6">
                              <br />
                              {testDone
                                ? "No candidate found"
                                : "No result yet"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {testDone && hasSrflx && hasRelay && (
                      <p className="">
                        <i className="icon cafe-selected icon-selected" />{" "}
                        Required candidates have been successfully generated
                      </p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-stats">
                  <CardHeader>
                    <h5 className="card-category">
                      Transport warnings and errors
                    </h5>
                    <CardTitle tag="h3">TO CONSIDER</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-left">Type</th>
                          <th className="text-left">Description</th>
                          <th className="text-left">Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        {iceError.map((error, key) => (
                          <tr key={key}>
                            <td className="text-left">
                              <span
                                className={
                                  error.level === "error"
                                    ? "text-danger"
                                    : "text-warning"
                                }
                              >
                                {error.code}
                              </span>
                            </td>
                            <td className="text-left">{error.text}</td>
                            <td className="text-left">{error.url}</td>
                          </tr>
                        ))}
                        {iceError.length === 0 && (
                          <tr>
                            <td colSpan="6">
                              <br />
                              {testDone ? "No error found" : "No result yet"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {iceError.length > 0 && (
                      <p>
                        Note: Consider these points in case of missing
                        candidates.
                      </p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default Ice;
