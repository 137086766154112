import { useRef, useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import "../testing/media.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import { createChart, updateChart } from "../../modules/callCharts";
import CardCallStat from "../CardCallStat";
import { CHART_COLORS } from "../../modules/helper";

function MOSStat({ dispatch }) {
  const appState = useContext(AppContext);
  const statsMOSChart = useRef();
  const [mos, setMos] = useState({});
  const [mosEmodel, setMosEModel] = useState({});

  useEffect(() => {
    createChart(document.querySelector("#chartMOS"), statsMOSChart, "MOS");
  }, []);

  useEffect(() => {
    updateChart(
      //appState.statsMOS,
      appState.statsMOSEModel,
      null,
      statsMOSChart,
      2,
      5
    );
  }, [appState.statsMOSEModel]);

  useEffect(() => {
    setMos(appState.finalMOSEModel);
  }, [appState.finalMOSEModel]);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Call quality measurement</h5>
        <CardTitle tag="h3">MEAN OPINION SCORE</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <CardCallStat
              min={mos.min}
              avg={mos.avg}
              max={mos.max}
              vol={mos.volatility}
              unit=""
              fixed={3}
              valueColor={CHART_COLORS.yellow}
              name="MOS"
              thresholds={[4.1, 3.6]}
              thresholdInverse={false}
            />
          </Col>
          <Col md="6"></Col>
        </Row>
        <div className="graph">
          <canvas id="chartMOS"></canvas>
        </div>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default MOSStat;
