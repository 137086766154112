import {log} from "../modules/logger";
import {APP_ACTIONS} from "./constants";
import {getInstances} from "./instanceActions";
import {getOutages, getOutagesForInstance, getSLA} from "./OutageActions";
import {getSettings} from "./settingActions";
import {
  getSeriesForAllInstances,
  getSeriesForInstance,
  getSeriesTestingForInstance, getTimelineForInstance,
} from "./seriesActions";
import {DateTime} from "luxon";
import {
  getLastUsageForAllHosts,
  getSummaryForInstance,
  getSummaryOverall,
  getTodayStatusForAllHosts,
} from "./summaryActions";
import {getLinksForInstance} from "./linkActions";
import {getParametersForUser} from "./parametersActions";
import {getCDRForUser} from "./cdrActions";
import {getAllVersionsforHost, getLastVersionOfAllHosts} from "./versionsActions";
import {getAllCustomization} from "../modules/status";
import {getOpsOfHost} from "./opsActions";
import {getAllOperationsForAHost} from "./operationActions";

const moduleName = "initActions";

export const getCustomization = async (token, dispatch) => {
  log(moduleName, "execute action", {action: "getCustomization"});
  dispatch({
    type: APP_ACTIONS.GET_CUSTOMIZATION_IN_PROGRESS,
    payload: {value: null},
  });

  try {
    const customization = await getAllCustomization(token, dispatch);
    dispatch({
      type: APP_ACTIONS.GET_CUSTOMIZATION_SUCCESS,
      payload: {value: customization},
    });
  } catch (err) {
    dispatch({
      type: APP_ACTIONS.GET_CUSTOMIZATION_FAILED,
      payload: {value: null},
    });
  }
};

export const initializeUserData = async (userId, token, dispatch) => {
  log(moduleName, "execute action", {action: "initializeUserData"});
  try {
    await getInstances(userId, token, dispatch);
    await getOutages(userId, token, dispatch);
    await getSLA(userId, null, token, dispatch);
    await getParametersForUser(userId, token, dispatch);
    await getSeriesForAllInstances(userId, token, dispatch);
    await getCDRForUser(userId, token, dispatch);
    await getSummaryOverall(userId, token, dispatch);
    await getLastVersionOfAllHosts(userId, token, dispatch);
    await getLastUsageForAllHosts(userId, token, dispatch);
    await getTodayStatusForAllHosts(userId, token, dispatch);

    //if (instances.length === 0) {
    dispatch({
      type: APP_ACTIONS.USER_DATA_LOADING_SUCCESS,
      payload: {value: null},
    });
    //}
  } catch {
    dispatch({
      type: APP_ACTIONS.USER_DATA_LOADING_FAILED,
      payload: {value: null},
    });
  }
};

export const initializeInstanceData = async (
  userId,
  instanceId,
  token,
  dispatch,
) => {
  log(moduleName, "execute action", {action: "initializeInstanceData"});

  try {
    await getSettings(instanceId, token, dispatch);
    await getSeriesForInstance(userId, instanceId, token, dispatch);
    await getSeriesTestingForInstance(userId, instanceId, token, dispatch);
    await getOutagesForInstance(
      userId,
      instanceId,
      DateTime.now().minus({months: 3}),
      DateTime.now().endOf("month"),
      token,
      dispatch,
    );
    await getSummaryForInstance(userId, instanceId, token, dispatch);
    await getLinksForInstance(userId, instanceId, token, dispatch);
    await getOpsOfHost(userId, instanceId, token, dispatch);
    await getAllOperationsForAHost(userId, instanceId, token, dispatch);
    await getTimelineForInstance(userId, instanceId, token, dispatch);
    await getAllVersionsforHost(userId, instanceId, token, dispatch);
    setTimeout(() => {
      dispatch({
        type: APP_ACTIONS.USER_INSTANCE_DATA_LOADING_SUCCESS,
        payload: {value: null},
      });
    }, 200);
  } catch {
    dispatch({
      type: APP_ACTIONS.USER_DATA_LOADING_FAILED,
      payload: {value: null},
    });
  }
};

export const bypassInstanceData = async (dispatch) => {
  log(moduleName, "execute action", {action: "bypassInstanceData"});
  dispatch({
    type: APP_ACTIONS.USER_INSTANCE_DATA_LOADING_SUCCESS,
    payload: {value: null},
  });
};
