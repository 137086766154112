import HostPlaceHolder from "../placeholder/HostPlaceHolder";
import NoHost from "../NoHost";
import React, {useContext, useState, useEffect, useRef} from "react";
import HeatMap from "@uiw/react-heat-map";
import AppContext from "../../contexts/appContext";
import {Card, CardBody, Row, Col, Badge, Label, CardTitle} from "reactstrap";
import "./Dashboard.css";
import {DateTime} from "luxon";
import CardSimple from "../CardSimple";
import {PALETTE_COLORS} from "../../modules/helper";
import BreadCrumbCusto from "../BreadCrumb";
import {Link} from "react-router-dom";

function Dashboard(props) {
  const appState = useContext(AppContext);
  const [prod, setProd] = useState([]);
  const [staging, setStaging] = useState([]);
  const [others, setOthers] = useState([]);
  const [today, setToday] = useState({});
  const [width, setWidth] = useState(null);
  const [start, setStart] = useState(
    DateTime.now().minus({months: 3}).toJSDate(),
  );
  const [selected, setSelected] = useState('')

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const w = entry.contentRect.width;
        const prevMonth = Math.floor(w / 100);
        setStart(
          DateTime.now()
            .minus({months: prevMonth + 2})
            .toJSDate(),
        );
        setWidth(w);
      }
    });
    const heatmap = document.querySelector("#heatmap");
    const heatmap_staging = document.querySelector("#heatmap-staging");
    const heatmap_dev = document.querySelector("#heatmap-dev");
    if (heatmap) {
      resizeObserver.observe(heatmap);
    } else if (heatmap_staging) {
      resizeObserver.observe(heatmap_staging);
    } else if (heatmap_dev) {
      resizeObserver.observe(heatmap_dev);
    }

  }, []);

  useEffect(() => {
    setToday(appState.today);
  }, [appState.today]);

  useEffect(() => {
    if (appState.overallSummaryStatus) {
      const production = appState.overallSummaryStatus.production;
      const staging = appState.overallSummaryStatus.staging;
      const others = appState.overallSummaryStatus.others;

      if (production) {
        const prodValues = mapResult(production);
        setProd(prodValues);
      }

      if (staging) {
        const stagingValues = mapResult(staging);
        setStaging(stagingValues);
      }

      if (others) {
        const othersValues = mapResult(others);
        setOthers(othersValues);
      }
    }
  }, [appState.overallSummaryStatus]);

  const mapResult = (results) => {
    return results.map((day) => {
      const date = DateTime.fromISO(day.created);

      const strDate = `${date.year}/${
        date.month < 10 ? `0${date.month}` : date.month
      }/${date.day < 10 ? `0${date.day}` : date.day}`;

      const totalCount = day.count;
      let count = 0;
      if (day.error) {
        count = 2;
      } else if (day.warning) {
        count = 1;
      }

      return {date: strDate, count};
    });
  };

  const countFor = (instances, env) => {
    if (!instances) {
      return 0;
    }
    if (env === "others") {
      return instances.filter(
        (instance) =>
          instance.env !== "production" && instance.env !== "staging",
      ).length;
    }
    return instances.filter((instance) => instance.env === env).length;
  };

  const getCompany = (user) => {
    if (!user.company) {
      return "your company";
    }

    return user.company;
  };

  return (
    <div className="content-top">
      {!appState.firstTimeUserAndInstanceLoaded && <HostPlaceHolder/>}
      {appState.instances &&
        appState.instances.length === 0 &&
        appState.firstTimeUserAndInstanceLoaded && (
          <NoHost dispatch={props.dispatch}/>
        )}
      {appState.firstTimeUserAndInstanceLoaded &&
        appState.instances &&
        appState.instances.length > 0 && (
          <>
            <BreadCrumbCusto
              dispatch={props.dispatch}
              instance={null}
              env={null}
            />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>
                      <p style={{display: "flex"}}>
                        <span className="mr-1">
                          Welcome to your infrastructure at
                        </span>
                        <Badge color="blue" className="mr-2">
                          {getCompany(appState.user)}
                        </Badge>
                      </p>
                    </CardTitle>
                    <p style={{display: "flex"}}>
                      <span className="mr-1">
                        Here is the latest update in your environments
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category fs-5">
                  <Link to="/production" style={{display: "flex"}}>
                    <i className={`icon cafe-production action-button-image`}/>{" "}
                    PRODUCTION ENVIRONMENT (
                    {countFor(appState.instances, "production")})
                  </Link>
                </span>{" "}
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col
                xxl="4"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                className="mb-3"
              >
                <div id="heatmap">
                  <HeatMap
                    value={prod}
                    width={width || "100%"}
                    height={"120px"}
                    monthLabels={undefined}
                    legendCellSize={0}
                    style={{color: "#666", backgroundColor: "fff"}}
                    startDate={start}
                    panelColors={PALETTE_COLORS}
                    rectRender={(props, data) => {
                      if (data.date === new Date()) {
                        props.color = "#000000"
                      }
                      return (
                        <rect {...props} />
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xxl="8" xl="6" lg="6" md="12" sm="12" xs="12">
                {countFor(appState.instances, "production") > 0 && (
                  <Row>
                    {appState.instances
                      .filter((instance) => instance.env === "production")
                      .map((instance, key) => (
                        <Col
                          xxl="6"
                          xl="12"
                          lg="12"
                          md="6"
                          sm="12"
                          xs="12"
                          key={key}
                        >
                          <CardSimple
                            data={{
                              instance: instance,
                              today: today[instance._id],
                            }}
                            dispatch={props.dispatch}
                          />
                        </Col>
                      ))}
                  </Row>
                )}
                {countFor(appState.instances, "production") === 0 && (
                  <Row>
                    <Col>
                      <p className="text-muted">No host for this environment</p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row className="mt-4">
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category fs-5">
                  <Link to={"/staging"} style={{display: "flex"}}>
                    <i className={`icon cafe-staging action-button-image`}/>{" "}
                    STAGING ENVIRONMENT (
                    {countFor(appState.instances, "staging")})
                  </Link>
                </span>
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col
                xxl="4"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                className="mb-3"
              >
                <div id="heatmap-staging">
                  <HeatMap
                    value={staging}
                    width={width || "100%"}
                    height={"120px"}
                    monthLabels={undefined}
                    legendCellSize={0}
                    style={{color: "#666", backgroundColor: "fff"}}
                    startDate={start}
                    panelColors={PALETTE_COLORS}
                  />
                </div>
              </Col>
              <Col xxl="8" xl="6" lg="6" md="12" sm="12" xs="12">
                {countFor(appState.instances, "staging") > 0 && (
                  <Row>
                    {appState.instances
                      .filter((instance) => instance.env === "staging")
                      .map((instance, key) => (
                        <Col
                          xxl="6"
                          xl="12"
                          lg="12"
                          md="6"
                          sm="12"
                          xs="12"
                          key={key}
                        >
                          <CardSimple
                            data={{
                              instance: instance,
                              today: today[instance._id],
                            }}
                            dispatch={props.dispatch}
                          />
                        </Col>
                      ))}
                  </Row>
                )}
                {countFor(appState.instances, "staging") === 0 && (
                  <Row>
                    <Col>
                      <p className="text-muted">No host for this environment</p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row className="mt-4">
              <Label xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="card-category fs-5">
                  <Link to={"/others"} style={{display: "flex"}}>
                    <i className={`icon cafe-others action-button-image`}/>{" "}
                    OTHERS ENVIRONMENT ({countFor(appState.instances, "others")}
                    )
                  </Link>
                </span>
                <div style={{borderBottom: "4px solid #28293d"}}/>
              </Label>
              <Col
                xxl="4"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                className="mb-3"
              >
                <div id="heatmap-dev">
                  <HeatMap
                    value={others}
                    width={width || "100%"}
                    height={"120px"}
                    monthLabels={undefined}
                    legendCellSize={0}
                    style={{color: "#666", backgroundColor: "fff"}}
                    startDate={start}
                    panelColors={PALETTE_COLORS}
                  />
                </div>
              </Col>
              <Col xxl="8" xl="6" lg="6" md="12" sm="12" xs="12">
                {countFor(appState.instances, "others") > 0 && (
                  <Row>
                    {appState.instances
                      .filter(
                        (instance) =>
                          instance.env !== "production" &&
                          instance.env !== "staging",
                      )
                      .map((instance, key) => (
                        <Col
                          xxl="6"
                          xl="12"
                          lg="12"
                          md="6"
                          sm="12"
                          xs="12"
                          key={key}
                        >
                          <CardSimple
                            data={{
                              instance: instance,
                              today: today[instance._id],
                            }}
                            dispatch={props.dispatch}
                          />
                        </Col>
                      ))}
                  </Row>
                )}
                {countFor(appState.instances, "others") === 0 && (
                  <Row>
                    <Col>
                      <p className="text-muted">No host for this environment</p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        )}
    </div>
  );
}

export default Dashboard;
