import CONFIG from "./environment";
import axios from "axios";
import { createHeader } from "./helper";
import { error, log } from "./logger";

const moduleName = "mod-admin";

export const getGlobalMetrics = async (token) => {
  try {
    const url = `${CONFIG.restUrl}/admin/metrics`;
    const response = await axios.get(url, { headers: createHeader(token) });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "metrics received", { data: response.data.data });
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/admin/metrics`, { error: err });
    return [];
  }
};

export const getAccounts = async (page, token) => {
  try {
    const url = `${CONFIG.restUrl}/admin/accounts?page=${page}`;
    const response = await axios.get(url, { headers: createHeader(token) });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "users received", { data: response.data.data });
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/admin/accounts`, { error: err });
    return [];
  }
};

export const getAllJobs = async (token) => {
  try {
    const url = `${CONFIG.restUrl}/admin/jobs`;
    const response = await axios.get(url, { headers: createHeader(token) });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "jobs received", { data: response.data.data });
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/admin/jobs`, { error: err });
    return [];
  }
};

export const createAccount = async (
  userName,
  password,
  profile,
  role,
  token
) => {
  try {
    const url = `${CONFIG.restUrl}/admin/accounts`;
    const response = await axios.post(
      url,
      { userName, password, profile, role },
      { headers: createHeader(token) }
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "user created", { data: response.data.data });
      return response.data.data;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, `can't GET /api/admin/accounts`, { error: err });
    return [];
  }
};

export const sendAdminCommand = async (command, token) => {
  try {
    const url = `${CONFIG.restUrl}/admin/command`;
    const response = await axios.post(
      url,
      { command },
      { headers: createHeader(token) }
    );
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "command executed", { data: response.data.data });
      return 1;
    } else {
      return -1;
    }
  } catch (err) {
    error(moduleName, `can't GET /api/admin/command`, { error: err });
    return -1;
  }
};
