import {log} from "../modules/logger";
import {OPERATIONS} from "./constants";
import {
  createOperation, deleteOperation,
  getHostOperations,
  updateOperation,
} from "../modules/status";

const moduleName = "operationActions";

export const createAnOperation = async (
  userId,
  instanceId,
  params,
  token,
  dispatch,
) => {
  log(moduleName, "create a new operations", {userId, instanceId});
  dispatch({
    type: OPERATIONS.CREATE_OPERATIONS_IN_PROGRESS,
    payload: {value: null},
  });

  let data = null;

  try {
    data = await createOperation(userId, instanceId, params, token);

    if (data) {
      dispatch({
        type: OPERATIONS.CREATE_OPERATIONS_SUCCESS,
        payload: {value: data},
      });
    } else {
      dispatch({
        type: OPERATIONS.CREATE_OPERATIONS_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: OPERATIONS.CREATE_OPERATIONS_FAILED,
      payload: {value: null},
    });
  }
};

export const updateAnOperation = async (
  userId,
  instanceId,
  operationId,
  params,
  token,
  dispatch,
) => {
  log(moduleName, "Update an operation", {userId, instanceId, operationId});
  dispatch({
    type: OPERATIONS.UPDATE_OPERATIONS_IN_PROGRESS,
    payload: {value: null},
  });

  let data = null;

  try {
    data = await updateOperation(
      userId,
      instanceId,
      operationId,
      params,
      token,
    );

    if (data) {
      dispatch({
        type: OPERATIONS.UPDATE_OPERATIONS_SUCCESS,
        payload: {value: data},
      });
    } else {
      dispatch({
        type: OPERATIONS.UPDATE_OPERATIONS_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: OPERATIONS.UPDATE_OPERATIONS_FAILED,
      payload: {value: null},
    });
  }
};

export const deleteAnOperation = async (
  userId,
  instanceId,
  operationId,
  perPage,
  token,
  dispatch,
) => {
  log(moduleName, "delete an operations", {userId, instanceId, operationId});
  dispatch({
    type: OPERATIONS.DELETE_OPERATIONS_IN_PROGRESS,
    payload: {value: null},
  });

  let data = null;

  try {
    data = await deleteOperation(userId, instanceId, operationId, perPage, token);

    if (data) {
      dispatch({
        type: OPERATIONS.DELETE_OPERATIONS_SUCCESS,
        payload: {value: data},
      });
    } else {
      dispatch({
        type: OPERATIONS.DELETE_OPERATIONS_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: OPERATIONS.DELETE_OPERATIONS_FAILED,
      payload: {value: null},
    });
  }
};

export const getAllOperationsForAHost = async (
  userId,
  instanceId,
  token,
  dispatch,
) => {
  log(moduleName, "Get operations", {userId, instanceId});
  dispatch({
    type: OPERATIONS.GET_OPERATIONS_IN_PROGRESS,
    payload: {value: null},
  });

  let data = null;

  try {
    data = await getHostOperations(userId, instanceId, -1, 20, token);

    if (data) {
      dispatch({
        type: OPERATIONS.GET_OPERATIONS_SUCCESS,
        payload: {value: data},
      });
    } else {
      dispatch({
        type: OPERATIONS.GET_OPERATIONS_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: OPERATIONS.GET_OPERATIONS_FAILED,
      payload: {value: null},
    });
  }
};

export const getAllOperationsPagedForAHost = async (
  userId,
  instanceId,
  page = 0,
  perPage = 20,
  token,
  dispatch,
) => {
  log(moduleName, "Get operations", {userId, instanceId});
  dispatch({
    type: OPERATIONS.GET_OPERATIONS_PAGED_IN_PROGRESS,
    payload: {value: null},
  });

  let data = null;

  try {
    data = await getHostOperations(userId, instanceId, page, perPage, token);

    if (data) {
      dispatch({
        type: OPERATIONS.GET_OPERATIONS_PAGED_SUCCESS,
        payload: {value: data},
      });
    } else {
      dispatch({
        type: OPERATIONS.GET_OPERATIONS_PAGED_FAILED,
        payload: {value: null},
      });
    }
  } catch (err) {
    dispatch({
      type: OPERATIONS.GET_OPERATIONS_PAGED_FAILED,
      payload: {value: null},
    });
  }
};

export const setCurrentOperation = (operation, dispatch) => {
  dispatch({
    type: OPERATIONS.SET_CURRENT_OPERATION_SUCCESS,
    payload: {value: operation},
  });
}
