const statsRoutes = [
  {
    path: "/monitoring/:hostId/trends",
    name: "Trends",
    icon: "icon cafe-monitoring-1",
  },
  {
    path: "/monitoring/:hostId/health",
    name: "Health",
    icon: "icon cafe-monitor",
  },
  {
    path: "/monitoring/:hostId/usage",
    name: "Usage",
    icon: "icon cafe-monitoring",
  },
  {
    path: "/monitoring/:hostId/tests",
    name: "Tests",
    icon: "icon cafe-chemistry",
  },
  {
    path: "/monitoring/:hostId/history",
    name: "History",
    icon: "icon cafe-list",
  },
];

export default statsRoutes;
