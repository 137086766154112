import { useState, useContext, useEffect } from "react";
import { CardTitle, Col, Row } from "reactstrap";
import AppContext from "../../contexts/appContext";

import "./media.css";
import { HOST_SERVICE } from "../../modules/bm";
import CardNumber from "../CardNumber";
import { getLabelFromDistance } from "../../modules/geo";

function HostManualTests({ dispatch }) {
  const appState = useContext(AppContext);
  const [series, setSeries] = useState([]);

  const TIMEOUT = 5000;

  useEffect(() => {
    if (appState.testingSeries) {
      setSeries(appState.testingSeries);
    }
  }, [appState.testingSeries]);

  const computeAverageSuccessRate = (series) => {
    const values = series.filter((serie) => serie.status === "success");
    const avgSuccess =
      series.length > 0 ? (values.length / series.length) * 100 : null;
    return avgSuccess ? avgSuccess.toFixed(2) : "-";
  };

  const computeAverageConnectionTime = (series) => {
    const values = series.filter(
      (serie) => serie.connectionTime && serie.connectionTime !== TIMEOUT
    );
    const avgTime =
      values.length > 0
        ? values.reduce((a, c) => a + c.connectionTime, 0) / values.length
        : null;
    return avgTime ? avgTime.toFixed(0) : "-";
  };

  const computeAverageGatheringTime = (series) => {
    const values = series.filter(
      (serie) => serie.gatheringTime && serie.gatheringTime !== TIMEOUT
    );
    const avgTime =
      values.length > 0
        ? values.reduce((a, c) => a + c.gatheringTime, 0) / values.length
        : null;
    return avgTime ? avgTime.toFixed(0) : "-";
  };

  const computeAverageDistanceFromHost = (series) => {
    const values = series.filter((serie) => serie.distance);
    const avgDistance =
      values.length > 0
        ? values.reduce((a, c) => a + c.distance, 0) / values.length
        : null;

    if (Number.isNaN(avgDistance)) {
      return "-";
    }
    return getLabelFromDistance(avgDistance);
  };

  return (
    <Row>
      <Col lg="3" md="6" sm="12" xs="12">
        <CardNumber
          name="SUCCESS Rate"
          value={{ data: { success: computeAverageSuccessRate(series) } }}
          prop="success"
          icon="cafe-creation"
          unit="p"
          last="Average time to connect"
        />
      </Col>
      <Col lg="3" md="6" sm="12" xs="12">
        <CardNumber
          name="GATHERING TIME"
          value={{ data: { time: computeAverageGatheringTime(series) } }}
          prop="time"
          icon="cafe-creation"
          unit="ms"
          last="Average gathering time"
        />
      </Col>
      <Col lg="3" md="6" sm="12" xs="12">
        <CardNumber
          name="CONNECTION TIME"
          value={{ data: { time: computeAverageConnectionTime(series) } }}
          prop="time"
          icon="cafe-creation"
          unit="ms"
          last="Average time to connect"
        />
      </Col>
      <Col lg="3" md="6" sm="12" xs="12">
        <CardNumber
          name="DISTANCE"
          value={{ data: { distance: computeAverageDistanceFromHost(series) } }}
          prop="distance"
          icon="cafe-creation"
          unit="u"
          last="Average distance to host"
        />
      </Col>
    </Row>
  );
}

export default HostManualTests;
