import { useRef, useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import "../testing/media.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import { createChart, updateChart } from "../../modules/callCharts";
import CardCallStat from "../CardCallStat";
import { CHART_COLORS } from "../../modules/helper";

function RTTStats({ dispatch }) {
  const appState = useContext(AppContext);
  const statsRTTChart = useRef();
  const [rttAudio, setRTTAudio] = useState({});
  const [rttVideo, setRTTVideo] = useState({});

  useEffect(() => {
    createChart(document.querySelector("#chartRTT"), statsRTTChart, "RTT");
  }, []);

  useEffect(() => {
    updateChart(appState.statsRTT, appState.statsRTTVideo, statsRTTChart, 100);
  }, [appState.statsRTTVideo, appState.statsRTT]);

  useEffect(() => {
    setRTTAudio(appState.finalRTT);
  }, [appState.finalRTT]);

  useEffect(() => {
    setRTTVideo(appState.finalRTTVideo);
  }, [appState.finalRTTVideo]);

  return (
    <Card className="card-stats">
      <CardHeader>
        <h5 className="card-category">Time to travel measurement</h5>
        <CardTitle tag="h3">ROUND TRIP TIME</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <CardCallStat
              min={rttAudio.min}
              avg={rttAudio.avg}
              max={rttAudio.max}
              vol={rttAudio.volatility}
              unit="ms"
              fixed={0}
              name="Audio"
              thresholds={[100, 200]}
              thresholdInverse={true}
            />
          </Col>
          <Col md="6">
            <CardCallStat
              min={rttVideo.min}
              avg={rttVideo.avg}
              max={rttVideo.max}
              vol={rttVideo.volatility}
              unit="ms"
              fixed={0}
              name="Video"
              thresholds={[100, 200]}
              thresholdInverse={true}
            />
          </Col>
        </Row>
        <div className="graph">
          <canvas id="chartRTT"></canvas>
        </div>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default RTTStats;
