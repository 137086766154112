import CryptoJS from "crypto-js";
import { createUUID } from "./helper";

export const CANDIDATE_TYPE = {
  RELAY: "relay",
  SRFLX: "srflx",
  HOST: "host",
};

export const TEST_TYPE = {
  HOST: "host",
  STUN: "stun",
  TURN_UDP: "turn_udp",
  TURN_TCP: "turn_tcp",
  TURN_TLS: "turn_tls",
  UNKNOWN: "unknown",
  UNRESTRICTED: "unrestricted",
};

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
};

export const getProtocolFromPriority = (priority, isForTLS = false) => {
  let protocolNumber = priority >> 24;

  const useFirefox = isFirefox();

  if (useFirefox) {
    // TCP
    if (protocolNumber === 0) {
      if (isForTLS) {
        protocolNumber = 0;
      } else {
        protocolNumber = 1;
      }
    }
    // UDP
    if (protocolNumber === 5) {
      protocolNumber = 2;
    }
  }

  switch (protocolNumber) {
    case 0:
      return "tls";
    case 1:
      return "tcp";
    case 2:
      return "udp";
    default:
      return "unknown";
  }
};

export const isBehindAProxy = async () => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.onload = () => {
      const proxyHeader = "via";
      const header = req.getResponseHeader(proxyHeader);
      if (header) {
        resolve(true);
        return;
      }
      resolve(false);
    };
    req.onloadend = () => {};

    req.onabort = (event) => {
      reject();
    };

    req.ontimeout = () => {
      reject();
    };

    req.onerror = (err) => {
      reject();
    };

    req.open("GET", document.location);
    req.send();
  });
};

export const getTURNCredentials = (
  name,
  turnUsername,
  turnPassword,
  secret,
) => {
  // First check if token is provided
  if (secret) {
    const unixTimeStamp = parseInt(Date.now() / 1000) + 24 * 3600; // this credential would be valid for the next 24 hours
    const username = [unixTimeStamp, name].join(":");
    const hmac = CryptoJS.HmacSHA1(username, secret);
    const password = CryptoJS.enc.Base64.stringify(hmac);
    return {
      username,
      password,
      ltcred: false,
    };
  }

  // Second check if username and password are provided
  if (turnUsername && turnPassword) {
    return {
      username: turnUsername,
      password: turnPassword,
      ltcred: true,
    };
  }

  // else return empty credentials
  return {
    username: "username",
    password: "password",
    ltcred: true,
  };
};

export const getUserCredentials = (uuid, username, password, token) => {
  const callId = createUUID();
  const credentials = getTURNCredentials(
    `tcstun-${uuid}#${callId}`,
    username,
    password,
    token,
  );
  return credentials;
};

export const getICETURNServers = (
  credentials,
  url,
  filter = "All transports",
) => {
  let TURNServers = url
    .replace(" ", "")
    .trim()
    .split(";")
    .filter((url) => url.length > 0);

  if (filter !== "All transports") {
    TURNServers = TURNServers.filter((url) => url === filter);
  }

  return TURNServers.map((url) => {
    if (!url.startsWith("turn:") && !url.startsWith("turns:")) {
      url = `turn:${url}`;
    }
    return {
      urls: url,
      username: credentials.username,
      credential: credentials.password,
    };
  });
};

export const getICESTUNServers = (url, filter = "All transports") => {
  let STUNServers = url
    .replace(" ", "")
    .trim()
    .split(";")
    .filter((url) => url.length > 0);

  if (filter !== "All transports") {
    STUNServers = STUNServers.filter((url) => url === filter);
  }

  return STUNServers.map((url) => {
    if (!url.startsWith("stun:")) {
      url = `stun:${url}`;
    }
    return {
      urls: url,
    };
  });
};

export const getICEHostServers = () => {
  return {};
};

export const detectTypeOfICETest = (server) => {
  if (!server) {
    return TEST_TYPE.UNKNOWN;
  }

  if (!("urls" in server)) {
    return TEST_TYPE.HOST;
  }

  if (server.urls.startsWith("stun:")) {
    return TEST_TYPE.STUN;
  }

  if (server.urls.startsWith("turns:")) {
    return TEST_TYPE.TURN_TLS;
  }

  if (server.urls.startsWith("turn:")) {
    if (server.urls.includes("?transport=tcp")) {
      return TEST_TYPE.TURN_TCP;
    }
    return TEST_TYPE.TURN_UDP;
  }

  return TEST_TYPE.UNKNOWN;
};

export const detectIceTypeFromUrl = (url) => {
  if (!url) {
    return TEST_TYPE.HOST;
  }

  if (url.startsWith("All")) {
    return TEST_TYPE.UNRESTRICTED;
  }

  if (url.startsWith("stun:")) {
    return TEST_TYPE.STUN;
  }

  if (url.startsWith("turns:")) {
    return TEST_TYPE.TURN_TLS;
  }

  if (url.startsWith("turn:")) {
    if (url.includes("?transport=tcp")) {
      return TEST_TYPE.TURN_TCP;
    }
    return TEST_TYPE.TURN_UDP;
  }

  return TEST_TYPE.HOST;
};
