const ICE_ERRORS = {
  300: {
    code: "300 - Try Alternate",
    level: "warning",
    text: "The browser should contact an alternate server for this request.",
  },
  400: {
    code: "400 - Bad Request",
    level: "error",
    text: "The request sent by the browser was malformed.",
  },
  401: {
    code: "401 - Unauthenticated",
    level: "error",
    text: "The request did not contain the correct credentials to proceed.",
  },
  403: {
    code: "403 - Forbidden",
    level: "error",
    text: "The request was valid but cannot be performed due to administrative or similar restrictions.",
  },
  405: {
    code: "405 - Mobility Forbidden",
    level: "error",
    text: "The mobility request was valid but cannot be performed due to administrative or similar restrictions.",
  },
  420: {
    code: "420 - Unknown Attribute",
    level: "error",
    text: "The server received a STUN packet containing a comprehension-required attribute that it did not understand.",
  },
  430: {
    code: "430 - Stale Credentials",
    level: "error",
    text: "The Binding Request did contain a MESSAGE-INTEGRITY attribute, but it used a shared secret that has expired.",
  },
  431: {
    code: "431 - Integrity Check Failure",
    level: "error",
    text: "The Binding Request contained a MESSAGE-INTEGRITY attribute, but the HMAC failed verification. This could be a sign of a potential attack.",
  },
  432: {
    code: "432 - Missing Username",
    level: "error",
    text: "The Binding Request contained a MESSAGE-INTEGRITY attribute, but not a USERNAME attribute. Both must be present for integrity checks.",
  },
  433: {
    code: "433 - Use TLS",
    level: "error",
    text: "The Shared Secret request has to be sent over TLS, but was not received over TLS.",
  },
  437: {
    code: "437 - Allocation Mismatch",
    level: "error",
    text: "A request was received by the server that requires an allocation to be in place, but no allocation exists, or a request was received that requires no allocation, but an allocation exists.",
  },
  438: {
    code: "438 - Stale Nonce",
    level: "error",
    text: "The NONCE used by the client was no longer valid. The client should retry, using the NONCE provided in the response.",
  },
  440: {
    code: "440 - Address Family not Supported",
    level: "error",
    text: "The server does not support the address family requested by the client.",
  },
  441: {
    code: "441 - Wrong Credentials",
    level: "error",
    text: "The credentials in the (non-Allocate) request do not match those used to create the allocation.",
  },
  442: {
    code: "442 - Unsupported Transport Protocol",
    level: "error",
    text: "The Allocate request asked the server to use a transport protocol between the server and the peer that the server does not support.",
  },
  443: {
    code: "443 - Peer Address Family Mismatch",
    level: "error",
    text: "A peer address is part of a different address family than that of the relayed transport address of the allocation.",
  },
  446: {
    code: "446 - Connection already exists",
    level: "error",
    text: "The server is currently processing a Connect request for this allocation.",
  },
  447: {
    code: "447 - Connection Timeout or Failure",
    level: "error",
    text: "The connection attempt fails or times out.",
  },
  486: {
    code: "486 - Allocation Quota Reached",
    level: "error",
    text: "No more allocations using this username can be created at the present time.",
  },
  487: {
    code: "487 - Role Conflict",
    level: "error",
    text: "The Binding request contained either the ICE-CONTROLLING or ICE-CONTROLLED attribute, indicating an ICE role that conflicted with the server.",
  },
  500: {
    code: "500 - Server Error",
    level: "error",
    text: "The server has suffered a temporary error. The client should try again.",
  },
  508: {
    code: "508 - Insufficient Capacity",
    level: "warning",
    text: "The server is unable to carry out the request due to some capacity limit being reached.",
  },
  600: {
    code: "600 - Global Failure",
    level: "error",
    text: "The server is refusing to fulfill the request.",
  },
  701: {
    code: "701 - Unreachable",
    level: "error",
    text: "No host candidate can reach the server.",
  },
  TC1: {
    code: "Missing TURN/TCP",
    level: "warning",
    text: "The TURN transport URL of type TCP is not configured. Please check your settings.",
  },
  TC2: {
    code: "Missing TURN/TCP port 80",
    level: "warning",
    text: "The TURN transport URL of type TCP with port 80 could help to traverse network equipments when other ports are blocked. Consider to support this configuration if needed.",
  },
  TC3: {
    code: "Missing TURN/TLS",
    level: "warning",
    text: "The TURN transport URL of type TLS is not configured. Please check your settings.",
  },
  TC4: {
    code: "Missing TURN/TLS port 443",
    level: "warning",
    text: "The TURN transport URL of type TLS with port 443 is not configured. Please check your settings",
  },
  TC5: {
    code: "Long Term Credentials",
    level: "warning",
    text: "Long term credentials seems to be used - Consider using a time-limited secret-based authentication",
  },
  TC6: {
    code: "ICE Gathering Timeout",
    level: "warning",
    text: "Too long to receive the ICE Gathering 'Complete' event (Timeout)",
  },
  TC7: {
    code: "Invalid URL",
    level: "error",
    text: "The TURN and STUN URL used are not valid. Please check your settings",
  },
  TC8: {
    code: "Missing STUN",
    level: "warning",
    text: "The STUN transport URL is not configured. Please check your settings.",
  },
  TC9: {
    code: "Missing TURN/UDP",
    level: "warning",
    text: "The TURN transport of type UDP is not configured. Please check your settings.",
  },
  TC10: {
    code: "Connection Timeout",
    level: "error",
    text: "Impossible to connect using the STUN transport(s) configured. Please check your settings.",
  },
  TC11: {
    code: "Connection Timeout",
    level: "error",
    text: "Impossible to connect using the TURN transport(s) configured. Please check your settings.",
  },
  TC12: {
    code: "Configuration Error",
    level: "error",
    text: "",
  },
  TC13: {
    code: "Firewall Class 2 Not Supported",
    level: "error",
    text: "",
  },
};

export const getErrorDetailsFromCode = (code, url, withText = "") => {
  if (!code in ICE_ERRORS) {
    return {
      code: `${code} - Unknown`,
      url,
      text: "Please contact the support team, this error has not been listed yet.",
    };
  }
  const error = Object.assign({}, ICE_ERRORS[code]);
  error.url = url;
  if (withText) {
    error.text = withText;
  }
  return error;
};
