import axios from "axios";
import { error, log } from "./logger";

const moduleName = "mod-github";

const extractRepoName = (path) => {
  if (!path.startsWith("https://github.com/")) {
    return null;
  }

  const repo = path.substring(19);
  if (!repo.includes("/")) {
    return null;
  }

  return repo;
};

export const getLastGithubRelease = async (openSourceUrl) => {
  try {
    const repo = extractRepoName(openSourceUrl);
    if (!repo) {
      return null;
    }

    const api = `https://api.github.com/repos/${repo}/releases`;

    const response = await axios.get(api, {});
    if (response.status >= 200 && response.status <= 400 && response.data) {
      log(moduleName, "Got answer from GitHub", {
        data: response.data.data,
      });

      const results = response.data;

      const releases = results.map((release) => ({
        name: release.name,
        created: release.created_at,
        published: release.published_at,
        url: release.url,
      }));

      return releases[0];
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, `can't GET releases from github`, {
      error: err,
    });
    return null;
  }
};
