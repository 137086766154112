import { useState, useContext, useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardHeader,
  Col,
  Button,
  ButtonGroup,
  Row
} from "reactstrap";
import { Bar } from "react-chartjs-2";
import { getCallTraffic } from "../actions/appActions";
import AppContext from "../contexts/appContext";
import { CHART_OPTIONS, getFromDateFromPeriod, PERIOD_TYPE } from "../modules/helper"

function CallLegsTraffic({ dispatch }) {
  const appState = useContext(AppContext);
  const [currentChart, setCurrentChart] = useState(PERIOD_TYPE.WEEK);
  const [traffic, setTraffic] = useState([]);

  useEffect(() => {
    getTraffic();
  }, [currentChart])

  useEffect(() => {
    setTraffic(appState.trafficCalls);
  }, [appState.trafficCalls])

  const getTraffic = () => {
    getCallTraffic(getFromDateFromPeriod(currentChart), appState.token, dispatch);
  }

  const updateCurrentChart = (name) => {
    setCurrentChart(name);
  };

  const charts = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    let gradientPurpleStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientPurpleStroke.addColorStop(1, "rgba(72,72,176,0.1)");
    gradientPurpleStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
    gradientPurpleStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

    return {
      labels: traffic.map(day => day._id),
      datasets: [
        {
          label: "ICE requests",
          fill: false,
          backgroundColor: "#fca311",
          hoverBackgroundColor: "#fca311",
          borderColor: "#fca311",
          borderWidth: 2,
          borderDash: [],
          type: "line",
          maxBarThickness: 50,
          borderDashOffset: 0.0,
          data: traffic.map(day => Math.max(day.requests, 0)),
        },
        {
          label: "Call Legs",
          fill: true,
          backgroundColor: "#e5e5e5",
          hoverBackgroundColor: "#e5e5e5",
          pointBackgroundColor: "#e5e5e5",
          borderColor: "#e5e5e5",
          borderWidth: 2,
          borderDash: [],
          maxBarThickness: 50,
          borderDashOffset: 0.0,
          pointRadius: 3,
          data: traffic.map(day => day.calls),
        },
      ],
    };
  };

  return (
    <Card className="card-chart">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="6">
            <h5 className="card-category">Number of call legs and ICE requests per day</h5>
            <CardTitle tag="h2">Daily interactions</CardTitle>
          </Col>
          <Col sm="6">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                color="info"
                id="0"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: currentChart === PERIOD_TYPE.WEEK,
                })}
                onClick={() => updateCurrentChart(PERIOD_TYPE.WEEK)}
              >
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Last week
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-single-02" />
                </span>
              </Button>
              <Button
                color="info"
                id="1"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: currentChart === PERIOD_TYPE.MONTH,
                })}
                onClick={() => updateCurrentChart(PERIOD_TYPE.MONTH)}
              >
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Last month
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-gift-2" />
                </span>
              </Button>
              <Button
                color="info"
                id="2"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: currentChart === PERIOD_TYPE.LAST_3_MONTHS,
                })}
                onClick={() => updateCurrentChart(PERIOD_TYPE.LAST_3_MONTHS)}
              >
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Last 3 months
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-tap-02" />
                </span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          <Bar
            data={charts}
            options={CHART_OPTIONS(getFromDateFromPeriod(currentChart))}
          />
        </div>
      </CardBody>
      <CardFooter>
      </CardFooter>
    </Card>
  );
}

export default CallLegsTraffic;
